<template>
  <div :class="{ 'comment-container': true, focus: textareaFocus }">
    <textarea
      v-if="!loading"
      v-model="commentText"
      class="comment-textarea"
      :placeholder="placeholderText"
      maxlength="500"
      @focus="onFocusTextarea"
      @blur="onBlurTextarea"
    ></textarea>
    <v-progress-circular v-else style="margin: auto; display: block; height: 80px" :size="30" color="#3d51fb" indeterminate></v-progress-circular>
    <div v-if="!loading" class="limit">{{ commentText.length }}/500 Characters</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    selectedId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      textareaFocus: false,
      commentText: '',
      loading: false,
    }
  },
  created() {
    window.addEventListener('keydown', this.onKeyDown)
  },
  destroyed() {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  computed: {
    placeholderText() {
      return this.selectedId === -1 ? 'Start a new discussion' : 'Reply'
    },
  },
  methods: {
    ...mapActions('superadmin', ['getDiscussionListAction', 'createDiscussionAction', 'createDiscussionReplyAction']),
    onFocusTextarea() {
      this.textareaFocus = true
    },
    onBlurTextarea() {
      this.textareaFocus = false
    },
    async onKeyDown(event) {
      if (!event.altKey && event.keyCode === 13 && !this.loading) {
        this.loading = true
        let createResult
        if (this.selectedId === -1) createResult = await this.createDiscussionAction(this.commentText)
        else createResult = await this.createDiscussionReplyAction({ id: this.selectedId, content: this.commentText })
        if (createResult === true) await this.getDiscussionListAction()
        this.commentText = ''
        this.loading = false
      } else if (event.altKey && event.keyCode === 13 && !this.loading) {
        this.commentText = this.commentText + '\n'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.comment-container {
  width: 472px;
  position: relative;
  flex-basis: 80px;
  min-height: 80px;
  margin: 5px 0px 24px 0px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  &.focus {
    border: 1px solid #3b77ff;
  }
  .comment-textarea {
    height: 58px;
    width: 100%;
    resize: none;
    padding: 8px 16px 14px 16px;
    &:focus {
      outline: none;
    }
  }
  .comment-loading {
    height: 80px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    padding: 8px 16px 0 16px;
  }
  .limit {
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.38);
    right: 16px;
    bottom: 8px;
  }
}
</style>
