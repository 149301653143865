<template>
  <div ref="container" @click="onClickOpen" v-click-outside="close" :class="{ dropdown: true, open, select: !!dateRange, 'error-status': errorFlag }">
    <img v-if="errorFlag" src="@/assets/Icon material-date-range-error.svg" height="14px" />
    <img v-else-if="open" src="@/assets/Icon material-date-range-active.svg" height="14px" />
    <img v-else-if="!dateRange" src="@/assets/Icon material-date-range-inactive.svg" height="14px" />
    <img v-else src="@/assets/Icon material-date-range-black.svg" height="14px" />
    <div class="text-truncate" style="margin-left: 6.2px">{{ dateRange ? dateRange : placeholder }}</div>
    <Calendar v-if="open" :minDate="minDate" :maxDate="maxDate" @update="onUpdateDate" :style="calendarStyle"></Calendar>
    <div class="error-status-text" :style="errorMessageStyle">{{ errorMessage }}</div>
  </div>
</template>

<script>
import Calendar from '@common/Calendar'
import moment from 'moment'

export default {
  components: { Calendar },
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Contract Period',
    },
    error: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      open: false,
      calendarRect: null,
      errorFlag: false,
      errorMessage: '',
      minDate: moment().startOf('day').subtract(1000, 'y'),
      maxDate: moment().startOf('day').add(1000, 'y'),
    }
  },
  computed: {
    dateRange() {
      return this.value ? this.value[0] + '~' + this.value[1] : ''
    },
    calendarStyle() {
      if (this.calendarRect) {
        return `position: fixed; top: ${this.calendarRect.top - 327}px; left: ${this.calendarRect.left}px;`
      }
      return ''
    },
    errorMessageStyle() {
      return this.errorMessage ? 'visibility: visible' : 'visibility: hidden'
    },
  },
  watch: {
    error(val) {
      this.errorFlag = val
    },
  },
  methods: {
    validate(range) {
      if (this.rules.length) {
        for (let i = 0; i < this.rules.length; i++) {
          const error = this.rules[i](range ? range : this.value)
          if (!error || typeof error == 'string') {
            this.errorFlag = true
            this.errorMessage = error
            break
          } else {
            this.errorFlag = false
            this.errorMessage = ''
          }
        }
      }
      return !this.errorFlag
    },
    close() {
      this.$log.debug('ContractPeriod#close')
      this.open = false
    },
    onClickOpen() {
      this.$log.debug('ContractPeriod#onClickOpen', this.block, this.open)
      if (!this.open) {
        this.open = true
      }
      if (this.open) {
        this.calendarRect = this.$refs.container.getBoundingClientRect()
      }
    },
    onUpdateDate(range) {
      this.$emit('input', range)
      setTimeout(() => {
        this.validate()
        this.close()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  color: #00000061;
  font-size: 14px;
  line-height: 19px;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.12);
  padding: 0 10px 7px 8px;
  cursor: pointer;

  &.open {
    border-bottom: 1.5px solid #3b77ff;
    color: #363636;
  }

  &.select {
    color: #363636;
  }

  &.error-status {
    color: #ff3b30;
    border-bottom: 1px solid #ff3b30;
  }
}

.error-status-text {
  position: absolute;
  top: 31.5px;
  left: 0;
  font-size: 10px;
  font-weight: 400;
  padding-left: 8px;
  height: 11px;
  line-height: 11px;
  color: #ff3b30;
  white-space: nowrap;
}
</style>
