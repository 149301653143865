<template>
  <audio ref="workspaceAudio" src="@/assets/App Mallet Notification.mp3" preload loop>Your browser does not support the audio element.</audio>
</template>

<script>
import { mapState } from 'vuex'
import { workspace } from '@/api'

export default {
  data() {
    return {
      soundTimerId: null,
      intervalSoundId: null,
      ALERT_TIME: 10000,
      ALERT_INTERVAL_TIME: 120000,
    }
  },
  computed: {
    ...mapState({
      adminSoundSetting: (state) => state.adminSoundSetting,
      monitoringLogs: (state) => state.superadmin.monitoringLogs,
    }),
  },
  destroyed() {
    this.stopAlertSound()
    clearTimeout(this.soundTimerId)
    this.stopRemainingLogChecking()
  },
  methods: {
    playAlertSound() {
      if (!this.adminSoundSetting) return
      this.$refs.workspaceAudio.play()
      clearTimeout(this.soundTimerId)
      this.soundTimerId = setTimeout(() => {
        this.stopAlertSound()
      }, this.ALERT_TIME)
    },
    playAlertAndCheckRemainingLog() {
      if (!this.adminSoundSetting) return
      this.playAlertSound()
      this.checkRemainingLog()
    },
    stopAlertSound() {
      if (this.$refs.workspaceAudio) this.$refs.workspaceAudio.pause()
    },
    startAlert() {
      this.playAlertSound()
      this.checkRemainingLog()
    },
    checkRemainingLog() {
      this.stopRemainingLogChecking()
      this.intervalSoundId = setInterval(async () => {
        const { data } = await workspace.getAlertsBox()
        if (data.logs?.length && this.monitoringLogs.length !== 0) this.playAlertSound()
        else this.stopRemainingLogChecking()
      }, this.ALERT_INTERVAL_TIME)
    },
    stopRemainingLogChecking() {
      clearInterval(this.intervalSoundId)
    },
  },
}
</script>
