<template>
  <div class="upload-content-container">
    <div class="content-top">
      <div class="content-top-text">Don't have the template? Click Here*</div>
      <!-- <v-btn class="btn-download-template" color="#8D90A2" depressed rounded :href="templateFilePath">
        <img :src="downloadIcon" height="16" alt="downloadIcon" style="margin-right: 10px" />
        <span>Download Template</span>
      </v-btn> -->
      <v-btn class="btn-download-template" color="#8D90A2" depressed rounded @click="onClickDownloadTemplate()">
        <img :src="downloadIcon" height="16" alt="downloadIcon" style="margin-right: 10px" />
        <span>Download Template</span>
      </v-btn>
    </div>
    <div
      :class="draggableClassEnabled ? 'draggable-enabled' : ''"
      class="content-bottom"
      @dragenter="draggableClassEnabled = true"
      @dragleave="draggableClassEnabled = false"
      @drop="handleDrop($event)"
      @mouseenter="hideFileInputArea = true"
      @mouseleave="
        hideFileInputArea = false
        draggableClassEnabled = false
      "
    >
      <input
        v-show="!hideFileInputArea"
        id="fileUploadInputDOM"
        type="file"
        accept=".xlsx"
        class="file-input-area"
        @input="onChangeFileInput($event)"
      />
      <div v-if="!selectedFile" class="content-bottom-subcontainer">
        <div class="file-upload-area-card">Drag & Drop</div>
        <div class="file-upload-area-text-top">
          a file here or <span class="file-upload-area-text-clickable" @click="onClickBrowse()">browse</span>
        </div>
        <ul class="file-upload-area-text-bottom">
          <li>.xlsx, .csv 형식의 파일만 업로드됩니다.</li>
          <li>200mb 미만의 파일만 업로드됩니다.</li>
        </ul>
      </div>
      <div v-else class="content-bottom-subcontainer">
        <div class="remove-file-btn" @click="onClickRemoveFileBtn()">X</div>
        <div class="selected-file-info-card">
          <div class="selected-file-info-file-name-text">
            {{ selectedFile.name }}
          </div>
          <div class="file-reupload-btn" @click="onClickBrowse()">Re-Upload</div>
          <div v-if="maxFileSizeExceeded" class="selected-file-info-error-text">Exceed maximum file size</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadStageContent',
  props: {
    selectedFileProp: {
      type: File,
      required: false,
    },
  },
  data() {
    return {
      // assets
      downloadIcon: require('@/assets/Download_Filled_24px_Icon.svg'),
      // templateFilePath: 'Camera_List_Template.xlsx',
      // file data variables
      selectedFile: null,
      hideFileInputArea: false,
      // misc
      draggableClassEnabled: false,
      validFileExtensionList: ['xlsx'],
    }
  },
  mounted() {
    this.selectedFile = this.selectedFileProp
  },
  computed: {
    maxFileSizeExceeded() {
      return this.selectedFile.size > 10 * 1024 * 1024
    },
  },
  methods: {
    onClickDownloadTemplate() {
      this.$store.dispatch('superadmin/downloadCameraTemplateExcelFile')
    },
    onClickBrowse() {
      document.getElementById('fileUploadInputDOM').click()
    },
    onClickRemoveFileBtn() {
      this.selectedFile = null
      document.getElementById('fileUploadInputDOM').value = ''
      this.$emit('file-selected', null)
    },
    onChangeFileInput(evt) {
      const file = evt.target.files[0]
      if (file) {
        this.$log.debug('file', file)
        this.selectedFile = file
      }
      this.$emit('file-selected', !!file ? file : null)
    },
    handleDrop(evt) {
      evt.stopPropagation()
      evt.preventDefault()
      const file = evt.dataTransfer.files[0]
      const fileNameExtension = file?.name.slice(file.name.length - 4, file.name.length) || ''
      if (file && this.validFileExtensionList.includes(fileNameExtension)) {
        this.$log.debug('file', file)
        this.selectedFile = file
      }
      this.draggableClassEnabled = false
      this.$emit('file-selected', !!file ? file : null)
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-content-container {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .content-top {
    display: flex;
    height: 34px;
    align-items: center;
    margin-bottom: 30px;

    .content-top-text {
      color: #363636;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0;
      margin-right: 16px;
    }

    .btn-download-template {
      height: 34px;
      width: 197px;
      font-weight: 500;
      font-size: 15px;
      color: #ffffff;
      letter-spacing: 0;
    }
  }

  .content-bottom {
    min-height: 209px;
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // flex-grow: 1;
    width: 100%;
    border: 1px dashed #d9d9d9;
    border-radius: 15px;

    &.draggable-enabled {
      opacity: 0.3;
    }

    .content-bottom-subcontainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .file-upload-area-card {
        height: 40px;
        width: 143px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f4f7ff;
        border-radius: 100px;
        font-weight: 700;
        font-size: 16px;
        color: #363636;
        margin-bottom: 12px;
      }

      .file-upload-area-text-top {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0;
        color: #363636;
        margin-bottom: 20px;
      }

      .file-upload-area-text-clickable {
        cursor: pointer;
        color: #6d7dff;
        text-decoration-line: underline;
      }

      .file-upload-area-text-bottom {
        font-weight: 500;
        font-size: 12px;
        color: #8d90a2;
        letter-spacing: 0;
      }

      .remove-file-btn {
        position: absolute;
        top: 10px;
        right: 16px;
        cursor: pointer;
      }

      .selected-file-info-card {
        display: flex;
        flex-direction: column;
        align-items: center;

        .selected-file-info-file-name-text {
          font-weight: 700;
          font-size: 24px;
          color: #6d7dff;
          margin-bottom: 26px;
        }

        .file-reupload-btn {
          height: 58px;
          width: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f4f7ff;
          font-weight: 400;
          font-size: 15px;
          color: #6d7dff;
          border-radius: 10px;
          cursor: pointer;
          margin-bottom: 4px;
        }

        .selected-file-info-error-text {
          font-weight: 400;
          font-size: 15px;
          color: #ff3b30;
        }
      }
    }
  }
}

.file-input-area {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
</style>
