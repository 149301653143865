<template>
  <header class="background-toolbar">
    <div @click="onClickHome" class="logo-container">
      <img src="@/assets/FireScoutLogo_color.svg" width="165" class="icon-logo" />
      <div class="text-subtitle">{{ organization }}</div>
    </div>
    <div class="spacer-1"></div>
    <SystemStatus class="status" />
    <div @click="onClickTab(0)" :class="{ tab: true, select: tabIndex === 0 }">
      Monitor
      <div v-if="tabIndex === 0" class="indicator"></div>
    </div>
    <div @click="onClickTab(1)" :class="{ tab: true, select: tabIndex === 1 }">
      Confirmed
      <div v-if="tabIndex === 1" class="indicator"></div>
    </div>
    <div @click="onClickTab(2)" :class="{ tab: true, select: tabIndex === 2 }">
      Skipped
      <div v-if="tabIndex === 2" class="indicator"></div>
    </div>
    <div @click="onClickTab(3)" :class="{ tab: true, select: tabIndex === 3 }">
      Skipped<br />(Hard Case)
      <div v-if="tabIndex === 3" class="indicator"></div>
    </div>
    <div v-if="user.isAdmin" @click="onClickTab(4)" :class="{ tab: true, select: tabIndex === 4 }">
      Settings
      <div v-if="tabIndex === 4" class="indicator"></div>
    </div>
    <button @click="onClickLogout" class="logout">Logout</button>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import Constants from '@/constants'
import SystemStatus from '@desktop/superadminOrWorker/workspace/SystemStatus'

export default {
  components: { SystemStatus },
  props: {
    setting: Boolean,
    tabIndex: Number,
  },
  data() {
    return {
      Constants,
      searchInput: '',
    }
  },
  /**
   * emit events
   * select Number
   * setting Boolean
   * home
   */
  watch: {
    workspaceTabIndex(newIndex, currIndex) {
      if (newIndex === 0 && newIndex !== currIndex) {
        this.$router.push('/workspace')
      }
    },
  },
  computed: {
    ...mapState(['user', 'workspaceTabIndex']),
    organization() {
      return this.user?.user_name
    },
    languageText() {
      let lang
      if (this.$vuetify.lang.current === 'ko') {
        lang = 'ENG'
      } else {
        lang = 'KOR'
      }
      return lang
    },
  },
  methods: {
    onChangeLanguage() {
      if (this.$vuetify.lang.current === 'en') {
        this.$vuetify.lang.current = 'ko'
      } else {
        this.$vuetify.lang.current = 'en'
      }
    },
    async onClickLogout() {
      this.$store.dispatch('logout')
    },
    onClickHome() {
      this.$emit('home')
    },
    onClickTab(tabIndex) {
      this.$store.dispatch('setWorkspaceTabIndexAction', tabIndex)
      switch (tabIndex) {
        case 0:
          if (this.$route.path !== '/workspace') {
            this.$router.push('/workspace')
          }
          break
        case 1:
          if (this.$route.path !== `/workspace/${Constants.CONFIRMED}`) {
            this.$router.push(`/workspace/${Constants.CONFIRMED}`)
          }
          break
        case 2:
          if (this.$route.path !== `/workspace/${Constants.SKIPPED}`) {
            this.$router.push(`/workspace/${Constants.SKIPPED}`)
          }
          break
        case 3:
          if (this.$route.path !== `/workspace/${Constants.SKIPPED_HARD}`) {
            this.$router.push(`/workspace/${Constants.SKIPPED_HARD}`)
          }
          break
        case 4:
          if (!this.$route.path.includes(`/workspace/${Constants.SETTINGS}/organization`)) {
            this.$router.push(`/workspace/${Constants.SETTINGS}/organization`)
          }
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.background-toolbar {
  position: fixed;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 74px;
  background-color: var(--v-background-base);
  z-index: 2;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #f3f3f9;

  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-left: 30px;
    justify-content: center;
  }
}

.text-title {
  color: var(--v-textDefault);
  font-weight: 500;
  font-size: 18px;
}

.text-subtitle {
  font-weight: 500;
  font-size: 14px;
  color: #3d51fb;
  margin-top: 1px;
}

.lang-text {
  height: 70px;
  margin-right: 15px;
  font-weight: bold !important;
  font-size: 20px !important;
  color: white !important;
}

.search-container {
  display: flex;
  align-items: center;
  width: 340px;
  height: 40px;
  margin-left: 50px;
  background-color: var(--v-backgroundSearch-base);
  border: 1px solid #d3d5dd;
  border-radius: 30px;
  font-size: 18px;
  color: #43425d;

  &.dark {
    border-width: 0;
    &.text-exist {
      border: 1px solid #3d51fb;
    }
  }

  input {
    margin-top: 7px;
  }

  .v-icon.text-exist {
    color: #3d51fb !important;
  }
}

.hello {
  font-weight: 500;
  font-size: 14px;
  color: #8d90a2;
  margin-right: 15px;

  & .name {
    color: #6d7dff;
  }
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 120px;
  height: 100%;
  font-weight: 500;
  font-size: 15px;
  color: #afb6c3;
  cursor: pointer;

  &.select {
    color: var(--v-textDefault);
  }

  &:active {
    opacity: 0.5;
  }

  .indicator {
    position: absolute;
    bottom: 0;
    width: 120px;
    height: 5px;
    background-color: #3d51fb;
    border-radius: 20px 20px 0px 0px;
  }
}

button {
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #3d51fb;
  border-radius: 30px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    margin-right: 10px;
  }

  &:active {
    opacity: 0.5;
  }

  &.monitor {
    color: #ffffffde;
    background-color: #3d51fb;
    margin-right: 15px;

    &.disabled {
      border-width: 0;
      color: var(--v-textDisabledButton);
      background-color: var(--v-backgroundDisabledButton);
    }
  }

  &.logout {
    color: var(--v-textDefault);
    margin-left: 50px;
    margin-right: 30px;
    border-width: 0px;
    min-width: 0px;
  }
}

.status {
  margin-right: 26px;
}
</style>
