<template>
  <div>
    <div class="input-container">
      <input
        ref="input"
        :type="type"
        :placeholder="placeholder"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
        @keydown.enter="onEnter"
        :class="{ empty, disabled, 'error-status': errorFlag }"
        :disabled="disabled"
        :readonly="readonly"
        maxlength="50"
      />
      <img v-if="appendIcon" @click="onClickIcon" :src="appendIcon" :style="appendIconStyle" />
    </div>
    <div :class="{ 'bottom-line': true, focus, disabled, error: errorFlag }"></div>
    <div class="status-text" :style="statusTextStyle">
      <div class="status-error" :style="errorMessageStyle">{{ errorMessage }}</div>
      <div :class="{ 'status-info': true, unfocus: !focus }" :style="infoMessageStyle">{{ infoMessage }}</div>
    </div>
  </div>
</template>

<script>
export default {
  /**
   * emits
   * icon
   * focus
   * enter
   */
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    transform: {
      type: Function,
      default: (v) => v,
    },
    width: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    revertErrorMessage: {
      type: Boolean,
      default: false,
    },
    infoMessage: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    appendIcon: {
      type: String,
      default: '',
    },
    appendIconStyle: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
  },
  data() {
    return {
      focus: false,
      text: this.value,
      errorFlag: this.error,
      errorMessage: '',
    }
  },
  computed: {
    empty() {
      return !this.text || !this.text.length
    },
    errorMessageStyle() {
      return this.errorMessage ? 'visibility: visible' : 'visibility: hidden'
    },
    infoMessageStyle() {
      return !this.errorMessage ? 'visibility: visible' : 'visibility: hidden'
    },
    containerStyle() {
      return this.width ? `width: ${this.width}` : ''
    },
    statusTextStyle() {
      if (this.revertErrorMessage) {
        return `position: absolute; text-align: right; width: 300px; transform: translateX(calc(-300px + ${this.width});`
      }
      return ''
    },
  },
  watch: {
    value(val) {
      this.text = val
      this.$refs.input.value = val
    },
    error(val) {
      this.errorFlag = val
    },
  },
  methods: {
    validate(text, justCheck) {
      let errorCheck = false
      if (this.rules.length) {
        for (let i = 0; i < this.rules.length; i++) {
          const error = this.rules[i](text ? text : this.text)
          if (!error || typeof error == 'string') {
            if (justCheck) {
              errorCheck = true
            } else {
              this.errorFlag = true
              this.errorMessage = error
            }
            break
          } else {
            if (!justCheck) {
              this.errorFlag = false
              this.errorMessage = ''
            }
          }
        }
      }
      return justCheck ? !errorCheck : !this.errorFlag
    },
    onInput(e) {
      this.text = e.target.value
      this.text = this.transform(this.text)
      e.target.value = this.text
      this.validate()
      this.$emit('input', this.text)
    },
    onEnter() {
      this.$emit('enter')
    },
    onBlur() {
      this.focus = false
      this.$emit('focus', this.focus)
    },
    onFocus() {
      this.focus = true
      this.$emit('focus', this.focus)
    },
    onClickIcon() {
      this.$emit('icon')
      setTimeout(() => {
        this.$refs.input.focus()
        this.$refs.input.setSelectionRange(this.text.length, this.text.length)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  width: 100%;

  img {
    cursor: pointer;
  }
}

input {
  flex-grow: 1;
  outline: none;
  padding: 0 8px;
  width: 100%;
  font-size: 14px;
  line-height: 19px;
  caret-color: #3b77ff;
  color: var(--v-textDefault);

  &::placeholder {
    color: #00000061;
  }

  &.empty {
    color: #ffffff99;
  }

  &.disabled {
    color: #ffffff61;
  }

  &.error-status {
    color: #ff3b30;
    caret-color: #ff3b30;
  }

  &:read-only:disabled {
    color: #00000042;
  }
}

.status-text {
  position: relative;
  margin-top: 5px;
  font-size: 10px;
  font-weight: 400;
  padding-left: 8px;
  height: 11px;
  line-height: 11px;

  .status-error {
    color: #ff3b30;
  }

  .status-info {
    position: absolute;
    top: 0;
    color: #3b77ff;

    &.unfocus {
      color: #00000061;
    }
  }
}

.bottom-line {
  width: 100%;
  margin-top: 4.5px;
  border-bottom: 1.5px solid #0000001f;

  &.focus {
    border-bottom: 1px solid #3b77ff;
  }

  &.disabled {
    border-bottom: 1px dashed #ffffff61;
  }

  &.error {
    border-bottom: 1px solid #ff3b30;
  }

  &:read-only.disabled {
    border-bottom: 1px dashed #0000001f;
  }
}
</style>
