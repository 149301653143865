<template>
  <section :class="`status-container`">
    <span :class="`status-text`"> System Status </span>
    <div :class="`status-elipse ${isError ? 'status-error' : 'normal'}`"></div>
  </section>
</template>

<script>
import Constants from '@/constants'
import { workspace } from '@/api'

export default {
  data() {
    return {
      isError: false,
      intervalTimerId: null,
    }
  },
  async created() {
    await this.checkServerStatus()
  },
  destroyed() {
    clearTimeout(this.intervalTimerId)
  },
  methods: {
    checkServerStatus() {
      clearTimeout(this.intervalTimerId)
      this.intervalTimerId = setTimeout(async () => {
        try {
          await workspace.getServerStatus()
          this.isError = false
        } catch (error) {
          this.isError = true
        }
        this.checkServerStatus()
      }, Constants.INTERVAL_SITE_LOG)
    },
  },
}
</script>

<style lang="scss" scoped>
.status-container {
  background: #f9f9f9;
  border-radius: 15px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  width: 154px;
}

.status-text {
  color: #000000de;
  font-weight: 500;
  font-size: 15px;
  margin-right: 15px;
  line-height: 18px;
  color: var(--f-text-black);
}

.status-elipse {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  &.normal {
    background-color: #64d280;
  }
  &.status-error {
    background-color: #ff6a6a;
  }
}
</style>
