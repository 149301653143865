import { fabric } from 'fabric'

export default class Box {
  constructor(object) {
    this.x = object.x || 0
    this.y = object.y || 0
    this.width = object.width || 0
    this.height = object.height || 0
    this.selectable = object.selectable
    this.fabricObject = null
    this.visible = true
    this.logId = object.logId || 0
  }

  draw(canvas) {
    if (!this.fabricObject && this.width > 0 && this.height > 0) {
      this.fabricObject = new fabric.Rect({
        width: this.width,
        height: this.height,
        top: this.y,
        left: this.x,
        rx: 3,
        ry: 3,
        stroke: '#F9423A',
        fill: '',
        strokeWidth: 3,
        strokeUniform: true,
        hasRotatingPoint: false,
        hoverCursor: 'pointer',
        selectable: this.selectable,
      })
      canvas.add(this.fabricObject)
      this.fabricObject.model = this
    }
  }

  setVisible(isVisible, shapes) {
    if (shapes) {
      const index = shapes.indexOf(this)
      if (index !== -1) {
        this.visible = isVisible
        this.fabricObject.set('visible', isVisible)
      }
    }
  }

  remove(canvas, shapes) {
    if (shapes) {
      const index = shapes.indexOf(this)
      if (index !== -1) {
        shapes.splice(index, 1)
      }
    }
    if (this.fabricObject) {
      canvas.remove(this.fabricObject)
    }
  }

  shapedUpdate() {
    this.width = this.fabricObject.width * this.fabricObject.scaleX
    this.height = this.fabricObject.height * this.fabricObject.scaleY
    this.x = this.fabricObject.left
    this.y = this.fabricObject.top
  }
}
