<template>
  <div class="reply-wrapper">
    <div class="reply-info">
      <div style="display: flex; align-items: center; margin-bottom: 8px">
        <span class="reply-owner name">{{ replyOwnerName }}</span
        ><span class="time">{{ replyTime }}</span>
      </div>
      <div class="content">{{ replyContent }}</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    reply: {
      type: Object,
      default: {},
    },
  },
  computed: {
    replyOwnerName() {
      return this.reply.user.username
    },
    replyTime() {
      return moment(this.reply.created_at).fromNow()
    },
    replyContent() {
      return this.reply.content
    },
  },
}
</script>

<style lang="scss" scoped>
.time {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.reply-wrapper {
  width: 448px;
  padding: 16px 24px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), rgba(233, 235, 255, 0.24);
  border-radius: 15px;
  margin: 0 0 8px 20px;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-right: 8px;
}

.content {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}
</style>
