<template>
  <table>
    <colgroup>
      <col />
      <col v-for="(header, index) in headers" :key="index" span="1" :style="header.width ? `width: ${header.width}` : ''" />
    </colgroup>
    <thead>
      <tr>
        <th v-if="!checkHide" scope="colgroup" style="width: 60px">
          <img
            v-if="items.length && checkAll && items.length !== select.length"
            @click="onClickCheckAll"
            src="@/assets/Checkbox_Unchecked_Grey_Icon.svg"
            width="15px"
            class="checkbox header"
          />
          <img
            v-else-if="items.length && checkAll"
            @click="onClickCheckAll"
            src="@/assets/Checkbox_Checked_Icon.svg"
            width="15px"
            class="checkbox header"
          />
        </th>
        <th v-else style="width: 30px"></th>
        <th
          scope="colgroup"
          :width="header.width ? header.width : headerWidth"
          v-for="(header, index) in headers"
          :key="index"
          class="text-truncate"
          style="overflow: visible !important"
        >
          <div :class="{ 'header-contents': true, 'align-center': index > 4 }">
            <div
              :class="header.key === 'organizations' ? '' : 'header-text'"
              @mouseenter="hoverHeaderIndex = index"
              @mouseleave="hoverHeaderIndex = -1"
              @click="onClickHeader(index)"
            >
              {{ header.name }}
            </div>
            <img
              v-if="isSortableHeader(index) && index === selectedHeaderIndex && sortDirection === 'increasing'"
              src="@/assets/Sort-Up_16px_Icon.svg"
              width="16"
              class="sort-icon"
              :style="showSortIconClass(index) ? 'opacity: 1 !important' : ''"
              @click="onClickHeader(index)"
            />
            <img
              v-else-if="isSortableHeader(index) && index === selectedHeaderIndex && sortDirection === 'decreasing'"
              src="@/assets/Sort-Down_16px_Icon.svg"
              width="16"
              class="sort-icon"
              :style="showSortIconClass(index) ? 'opacity: 1 !important' : ''"
              @click="onClickHeader(index)"
            />
            <img
              v-else-if="isSortableHeader(index)"
              src="@/assets/Sort-Both_16px_Icon.svg"
              width="16"
              class="sort-icon"
              :style="showSortIconClass(index) ? 'opacity: 1 !important' : ''"
              @click="onClickHeader(index)"
            />
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in sortedItems" :key="item[itemKey]">
        <td v-if="!checkHide" style="text-align: center; display: flex; align-items: center; justify-content: center; height: 44px">
          <img
            v-if="!selectMap[item[itemKey]]"
            @click="onClickCheck(item)"
            src="@/assets/Checkbox_Unchecked_Grey_Icon.svg"
            width="15px"
            height="15px"
            class="checkbox"
          />
          <img v-else @click="onClickCheck(item)" src="@/assets/Checkbox_Checked_Icon.svg" width="15px" class="checkbox" />
        </td>
        <td v-else></td>
        <td v-for="(header, headerIndex) in headers" :key="headerIndex" :align="header.align ? header.align : 'left'">
          <img v-if="header.icon && item[header.key]" @click="onClickIcon(header.key, item)" :src="header.iconTrue" class="icon" />
          <img v-else-if="header.icon" :src="header.iconFalse" class="icon" />
          <div v-else-if="header.capitalize" style="display: flex; overflow: auto">
            <div
              v-for="(text, index) of item[header.key]"
              :key="index"
              class="capitalize"
              @mouseenter="onColumnMouseOver(item, header, index, $event)"
              @mouseleave="onColumnMouseOut"
              :style="`background-color: ${organizationsColorMap[text]};`"
            >
              {{ getCapitalize(text) }}
            </div>
          </div>
          <div v-else-if="header.clickable" @click="onClickColumn(item[header.valueKey], header.key)" class="text-truncate text-truncate-clickable">
            {{ item[header.key] }}
          </div>
          <div
            v-else
            @mouseenter="onColumnMouseOver(item, header, index, $event)"
            @mouseleave="onColumnMouseOut"
            :class="{ 'text-truncate': true, center: headerIndex > 4 }"
          >
            {{ item[header.key] }}
          </div>
        </td>
      </tr>
    </tbody>
    <div v-if="tooltipItem" class="tooltip text-truncate" :style="tooltipStyle">
      {{
        tooltipItem.item[tooltipItem.header.key] instanceof Array
          ? tooltipItem.item[tooltipItem.header.key][tooltipItem.index]
          : tooltipItem.item[tooltipItem.header.key]
      }}
    </div>
  </table>
</template>

<script>
import Util from '@/util'
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    checkAll: {
      type: Boolean,
      default: false,
    },
    checkHide: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: '',
    },
    /**
     * headers format
     * [{
     *  name: string,
     *  key: string,
     *  width: string,
     *  align: string,
     *  icon: boolean,
     *  iconTrue: string,
     *  iconFalse: string,
     * }]
     */
    headers: {
      type: Array,
      default: () => [],
    },
    selectOnlyOneItem: {
      type: Boolean,
      default: false,
    },
    tooltipItem: null,
  },
  data() {
    return {
      select: [],
      selectMap: {},
      tooltipTimeout: null,
      selectedHeaderIndex: -1,
      sortDirection: 'none',
      hoverHeaderIndex: -1,
    }
  },
  watch: {
    value(val) {
      this.select = val
      if (!val.length) {
        this.selectClearAll()
      }
    },
    headers(val) {
      if (val && val.length) {
        this.selectedHeaderIndex = 0
        this.sortDirection = 'increasing'
      }
    },
  },
  computed: {
    ...mapState('superadmin', {
      organizationsColorMap: (state) => state.organizationsColorMap,
    }),
    sortedItems() {
      if (this.items.length && this.headers.length && this.selectedHeaderIndex !== -1) {
        const result = this.items.sort((a, b) => {
          const compareAfter = a[this.headers[this.selectedHeaderIndex].key] !== '-' ? a[this.headers[this.selectedHeaderIndex].key] : 0
          const compareBefore = b[this.headers[this.selectedHeaderIndex].key] !== '-' ? b[this.headers[this.selectedHeaderIndex].key] : 0
          if (Number.isInteger(compareAfter) && Number.isInteger(compareBefore)) {
            return compareAfter - compareBefore
          } else {
            return compareAfter.localeCompare(compareBefore)
          }
        })
        return this.sortDirection === 'increasing' ? result : result.reverse()
      }
      return this.items.length && this.headers.length
        ? this.items.sort((a, b) => a[this.headers[0].key].localeCompare(b[this.headers[0].key]))
        : this.items
    },
    headerWidth() {
      return 100 / this.headers.filter((header) => !header.width).length + '%'
    },
    tooltipStyle() {
      if (this.tooltipItem) {
        let topPadding
        if (this.tooltipItem.element.children[0]) {
          topPadding = -24
        } else {
          topPadding = -35
        }
        const leftPadding = 20
        const rect = this.tooltipItem.element.getBoundingClientRect()
        const top = rect.top + topPadding
        let left = rect.left + leftPadding
        const tooltipWidth = this.tooltipItem.element.scrollWidth
        const tooltipRight = rect.left + (leftPadding + 25) + tooltipWidth
        const documentRight = document.documentElement.clientWidth
        if (documentRight < tooltipRight) {
          left -= tooltipRight - documentRight + leftPadding
        }
        return `top: ${top}px; left: ${left}px`
      }
      return ''
    },
  },
  beforeDestroy() {
    clearTimeout(this.tooltipTimeout)
  },
  methods: {
    isSortableHeader(index) {
      return this.headers && this.headers[index].name !== '' && this.headers[index].key !== 'organizations'
    },
    showSortIconClass(index) {
      return (index === this.selectedHeaderIndex && this.sortDirection !== 'none') || index === this.hoverHeaderIndex
    },
    onClickHeader(index) {
      if (this.headers[index].key === 'organizations') return
      if (this.selectedHeaderIndex !== index) {
        this.selectedHeaderIndex = index
        this.sortDirection = 'increasing'
      } else {
        switch (this.sortDirection) {
          case 'none':
            this.sortDirection = 'increasing'
            break
          case 'increasing':
            this.sortDirection = 'decreasing'
            break
          case 'decreasing':
            this.sortDirection = 'none'
            this.selectedHeaderIndex = -1
            break
        }
      }
    },
    onClickColumn(value, key) {
      this.$emit('click-column', { value, key })
    },
    selectClearAll() {
      this.select = []
      this.selectMap = {}
    },
    onClickCheckAll() {
      if (this.selectOnlyOneItem) return
      if (this.select.length && this.select.length === this.items.length) {
        this.select = []
        this.selectMap = {}
      } else {
        this.select = [...this.items]
        for (let item of this.select) {
          this.selectMap[item[this.itemKey]] = item
        }
      }
      this.$emit('input', this.select)
      this.$emit('change', this.select)
    },
    onClickCheck(item) {
      if (this.select.length === 1 && this.selectOnlyOneItem && item.id !== this.select[0].id) return
      const key = item[this.itemKey]
      if (this.selectMap[key]) {
        delete this.selectMap[key]
        const index = this.select.findIndex((v) => v[this.itemKey] === key)
        if (index !== -1) {
          this.select.splice(index, 1)
        }
      } else {
        this.select.push(item)
        this.selectMap[key] = item
      }
      this.selectMap = { ...this.selectMap }
      this.$emit('input', this.select)
      this.$emit('change', this.select)
    },
    onClickIcon(key, item) {
      this.$emit('icon', { key, item })
    },
    onColumnMouseOver(item, header, index, e) {
      const isEllipsis = Util.isEllipsis(e.target)
      if (isEllipsis || header.capitalize) {
        clearTimeout(this.tooltipTimeout)
        this.tooltipItem = { element: e.target, item, header, index }
        e.stopPropagation()
      }
    },
    onColumnMouseOut() {
      clearTimeout(this.tooltipTimeout)
      this.tooltipTimeout = setTimeout(() => {
        this.tooltipItem = null
      }, 100)
    },
    getCapitalize(text) {
      return !!text && text.length ? text.slice(0, 1).toUpperCase() : ''
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  user-select: text !important;
}

table {
  table-layout: fixed;
  width: 100%;
  background-color: white;
  border-collapse: collapse;

  thead {
    position: sticky;
    top: 0;
    background-color: #e9ebff;
    color: #6d7dff;
    font-size: 16px;
    line-height: 22px;
    text-align: left;

    tr {
      height: 46px;

      th {
        font-weight: 600;

        &:not(:nth-child(2)) {
          padding: 0 20px;
        }
      }
    }
  }

  tbody {
    font-size: 14px;
    line-height: 19px;
    color: black;

    tr {
      height: 44px;
      border-bottom: 1px solid #f9f9f9;

      td:not(:nth-child(2)) {
        padding: 0 20px;
      }

      .capitalize {
        height: 24px;
        min-height: 24px;
        width: 30px;
        min-width: 30px;
        border-radius: 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: white;
        text-align: center;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .checkbox {
    cursor: pointer;
    &.header {
      display: flex;
      margin: 0 auto;
    }
  }

  .text-truncate {
    &.center {
      text-align: center;
    }
  }

  .text-truncate-clickable {
    cursor: pointer;
  }

  .tooltip {
    position: absolute;
    max-width: 300px;
    background-color: red;
    background: #6d7dff;
    border-radius: 6px;
    padding: 0 11px;
    font-size: 14px;
    line-height: 30px;
    color: white;
    height: 30px;
    filter: drop-shadow(3px 3px 10px rgba(61, 81, 251, 0.2));
  }
}

.header-contents {
  display: flex;
  align-items: center;
  &.align-center {
    justify-content: center;
    .header-text {
      margin-left: 24px;
    }
  }

  .header-text {
    cursor: pointer;
    margin-right: 8px;
  }

  .sort-icon {
    cursor: pointer;
    opacity: 0;
  }

  .sort-icon:hover {
    opacity: 1;
  }
}
</style>
