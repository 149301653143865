<template>
  <div class="settings-background">
    <div class="workspace">
      <main class="main">
        <ConfirmPassword v-if="!confirmPassword" @confirm="onConfirmPassword"></ConfirmPassword>
        <Navigation v-if="confirmPassword" :selectIndex="navigation" @select="onChangeNavigation($event)"></Navigation>
        <Content v-if="confirmPassword" :navigation="navigation"></Content>
      </main>
    </div>
  </div>
</template>

<script>
import Constants from '@/constants'
import { mapState } from 'vuex'
import ConfirmPassword from '@desktop/superadminOrWorker/settings/ConfirmPassword'
import Navigation from '@desktop/superadminOrWorker/settings/Navigation'
import Content from '@desktop/superadminOrWorker/settings/Content'
import { auth } from '@/api'

export default {
  components: { ConfirmPassword, Navigation, Content },
  data() {
    return {
      confirmPassword: false,
      navigationItemsIndex: {
        organization: Constants.NAVI_ORGANIZATION, // 0
        useraccount: Constants.NAVI_USER_ACCOUNT, // 1
        alertrecipient: Constants.NAVI_ALERT_RECIPIENT, // 2
        cameralist: Constants.NAVI_CAMERA_LIST, // 3
        cameraallocation: Constants.NAVI_CAMERA_ALLOCATION, // 4
      },
      navigationItemsName: {
        0: 'organization',
        1: 'useraccount',
        2: 'alertrecipient',
        3: 'cameralist',
        4: 'cameraallocation',
      },
    }
  },
  computed: {
    ...mapState(['user']),
    navigation() {
      return this.navigationItemsIndex[this.$route.params.name]
    },
  },
  methods: {
    onConfirmPassword() {
      if (this.$route.params.name) {
        this.$router.push({
          params: {
            name: this.$route.params.name,
            tab: 'settings',
          },
        })
      } else {
        this.$router.push({
          params: {
            name: 'organization',
            tab: 'settings',
          },
          path: '/workspace/settings/organization',
        })
      }
      this.confirmPassword = true
    },
    onChangeNavigation(nav) {
      const currentPathName = this.$route.params.name
      const name = this.navigationItemsName[nav]
      if (currentPathName !== name) {
        this.$router.push({
          params: {
            name,
            tab: 'settings',
          },
          path: `/workspace/settings/${name}`,
        })
      }
    },
  },
  async created() {
    if (await auth.isSettingPasswordConfirmed()) {
      this.onConfirmPassword()
    }
  },
  beforeDestroy() {
    auth.clearSettingPasswordConfirmed()
  },
}
</script>

<style lang="scss" scoped>
.settings-background {
  display: flex;
  flex-grow: 1;
}

.workspace {
  display: flex;
  margin-top: 74px;
  flex-grow: 1;
  overflow: auto;
}

.main {
  display: flex;
  flex-grow: 1;
  background-color: #f5f6fb;
  overflow: auto;
}
</style>
