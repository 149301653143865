<template>
  <v-dialog v-model="value" width="520px" persistent>
    <v-card style="background-color: white">
      <v-card-text class="pa-0">
        <div class="user-add-dialog-title">{{ titleText }}</div>
        <div class="user-add-dialog-content">
          <div class="label-container">
            <span class="label" style="margin-top: 33px">Name</span>
            <span class="label" style="margin-top: 31px">Email</span>
            <span class="label" style="margin-top: 31px">Phone Number</span>
            <span class="label" style="margin-top: 31px">Memo</span>
            <span class="label" style="margin-top: 84px">Receive Alert</span>
          </div>
          <div class="label-container" style="margin-left: 0; width: 300px">
            <Input ref="username" v-model="username" :rules="rules" placeholder="Name" style="margin-top: 30px"></Input>
            <Input
              ref="email"
              v-model="email"
              type="email"
              :rules="emailRules"
              @input="onInputEmail"
              @focus="onFocusEmail"
              placeholder="Email (youremail@email.com)"
              style="margin-top: 9px"
            ></Input>
            <div style="display: flex">
              <Input
                ref="national"
                v-model="national"
                :rules="nationalRules"
                :transform="natinoalTransform"
                @input="onInputPhoneNumber"
                @focus="onFocusPhoneNumber"
                placeholder="+"
                width="60px"
                revertErrorMessage
                style="margin-top: 9px; margin-right: 10px; flex-basis: 60px"
              ></Input>
              <Input
                ref="phone"
                type="nubmer"
                v-model="phone"
                :rules="phoneNumberRules"
                :transform="phoneNumberTransform"
                @input="onInputPhoneNumber"
                @focus="onFocusPhoneNumber"
                placeholder="Phone Number"
                style="margin-top: 9px; flex-grow: 1"
              ></Input>
            </div>
            <InputBox
              ref="memo"
              v-model="memo"
              placeholder="Type a memo"
              maxlength="70"
              height="54px"
              :rules="memoRules"
              style="margin-top: 9px"
            ></InputBox>
            <div style="display: flex; align-items: center; margin-top: 11px; positino: relative; font-size: 14px; position: relative">
              <div :style="receiveEmailDisabled ? 'color: #00000042;' : ''">Email</div>
              <div
                @click="!receiveEmailDisabled && (receiveEmail = !receiveEmail)"
                style="display: flex; align-items: center; margin-left: 10px; margin-right: 30px; cursor: pointer"
              >
                <img v-if="receiveEmailDisabled" src="@/assets/Checkbox_Default_Grey_Disabled_Icon.svg" width="20px" />
                <img v-else-if="receiveEmail" src="@/assets/Checkbox_Checked_Blue_Icon.svg" width="20px" />
                <img v-else src="@/assets/Checkbox_Default_Grey_Icon.svg" width="20px" />
              </div>
              <div :style="receiveSmsDisabled ? 'color: #00000042;' : ''">SMS</div>
              <div
                @click="!receiveSmsDisabled && (receiveSms = !receiveSms)"
                style="display: flex; align-items: center; margin-left: 10px; cursor: pointer"
              >
                <img v-if="receiveSmsDisabled" src="@/assets/Checkbox_Default_Grey_Disabled_Icon.svg" width="20px" />
                <img v-else-if="receiveSms" src="@/assets/Checkbox_Checked_Blue_Icon.svg" width="20px" />
                <img v-else src="@/assets/Checkbox_Default_Grey_Icon.svg" width="20px" />
              </div>
              <div
                v-if="receiveError || receiveCheckError"
                style="position: absolute; top: 24px; font-weight: normal; font-size: 10px; color: #ff3b30"
              >
                {{ receiveError ? 'Please fill out this field.' : 'Please check this field.' }}
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-end" style="padding-right: 30px; padding-bottom: 20px">
          <button v-ripple @click="hide" class="user-add-dialog-button cancel">Cancel</button>
          <button v-ripple @click="ok" class="user-add-dialog-button ok">{{ okText }}</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Input from '@common/signup/Input'
import InputBox from '@common/signup/InputBox'
import { superadmin } from '@/api'
import rules from '@/rules'

export default {
  components: { Input, InputBox },
  props: {
    value: Boolean,
    organization: Object,
    /**
     * emit events
     * ok
     */
  },
  data() {
    const numberRegx = /[0-9]/
    const notNumberRegx = /[^0-9]/
    const notNumberNationalRegx = /[^0-9+]/
    const self = this
    return {
      titleText: 'Add Recipient',
      okText: 'Add',
      username: '',
      email: '',
      national: '',
      phone: '',
      memo: '',
      receiveEmail: false,
      receiveEmailDisabled: true,
      receiveSms: false,
      receiveSmsDisabled: true,
      receiveError: false,
      receiveCheckError: false,
      rules: [(v) => !!v || 'Please fill out this field.'],
      emailRules: [(v) => !v || !!rules.emailTest.test(v) || 'Enter a valid email address.'],
      nationalMaximumError: false,
      nationalNumberLetterError: false,
      nationalRules: [
        (v) => (!self.phone && !v) || (!!self.phone && !!v) || (!self.phone && !!v) || 'Please fill out this field.',
        (v) => !self.nationalNumberLetterError || 'Enter numbers only.',
        (v) => !self.nationalMaximumError || 'Enter maximum 3 digits.',
      ],
      natinoalTransform: (v) => {
        if (notNumberNationalRegx.test(v)) {
          self.nationalNumberLetterError = true
        } else {
          self.nationalNumberLetterError = false
        }
        if (v.length > 4) {
          self.nationalMaximumError = true
        } else {
          self.nationalMaximumError = false
        }
        if (v.length === 1 && v === '+') {
          v = ''
        } else if (numberRegx.test(v.slice(-1))) {
          if (v.length === 1) {
            v = '+' + v
          }
        } else {
          v = v.slice(0, -1)
        }
        if (v.length > 4) {
          v = v.slice(0, 4)
        }
        return v
      },
      phoneNumberLetterError: false,
      phoneNumberRules: [
        () => !self.phoneNumberLetterError || 'Enter numbers only.',
        (v) => (!self.national && !v) || (!!self.national && !!v) || (!self.national && !!v) || 'Please fill out this field.',
      ],
      phoneNumberTransform: (v) => {
        if (notNumberRegx.test(v)) {
          self.phoneNumberLetterError = true
        } else {
          self.phoneNumberLetterError = false
        }
        return v.replace(/[^0-9]/gi, '')
      },
      memoRules: [(v) => !!v || 'Please fill out this field.', (v) => v.length <= 70 || ''],
    }
  },
  created() {
    this.$log.debug('DialogAdd#created')
    this.username = ''
    this.email = ''
    this.national = ''
    this.phone = ''
    this.memo = ''
    this.receiveEmail = false
    this.receiveSms = false
  },
  methods: {
    onInputEmail(text) {
      const emailValid = this.$refs.email.validate()
      this.receiveEmailDisabled = !emailValid || !text
      if (this.receiveEmailDisabled) {
        this.receiveEmail = false
      }
    },
    onFocusEmail(isFocus) {
      // blur
      if (this.email && !isFocus) {
        const emailValid = this.$refs.email.validate()
        this.receiveEmail = emailValid
      }
    },
    onInputPhoneNumber(text) {
      const nationalValid = this.$refs.national.validate()
      const phoneValid = this.$refs.phone.validate()
      this.receiveSmsDisabled = !(nationalValid && phoneValid) || !text
      if (this.receiveSmsDisabled) {
        this.receiveSms = false
      }
    },
    onFocusPhoneNumber(isFocus) {
      // blur
      if (this.national && this.phone && !isFocus) {
        const nationalValid = this.$refs.national.validate()
        const phoneValid = this.$refs.phone.validate()
        this.receiveSms = nationalValid && phoneValid
      }
    },
    hide() {
      this.$emit('input', false)
    },
    async ok() {
      const usernameValid = this.$refs.username.validate()
      const memoValid = this.$refs.memo.validate()

      let valid = true
      const emailEmpty = !this.email
      if (!emailEmpty) {
        valid = this.$refs.email.validate()
      }
      const nationalEmpty = !this.national
      const phoneEmpty = !this.phone
      if (!nationalEmpty || !phoneEmpty) {
        const nationalValid = this.$refs.national.validate()
        const phoneValid = this.$refs.phone.validate()
        if (!nationalValid || !phoneValid) {
          valid = false
        }
      }

      if (usernameValid && memoValid && valid) {
        try {
          await this.requestAction()
          this.$emit('ok', true)
          this.hide()
        } catch (error) {
          this.$emit('ok', false)
        }
      }
    },
    async requestAction() {
      this.$log.debug(
        'DialogAdd#requestAction',
        this.organization.id,
        this.username,
        this.email,
        this.national,
        this.phone,
        this.memo,
        this.receiveEmail,
        this.receiveSms
      )
      await superadmin.createNotiReceiver({
        organization_id: this.organization.id,
        name: this.username,
        email: this.email,
        country_code: this.national,
        phone_number: this.phone,
        allow_email: this.receiveEmail,
        allow_sms: this.receiveSms,
        memo: this.memo,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-add-dialog-title {
  padding: 30px 40px 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #3d51fb;
}

.user-add-dialog-content {
  display: flex;
  height: 355px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #363636;

  .label-container {
    display: flex;
    flex-direction: column;
    margin-left: 51px;
    width: 118px;
  }
}

.user-add-dialog-button {
  font-weight: 500;
  font-size: 15px;
  width: 120px;
  height: 38px;
  border-radius: 30px;

  &.ok {
    background-color: #3d51fb;
    color: var(--v-textCount);
  }

  &.cancel {
    background-color: var(--v-backgroundButtonCancel-base);
    color: var(--v-textButtonCancel);
    margin-right: 15px;
  }
}
</style>
