<template>
  <div>
    <div :class="{ 'border-line': true, focus, disabled, 'error-status': errorFlag }">
      <textarea
        ref="input"
        :type="type"
        :placeholder="placeholder"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
        :class="{ empty, disabled, 'error-status': errorFlag && !isTrancated }"
        :disabled="disabled"
        :style="`height: ${height}`"
      />
      <div v-if="this.maxlength" :class="{ 'character-count': true, 'error-status': errorFlag }">
        {{ this.text.length }}/{{ this.maxlength }} Characters
      </div>
    </div>
    <div class="error-status-text" :style="errorMessageStyle">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    maxlength: {
      type: Number,
      default: 0,
    },
    height: {
      type: String,
      default: '122px',
    },
  },
  data() {
    return {
      focus: false,
      text: this.value,
      errorFlag: this.error,
      errorMessage: '',
      isTrancated: false,
    }
  },
  computed: {
    empty() {
      return !this.text.length
    },
    errorMessageStyle() {
      return this.errorMessage ? 'visibility: visible' : 'visibility: hidden'
    },
  },
  watch: {
    value(val) {
      this.text = val
      this.$refs.input.value = val
    },
    error(val) {
      this.errorFlag = val
    },
  },
  methods: {
    validate(text) {
      this.isTrancated = false
      if (this.rules.length) {
        for (let i = 0; i < this.rules.length; i++) {
          const error = this.rules[i](text ? text : this.text)
          if (!error || typeof error == 'string') {
            this.errorFlag = true
            this.errorMessage = error
            break
          } else {
            this.errorFlag = false
            this.errorMessage = ''
          }
        }
      }
      return !this.errorFlag
    },
    onInput(e) {
      this.text = e.target.value
      this.validate()
      if (this.text && this.text.length && this.maxlength && this.maxlength < this.text.length) {
        this.text = this.text.slice(0, 70)
        e.target.value = this.text
        this.isTrancated = true
      }
      this.$emit('input', this.text)
    },
    onBlur() {
      this.focus = false
    },
    onFocus() {
      this.focus = true
    },
  },
}
</script>

<style lang="scss" scoped>
textarea {
  outline: none;
  padding: 10px 15px 0;
  width: 100%;
  font-size: 14px;
  line-height: 19px;
  caret-color: #3b77ff;
  color: var(--v-textDefault);
  resize: none;

  &::placeholder {
    color: #00000061;
  }

  &.empty {
    color: #ffffff99;
  }

  &.disabled {
    color: #ffffff61;
  }

  &.error-status {
    color: #ff3b30;
    caret-color: #ff3b30;
  }
}

.error-status-text {
  margin-top: 5px;
  margin-left: 8px;
  font-size: 10px;
  font-weight: 400;
  height: 11px;
  line-height: 11px;
  color: #ff3b30;
}

.border-line {
  width: 100%;
  margin-top: 4.5px;
  border: 1px solid #0000001f;
  border-radius: 6px;

  &.focus {
    border: 1px solid #3b77ff;
  }

  &.disabled {
    border: 1px dashed #ffffff61;
  }

  &.error-status {
    border: 1px solid #ff3b30;
  }

  .character-count {
    text-align: right;
    font-size: 10px;
    line-height: 14px;
    margin: 0 5px 5px;
    color: #00000061;

    &.error-status {
      color: #ff3b30;
    }
  }
}
</style>
