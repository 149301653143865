<template>
  <div>
    <div v-if="inReviewList.length">
      <ReviewWithRecipientItem
        v-for="(review, index) in inReviewList"
        :key="index"
        :review="review"
        :open="openedLogId[review.id]"
        :select="selectReviewId === review.id"
        :isReviewedTab="isReviewedTab"
        @showReviewStatus="showReviewStatus"
        @setSelectedReviewId="setSelectedReviewId"
      />
    </div>
    <div v-else style="flex-grow: 1">
      <NoDataItem />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import NoDataItem from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/NoDataItem'
import ReviewWithRecipientItem from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/ReviewWithRecipientItem'
export default {
  components: { NoDataItem, ReviewWithRecipientItem },
  props: {
    isReviewedTab: {
      type: Boolean,
      default: false,
    },
    currentSelectedId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      selectReviewId: -1,
      openedLogId: {},
      reviewStatus: 'confirmed by alchera-aal',
    }
  },
  created() {
    this.selectReviewId = this.currentSelectedId
  },
  computed: {
    ...mapState({
      changeRequestInReviewList: (state) => state.superadmin.changeRequestInReviewList,
      changeRequestReviewedList: (state) => state.superadmin.changeRequestReviewedList,
    }),
    inReviewList() {
      if (this.isReviewedTab && !!this.changeRequestReviewedList) return this.changeRequestReviewedList
      else if (!this.isReviewedTab && !!this.changeRequestInReviewList) return this.changeRequestInReviewList
      else return []
    },
  },
  methods: {
    setSelectedReviewId(id) {
      this.selectReviewId = id
    },
    showReviewStatus(id) {
      if (!!this.openedLogId[id]) delete this.openedLogId[id]
      else this.openedLogId[id] = true
      this.openedLogId = { ...this.openedLogId }
    },
  },
}
</script>

<style lang="scss" scoped></style>
