<template>
  <v-menu v-model="open" offset-y nudge-bottom="5px" :close-on-content-click="false" :disabled="readonly">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" :class="{ dropdown: true, open, select: !!value, 'error-status': error, readonly }">
        <div class="text-truncate">
          <input v-model="search" @focus="onFocus" @blur="onBlur" @input="onSearchChange" placeholder="Search" maxlength="50" :disabled="readonly" />
        </div>
        <v-spacer></v-spacer>
        <img v-if="error" src="@/assets/icon-arrow-down-error.svg" width="10px" />
        <img v-else-if="readonly" src="@/assets/icon-arrow-down-disabled.svg" width="10px" />
        <img v-else-if="open" src="@/assets/icon-arrow-up-blue.svg" width="10px" />
        <img v-else src="@/assets/icon-arrow-down-grey.svg" width="10px" />
        <div class="error-status-text" :style="errorMessageStyle">{{ errorMessage }}</div>
      </div>
    </template>
    <div class="dropdown-container" :style="dropdownStyle">
      <div class="wrapper" v-if="filteredItems.length">
        <div
          v-for="item in filteredItems"
          :key="item.index"
          @click="onClickItem(item.value)"
          :class="{ item: true, select: selectMap.get(item.value) }"
        >
          <div class="text-truncate">{{ item.start }}</div>
          <div class="text-truncate search">{{ item.middle }}</div>
          <div class="text-truncate">{{ item.end }}</div>
        </div>
      </div>
      <div class="empty" v-else>No Data available.</div>
      <div class="button-container">
        <button @click="onClickAddNew" :disabled="newItemAddButtonDisable">Add New</button>
      </div>
    </div>
  </v-menu>
</template>

<script>
import Validator from '@desktop/superadminOrWorker/settings/camera-list/feature/validator'
export default {
  props: {
    value: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    validateType: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      open: false,
      search: '',
      searchFocus: false,
      selected: false,
      selectMap: new Map(),
      selectAll: false,
      dropdownStyle: '',
    }
  },
  mounted() {
    if (this.value) this.search = this.items.find((item) => item.value === this.value).text
  },
  computed: {
    errorMessageStyle() {
      return this.errorMessage ? 'visibility: visible' : 'visibility: hidden'
    },
    newItemAddButtonDisable() {
      const exactlyMatch = this.items.filter((item) => item.text === this.search)
      return this.search === '' || (!!this.search.length && !!exactlyMatch.length)
    },
    filteredItems() {
      if (this.search) {
        const search = this.search.toUpperCase()
        let items = []
        for (let i = 0, j = this.items.length; i < j; i++) {
          const item = this.items[i]
          if (item.text.toUpperCase().includes(search)) {
            const index = item.text.toUpperCase().indexOf(search)
            items.push({
              start: item.text.substring(0, index),
              middle: item.text.substring(index, index + search.length),
              end: item.text.substring(index + search.length),
              index: i,
              value: item.value,
            })
          }
        }
        return items
      } else {
        let items = []
        for (let i = 0, j = this.items.length; i < j; i++) {
          const item = this.items[i]
          items.push({ start: item.text, middle: '', end: '', index: i, value: item.value })
        }
        return items
      }
    },
  },
  watch: {
    value(val) {
      if (val === undefined || val === '') this.search = ''
    },
    items(nextItems, prevItems) {
      if (!!prevItems.length && nextItems.length) {
        const searchText = this.items.find((item) => item.value === this.value)?.text
        if (searchText) this.search = searchText
        else {
          this.search = ''
          // this.$emit('save', 0)
        }
      }
    },
    open(val) {
      if (val) {
        this.calculateDropdownStyle()
      }
    },
  },
  methods: {
    calculateDropdownStyle() {
      const rect = this.$refs.dropdown && this.$refs.dropdown.getBoundingClientRect()
      if (rect && rect.width) {
        this.dropdownStyle = `width: ${rect.width}px;`
      } else {
        this.dropdownStyle = 'width: 100%;'
      }
    },
    onSearchChange(event) {
      this.search = Validator.preventSpecificCharacterType({ value: event.target.value, types: this.validateType })
      if (this.search !== '') {
        this.open = true
      }
    },
    onFocus() {
      this.searchFocus = true
    },
    onBlur() {
      this.searchFocus = false
    },
    onClickItem(itemValue) {
      this.search = this.items.find((item) => item.value === itemValue).text
      this.$emit('save', itemValue)
      this.$emit('resolveError')
      this.open = false
    },
    onClickSelectAll(forceValue = false, selectAll = false) {
      if (forceValue) {
        this.selectAll = selectAll
      } else {
        this.selectAll = !this.selectAll
      }
      this.selectMap = new Map()
      if (this.selectAll) {
        for (let i = 0, j = this.items.length; i < j; i++) {
          this.selectMap.set(this.item[i].value, true)
        }
      }
    },
    onClickAddNew() {
      if (this.search === '') {
        //blank 에 대한 에러처리
        return
      }
      this.$emit('add', this.search)
      this.open = false
    },
    onClear() {
      this.onClickSelectAll(true)
      this.selected = false
      this.$emit('clear')
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  color: #00000061;
  font-size: 14px;
  line-height: 19px;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.12);
  padding: 0 10px 7px 8px;

  &.error-status {
    border-bottom: 1.5px solid #ff3b30;
  }

  &.select {
    //color: #3d51fb;
    //border: 1px solid #3d51fb¸;
  }

  &.open {
    border-bottom: 1.5px solid #3b77ff;
  }

  .text-truncate {
    input {
      outline: none;

      &::placeholder {
        color: #00000061;
      }
    }
  }
}
.error-status-text {
  position: absolute;
  top: 31.5px;
  left: 0;
  font-size: 10px;
  font-weight: 400;
  padding-left: 8px;
  height: 11px;
  line-height: 11px;
  color: #ff3b30;
  white-space: nowrap;
}
.dropdown-container {
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(61, 81, 251, 0.1);
  border-radius: 10px;
  overflow: auto;
  overflow-x: hidden;
  font-size: 12px;
  color: #363636;

  .search-container {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 29px;
    border-bottom: 1px solid #d8d9da;
    border-radius: 10px 10px 0px 0px;

    &.focus {
      border: 1px solid #3d51fb;
    }

    input {
      outline: none;
      caret-color: #3d51fb;

      &::placeholder {
        font-size: 12px;
        color: #363636;
      }
    }
  }

  .select-all-container {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 29px;
    border-bottom: 1px solid #d8d9da;
    cursor: pointer;

    div {
      margin-left: 5px;
    }
  }

  .wrapper {
    max-height: 150px;
    overflow: auto;
    border-bottom: 1px solid #d8d9da;
  }

  .empty {
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-bottom: 1px solid #d8d9da;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.26);
  }

  .item {
    display: flex;
    align-items: center;
    height: 29px;
    padding: 0 10px;
    width: 100%;
    color: #363636;
    cursor: pointer;

    &:hover {
      color: #ffffff;
      background: #6d7dff;
    }

    &.select {
      background: #fdfdff;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #f9f9f9;
    }

    img {
      width: 16px;
      min-width: 16px;
      margin-right: 5px;
    }

    .search {
      color: white;
      background: #6d7dff;
    }
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 110px;
      height: 30px;
      background: #3d51fb;
      border-radius: 30px;
      font-weight: 500;
      font-size: 14px;
      color: white;
      margin-top: 10px;
      margin-bottom: 16px;
      &:disabled {
        color: #959595;
        background: #c9c9c9;
      }
    }
  }
}
</style>
