<template>
  <div class="root-container">
    <div class="confirm-container">
      <div class="confirm-title">Settings</div>
      <div class="confirm-subtitle">Confirm Password</div>
      <InputPassword
        ref="input"
        v-model="password"
        :error="error"
        :rule="passwordRules"
        @confirm="onClickEnter"
        style="margin: 30px 50px 0"
      ></InputPassword>
      <v-spacer></v-spacer>
      <button v-ripple @click="onClickEnter">Enter</button>
    </div>
  </div>
</template>

<script>
import InputPassword from '@common/signup/InputPassword'
import { auth } from '@/api'

export default {
  components: { InputPassword },
  data() {
    const self = this
    return {
      password: '',
      error: false,
      passwordRules: [(val) => !!val || 'Password is required.', () => !self.error || 'Incorrect Password.'],
    }
  },
  watch: {
    password(val) {
      this.error = false
    },
  },
  methods: {
    async onClickEnter() {
      const valid = this.$refs.input.validate()
      this.$log.debug('ConfirmPassword#onClickEnter', valid)
      if (valid) {
        try {
          const result = await auth.checkPassword(this.password)
          if (result) {
            this.$emit('confirm')
          } else {
            this.error = true
          }
        } catch (error) {
          this.error = true
          setTimeout(() => {
            this.$refs.input.validate()
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.root-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.confirm-container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 400px;
  max-width: 400px;
  height: 256px;
  background-color: white;
  border-radius: 20px;

  .confirm-title {
    position: absolute;
    top: -57px;
    left: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #3d51fb;
  }

  .confirm-subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #363636;
    margin-top: 30px;
    margin-left: 40px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    color: white;
    background: #3d51fb;
    box-shadow: 0px 5px 10px rgba(50, 107, 179, 0.2);
    border-radius: 30px;
    height: 44px;
    margin: 0 50px 40px;
  }
}
</style>
