<template>
  <div class="select-and-button-wrapper" :style="style">
    <Button :class="`action-button-class ${!!items[selectedItem].disabled ? 'disabled' : ''}`" @click="onClickAction()">
      {{ buttonTitle }}
    </Button>
    <v-menu v-model="open" offset-y nudge-bottom="5px" :nudge-left="nudgeLeftValue" :disabled="disabled" :content-class="menuClass">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" :class="{ 'filter-container': true, disabled }">
          <img src="@/assets/icon-arrow-down-black.svg" style="margin: 0 auto" width="10px" alt="icon" />
        </div>
      </template>
      <div class="item-container" :style="menuStyle">
        <div class="wrapper">
          <div
            v-for="(item, index) in items"
            :key="index"
            @click="onClickItem(item.value)"
            :class="item.disabled ? 'disabled-menu-item' : ''"
            class="item"
            :style="itemStyle"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: 'SelectAndActionButton.vue',
  props: {
    value: String,
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: Number,
      default: 0,
    },
    nudgeLeftValue: {
      type: String,
      default: '128px',
    },
    menuClass: {
      type: String,
      default: '',
    },
    menuStyle: {
      type: String,
      default: '',
    },
    itemStyle: {
      type: String,
      default: '',
    },
    style: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
      selectedItem: this.defaultValue,
    }
  },
  computed: {
    buttonTitle() {
      return this.items.find((item) => item.value === this.selectedItem).buttonText
    },
  },
  created() {},
  mounted() {},
  methods: {
    onClickAction() {
      if (!this.items[this.selectedItem].disabled) {
        this.$emit('handler', this.selectedItem)
      }
    },
    onClickItem(itemValue) {
      if (!this.items[itemValue].disabled) {
        this.$emit('updateActionType', itemValue)
        this.selectedItem = itemValue
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-container {
  display: flex;
  align-items: center;
  width: 32px;
  height: 34px;
  background-color: #fff;
  border-radius: 0 8px 8px 0;
  border: solid 1px #d9d9d9;
  font-weight: 500;
  font-size: 15px;
  color: #363636;

  &[aria-expanded='true'] {
    > img {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  &:hover {
    background-color: #f0f2ff;
  }
  &.disabled {
    color: #00000042;
    background-color: #0000001f;
  }

  .text-truncate {
    font-size: 15px;
    color: white;
  }
}

.item-container {
  max-height: 136px;
  border-radius: 10px;
  background-color: white;
  padding: 10px 0;
  overflow: hidden;

  .wrapper {
    display: flex;
    flex-direction: column;
    max-height: calc(136px - 20px);
    overflow: auto;
  }

  .item {
    display: flex;
    align-items: center;
    height: 29px;
    font-size: 14px;
    padding: 0 20px;
    color: #363636;
    cursor: pointer;

    &:hover {
      color: white;
      background: #6d7dff;
    }

    &.disabled-menu-item {
      cursor: default;
      color: #00000042;
      background-color: #ffffff;
    }
  }
}
.select-and-button-wrapper {
  display: flex;
}
.action-button-class {
  border-radius: 8px 0 0 8px;
  background-color: #fff;
  border: solid 1px #d9d9d9;
  border-right: none;
  color: #363636;
  font-weight: 500;
  text-align: left;
  font-size: 14px;
  padding: 8px 16px;
  line-height: 16px;
  min-width: 81px;

  &:hover {
    background-color: #f0f2ff;
  }

  &.disabled {
    background-color: #ffffff;
    color: #00000042;
    cursor: default;
  }
}
</style>
