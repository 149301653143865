<template>
  <v-dialog v-model="value" width="500px" scrollable persistent>
    <v-card>
      <v-card-text class="d-flex pa-0 ma-0">
        <div class="container">
          <div class="text-title">{{ title }}</div>
          <div class="text-info" v-html="message"></div>
          <div class="control-container">
            <button @click="onClickOk" class="ok">확인</button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: {
      type: String,
      default: '오류.',
    },
    message: {
      type: String,
      default: '일시적 오류입니다. 창을 닫은 후 다시 시도해주세요.',
    },
    result: Boolean,
    timeout: Number,
    /**
     * emit events
     */
  },
  data() {
    return {
      timeoutAutohClose: null,
    }
  },
  watch: {
    message(nextMessage, prevMessage) {
      if (nextMessage !== prevMessage) {
        this.startAutoCloseTimeout()
      }
    },
  },
  mounted() {
    if (this.timeout) this.startAutoCloseTimeout()
  },
  destroyed() {
    this.$log.debug('DialogDefault#destroyed')
    clearTimeout(this.timeoutAutohClose)
  },
  methods: {
    startAutoCloseTimeout() {
      clearTimeout(this.timeoutAutohClose)
      if (this.timeout) {
        this.timeoutAutohClose = setTimeout(() => {
          this.onClickOk()
        }, this.timeout)
      }
    },
    onClickClose() {
      this.$emit('input', false)
    },
    onClickOk() {
      this.$emit('result', true)
      this.onClickClose()
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 500px;
  height: 300px;
}
.text-title {
  font-weight: bold;
  font-size: 20px;
  color: #3d51fb;
  margin-top: 30px;
  margin-left: 40px;
  height: 27px;
}

.text-button {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 150px;
  height: 40px;
  background-color: #3d51fb;
  border-radius: 30px;
  font-size: 15px;
  color: white;
  margin-top: 33px;
}

.text-info {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  min-height: 185px;
  color: #363636;
}

.control-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  margin-bottom: 20px;
}

button {
  width: 120px;
  height: 38px;
  border-radius: 30px;
  font-size: 15px;

  &.cancel {
    background-color: #d8d9da;
    color: #484e60;
    margin-right: 15px;
  }

  &.ok {
    background-color: #3d51fb;
    color: white;
  }
}
</style>
