<template>
  <div>
    <div v-if="archiveList.length">
      <ReviewItem
        v-for="(archive, index) in archiveList"
        :key="index"
        :select="selectArchiveId === archive.id"
        :review="archive"
        @onClickReview="onClickArchive"
      />
    </div>
    <div v-else style="flex-grow: 1">
      <NoDataItem />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ReviewItem from './ReviewItem'
import NoDataItem from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/NoDataItem'
export default {
  components: { ReviewItem, NoDataItem },
  props: {
    currentSelectedId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      selectArchiveId: -1,
    }
  },
  async created() {
    this.selectArchiveId = this.currentSelectedId
  },
  computed: {
    ...mapState('superadmin', {
      archiveList: (state) => state.archiveList,
    }),
  },
  methods: {
    ...mapActions('superadmin', ['getSelectedArchiveTableAction']),
    async onClickArchive(archiveInfo) {
      this.selectArchiveId = archiveInfo.id
      await this.getSelectedArchiveTableAction(archiveInfo)
    },
  },
}
</script>

<style lang="scss" scoped></style>
