<template>
  <v-menu v-model="open" offset-y nudge-bottom="5px" :close-on-content-click="false" :content-class="menuClass">
    <template v-slot:activator="{ on, attrs }">
      <div ref="dropdown" v-bind="attrs" v-on="on" :class="{ dropdown: true, open, select: selected }">
        <img v-if="selected" src="@/assets/carbon_checkbox-checked-blue.svg" width="18px" style="margin-right: 10px" />
        <img v-else src="@/assets/carbon_checkbox-checked-gray.svg" width="18px" style="margin-right: 10px" />
        <div class="text-truncate">{{ placeholder }}</div>
        <v-spacer></v-spacer>
        <img v-if="!open && selected" @click.stop="onClear" src="@/assets/Exit_Icon_Blue.svg" width="10px" style="margin-left: 10px" />
        <img v-else-if="open" src="@/assets/icon-arrow-up-black.svg" width="10px" style="margin-left: 10px" />
        <img v-else src="@/assets/icon-arrow-down-black.svg" width="10px" style="margin-left: 10px" />
      </div>
    </template>
    <div class="dropdown-container" :style="dropdownStyle">
      <div :class="{ 'search-container': true, focus: searchFocus }">
        <img v-if="!search.length" src="@/assets/Icon ionic-ios-search_black.svg" width="10px" style="margin-right: 5px" />
        <input v-model="search" @focus="onFocus" @blur="onBlur" placeholder="Search" maxlength="50" />
      </div>
      <div v-if="filteredItems.length">
        <div @click="onClickSelectAll()" class="select-all-container">
          <img v-if="selectAll" src="@/assets/Checkbox_Checked_Icon.svg" width="16px" />
          <img v-else src="@/assets/Checkbox_Unchecked_Grey_Icon.svg" width="16px" />
          <div>Select All</div>
        </div>
        <div class="wrapper">
          <div
            v-for="item in filteredItems"
            :key="item.index"
            @click="onClickItem(item.index)"
            :class="{ item: true, select: selectMap.get(item.index) }"
          >
            <img v-if="selectMap.get(item.index)" src="@/assets/Checkbox_Checked_Icon.svg" />
            <img v-else src="@/assets/Checkbox_Unchecked_Grey_Icon.svg" />
            <div class="text-truncate">{{ item.start }}</div>
            <div class="text-truncate search">{{ item.middle }}</div>
            <div class="text-truncate">{{ item.end }}</div>
          </div>
        </div>
      </div>
      <div v-else class="empty">No data available</div>
      <div class="button-container">
        <button @click="onClickSave">Save</button>
      </div>
    </div>
    <div></div>
  </v-menu>
</template>

<script>
export default {
  props: {
    menuClass: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
      search: '',
      searchFocus: false,
      selected: false,
      selectMap: new Map(),
      selectAll: false,
      dropdownStyle: '',
    }
  },
  computed: {
    filteredItems() {
      if (this.search) {
        const search = String(this.search).toUpperCase()
        let items = []
        for (let i = 0, j = this.items.length; i < j; i++) {
          const item = String(this.items[i])
          if (item.toUpperCase().includes(search)) {
            const index = item.toUpperCase().indexOf(search)
            items.push({
              start: item.substring(0, index),
              middle: item.substring(index, index + search.length),
              end: item.substring(index + search.length),
              index: i,
            })
          }
        }
        return items
      } else {
        let items = []
        for (let i = 0, j = this.items.length; i < j; i++) {
          const item = this.items[i]
          items.push({ start: item, middle: '', end: '', index: i })
        }
        return items
      }
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.calculateDropdownStyle()
      } else {
        this.close()
      }
    },
  },
  methods: {
    calculateDropdownStyle() {
      const rect = this.$refs.dropdown && this.$refs.dropdown.getBoundingClientRect()
      if (rect && rect.width) {
        this.dropdownStyle = `width: ${rect.width}px;`
      } else {
        this.dropdownStyle = 'width: 100%;'
      }
    },
    onFocus() {
      this.searchFocus = true
    },
    onBlur() {
      this.searchFocus = false
    },
    onClickItem(index) {
      this.selectMap.set(index, !this.selectMap.get(index))
      this.selectMap = new Map(this.selectMap)
      if (this.selectMap.size === this.items.length) {
        let selectAll = true
        const values = this.selectMap.values()
        for (let v of values) {
          if (!v) {
            selectAll = false
            break
          }
        }
        this.selectAll = selectAll
      }
    },
    onClickSelectAll(forceValue = false, selectAll = false) {
      if (forceValue) {
        this.selectAll = selectAll
      } else {
        this.selectAll = !this.selectAll
      }
      this.selectMap = new Map()
      if (this.selectAll) {
        for (let i = 0, j = this.items.length; i < j; i++) {
          this.selectMap.set(i, true)
        }
      }
    },
    onClickSave() {
      let indexes = []
      const entries = this.selectMap.entries()
      for (let v of entries) {
        if (v[1]) {
          indexes.push(v[0])
        }
      }
      this.$emit('save', indexes)
      this.open = false
      this.selected = !!indexes.length
      this.close()
    },
    onClear() {
      this.onClickSelectAll(true)
      this.selected = false
      this.$emit('clear')
    },
    close() {
      this.search = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  color: #363636;
  font-weight: 500;
  font-size: 15px;
  padding: 0 15px;
  background-color: white;
  border-radius: 17px;
  height: 34px;

  &.select {
    color: #3d51fb;
    border: 1px solid #3d51fb;
  }
}

.dropdown-container {
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(61, 81, 251, 0.1);
  border-radius: 10px;
  overflow: auto;
  overflow-x: hidden;
  font-size: 12px;
  color: #363636;

  .search-container {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 29px;
    border-bottom: 1px solid #d8d9da;
    border-radius: 10px 10px 0px 0px;

    &.focus {
      border: 1px solid #3d51fb;
    }

    input {
      outline: none;
      caret-color: #3d51fb;

      &::placeholder {
        font-size: 12px;
        color: #363636;
      }
    }
  }

  .select-all-container {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 29px;
    border-bottom: 1px solid #d8d9da;
    cursor: pointer;

    div {
      margin-left: 5px;
    }
  }

  .empty {
    height: 107px;
    line-height: 107px;
    text-align: center;
    color: #d8d9da;
    font-weight: 400;
    font-size: 12px;
    border-bottom: 1px solid #d8d9da;
  }

  .wrapper {
    max-height: 150px;
    overflow: auto;
    border-bottom: 1px solid #d8d9da;
  }

  .item {
    display: flex;
    align-items: center;
    height: 29px;
    padding: 0 10px;
    width: 100%;
    color: #363636;
    cursor: pointer;

    &.select {
      background: #fdfdff;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #f9f9f9;
    }

    img {
      width: 16px;
      min-width: 16px;
      margin-right: 5px;
    }

    .search {
      color: white;
      background: #6d7dff;
    }
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 110px;
      height: 30px;
      background: #3d51fb;
      border-radius: 30px;
      font-weight: 500;
      font-size: 14px;
      color: white;
      margin-top: 10px;
      margin-bottom: 16px;
    }
  }
}
</style>
