<template>
  <div class="background">
    <div class="workspace">
      <main class="main">
        <ImagePlayerWorkspace :currentLog="currentLog" class="player" @updateBox="onUpdatePlayerBox" @stopAlertSound="$emit('stopAlertSound')">
          <template v-slot="{ currentIndex, moveFrame, playerState, imageUrlList, play, pause }">
            <FramePlayerBarWorkspace
              :currentIndex="currentIndex"
              @moveFrame="moveFrame"
              :playerState="playerState"
              :imageUrlList="imageUrlList"
              @play="play"
              @pause="pause"
              :imageInfo="imageInfo"
            >
              <a v-if="guideVisible" :href="guideLink" target="_blank" class="button-guide"
                >Guide<img src="@/assets/CheckGuideTooltip_33px.svg" width="33px"
              /></a>
              <a v-else class="button-guide disabled">Guide</a>
              <div style="flex-grow: 1"></div>
              <ButtonToggleSound :default="adminSoundSetting" @change="onClickSound" style="z-index: 1"></ButtonToggleSound>
            </FramePlayerBarWorkspace>
          </template>
        </ImagePlayerWorkspace>
        <div class="d-flex" style="margin-top: 20px; margin-left: 30px; margin-bottom: 22px">
          <div @click="openConfirmDialog" :class="{ 'worker-button': true, disabled: !currentLog || buttonDisabled }">확인, 보내기</div>
          <div @click="onSkip" :class="{ 'worker-button': true, skip: true, disabled: !currentLog || buttonDisabled }">스킵하기</div>
          <div @click="onSkipHardCase" :class="{ 'worker-button': true, skip: true, disabled: !currentLog || buttonDisabled }">스킵하기(애매함)</div>
        </div>
      </main>
      <div :class="{ 'log-sidebar': true, 'log-sidebar--open': openAside }">
        <AsideLog v-model="openAside" @expire="onExpireTimer" :logs="monitoringLogs"></AsideLog>
      </div>
    </div>
    <DialogOk v-model="dialogOk" @result="onConfirm" @onCancelConfirm="onCancelConfirm"></DialogOk>
    <DialogDefault
      v-model="detectionBoxAlertDialog"
      :title="boxAlertDialogContent.title"
      :message="boxAlertDialogContent.message"
      @input="closeBoxCheckingModal"
    />
    <RepetitiveConfirmModal
      v-if="dialogRepeatitive"
      v-model="dialogRepeatitive"
      @close="closeRepetitiveModal"
      :currentLog="currentLog"
      :compareLogs="compareLogs"
      @confirmAndSendLog="confirmAndSendLog"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Util from '@/util'
import { workspace } from '@/api'
import Toolbar from '@desktop/superadminOrWorker/workspace/Toolbar'
import ButtonPlayCircle from '@desktop/superadminOrWorker/alert/ButtonPlayCircle'
import ButtonToggleSound from '@desktop/superadminOrWorker/alert/ButtonToggleSound'
import AsideLog from '@desktop/superadminOrWorker/workspace/AsideLog'
import DialogOk from '@desktop/superadminOrWorker/workspace/DialogOk'
import DialogDefault from '@desktop/superadminOrWorker/workspace/DialogDefault'
import ImagePlayerWorkspace from '@common/newImage/ImagePlayerWorkspace'
import FramePlayerBarWorkspace from '@common/newImage/FramePlayerBarWorkspace'
import RepetitiveConfirmModal from '@desktop/superadminOrWorker/workspace/RepetitiveConfirmModal'
export default {
  components: {
    Toolbar,
    AsideLog,
    ImagePlayerWorkspace,
    FramePlayerBarWorkspace,
    ButtonPlayCircle,
    ButtonToggleSound,
    DialogOk,
    DialogDefault,
    RepetitiveConfirmModal,
  },
  data() {
    return {
      openAside: true,
      currentLog: null,
      INTERVAL_LOG: 10000,
      imageInfo: '',
      dialogOk: false,
      guideVisible: false,
      buttonDisabled: false,
      dialogRepeatitive: false,
      compareLogs: [],
      detectionBoxAlertDialog: false,
      boxAlertDialogContent: {
        title: '검출 박스가 없습니다.',
        message:
          '검출 박스가 없어 확인, 보내기를 시도할 수 없습니다.<br /><br /> 검출 박스를 생성해주시거나, <br /><div><a href="mailto:yw.jun@alcherainc.com" target="_blank" style="text-decoration: underline">yw.jun@alcherainc.com</a>으로 이메일을 보내주세요.</div>',
      },
    }
  },
  computed: {
    ...mapState({
      adminSoundSetting: (state) => state.adminSoundSetting,
      monitoringLogs: (state) => state.superadmin.monitoringLogs,
    }),
    expandedByLogId() {
      return this.$store.state.superadmin.expandedByLogId
    },
    guideLink() {
      if (this.currentLog && this.currentLog.camera_guide) {
        return this.currentLog.camera_guide
      }
      return ''
    },
  },
  watch: {
    monitoringLogs(nextLogs, prevLogs) {
      this.monitoringLogsUpdateCallback()
      if (nextLogs.length && !prevLogs.length) this.$emit('playAlertAndCheckRemainingLog')
      else if (!nextLogs.length && this.adminSoundSetting) this.$emit('stopRemainingCheck')
    },
  },
  mounted() {
    this.setCurrentWork()
  },
  destroyed() {
    clearTimeout(this.timeoutDisableButton)
    this.buttonDisabled = false
    this.isDestroyed = true
  },
  methods: {
    ...mapActions(['superadmin/deleteMonitorLogByIndexAction', 'superadmin/getMonitoringLogs']),
    onClickSound(sound) {
      this.$log.debug('Admin#onClickSound', sound)
      if (!sound) this.$emit('stopAlertSound')
      this.$store.commit('setAdminSoundSetting', sound)
    },
    setCurrentWork() {
      if (this.monitoringLogs && this.monitoringLogs.length && this.monitoringLogs[0]) {
        if (!this.currentLog || this.currentLog.id !== this.monitoringLogs[0].id) {
          this.currentLog = this.monitoringLogs[0]
          this.imageInfo = `[${Util.getOffsetDate(this.currentLog.created_at, this.currentLog.offset)}] ${this.currentLog.camera_name}`
          this.guideVisible = !!this.guideLink
        }
      } else this.clearCurrentLog()
    },
    async onSkipHardCase() {
      this.$log.debug('Admin#onSkipHardCase')
      this.$emit('stopAlertSound')
      if (this.currentLog.boxes !== undefined && this.currentLog.boxes.length === 0) this.detectionBoxAlertDialog = true
      else if (this.currentLog && !this.buttonDisabled) {
        this.disableButton2Seconds()
        await this.updateAlert(2)
      }
    },
    async onSkip() {
      this.$log.debug('Admin#onSkip')
      this.$emit('stopAlertSound')
      if (this.currentLog.boxes !== undefined && this.currentLog.boxes.length === 0) this.detectionBoxAlertDialog = true
      else if (this.currentLog && !this.buttonDisabled) {
        this.disableButton2Seconds()
        await this.updateAlert(0)
      }
    },
    async closeBoxCheckingModal() {
      this.detectionBoxAlertDialog = false
      await this.extendExpireTime()
    },
    async closeRepetitiveModal() {
      this.dialogRepeatitive = false
      await this.extendExpireTime()
    },
    async confirmAndSendLog(duplicatedLogId) {
      this.dialogRepeatitive = false
      this.disableButton2Seconds()
      await this.updateAlert(1, duplicatedLogId)
    },
    async onConfirm() {
      this.$log.debug('Admin#onConfirm', this.currentLog && !this.buttonDisabled)
      if (this.currentLog && !this.buttonDisabled) {
        this.dialogOk = false
        this.disableButton2Seconds()
        await this.updateAlert(1)
      }
    },
    async openConfirmDialog() {
      this.$emit('stopAlertSound')
      if (this.currentLog.boxes !== undefined && this.currentLog.boxes.length === 0) this.detectionBoxAlertDialog = true
      else if (this.currentLog && !this.buttonDisabled) {
        const recentLogs = await this.$store.dispatch('superadmin/getRecentLogsAboutCameraAction', this.currentLog.id)
        if (recentLogs.length) {
          this.compareLogs = recentLogs
          this.dialogRepeatitive = true
        } else this.dialogOk = true
      }
    },
    async onCancelConfirm() {
      this.dialogOk = false
      await this.extendExpireTime()
    },
    async extendExpireTime() {
      if (this.expandedByLogId === this.currentLog.id) return
      this.disableButton2Seconds()
      const expireTime = new Date(this.currentLog.expire_time).getTime()
      const now = Date.now()
      const oneMinute = 60 * 1000
      if (expireTime - now < oneMinute) {
        try {
          await workspace.extendExpireOfLog(this.currentLog.id)
          this.$store.commit('setNetworkError', { flag: false })
          this.$store.dispatch('superadmin/setExpandedByLogIdAction', this.currentLog.id)
          await this.refreshMonitoringLogs()
        } catch (e) {
          this.$log.debug('Admin#onConfirm error')
          this.$store.commit('setNetworkError', { flag: true, errorCode: e.response?.data?.result_code })
        }
      }
    },
    onUpdatePlayerBox(boxes) {
      this.currentLog.boxes = boxes
      if (boxes.length && this.currentLog.id > 0) {
        this.currentLog.boxes.forEach((box) => (box.logId = this.currentLog.id))
      }
      this.$log.debug('Admin#onUpdatePlayerBox', this.currentLog)
    },
    onExpireTimer(log) {
      const index = this.monitoringLogs.findIndex((v) => v.id === log.id)
      this.$log.debug('Admin#onExpireTimer', index)
      // Close dialog according to expire time
      if (index === 0) {
        if (this.dialogOk) this.dialogOk = false
        else this.dialogRepeatitive = false
        this.detectionBoxAlertDialog = false
      }
      if (index !== -1) {
        this['superadmin/deleteMonitorLogByIndexAction'](index)
        this.setCurrentWork()
      }
    },
    async monitoringLogsUpdateCallback() {
      if (this.monitoringLogs?.length) {
        if (this.currentLog && this.monitoringLogs[0].id !== this.currentLog.id) {
          if (this.dialogRepeatitive) this.dialogRepeatitive = false
          else this.dialogOk = false
        }
        this.setCurrentWork()
      } else this.clearCurrentLog()
    },
    clearCurrentLog() {
      this.currentLog = null
      this.imageInfo = '-'
      this.guideVisible = false
    },
    async refreshMonitoringLogs() {
      this.$emit('monitorLogUpdateInterval')
      await this['superadmin/getMonitoringLogs']()
      this.setCurrentWork()
    },
    async updateAlert(value, duplicatedLogId) {
      this.$log.debug('Admin#updateAlert', value, this.currentLog)
      const boxes = this.currentLog.boxes ? this.currentLog.boxes : this.currentLog.objects
      try {
        await workspace.updateAlertBox({
          duplicated_log_id: duplicatedLogId,
          log_id: this.currentLog.id,
          is_alert: value,
          boxes: boxes.map((box) => {
            return { x: box.x, y: box.y, width: box.width, height: box.height }
          }),
        })
        await this.refreshMonitoringLogs()
        this.$store.commit('setNetworkError', { flag: false })
      } catch (e) {
        if (e.response?.data?.result_code !== 'FIRE-F1200') {
          this.$log.debug('Admin#updateAlert error')
          this.$store.commit('setNetworkError', { flag: true, errorCode: e.response?.data?.result_code })
        }
      }
    },
    disableButton2Seconds() {
      this.buttonDisabled = true
      clearTimeout(this.timeoutDisableButton)
      this.timeoutDisableButton = setTimeout(() => {
        this.buttonDisabled = false
      }, 2000)
    },
  },
}
</script>
<style lang="scss" scoped>
.background {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.workspace {
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 74px;
}
.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - 74px);
  width: calc(100% - 485px);
  min-width: 495px;
}
.player {
  position: relative;
  flex-grow: 1;
  margin-top: 30px !important;
  margin-left: 30px !important;
}
.toolbar {
  position: relative;
  flex-basis: 65px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  .label {
    min-width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #ffc32b;
    &.unselected {
      background: none;
      border: 1px solid var(--v-backgroundUnselected);
    }
    &.fire {
      background-color: var(--v-backgroundFire-base);
    }
    &.non-fire {
      background-color: var(--v-backgroundNoneFire-base);
    }
    &.unknown {
      background-color: var(--v-backgroundUnknown-base);
    }
  }
}
.toolbar-background {
  position: absolute;
  width: calc(100% - 30px);
  height: 65px;
  margin-left: 30px;
  border: solid #d3d5dd;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: var(--v-borderToolbarBottom-base);
}
.toolbar-title-container {
  position: relative;
  margin-left: 50px;
  overflow: hidden;
}
.toolbar-title {
  height: 65px;
  line-height: 65px;
  font-weight: 500;
  font-size: 18px;
  color: var(--v-textDefault);
  &.log {
    font-size: 15px;
    color: var(--v-textDefault-white);
  }
  &.live {
    font-weight: 400;
    color: var(--v-textToolbarLiveview);
  }
}
.worker-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 150px;
  height: 40px;
  background-color: #3d51fb;
  border-radius: 38px;
  margin-right: 15px;
  font-size: 15px;
  color: white;
  &:active {
    opacity: 0.5;
  }
  &.skip {
    background-color: #e9ebff;
    color: #3d51fb;
  }
  &.disabled {
    background-color: #0000001f;
    color: #00000042;
  }
}
.button-guide {
  margin-left: 20px;
  font-weight: 500;
  font-size: 15px;
  text-decoration-line: underline;
  color: #3d51fb;
  z-index: 1;
  display: flex;
  align-items: center;
  img {
    margin: -10px 0 0 3px;
  }
  &.disabled {
    color: #00000042;
  }
}
.log-sidebar {
  flex: 0 0 90px;
  transition: flex-basis 0.3s ease-in;
}
.log-sidebar--open {
  flex-basis: 485px;
}
</style>
