<template>
  <section class="history-tab">
    <div class="history-title">History</div>
    <div v-if="historyList.length" class="history-list">
      <div v-for="history in historyList" :key="history.id">
        <HistoryItem :class="{ select: history.id === selectedId }" :history="history" @onClickHistory="onClickHistory" />
      </div>
    </div>
    <div v-else style="flex-grow: 1">
      <NoDataItem />
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HistoryItem from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/HistoryItem'
import NoDataItem from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/NoDataItem'
export default {
  components: { HistoryItem, NoDataItem },
  props: {
    selectedId: {
      type: Number,
      default: -1,
    },
  },
  async created() {
    await this.getHistoryListAction()
  },
  computed: {
    ...mapState('superadmin', {
      historyList: (state) => state.historyList,
    }),
  },
  methods: {
    ...mapActions('superadmin', ['getHistoryListAction']),
    onClickHistory(id) {
      this.selectedId = id
    },
  },
}
</script>

<style lang="scss" scoped>
.history-tab {
  padding: 24px 0px 0 24px;
  height: 100%;

  .history-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    border-bottom: 2px solid #f9f9f9;
  }
  .history-list {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 75px);
    .select {
      background: linear-gradient(0deg, rgba(109, 125, 255, 0.1), rgba(109, 125, 255, 0.1)),
        linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), rgba(233, 235, 255, 0.24) !important;
    }
  }
}
</style>
