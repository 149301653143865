<template>
  <v-dialog v-model="value" width="520px" persistent>
    <v-card style="background-color: white">
      <v-card-text class="pa-0">
        <div class="user-add-dialog-title">{{ titleText }}</div>
        <div class="user-add-dialog-content">
          <div class="label-container">
            <span class="label" style="margin-top: 33px">Organization</span>
            <span class="label" style="margin-top: 31px">User Role</span>
            <span class="label" style="margin-top: 31px">ID</span>
            <span class="label" style="margin-top: 31px">Password</span>
          </div>
          <div class="label-container" style="margin-left: 0; width: 300px">
            <div style="margin-top: 30px">
              <DropdownNormal
                ref="organization"
                v-model="organization"
                :items="organizationList"
                :rules="rules"
                :error="organizationError"
                placeholder="Organization"
                readonly
              ></DropdownNormal>
            </div>
            <div style="margin-top: 25px">
              <DropdownNormal ref="role" v-model="role" :items="roleList" :rules="rules" :error="roleError" placeholder="User Role"></DropdownNormal>
            </div>
            <Input
              ref="username"
              v-model="username"
              :transform="transformUsername"
              :rules="usernameRules"
              placeholder="ID"
              style="margin-top: 25px"
            ></Input>
            <InputPassword ref="password" v-model="password" :infoMessage="infoMessage" :icon="true" style="margin-top: 9px"></InputPassword>
          </div>
        </div>
        <div class="d-flex justify-end" style="padding-right: 30px; padding-bottom: 20px">
          <button v-ripple @click="hide" class="user-add-dialog-button cancel">Cancel</button>
          <button v-ripple @click="ok" class="user-add-dialog-button ok">{{ okText }}</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Constants from '@/constants'
import DialogAdd from './DialogAdd'
import { superadmin } from '@/api'

export default {
  extends: DialogAdd,
  props: {
    data: {
      type: Object,
      defualt: {},
    },
  },
  async mounted() {
    this.id = this.data.id
    this.organization = this.data.organization_name
    this.role = this.data.user_role
    this.username = this.data.user_id
    this.password = ''
  },
  data() {
    return {
      isEdit: true,
      titleText: 'Edit User ID',
      okText: 'Save',
    }
  },
  methods: {
    async requestAction() {
      this.$log.debug('DialogEdit#requestAction', this.id, this.role, this.username, this.password)
      await superadmin.updateUser(this.id, {
        user_role_id: Constants.ROLE_ID[this.role],
        user_id: this.username,
        password: this.password,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-add-dialog-title {
  padding: 30px 40px 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #3d51fb;
}

.user-add-dialog-content {
  display: flex;
  height: 282px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #363636;

  .label-container {
    display: flex;
    flex-direction: column;
    margin-left: 51px;
    width: 118px;
  }
}

.user-add-dialog-button {
  font-weight: 500;
  font-size: 15px;
  width: 120px;
  height: 38px;
  border-radius: 30px;

  &.ok {
    background-color: #3d51fb;
    color: var(--v-textCount);
  }

  &.cancel {
    background-color: var(--v-backgroundButtonCancel-base);
    color: var(--v-textButtonCancel);
    margin-right: 15px;
  }
}
</style>
