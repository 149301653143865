<template>
  <Input
    ref="input"
    :value="value"
    @input="onInput"
    :type="type"
    @focus="onFocus"
    @icon="onClickIcon"
    @enter="onClickEnter"
    placeholder="Password"
    :rules="rule || passwordRules"
    :appendIcon="appendIcon"
    :appendIconStyle="appendIconStyle"
    :infoMessage="infoMessage"
  ></Input>
</template>

<script>
import Input from './Input'
import rules from '@/rules'

export default {
  components: { Input },
  props: {
    value: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    infoMessage: {
      type: String,
      default: '',
    },
    rule: {
      type: Array,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let self = this
    return {
      PASSWORD_ICON_VISIBLE: require('../../../assets/icon-password-visible.svg'),
      PASSWORD_ICON_INVISIBLE: require('../../../assets/icon-password-invisible.svg'),
      type: 'password',
      password: '',
      passwordRules: [
        (val) => !!val || 'Please fill out this field.',
        (val) => {
          if (
            !!val &&
            val.length > 7 &&
            rules.uppercase.test(val) &&
            rules.lowercase.test(val) &&
            rules.number.test(val) &&
            rules.regxSpecial.test(val)
          ) {
            return true
          }
          return 'Use at least 8 characters. Include both an uppercase letter, lowercase letter, number and a symbol.'
        },
        () => !self.error || 'Incorrect Password.',
      ],
      focused: false,
      error: false,
    }
  },
  computed: {
    appendIcon() {
      if (!this.icon) {
        return ''
      }
      if (this.type === 'text') {
        return this.PASSWORD_ICON_VISIBLE
      } else {
        return this.PASSWORD_ICON_INVISIBLE
      }
    },
    appendIconStyle() {
      if (!this.icon) {
        return ''
      }
      if (this.focused) {
        return 'width: 14px;'
      } else {
        return 'width: 14px;opacity: 0.26;'
      }
    },
  },
  methods: {
    validate() {
      return this.$refs.input.validate()
    },
    onInput(text) {
      this.$emit('input', text)
    },
    onFocus(isFocus) {
      this.focused = isFocus
      if (isFocus && !this.Clicked) {
        this.password = ''
        this.$emit('input', this.password)
      }
      this.Clicked = false
    },
    onClickIcon() {
      this.Clicked = true
      if (this.type === 'text') {
        this.type = 'password'
      } else {
        this.type = 'text'
      }
    },
    onClickEnter() {
      const valid = this.$refs.input.validate()
      if (valid) {
        this.error = false
        this.$emit('confirm', this.password)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
