<template>
  <div class="background">
    <Toolbar :tabIndex="tabIndex"></Toolbar>
    <Admin
      v-if="tabIndex === 0"
      ref="admin"
      @playAlertAndCheckRemainingLog="playAlertAndCheckRemainingLog"
      @stopAlertSound="stopSound"
      @stopRemainingCheck="stopRemainingCheck"
      @monitorLogUpdateInterval="monitorLogUpdateInterval"
    ></Admin>
    <Settings v-else-if="tabIndex === 4"></Settings>
    <WorkspaceAlert
      v-else
      :name="name"
      :logId="logId"
      :tabIndex="tabIndex"
      @stopRemainingCheck="stopRemainingCheck"
      @playAlertAndCheckRemainingLog="playAlertAndCheckRemainingLog"
    ></WorkspaceAlert>
    <DialogDefault
      v-if="dialogRest"
      v-model="dialogRest"
      :title="restMessage.title"
      :message="restMessage.message"
      :timeout="restMessage.timeout"
      @result="onClickRestDialogOk"
    ></DialogDefault>
    <DialogError :value="showNetworkErrorDialog" @result="$store.dispatch('setNetworkErrorAction', { flag: false })"></DialogError>
    <DialogErrorF1201
      v-if="tabIndex === 0"
      :value="showNetworkErrorF1201Dialog"
      @result="$store.dispatch('setNetworkErrorAction', { flag: false })"
    ></DialogErrorF1201>
    <MonitoringAlert ref="alertAudio" />
  </div>
</template>

<script>
import Toolbar from '@desktop/superadminOrWorker/workspace/Toolbar'
import DialogDefault from '@desktop/superadminOrWorker/workspace/DialogDefault'
import DialogError from '@desktop/superadminOrWorker/workspace/DialogError'
import DialogErrorF1201 from '@desktop/superadminOrWorker/workspace/DialogErrorF1201'
import WorkspaceAlert from '@/views/desktop/superadminOrWorker/WorkspaceAlert'
import Admin from '@/views/desktop/superadminOrWorker/Admin'
import Settings from '@/views/desktop/superadminOrWorker/Settings'
import AsideLog from '@desktop/superadminOrWorker/workspace/AsideLog'
import Constants from '@/constants'
import { workspace } from '@/api'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import MonitoringAlert from '@desktop/superadminOrWorker/workspace/MonitoringAlert'

export default {
  components: { Toolbar, WorkspaceAlert, Admin, DialogDefault, DialogError, DialogErrorF1201, Settings, AsideLog, MonitoringAlert },
  props: ['name', 'logId'],
  data() {
    return {
      dialogRest: false,
      restIndex: 0,
      restMinute: '',
      INTERVAL_LOG: 60000,
      intervalGetBreaktime: null,
      timeoutList: [],
      timeoutRestMyStartList: [],
      timeoutRestMyEndList: [],
      timeoutRestAnotherStartList: [],
      timeoutRestAnotherEndList: [],
      showNetworkErrorDialog: false,
      showNetworkErrorF1201Dialog: false,
      routeWhiteList: ['/workspace/confirmed', '/workspace/skipped', '/workspace/skipped-hard'],

      // Alert Sound
      MONITOR_LOG_INTERVAL: 10000,
      monitorLogId: null,
    }
  },
  watch: {
    networkError(val) {
      if (!val) {
        this.showNetworkErrorDialog = false
        this.showNetworkErrorF1201Dialog = false
      } else if (!this.dialogRest) {
        if (this.networkErrorCode === 'FIRE-F1201') {
          this.showNetworkErrorF1201Dialog = true
        } else {
          this.showNetworkErrorDialog = true
        }
      }
    },
    workspaceTabIndex(newIndex, oldIndex) {
      // confirmed, skipped, skipped(hard case) indices
      const showToastTabIndexList = [1, 2, 3]
      // move from tabs 1,2,3 to another tab
      if (!showToastTabIndexList.includes(newIndex) && showToastTabIndexList.includes(oldIndex)) {
        this.$toast.clear()
        if (newIndex === 0) {
          this.$router.push('/workspace')
        }
      }
    },
  },
  computed: {
    ...mapState(['networkError', 'networkErrorCode', 'workspaceTabIndex', 'adminSoundSetting']),
    monitoringLogs() {
      return this.$store.state.superadmin.monitoringLogs
    },
    isWhiteListedRoute() {
      return this.$route.path && this.routeWhiteList.includes(this.currentRoutePath)
    },
    currentRoutePath() {
      return this.$route.path
    },
    tabIndex() {
      const path = this.$route.path
      if (path.includes(`/workspace/${Constants.SETTINGS}`)) {
        return 4
      } else if (path.includes(`/workspace/${Constants.SKIPPED_HARD}`)) {
        return 3
      } else if (path.includes(`/workspace/${Constants.SKIPPED}`)) {
        return 2
      } else if (path.includes(`/workspace/${Constants.CONFIRMED}`)) {
        return 1
      }
      return 0
    },
    restMessage() {
      const message = [
        {
          title: '내 휴게시간 시작',
          message: `휴게시간이 시작됩니다.<br>${this.restMinute}분 뒤 작업을 재개해주세요.`,
          timeout: 0,
        },
        {
          title: '내 휴게시간 종료',
          message: '휴게시간이 종료됩니다.<br>작업을 재개해주세요.',
          timeout: 10000,
        },
        {
          title: '상대 휴게시간 시작',
          message: '상대 작업자의 휴게시간입니다.',
          timeout: 10000,
        },
        {
          title: '상대 휴게시간 종료',
          message: '상대 작업자의 휴게시간이 종료되었습니다.',
          timeout: 10000,
        },
        {
          title: '내 휴게시간 시작',
          message: `상대 작업자의 휴게시간이 종료되었습니다.<br>내 휴게시간이 시작됩니다.<br>${this.restMinute}분 뒤 작업을 재개해주세요.`,
          timeout: 0,
        },
        {
          title: '내 휴게시간 종료',
          message: '내 휴게시간이 종료되었습니다.<br>상대 작업자의 휴게시간이 시작되었습니다.<br>작업을 재개해주세요.',
          timeout: 10000,
        },
      ]
      return message[this.restIndex]
    },
  },
  async created() {
    await this['superadmin/getMonitoringLogs']()
    this.$vuetify.theme.dark = false
    document.querySelector('html').classList.add('hide-scroll')
    await this.startIntervalGetBreaktime()
    this.monitorLogUpdateInterval()
    this.initCheckRemainLog()
  },
  destroyed() {
    clearTimeout(this.monitorLogId)
    clearInterval(this.intervalGetBreaktime)
    this.clearReserveTimeout()
    document.querySelector('html').classList.remove('hide-scroll')
  },
  methods: {
    ...mapActions(['superadmin/getMonitoringLogs']),
    // Alert Sound
    initCheckRemainLog() {
      if (this.monitoringLogs?.length) this.$refs.alertAudio.checkRemainingLog()
    },

    monitorLogUpdateInterval() {
      clearTimeout(this.monitorLogId)
      this.monitorLogId = setTimeout(async () => {
        await this['superadmin/getMonitoringLogs']()
        this.monitorLogUpdateInterval()
      }, this.MONITOR_LOG_INTERVAL)
    },
    playAlertAndCheckRemainingLog() {
      this.$refs.alertAudio.playAlertAndCheckRemainingLog()
    },
    stopSound() {
      this.$refs.alertAudio.stopAlertSound()
    },
    stopRemainingCheck() {
      this.$refs.alertAudio.stopRemainingLogChecking()
    },
    // BREAK
    async startIntervalGetBreaktime() {
      await this.startCheckBreaktime()
      clearInterval(this.intervalGetBreaktime)
      this.intervalGetBreaktime = setInterval(async () => {
        await this.startCheckBreaktime()
      }, this.INTERVAL_LOG)
    },
    async startCheckBreaktime() {
      try {
        const { data } = await workspace.getBreaktime()
        if (data.my_breaktimes || data.opponent_breaktimes) {
          this.reserveBreaktimeout({
            my_breaktimes: data.my_breaktimes || [],
            opponent_breaktimes: data.opponent_breaktimes || [],
          })
        }
        this.$store.dispatch('setNetworkErrorAction', { flag: false })
      } catch (e) {
        this.$log.debug('Workspace#startCheckBreaktime error', e)
        this.$store.dispatch('setNetworkErrorAction', { flag: true, errorCode: e.response?.data?.result_code })
      }
    },
    setRestMinute(myBreaktimeList, timeout) {
      const breaktime = myBreaktimeList.find((breaktime) => breaktime.start === timeout)
      if (breaktime) {
        this.restMinute = moment(breaktime.end).subtract(moment(breaktime.start)).format('m')
      } else {
        this.restMinute = '-'
      }
    },
    makeUtcTime(time, beforeTime) {
      const arrTime = time.split(':')
      const hour = parseInt(arrTime[0])
      const minute = parseInt(arrTime[1])
      const second = parseInt(arrTime[2])
      const date = moment().utc().set('hour', hour).set('minute', minute).set('second', second).set('millisecond', 0).local()
      if (beforeTime && date.isBefore(beforeTime)) {
        date.add(1, 'd')
      }
      return date
    },
    reserveBreaktimeout(breaktime) {
      this.clearReserveTimeout()

      let myBreaktimeList = []
      let timeoutRestMyStartList = []
      let timeoutRestMyEndList = []
      let timeoutRestAnotherStartList = []
      let timeoutRestAnotherEndList = []
      let timeoutRestMyStartAnotherEndList = []
      let timeoutRestMyEndAnotherStartList = []
      const now = moment()
      // calculate timer
      breaktime.my_breaktimes.forEach((time) => {
        const myStartTime = this.makeUtcTime(time.my_breaktime_start)
        const myEndTime = this.makeUtcTime(time.my_breaktime_end, myStartTime)
        this.$log.debug(`Workspace#reserveBreaktimeout my start ${myStartTime.local().format()} end ${myEndTime.local().format()}`)
        const myStartTimeout = moment(myStartTime).subtract(now).valueOf()
        const myEndTimeout = moment(myEndTime).subtract(now).valueOf()
        timeoutRestMyStartList.push(myStartTimeout)
        timeoutRestMyEndList.push(myEndTimeout)
        myBreaktimeList.push({ start: myStartTimeout, end: myEndTimeout })
      })
      breaktime.opponent_breaktimes.forEach((time) => {
        const anotherStartTime = this.makeUtcTime(time.opponent_breaktime_start)
        const anotherEndTime = this.makeUtcTime(time.opponent_breaktime_end, anotherStartTime)
        this.$log.debug(`Workspace#reserveBreaktimeout other start ${anotherStartTime.local().format()} end ${anotherEndTime.local().format()}`)
        const anotherStartTimeout = moment(anotherStartTime).subtract(now).valueOf()
        const anotherEndTimeout = moment(anotherEndTime).subtract(now).valueOf()

        const myEndIndex = timeoutRestMyEndList.indexOf(anotherStartTimeout)
        if (myEndIndex != -1 && anotherStartTimeout > 0) {
          this.$log.debug(`Workspace#reserveBreaktimeout RestMyEndAnotherStart ${anotherStartTime.local().format()}`)
          const myEndAnotherStartTimeout = timeoutRestMyEndList[myEndIndex]
          timeoutRestMyEndAnotherStartList.push(myEndAnotherStartTimeout)
          timeoutRestMyEndList = timeoutRestMyEndList
            .slice(0, myEndIndex)
            .concat(timeoutRestMyEndList.slice(myEndIndex + 1, timeoutRestMyEndList.length))
        } else {
          timeoutRestAnotherStartList.push(anotherStartTimeout)
        }
        const myStartIndex = timeoutRestMyStartList.indexOf(anotherEndTimeout)
        if (myStartIndex != -1 && anotherEndTimeout > 0) {
          this.$log.debug(`Workspace#reserveBreaktimeout RestMyStartAnotherEnd ${anotherEndTime.local().format()}`)
          const myStartAnotherEndTimeout = timeoutRestMyStartList[myStartIndex]
          timeoutRestMyStartAnotherEndList.push(myStartAnotherEndTimeout)
          timeoutRestMyStartList = timeoutRestMyStartList
            .slice(0, myStartIndex)
            .concat(timeoutRestMyStartList.slice(myStartIndex + 1, timeoutRestMyStartList.length))
        } else {
          timeoutRestAnotherEndList.push(anotherEndTimeout)
        }
      })
      // filter timer
      timeoutRestMyStartList = timeoutRestMyStartList.filter((timeout) => timeout > 0)
      timeoutRestMyEndList = timeoutRestMyEndList.filter((timeout) => timeout > 0)
      timeoutRestAnotherStartList = timeoutRestAnotherStartList.filter((timeout) => timeout > 0)
      timeoutRestAnotherEndList = timeoutRestAnotherEndList.filter((timeout) => timeout > 0)
      timeoutRestMyStartList.sort()
      timeoutRestMyEndList.sort()
      timeoutRestAnotherStartList.sort()
      timeoutRestAnotherEndList.sort()
      // start timer
      timeoutRestMyStartList.forEach((timeout) => {
        this.timeoutList.push(
          setTimeout(() => {
            this.$log.debug(`Workspace#reserveBreaktimeout RestMyStart ${moment().format()}`)
            this.setRestMinute(myBreaktimeList, timeout)
            this.dialogRest = true
            this.restIndex = 0
            this.onClickRestDialogOk(true)
          }, timeout)
        )
      })
      timeoutRestMyEndList.forEach((timeout) => {
        this.timeoutList.push(
          setTimeout(() => {
            this.$log.debug(`Workspace#reserveBreaktimeout RestMyEnd ${moment().format()}`)
            this.dialogRest = true
            this.restIndex = 1
            this.onClickRestDialogOk(true)
          }, timeout)
        )
      })
      timeoutRestAnotherStartList.forEach((timeout) => {
        this.timeoutList.push(
          setTimeout(() => {
            this.$log.debug(`Workspace#reserveBreaktimeout RestOtherStart ${moment().format()}`)
            this.dialogRest = true
            this.restIndex = 2
            this.onClickRestDialogOk(true)
          }, timeout)
        )
      })
      timeoutRestAnotherEndList.forEach((timeout) => {
        this.timeoutList.push(
          setTimeout(() => {
            this.$log.debug(`Workspace#reserveBreaktimeout RestOtherEnd ${moment().format()}`)
            this.dialogRest = true
            this.restIndex = 3
            this.onClickRestDialogOk(true)
          }, timeout)
        )
      })
      timeoutRestMyStartAnotherEndList.forEach((timeout) => {
        this.timeoutList.push(
          setTimeout(() => {
            this.$log.debug(`Workspace#reserveBreaktimeout RestMyStartOtherEnd ${moment().format()}`)
            this.setRestMinute(myBreaktimeList, timeout)
            this.dialogRest = true
            this.restIndex = 4
            this.onClickRestDialogOk(true)
          }, timeout)
        )
      })
      timeoutRestMyEndAnotherStartList.forEach((timeout) => {
        this.timeoutList.push(
          setTimeout(() => {
            this.$log.debug(`Workspace#reserveBreaktimeout RestMyEndOtherStart ${moment().format()}`)
            this.dialogRest = true
            this.restIndex = 5
            this.onClickRestDialogOk(true)
          }, timeout)
        )
      })
    },
    clearReserveTimeout() {
      this.timeoutList.forEach((timeout) => clearTimeout(timeout))
      this.timeoutList = []
    },
    onClickRestDialogOk(isDelay) {
      this.$log.debug('Workspace#onClickRestDialogOk')
      if (this.$refs.admin) {
        if (isDelay) {
          setTimeout(() => {
            this.$refs.admin.refreshMonitoringLogs()
          }, 1000)
        } else {
          this.$refs.admin.refreshMonitoringLogs()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.background {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
