<template>
  <div>
    <div class="frame-bar">
      <div class="timeline">
        <div class="progress-cover" v-if="!this.selectMode" :style="progressWdith"></div>
        <div
          v-for="(image, index) in loadedImages"
          :key="index"
          :class="{
            now: index === currentIndex && isLoaded && playerState !== CONSTANT.STOP_STATE,
            firstIndex: index === 0,
            lastIndex: loadedImages.length === index + 1,
            'selected-frame': currentIndex === index && selectMode,
          }"
          :style="frameStyle(image, index)"
          @click="moveFrame(index)"
        ></div>
      </div>
    </div>
    <div class="controller">
      <span class="log-info">
        {{ imageInfo }}
      </span>
      <CircleButton :playerState="imageInfo !== '-' ? playerState : 'DISABLE'" :isWorkspace="true" @play="onClickPlay" @pause="$emit('pause')" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import CONSTANT from '@common/newImage/canvas/constant'
import CircleButton from '@common/newImage/CircleButton'

export default {
  components: { CircleButton },
  props: {
    imageInfo: {
      type: String,
      default: '',
    },
    imageUrlList: {
      type: Object,
      default: [],
    },
    currentIndex: {
      type: Number,
      default: -1,
    },
    playerState: {
      type: String,
      default: CONSTANT.PAUSE_STATE,
    },
  },
  data() {
    return {
      defaultImages: (() => {
        const defaultImage = require('@/assets/playerbar-default-image.jpg')
        return Array(20).fill(defaultImage)
      })(),
      CONSTANT,
      ARROW_LEFT: 37,
      ARROW_RIGHT: 39,
      SPACEBAR: 32,
      selectMode: false,
    }
  },
  watch: {
    imageInfo(newInfo, prevInfo) {
      if (newInfo !== prevInfo) this.selectMode = false
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyDown)
  },
  destroyed() {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  computed: {
    progressWdith() {
      if (this.playerState !== CONSTANT.STOP_STATE && this.isLoaded) {
        return {
          transform: `scaleX(${this.imageWidth * (this.currentIndex + 1)}%)`,
        }
      }
    },
    isLoaded() {
      return this.imageUrlList.length
    },
    imageWidth() {
      if (this.loadedImages.length) return 100 / this.loadedImages.length
      else return 0
    },
    loadedImages() {
      if (this.imageUrlList.length) return this.imageUrlList
      else return this.defaultImages
    },
  },
  methods: {
    onClickPlay() {
      if (this.selectMode) this.selectMode = false
      this.$emit('play')
    },
    moveFrame(frameIndex) {
      if (frameIndex >= 0 && frameIndex <= this.imageUrlList?.length) {
        if (!this.selectMode) this.selectMode = true
        this.$emit('moveFrame', frameIndex)
      }
    },
    onKeyDown(e) {
      if (e.keyCode === this.SPACEBAR) {
        e.preventDefault()
        this.playerPlayBySpaceBar()
      } else if (this.isLoaded && this.playerState === CONSTANT.PAUSE_STATE) {
        if (e.keyCode === this.ARROW_LEFT) {
          if (this.currentIndex > 0) {
            this.moveFrame(this.currentIndex - 1)
          }
        } else if (e.keyCode === this.ARROW_RIGHT) {
          if (this.currentIndex < this.imageUrlList.length - 1) {
            this.moveFrame(this.currentIndex + 1)
          }
        }
      } else if (this.isLoaded && this.playerState === CONSTANT.STOP_STATE && this.currentIndex === -1 && e.keyCode === this.ARROW_LEFT) {
        this.$emit('pause')
        this.moveFrame(this.imageUrlList.length - 2)
      }
    },
    playerPlayBySpaceBar() {
      if (this.playerState === CONSTANT.PLAY_STATE) this.$emit('pause')
      else this.onClickPlay()
    },
    frameStyle(image, index) {
      if (index === this.detectedImageIndex) {
        return {
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          height: '100%',
          width: `${this.imageWidth}%`,
          display: 'flex',
          justifyContent: 'center',
          opacity: this.$vuetify.theme.dark ? '0.7' : '0.45',
          border: '2px solid red',
          padding: '1px 0',
          boxSizing: 'border-box',
          backgroundClip: 'content-box',
        }
      }
      return {
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        height: '100%',
        width: `${this.imageWidth}%`,
        justifyContent: 'center',
        opacity: this.$vuetify.theme.dark ? '0.7' : '0.45',
        display: 'flex',
        padding: '1px 0',
        boxSizing: 'border-box',
        backgroundClip: 'content-box',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.frame-bar {
  width: 100%;
  height: 27px;
  display: flex;
  margin-top: 11px;
  position: relative;
}

.controller {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 65px;
  padding: 20px;
  border-bottom: 1px solid #d3d5dd;
  .log-info {
    font-weight: 500;
    font-size: 18px;
    color: var(--v-textDefault);
    margin-right: 15px;
    user-select: text;
  }
}

.timeline {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  border-radius: 17px;
  overflow: hidden;
}
.progress-cover {
  position: absolute;
  opacity: 0.7;
  padding: 1px 0;
  height: 25px;
  width: 100%;
  border-radius: 17px;
  transform-origin: left;
  background: linear-gradient(to right, rgba(0, 255, 255, 1), rgba(245, 235, 53, 1));
}

.now {
  border-right: 3px solid rgba(245, 235, 53, 1) !important;
}

.selected-frame {
  border: 3px solid;
  border-image: linear-gradient(to right, rgba(0, 255, 255, 1), rgba(245, 235, 53, 1));
  border-image-slice: 1;
}

.lastIndex {
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
}

.firstIndex {
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}
</style>
