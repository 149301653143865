<template>
  <v-menu v-model="open" offset-y nudge-bottom="5px" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <div ref="dropdown" v-bind="attrs" v-on="on" :class="{ dropdown: true, open }">
        <div :class="displayText === placeholder ? 'placeholder-text-empty' : ''" class="text-truncate placeholder-text">{{ displayText }}</div>
        <v-spacer></v-spacer>
        <img v-if="open" src="@/assets/icon-arrow-up-blue.svg" width="10px" style="margin-left: 10px" />
        <img v-else src="@/assets/icon-arrow-down-black.svg" width="10px" style="margin-left: 10px" />
      </div>
    </template>
    <div class="dropdown-container" :style="dropdownStyle">
      <div class="wrapper">
        <div
          v-for="(item, itemIndex) in menuOptions"
          :key="item.id"
          @click="onClickItem(item)"
          @mouseenter="hoveredItemIndex = itemIndex"
          @mouseleave="hoveredItemIndex = -1"
          :class="{ item: true, select: selectedItems.includes(item) }"
        >
          <img v-if="!selectedItems.includes(item) && itemIndex === hoveredItemIndex" src="@/assets/Checkbox-Off_16px_2_Icon.svg" />
          <img v-else-if="selectedItems.includes(item) && itemIndex === hoveredItemIndex" src="@/assets/Checkbox-On_16px_Icon_dark.svg" />
          <img v-else-if="selectedItems.includes(item)" src="@/assets/Checkbox_Checked_Icon.svg" />
          <img v-else-if="!selectedItems.includes(item)" src="@/assets/Checkbox_Unchecked_Grey_Icon.svg" />
          <div class="text-truncate">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      dropdownStyle: '',
      hoveredItemIndex: -1,
    }
  },
  computed: {
    menuOptions() {
      let result = []
      for (let i = 0; i < this.items.length; i += 1) {
        result.push({
          id: this.items[i].id,
          text: this.items[i].user_id,
        })
      }
      return result
    },
    selectedItemsIdList() {
      return this.selectedItems.length ? this.selectedItems.map((i) => i.id) : []
    },
    displayText() {
      const selectedItemsIdList = this.selectedItems.filter((i) => i.value)
      if (selectedItemsIdList.length) {
        return selectedItemsIdList.map((i) => i.text).join(', ')
      }
      return this.placeholder
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.calculateDropdownStyle()
        this.$emit('focus')
      } else {
        this.$emit('blur')
      }
    },
  },
  methods: {
    calculateDropdownStyle() {
      const rect = this.$refs.dropdown && this.$refs.dropdown.getBoundingClientRect()
      if (rect && rect.width) {
        this.dropdownStyle = `width: ${rect.width}px;`
      } else {
        this.dropdownStyle = 'width: 100%;'
      }
    },
    onClickItem(item) {
      this.$emit('click-item', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  color: #363636;
  font-weight: 500;
  font-size: 15px;
  padding: 0 7px;
  background-color: white;
  height: 34px;
}

.placeholder-text-empty {
  color: #000000;
  opacity: 0.38;
}

.placeholder-text {
  font-size: 14px;

  &.text-truncate {
    max-width: 270px;
  }
}

.dropdown-container {
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(61, 81, 251, 0.1);
  border-radius: 10px;
  overflow: auto;
  overflow-x: hidden;
  font-size: 12px;
  color: #363636;

  .select-all-container {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 29px;
    border-bottom: 1px solid #d8d9da;
    cursor: pointer;

    div {
      margin-left: 5px;
    }
  }

  .wrapper {
    max-height: 150px;
    overflow: auto;
    border-bottom: 1px solid #d8d9da;
  }

  .item {
    display: flex;
    align-items: center;
    height: 29px;
    padding: 0 10px;
    width: 100%;
    color: #363636;
    cursor: pointer;

    &.select {
      background: #fdfdff;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #f9f9f9;
    }

    img {
      width: 16px;
      min-width: 16px;
      margin-right: 5px;
    }
  }

  .item:hover {
    background-color: #6d7dff;
    color: #ffffff;
  }
}
</style>
