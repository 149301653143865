var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root-container"},[_c('div',{class:{ 'monitoring-container': true, worker: _vm.user.isWorker }},[_c('main',[(!_vm.currentCam)?_c('ImagePlayer',{staticClass:"alert-player",attrs:{"currentLog":_vm.currentLog},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentIndex = ref.currentIndex;
var moveFrame = ref.moveFrame;
var playerState = ref.playerState;
var imageUrlList = ref.imageUrlList;
var play = ref.play;
var pause = ref.pause;
return [_c('FramePlayerBarAlert',{attrs:{"currentIndex":currentIndex,"playerState":playerState,"imageUrlList":imageUrlList,"currentLog":_vm.currentLog},on:{"moveFrame":moveFrame,"play":play,"pause":pause,"moveToLiveCam":_vm.onClickSite}},[_c('div',[(!_vm.user.isWorker)?_c('LinkGoto',{staticStyle:{"margin-right":"21px"},on:{"click":_vm.onClickLinkGoto}}):_vm._e(),_c('LinkShare',{attrs:{"disabled":_vm.user.isWorker && !_vm.currentLog},on:{"click":_vm.onClickLinkShare}})],1)])]}}],null,false,3772518849)}):_c('div',{staticClass:"live-wrapper"},[_c('LiveViewPlayer',{staticClass:"live-player",attrs:{"currentCam":_vm.currentCam}}),_c('div',{staticClass:"live-info-name"},[_vm._v(_vm._s(_vm.cameraName))]),_c('div',{staticClass:"live-info-indicator"},[_c('EventCircle',{staticStyle:{"margin-right":"10px"},attrs:{"value":1006}}),_vm._v(" Live View")],1)],1)],1)]),_c('AsideLog',{ref:"asideLog",attrs:{"currentCam":_vm.currentCam,"currentLog":_vm.currentLog,"tabIndex":_vm.tabIndex},on:{"select-cam":function($event){_vm.historyCam = $event},"select-log":_vm.onClickLog,"select-tab":_vm.onSelectTab}}),_c('SnackBar',{attrs:{"color":_vm.clipboardColor,"message":_vm.clipboardMsg},model:{value:(_vm.clipboard),callback:function ($$v) {_vm.clipboard=$$v},expression:"clipboard"}}),_c('GotoDialog',{attrs:{"urls":_vm.gotoLinks},model:{value:(_vm.dialogGoto),callback:function ($$v) {_vm.dialogGoto=$$v},expression:"dialogGoto"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }