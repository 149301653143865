<template>
  <div class="camera-organization-container">
    <div class="content-container">
      <div class="content-title">Camera Allocation</div>
      <div class="toolbar-container">
        <InputSearchBar
          @input="onInputSearch"
          placeholder="Search"
          style="width: 300px; background-color: white; margin-right: 10px"
        ></InputSearchBar>
      </div>
      <div v-if="items.length" style="flex-grow: 1; overflow: auto; padding-right: 10px">
        <button v-ripple v-for="(item, index) of items" :key="index" @click="onClickOrganization(item)" class="item-organization">
          {{ item.name }}
        </button>
      </div>
      <div v-if="!loading && !items.length" style="flex-grow: 1; overflow: auto; border-radius: 15px">
        <div class="no-data">No data available.</div>
      </div>
    </div>
  </div>
</template>

<script>
import InputSearchBar from '@common/InputSearchBar'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  components: { InputSearchBar },
  data() {
    return {
      loading: false,
      requestTimeout: null,
    }
  },
  computed: {
    ...mapState('superadmin', {
      items: (state) => state.organizations,
    }),
  },
  async created() {
    await this.onRequestCallback()
  },
  beforeDestroy() {
    clearTimeout(this.requestTimeout)
  },
  methods: {
    ...mapMutations('superadmin', ['setOrganizations']),
    ...mapActions('superadmin', ['getOrganizations']),
    onInputSearch(text) {
      this.$log.debug('CameraOrganization#onInputSearch', text)
      clearTimeout(this.requestTimeout)
      this.requestTimeout = setTimeout(async () => {
        await this.onRequestCallback(text)
      }, 500)
    },
    async onRequestCallback(name) {
      this.loading = true
      try {
        this.setOrganizations([])
        await this.getOrganizations({ name })
      } catch (e) {
        this.$log.error(e)
        this.$emit('snackbar', {
          snackbarOpen: true,
          snackbarColor: 'error',
          snackbarMessage: 'Server error occurred.',
        })
      }
      this.loading = false
    },
    onClickOrganization(item) {
      this.$emit('organization', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.camera-organization-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content-container {
  display: flex;
  flex-direction: column;
  height: 0;
  flex-grow: 1;
  margin: 40px 20px 20px 30px;

  .content-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    height: 34px;
    color: #3d51fb;
  }

  .toolbar-container {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin: 25px 0 15px;
  }

  .item-organization {
    display: flex;
    align-items: center;
    padding: 0 30px;
    width: 100%;
    height: 54px;
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 5px 5px 10px rgba(61, 81, 251, 0.07);
    border-radius: 15px;
    font-weight: 600;
    font-size: 16px;
    color: #6d7dff;
    margin-right: 10px;
    margin-bottom: 15px;
  }

  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #8d90a2;
    font-size: 14px;
  }
}
</style>
