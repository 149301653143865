<template>
  <v-dialog v-model="value" width="1012px" persistent no-click-animation>
    <v-card class="dialog">
      <v-card-title class="dialog-title">
        중복감지 여부 확인
        <img src="@/assets/Exit_Icon_Black.svg" alt="close" class="close" @click="onCloseModal" />
      </v-card-title>
      <div class="dialog-container">
        <section class="current">
          <div class="current-title">현재 로그</div>
          <div class="current-wrapper">
            <div class="current-image">
              <div class="canvas-cover"></div>
              <DetectImage :logInfo="currentLog" />
            </div>
            <div class="current-info">{{ getCameraNameAndLogTime(currentLog) }}</div>
          </div>
        </section>
        <section class="compare">
          <div class="compare-title">비교 로그</div>
          <div v-if="!isExpand" :class="{ 'compare-wrapper': true, two: compareLogs.length === 2, one: compareLogs.length === 1 }">
            <div v-if="compareLogs.length === 1">
              <DetectImage
                :class="{ select: selectedLogId === compareLogs[0].log_id }"
                :logInfo="compareLogs[0]"
                @click="clickImage(compareLogs[0].log_id)"
              />
              <div class="expand-log-info">{{ getLogTime(compareLogs[0]) }}</div>
            </div>
            <div
              v-else
              class="compare-log"
              v-for="(log, idx) in compareLogs"
              :key="idx"
              @mouseover="showExpandButton(idx)"
              @mouseleave="closeExpandButton()"
            >
              <img
                :class="{ 'compare-image': true, select: selectedLogId === log.log_id }"
                :src="getImgUrl(log.detected_image_path)"
                @click="clickImage(log.log_id)"
              />
              <img :class="{ expand: true, show: hoverImageIdx === idx }" src="@/assets/Maximize_Icon.svg" width="16px" @click="expandImage(log)" />
              <div class="compare-info">{{ getLogTime(log) }}</div>
            </div>
          </div>
          <div v-else class="select-log">
            <div class="back" @click="backwardToList">
              <div>
                <img src="@/assets/Other_Log_Button.svg" width="26px" />
              </div>
              다른 로그
            </div>
            <DetectImage :logInfo="expandLog" />
            <div class="expand-log-info">{{ getCameraNameAndLogTime(expandLog) }}</div>
          </div>
        </section>
      </div>
      <div class="dialog-btns">
        <button class="not-repetitiv" @click="confirmNotCompetitive">중복 아님</button>
        <button :class="{ repetitiv: true, select: selectedLogId }" @click="confirmCompetitive">중복 확인</button>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Util from '@/util'
import DetectImage from '@common/ImagePlayer/DetectImage'

export default {
  components: { DetectImage },
  data() {
    return {
      selectedLogId: '',
      confirmDisable: false,
      isExpand: false,
      hoverImageIdx: -1,
      expandImageUrl: '',
      canvas: null,
      expandLog: null,
    }
  },
  props: {
    value: Boolean,
    currentLog: {
      type: Object,
      default: null,
    },
    compareLogs: {
      type: Object,
      default: [],
    },
  },
  methods: {
    getCameraNameAndLogTime(log) {
      if (log) return `[${Util.getOffsetDate(log.created_at, log.offset)}] ${this.currentLog.camera_name}`
      else return '-'
    },
    getLogTime(log) {
      if (log) return `[${Util.getOffsetDate(log.created_at, log.offset)}]`
      else return '-'
    },
    onCloseModal() {
      this.$emit('close')
    },
    getImgUrl(src) {
      return Util.getImageUrl(src)
    },
    clickImage(logId) {
      if (this.selectedLogId === logId) this.selectedLogId = ''
      else this.selectedLogId = logId
    },
    showExpandButton(idx) {
      this.hoverImageIdx = idx
    },
    closeExpandButton() {
      this.hoverImageIdx = -1
    },
    backwardToList() {
      this.isExpand = false
      this.expandLog = null
      this.expandImageUrl = ''
      this.selectedLogId = ''
    },
    expandImage(log) {
      this.isExpand = true
      this.expandImageUrl = this.getImgUrl(log.detected_image_path)
      this.expandLog = log
      this.selectedLogId = log.log_id
    },
    async confirmCompetitive() {
      await this.$emit('confirmAndSendLog', this.selectedLogId)
    },
    async confirmNotCompetitive() {
      await this.$emit('confirmAndSendLog')
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  padding: 0 40px 30px 40px;
}
.dialog-title {
  display: flex;
  justify-content: space-between;
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #3d51fb;
  line-height: 25px;
  padding: 30px 0 40px 0 !important;
}

.dialog-container {
  height: 430px;
  display: flex;
  justify-content: space-between;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.current-info {
  text-align: center;
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
}

.current-image {
  margin-top: 38px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  img {
    user-select: none;
    width: 446px;
    height: 251px;
    border-radius: 16px;
  }
}

.current-title,
.compare-title {
  color: var(--f-text-black);
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px;
  color: #000000de;
  line-height: 19.5px;
}

.compare-image {
  width: 213px;
  height: 120px;
  border-radius: 16px;
  user-select: none;

  &.select {
    border: 4px solid #3d51fb;
  }
}

.compare-wrapper {
  display: grid;
  grid-template-columns: 213px 213px;
  column-gap: 20px;
  row-gap: 40px;
  // margin: auto 0;
  grid-template-rows: 150px 150px;

  &.two {
    margin-top: 103px;
  }
  &.one {
    display: block;
    padding-top: 38px;
    .compare-image {
      width: 446px;
      height: 251px;
    }
  }
  .compare-log {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 16px;
    position: relative;
  }
  .compare-info {
    position: absolute;
    bottom: 0px;
    &.one {
      position: inherit;
      padding-top: 10px;
    }
  }
}

.select-image {
  width: 446px;
  height: 251px;
  border-radius: 16px;
}

.canvas-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.expand {
  position: absolute;
  bottom: 38px;
  right: 8px;
  cursor: pointer;
  display: none;
  &.show {
    display: block;
  }
}
.expand-log-info {
  text-align: center;
  margin-top: 5px;
}
.dialog-btns {
  display: flex;
  justify-content: center;
  font-size: 15px;
  button {
    width: 150px;
    height: 40px;
    border-radius: 30px;
    &:last-child {
      margin-left: 20px;
      background-color: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
      &.select {
        background-color: #3d51fb !important;
        color: var(--f-text-white);
      }
    }
    &:first-child {
      background-color: var(--v-backgroundSearchMask);
      color: #3d51fb;
    }
  }
}

.image-wrapper {
  border: 4px solid rgba(0, 0, 0, 0);
  &.select {
    border: 4px solid #3d51fb;
  }
}
.close {
  cursor: pointer;
}
.back {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #3d51fb;
  margin-bottom: 10px;
  div {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }
}
</style>
