<template>
  <v-menu v-model="open" offset-y nudge-bottom="5px" :disabled="disabled" content-class="add-filter-menu">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" :class="{ 'filter-container': true, disabled }">
        <img src="@/assets/Filter_Line_16px_Icon.svg" style="margin-left: 15px" width="15px" alt="icon" />
        <v-spacer></v-spacer>
        <span class="text-truncate" style="margin-right: 16px">Add Filter</span>
      </div>
    </template>
    <div class="item-container">
      <div class="wrapper">
        <div v-for="(item, index) in items" :key="index" @click="onClickItem(item.value)" class="item">
          {{ item.text }}
        </div>
      </div>
    </div>
  </v-menu>
</template>
<script>
export default {
  name: 'AddFilter.vue',
  props: {
    value: String,
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    onClickItem(item) {
      this.$emit('input', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-container {
  display: flex;
  align-items: center;
  width: 127px;
  height: 34px;
  margin-top: 3px;
  background-color: var(--v-backgroundDownload);
  border-radius: 30px;
  font-weight: 500;
  font-size: 15px;
  color: #363636;

  &[aria-expanded='true'] {
    background-color: var(--v-backgroundSelected);
  }
  &:hover {
    background-color: var(--v-backgroundSelected);
  }
  &.disabled {
    color: #00000042;
    background-color: #0000001f;
  }

  .text-truncate {
    font-size: 15px;
    color: white;
  }
}

.item-container {
  // max-height: 136px;
  border-radius: 10px;
  background-color: white;
  padding: 10px 0;
  // overflow: hidden;

  .wrapper {
    display: flex;
    flex-direction: column;
    // max-height: calc(136px - 20px);
    overflow: auto;
  }

  .item {
    display: flex;
    align-items: center;
    height: 31px;
    width: 180px;
    font-size: 14px;
    padding: 0 20px;
    color: #363636;
    cursor: pointer;

    &:hover {
      color: white;
      background: #6d7dff;
    }
  }
}
</style>
