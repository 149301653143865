<template>
  <div class="org-dialog-section-wrap">
    <div class="select-content radio">
      <div class="select-content-title">Monitored*</div>
      <div class="radio-wrapper">
        <SelectRadioButton
          uniqueName="monitoredValue"
          :items="YES_OR_NO_OPTION"
          @select="onClickMonitored"
          :errorClassName="monitorValidated['monitored'][1]"
          :selectValue="monitoredValue"
        />
      </div>
    </div>
    <div class="select-content">
      <div class="select-content-title">Monitoring Group*</div>
      <div class="dropdown-wrapper">
        <DropdownSimple
          v-model="monitoringGroupValue"
          :error="!monitorValidated['monitoringGroup'][0]"
          :errorMessage="monitorValidated['monitoringGroup'][1]"
          @resolveError="resolveMonitorError('monitoringGroup')"
          :items="monitoringGroups.map((item) => ({ text: item.id, value: item.id }))"
          :readonly="monitoredValue !== 1"
          placeholder="Select"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { YES_OR_NO_OPTION } from '@desktop/superadminOrWorker/settings/camera-list/feature/options'
import Validator from '@desktop/superadminOrWorker/settings/camera-list/feature/validator'
import DropdownSimple from '@desktop/superadminOrWorker/settings/DropdownSimple'
import SelectRadioButton from '@desktop/superadminOrWorker/settings/camera-list/SelectRadioButton'
import { mapState } from 'vuex'

export default {
  components: { DropdownSimple, SelectRadioButton },
  props: {
    monitoringValues: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      YES_OR_NO_OPTION,
      monitorValidated: {
        monitored: [true, ''],
        monitoringGroup: [true, ''],
      },
      monitoredValue: -1,
      monitoringGroupValue: '',
    }
  },
  created() {
    this.monitoredValue = this.monitoringValues.monitoredValue
    this.monitoringGroupValue = this.monitoringValues.monitoringGroupValue
  },
  computed: {
    ...mapState('superadmin', {
      monitoringGroups: (state) => state.cameraGroups,
    }),
    monitoringGroupsTest() {
      return this.monitoringGroups.map((item) => ({ text: item.id, value: item.id }))
    },
  },
  methods: {
    onClickMonitored(idx) {
      this.monitoredValue = idx
      this.monitorValidated.monitored = [true, '']
      if (idx === 2 && !this.monitorValidated.monitoringGroup[0]) this.resolveMonitorError('monitoringGroup')
    },
    validateData() {
      this.monitorValidated.monitored = Validator.validateMonitored(this.monitoredValue)
      if (this.monitoredValue === 1)
        this.monitorValidated.monitoringGroup = Validator.validateDropdown(this.monitoringGroupValue, this.monitoringGroups)
      else this.monitorValidated.monitoringGroup = [true, '']
    },
    updateData() {
      this.validateData()
      const updatedData = {
        monitoredValue: this.monitoredValue,
        monitoringGroupValue: this.monitoringGroupValue,
      }
      this.$emit('updateMonitorValues', updatedData)
    },
    resolveMonitorError(key) {
      this.monitorValidated[key] = [true, '']
    },
  },
}
</script>

<style lang="scss" scoped>
.org-dialog-section-wrap {
  padding: 0 10px;
  margin-top: 3px;
}
.select-content {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  &.radio {
    margin-bottom: 25px;
  }
  .select-content-title {
    width: 45.7%;
    font-weight: 500;
    font-size: 15px;
  }
  .dropdown-wrapper {
    flex-grow: 1;
  }
  .radio-wrapper {
    width: 54.3%;
  }
}
</style>
