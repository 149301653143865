<template>
  <section class="discussion-tab">
    <span class="discussion-title">Discussion</span>
    <div class="detail-tab-wrapper">
      <div :class="{ tab: true, select: tab === currentTab }" v-for="(tab, index) in TAB_KEYS" :key="index" @click="onClickDetailTab(tab)">
        {{ tab }}
        <CountItem :select="tab == currentTab" :count="tabCounts[index]" />
      </div>
    </div>
    <DiscussionCommentTab ref="discussionCommentTab" v-if="currentTab === TAB_KEYS[0]" />
    <ResolvedDiscussionTab v-else />
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DiscussionCommentTab from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/DiscussionCommentTab'
import ResolvedDiscussionTab from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/ResolvedDiscussionTab'
import CommentTextarea from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/CommentTextarea'
import CountItem from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/CountItem'
const TAB_KEYS = ['Discussion', 'Resolved']
export default {
  components: {
    DiscussionCommentTab,
    ResolvedDiscussionTab,
    CommentTextarea,
    CountItem,
  },
  data() {
    return {
      TAB_KEYS,
      currentTab: TAB_KEYS[0],
    }
  },
  async created() {
    await this.getDiscussionListAction()
  },
  computed: {
    ...mapState('superadmin', {
      discussionList: (state) => state.discussionList,
      resolvedDiscussionList: (state) => state.resolvedDiscussionList,
    }),
    tabCounts() {
      return [this.discussionList.length, this.resolvedDiscussionList.length]
    },
  },
  methods: {
    ...mapActions('superadmin', ['getDiscussionListAction']),
    onClickDetailTab(tab) {
      this.currentTab = tab
    },
  },
}
</script>

<style lang="scss" scoped>
.discussion-tab {
  padding: 24px 0 0 24px;
  height: 100%;
  .discussion-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: var(--f-text-black);
    margin-bottom: 18px;
    display: inline-block;
  }
  .detail-tab-wrapper {
    display: flex;
    padding-bottom: 16px;
    border-bottom: 2px solid #f9f9f9;
    margin-bottom: 32px;
  }
  .tab {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: var(--f-text-black);
    margin-right: 16px;
    .count {
      background-color: #e9ebff;
      color: #6d7dff;
      width: 29px;
      height: 24px;
      display: inline-block;
      text-align: center;
      border-radius: 12px;
      margin-left: 4px;
      font-weight: 500;
      font-size: 14px;
    }
    &.select {
      font-weight: 600;
      color: #3d51fb;
      .count {
        background-color: #3d51fb;
        color: #ffffff;
      }
    }
  }
}
</style>
