<template>
  <div class="root-container">
    <ContentOrganization v-if="navigation === Constants.NAVI_ORGANIZATION" @snackbar="showSnackbar"></ContentOrganization>
    <UserAccount v-if="navigation === Constants.NAVI_USER_ACCOUNT" @snackbar="showSnackbar"></UserAccount>
    <AlertOrganization
      v-if="navigation === Constants.NAVI_ALERT_RECIPIENT && !organization"
      @organization="organization = $event"
      @snackbar="showSnackbar"
    ></AlertOrganization>
    <AlertRecipient
      v-if="navigation === Constants.NAVI_ALERT_RECIPIENT && organization"
      :organization="organization"
      @back="organization = null"
      @snackbar="showSnackbar"
    ></AlertRecipient>
    <CameraList
      v-if="navigation === Constants.NAVI_CAMERA_LIST || (navigation === Constants.NAVI_CAMERA_ALLOCATION && organization && addCamera)"
      :navigation="navigation"
      :organization="organization"
      @add="addCamera = false"
      @snackbar="showSnackbar"
    ></CameraList>
    <CameraOrganization
      v-if="navigation === Constants.NAVI_CAMERA_ALLOCATION && !organization"
      @organization="organization = $event"
    ></CameraOrganization>
    <CameraAllocation
      v-if="navigation === Constants.NAVI_CAMERA_ALLOCATION && organization && !addCamera"
      :organization="organization"
      @add="addCamera = true"
      @back="organization = null"
      @snackbar="showSnackbar"
    ></CameraAllocation>
    <SnackBar v-model="snackbarOpen" :color="snackbarColor" :message="snackbarMessage"></SnackBar>
  </div>
</template>

<script>
import Constants from '@/constants'
import ContentOrganization from './organization/ContentOrganization'
import UserAccount from './user-account/UserAccount'
import AlertOrganization from './alert-recipient/AlertOrganization'
import AlertRecipient from './alert-recipient/AlertRecipient'
import CameraOrganization from './camera-allocation/CameraOrganization'
import CameraAllocation from './camera-allocation/CameraAllocation'
import CameraList from './camera-list/CameraList'
import SnackBar from '@common/SnackBar'

export default {
  components: { ContentOrganization, UserAccount, AlertOrganization, AlertRecipient, CameraList, CameraOrganization, CameraAllocation, SnackBar },
  props: {
    navigation: Number,
  },
  data() {
    return {
      Constants,
      snackbarOpen: false,
      snackbarColor: '',
      snackbarMessage: '',
      organization: '',
      addCamera: false,
    }
  },
  watch: {
    navigation() {
      this.organization = null
      this.addCamera = false
    },
  },
  computed: {},
  methods: {
    showSnackbar(e) {
      this.snackbarOpen = e.snackbarOpen
      this.snackbarColor = e.snackbarColor
      this.snackbarMessage = e.snackbarMessage
    },
  },
}
</script>

<style lang="scss" scoped>
.root-container {
  display: flex;
  flex-grow: 1;
  overflow: auto;
}
</style>
