<template>
  <button @click="onClickSound" class="sound">
    <div style="margin-right: 10px">ON</div>
    <img v-if="!isSound" src="@/assets/SoundToggle_Off.svg" />
    <img v-if="isSound" src="@/assets/SoundToggle_On.svg" />
    <div style="margin-left: 10px">OFF</div>
  </button>
</template>

<script>
export default {
  props: {
    default: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isSound: this.default,
    }
  },
  methods: {
    onClickSound() {
      this.isSound = !this.isSound
      this.$emit('change', this.isSound)
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid #3d51fb;
  border-radius: 30px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    opacity: 0.5;
  }

  &.sound {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: var(--v-textDefault);
    border-style: none;
  }
}
</style>
