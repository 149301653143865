<template>
  <div class="select-action-content-container">
    <div class="select-action-content-title">Select Action</div>
    <v-radio-group v-model="selectedActionOption" @change="onChangeSelectActionOption($event)">
      <v-radio
        v-for="option in selectActionOptions"
        :key="option.value"
        class="select-action-option"
        color="#3B77FF"
        :label="option.text"
        :value="option.value"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'SelectActionStageContent',
  props: {
    selectedAction: String,
  },
  data() {
    return {
      selectedActionOption: 'add',
      selectActionOptions: [
        {
          text: 'Upload cameras and add to the list.',
          value: 'add',
        },
        {
          text: 'Upload cameras and edit the list.',
          value: 'replace',
        },
      ],
    }
  },
  mounted() {
    this.selectedActionOption = this.selectedAction
  },
  methods: {
    onChangeSelectActionOption(evt) {
      this.$emit('select-action', evt)
    },
  },
}
</script>

<style lang="scss" scoped>
.select-action-content-container {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px dashed #d9d9d9;
  border-radius: 15px;
  padding: 30px;

  .select-action-content-title {
    font-weight: 600;
    font-size: 16px;
    color: #363636;
    margin-bottom: 20px;
  }
}

.select-action-option {
  width: fit-content;
}
</style>
