<template>
  <div class="root-container">
    <div class="navi-title user">User</div>
    <div class="navi">
      <div @click="onClickNavi(Constants.NAVI_ORGANIZATION)" :class="{ 'navi-container': true, select: selectIndex === Constants.NAVI_ORGANIZATION }">
        <img v-if="selectIndex === Constants.NAVI_ORGANIZATION" src="@/assets/Organization_White_Icon.svg" width="14px" />
        <img v-else src="@/assets/Organization_Grey_Icon.svg" width="14px" />
        <div>Organization</div>
      </div>
    </div>
    <div class="navi">
      <div @click="onClickNavi(Constants.NAVI_USER_ACCOUNT)" :class="{ 'navi-container': true, select: selectIndex === Constants.NAVI_USER_ACCOUNT }">
        <img v-if="selectIndex === Constants.NAVI_USER_ACCOUNT" src="@/assets/UserAccount_White_Icon.svg" width="14px" />
        <img v-else src="@/assets/UserAccount_Grey_Icon.svg" width="14px" />
        <div>User Account</div>
      </div>
    </div>
    <div class="navi">
      <div
        @click="onClickNavi(Constants.NAVI_ALERT_RECIPIENT)"
        :class="{ 'navi-container': true, select: selectIndex === Constants.NAVI_ALERT_RECIPIENT }"
      >
        <img v-if="selectIndex === Constants.NAVI_ALERT_RECIPIENT" src="@/assets/AlertRecipient_White_Icon.svg" width="14px" />
        <img v-else src="@/assets/AlertRecipient_Grey_Icon.svg" width="14px" />
        <div>Alert Recipient</div>
      </div>
    </div>
    <div class="navi-title camera">Camera</div>
    <div class="navi">
      <div @click="onClickNavi(Constants.NAVI_CAMERA_LIST)" :class="{ 'navi-container': true, select: selectIndex === Constants.NAVI_CAMERA_LIST }">
        <img v-if="selectIndex === Constants.NAVI_CAMERA_LIST" src="@/assets/CameraList_White_Icon.svg" width="14px" />
        <img v-else src="@/assets/CameraList_Grey_Icon.svg" width="14px" />
        <div>Camera List</div>
      </div>
    </div>
    <div class="navi">
      <div
        @click="onClickNavi(Constants.NAVI_CAMERA_ALLOCATION)"
        :class="{ 'navi-container': true, select: selectIndex === Constants.NAVI_CAMERA_ALLOCATION }"
      >
        <img v-if="selectIndex === Constants.NAVI_CAMERA_ALLOCATION" src="@/assets/CameraAllocation_White_Icon.svg" width="14px" />
        <img v-else src="@/assets/CameraAllocation_Grey_Icon.svg" width="14px" />
        <div>Camera Allocation</div>
      </div>
    </div>
  </div>
</template>

<script>
import Constants from '@/constants'

export default {
  props: {
    selectIndex: String,
  },
  data() {
    return {
      Constants,
    }
  },
  methods: {
    onClickNavi(index) {
      this.$emit('select', index)
    },
  },
}
</script>

<style lang="scss" scoped>
.root-container {
  display: flex;
  flex-direction: column;
  width: 200px;
  min-width: 200px;
  background: #ffffff;
  box-shadow: 5px 10px 20px rgba(61, 81, 251, 0.1);
  border-radius: 0px 30px 30px 0px;
}

.navi-title {
  color: #6d7dff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  &.user {
    margin: 40px 20px 10px;
  }

  &.camera {
    margin: 49px 20px 10px;
  }
}

.navi {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;

  .navi-container {
    display: flex;
    align-items: center;
    width: 190px;
    height: 100%;
    border-radius: 20px 0px 0px 20px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
    color: #8d90a2;

    &.select {
      color: white;
      background: #6d7dff;
    }

    img {
      margin: 0 10px 0 25px;
    }
  }
}
</style>
