<template>
  <div class="history-item" @click="onClickHistory">
    <div class="history-item-title">
      {{ historyTitle }}
    </div>
    <div class="history-item-date">{{ historyCreateTime }}</div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import { HISTORY_ACTION_TYPE } from '@desktop/superadminOrWorker/settings/camera-list/feature/options'

export default {
  props: {
    history: {
      type: Object,
      default: {},
    },
  },
  data() {
    return { HISTORY_ACTION_TYPE }
  },
  computed: {
    historyTitle() {
      if (!this.history) return '-'
      return `${this.history.user.username} ${this.historyActionText} '${this.history.subject}'`
    },
    historyActionText() {
      return HISTORY_ACTION_TYPE[0] === this.history.action ? 'merged' : 'Rolled back to'
    },
    historyCreateTime() {
      if (!this.history) return '-'
      return moment(this.history.created_at).format('YYYY/MM/DD HH:mm:ss')
    },
  },
  methods: {
    ...mapActions('superadmin', ['getSelectedHistoryTableAction']),
    async onClickHistory() {
      this.$emit('onClickHistory', this.history.id)
      await this.getSelectedHistoryTableAction({ id: this.history.id, userName: this.history.user.username, subject: this.history.subject })
    },
  },
}
</script>

<style lang="scss" scoped>
.history-item {
  width: 472px;
  height: 86px;
  background: rgba(233, 235, 255, 0.24);
  padding: 16px 24px;
  margin-bottom: 16px;
  border-radius: 15px;
  &:hover {
    background: linear-gradient(0deg, rgba(109, 125, 255, 0.1), rgba(109, 125, 255, 0.1)), rgba(233, 235, 255, 0.24);
  }
  .history-item-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--f-text-black);
    margin-bottom: 15px;
  }
  .history-item-date {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--f-text-black);
    text-align: right;
  }
}
</style>
