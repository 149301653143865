<template>
  <div class="camera-allocation-container">
    <div class="content-container">
      <div class="content-title">
        <div class="content-title clickable" @click="$emit('back')">
          <img src="@/assets/Chevron_Left_Black_Icon.svg" height="14px" style="margin-right: 16px" />
          <div style="color: #8d90a2">Camera Allocation</div>
        </div>
        <div style="color: #8d90a2; margin: 0 10px">></div>
        {{ organization.name }}
      </div>
      <div class="toolbar-container">
        <InputSearchBar
          @input="onInputSearch"
          placeholder="Search"
          style="width: 300px; background-color: white; margin-right: 10px"
        ></InputSearchBar>
        <v-spacer></v-spacer>
        <div style="width: 150px; margin-left: 10px">
          <DropdownSelect placeholder="Region" :items="regionList" @clear="onClearRegion" @save="onSaveRegion"></DropdownSelect>
        </div>
        <div style="width: 150px; margin-left: 10px">
          <DropdownSelect placeholder="County" :items="countyList" @clear="onClearCounty" @save="onSaveCounty"></DropdownSelect>
        </div>
        <Button @click="$emit('add')" :prependIcon="addIcon" :prependIconStyle="iconStyle" style="margin-left: 10px">Add</Button>
        <Button
          @click="onClickDelete"
          :prependIcon="removeIcon"
          :prependIconStyle="iconStyle"
          :prependIconDisabled="removeIconDisabled"
          :disabled="select.length === 0"
          style="margin-left: 10px"
        >
          Remove
        </Button>
      </div>
      <div style="flex-grow: 1; overflow: auto; border-radius: 15px; background-color: white">
        <Table v-model="select" @change="onChangeSelectItem" :headers="headers" :items="items" itemKey="id" checkAll></Table>
        <div v-if="!loading && !items.length" class="no-data">No data available.</div>
      </div>
    </div>
    <DialogDelete
      v-if="openDeleteDialog"
      v-model="openDeleteDialog"
      :all="items.length === select.length"
      :organization="organization"
      :data="select"
      @ok="onRequestCallback"
    ></DialogDelete>
  </div>
</template>

<script>
import InputSearchBar from '@common/InputSearchBar'
import DropdownSelect from '@desktop/superadminOrWorker/settings/DropdownSelect'
import DialogDelete from './DialogDelete'
import Button from '@desktop/superadminOrWorker/settings/Button'
import Table from '@desktop/superadminOrWorker/settings/Table'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  components: { InputSearchBar, Button, Table, DropdownSelect, DialogDelete },
  props: {
    organization: Object,
  },
  data() {
    return {
      openDeleteDialog: false,
      addIcon: require('@/assets/Add_Icon.svg'),
      removeIcon: require('@/assets/Remove_Red_Icon.svg'),
      removeIconDisabled: require('@/assets/Remove_Red_Icon_Disabled.svg'),
      iconStyle: 'width: 14px;',
      headers: [
        { name: 'Name', key: 'name' },
        { name: 'Region', key: 'region' },
        { name: 'County', key: 'county' },
        { name: 'Description', key: 'description' },
        { name: 'Axis-Name', key: 'axis_name' },
      ],
      loading: false,
      select: [],
      filterRegion: [],
      filterCounty: [],
      searchKeyword: '',
      searchTimeout: null,
    }
  },
  computed: {
    ...mapState('superadmin', {
      items: (state) => state.allocatedCameras,
      regions: (state) => state.regions,
      counties: (state) => state.counties,
    }),
    regionList() {
      return this.regions.map((v) => v.name)
    },
    countyList() {
      return this.counties.map((v) => v.name)
    },
  },
  async created() {
    await Promise.all([this.getCameraSearchOptions(), this.onRequestCallback(true)])
  },
  beforeDestroy() {
    clearTimeout(this.searchTimeout)
  },
  methods: {
    ...mapMutations('superadmin', ['setAllocatedCameras']),
    ...mapActions('superadmin', ['getCameraSearchOptions', 'getAllocatedCameras']),
    onClickDelete() {
      this.editData = this.select
      this.$log.debug('CameraAllocation#onClickDelete', this.editData)
      this.openDeleteDialog = true
    },
    onInputSearch(text) {
      this.$log.debug('CameraAllocation#onInputSearch', text)
      this.searchKeyword = text
      clearTimeout(this.requestTimeout)
      this.requestTimeout = setTimeout(async () => {
        await this.onRequestCallback(true)
      }, 500)
    },
    onChangeSelectItem(select) {
      this.select = select
    },
    async onClearRegion() {
      this.$log.debug('CameraAllocation#onClearRegion')
      this.filterRegion = []
      await this.onRequestCallback(true)
    },
    async onClearCounty() {
      this.$log.debug('CameraAllocation#onClearCounty')
      this.filterCounty = []
      await this.onRequestCallback(true)
    },
    async onSaveRegion(itemIndexes) {
      this.$log.debug('CameraAllocation#onSaveRegion', itemIndexes)
      this.filterRegion = itemIndexes.map((index) => this.regions[index])
      await this.onRequestCallback(true)
    },
    async onSaveCounty(itemIndexes) {
      this.$log.debug('CameraAllocation#onSaveCounty', itemIndexes)
      this.filterCounty = itemIndexes.map((index) => this.counties[index])
      await this.onRequestCallback(true)
    },
    async onRequestCallback(success) {
      this.$log.debug('CameraAllocation#onRequestCallback', success, this.openDeleteDialog)
      if (success) {
        if (this.openDeleteDialog) {
          this.$emit('snackbar', {
            snackbarOpen: true,
            snackbarColor: 'secondary',
            snackbarMessage: 'Successfully Deleted.',
          })
        }
        this.loading = true
        try {
          this.setAllocatedCameras([])
          await this.getAllocatedCameras({
            keyword: this.searchKeyword,
            organization_id: this.organization.id,
            region_ids: this.filterRegion.map((v) => v.id),
            county_ids: this.filterCounty.map((v) => v.id),
          })
        } catch (e) {
          this.$log.error(e)
          this.$emit('snackbar', {
            snackbarOpen: true,
            snackbarColor: 'error',
            snackbarMessage: 'Server error occurred.',
          })
        }
        this.loading = false
      } else {
        this.$emit('snackbar', {
          snackbarOpen: true,
          snackbarColor: 'error',
          snackbarMessage: 'Server error occurred.',
        })
      }
      this.select = []
    },
  },
}
</script>

<style lang="scss" scoped>
.camera-allocation-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content-container {
  display: flex;
  flex-direction: column;
  height: 0;
  flex-grow: 1;
  margin: 40px 30px 20px;

  .content-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    height: 34px;
    color: #3d51fb;

    &.clickable {
      cursor: pointer;
    }
  }

  .toolbar-container {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin: 25px 0 15px;
  }
  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #8d90a2;
    font-size: 14px;
  }
}
</style>
