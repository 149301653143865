<template>
  <div class="root-container">
    <div :class="{ 'monitoring-container': true, worker: user.isWorker }">
      <main>
        <ImagePlayer v-if="!currentCam" :currentLog="currentLog" class="alert-player">
          <template v-slot="{ currentIndex, moveFrame, playerState, imageUrlList, play, pause }">
            <FramePlayerBarAlert
              :currentIndex="currentIndex"
              @moveFrame="moveFrame"
              :playerState="playerState"
              :imageUrlList="imageUrlList"
              @play="play"
              @pause="pause"
              :currentLog="currentLog"
              @moveToLiveCam="onClickSite"
            >
              <div>
                <LinkGoto v-if="!user.isWorker" @click="onClickLinkGoto" style="margin-right: 21px"></LinkGoto>
                <LinkShare @click="onClickLinkShare" :disabled="user.isWorker && !currentLog"></LinkShare>
              </div>
            </FramePlayerBarAlert>
          </template>
        </ImagePlayer>
        <div v-else class="live-wrapper">
          <LiveViewPlayer :currentCam="currentCam" class="live-player" />
          <div class="live-info-name">{{ cameraName }}</div>
          <div class="live-info-indicator"><EventCircle :value="1006" style="margin-right: 10px" /> Live View</div>
        </div>
      </main>
    </div>
    <AsideLog
      ref="asideLog"
      :currentCam="currentCam"
      :currentLog="currentLog"
      :tabIndex="tabIndex"
      @select-cam="historyCam = $event"
      @select-log="onClickLog"
      @select-tab="onSelectTab"
    ></AsideLog>
    <SnackBar v-model="clipboard" :color="clipboardColor" :message="clipboardMsg"></SnackBar>
    <GotoDialog v-model="dialogGoto" :urls="gotoLinks"></GotoDialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import Util from '@/util'
import Constants from '@/constants'
import AsideLog from '@desktop/superadminOrWorker/alert/AsideLog'
import LinkGoto from '@desktop/superadminOrWorker/alert/LinkGoto'
import LinkShare from '@desktop/superadminOrWorker/alert/LinkShare'
import GotoDialog from '@desktop/dialog/GotoDialog'
import SnackBar from '@common/SnackBar'
import SelfInfoMixin from '@/mixin/selfinfo.mixin'
import EventCircle from '@common/EventCircle'

import WorkspaceToastContent from '@/components/common/WorkspaceToastContent.vue'
import ToastCloseIconLight from '@/components/common/ToastCloseIconLight.vue'
import { POSITION } from 'vue-toastification'

import ImagePlayer from '@common/newImage/ImagePlayer'
import FramePlayerBarAlert from '@common/newImage/FramePlayerBarAlert'
import LiveViewPlayer from '@common/newImage/LiveViewPlayer'

export default {
  mixins: [SelfInfoMixin],
  components: {
    AsideLog,
    SnackBar,
    LinkGoto,
    LinkShare,
    GotoDialog,
    WorkspaceToastContent,
    ToastCloseIconLight,
    ImagePlayer,
    FramePlayerBarAlert,
    LiveViewPlayer,
    EventCircle,
  },
  props: ['name', 'logId', 'tabIndex'],
  data() {
    return {
      Constants,
      dialogGoto: false,
      currentCam: null,
      historyCam: null,
      clipboard: false,
      clipboardColor: 'secondary',
      clipboardMsg: 'Copied to clipboard.',
      selectTab: '',
      currentFrameIndex: 0,
      frameIndex: 0,
      frameMode: '',
      gotoLinks: [],
      canvasActiveOption: {
        selectable: false,
        wheel: false,
        dragLeftButton: true,
      },
    }
  },
  computed: {
    ...mapState(['camMap', 'camList', 'logListv2', 'eventTypeList', 'user', 'logInfo']),
    currentLog() {
      if (this.logInfo) this.logInfo.after_image_paths = null
      return this.logInfo
    },
    monitoringLogs() {
      return this.$store.state.superadmin.monitoringLogs
    },
    userRole() {
      return this.user.user_role
    },
    cameraName() {
      if (this.currentCam) return this.currentCam.name
      else return '-'
    },
  },
  watch: {
    async tabIndex() {
      if (this.selectTab) {
        this.$refs.asideLog.onClickOpen()
      }
    },
    monitoringLogs(newList, prevList) {
      if (newList.length && !prevList.length) this.$emit('playAlertAndCheckRemainingLog')
      else if (!newList.length && prevList.length) this.$emit('stopRemainingCheck')
      if (this.userRole === Constants.ROLE_WORKER && !!prevList && newList && prevList.at(-1)?.id !== newList.at(-1)?.id) {
        this.$toast(
          {
            component: WorkspaceToastContent,
            listeners: {
              'view-toast': () => {
                this.$store.dispatch('setWorkspaceTabIndexAction', 0)
              },
            },
          },
          {
            bodyClassName: `mapview-toast-body dark`,
            closeButton: ToastCloseIconLight,
            toastClassName: `mapview-toast-container dark`,
          }
        )
      }
    },
  },
  async created() {
    const updateToastDefaultOptions = {
      hideProgressBar: true,
      icon: false,
      maxToasts: 1,
      position: POSITION.TOP_RIGHT,
      timeout: 7000,
    }
    Vue.$toast.updateDefaults(updateToastDefaultOptions)
    await this.initialize()
  },
  methods: {
    ...mapActions(['getCamList', 'getRecentLogListInWorkspace', 'getLogList', 'getLog', 'setLogInfo']),
    initVariable() {
      this.setLogInfo(false)
      this.currentCam = null
    },
    async initialize() {
      this.initVariable()
      let name = this.name
      let logId = parseInt(this.logId)
      try {
        await this.getCamList({ tabIndex: this.tabIndex })
        if (logId >= 0) {
          // email or phone link의 logid로 들어왔을 시 해당 로그를 화면에 보여주기 위한 프로세스
          const log = this.logListv2.find((log) => log.log_id === logId)
          // this.$log.debug(`WorkspaceAlert#created log=${log}`)
          if (log) {
            await this.onClickLog(log)
          } else {
            const data = await this.$store.dispatch('getLog', logId)
            // this.$log.debug('WorkspaceAlert#created log=', data)
            if (data && data.created_at) {
              await this.onClickLog(data)
            }
          }
        } else if (name) {
          const cam = this.camList.find((cam) => cam.name === name)
          // this.$log.debug(`WorkspaceAlert#created cam=${cam}`)
          if (cam) {
            this.onClickCamImage(cam)
          } else {
            // 최근 로그를 보여줌
            await this.onClickLog(this.logListv2[0])
          }
        } else if (this.logListv2 && this.logListv2[0]) {
          // 최근 로그를 보여줌
          // this.$log.debug('WorkspaceAlert#created', this.recentLogList[0])
          await this.onClickLog(this.logListv2[0])
        } else if (this.camList && this.camList[0]) {
          // this.$log.debug('WorkspaceAlert#created recent log empty')
          this.showFirstSiteCam()
        }
        this.$refs.asideLog.selectId = -1
      } catch (e) {
        this.$log.debug(e)
      }
    },
    showFirstSiteCam() {
      if (!this.$route.path.includes('/workspace/')) {
        this.onClickSite(this.camList[0].id)
      }
    },
    async onClickHome() {
      if (this.logListv2 && this.logListv2[0]) {
        await this.onClickLog(this.logListv2[0])
        this.$refs.asideLog.onClickOpen()
        this.selectTab = ''
      } else {
        this.$refs.asideLog.onClickOpen()
        this.selectTab = ''
        if (this.camList && this.camList[0]) {
          this.showFirstSiteCam()
        }
      }
    },
    async onClickCamImage(cam) {
      this.currentCam = cam
      if (this.currentCam) {
        this.setGotoLink(this.currentCam)
        this.setLogInfo(null)
        Util.moveScrollToTop()
      }
    },
    setGotoLink(cam) {
      if (!!cam && !!cam.public_url && !!cam.private_url_1 && !!cam.private_url_2) {
        this.gotoLinks = [cam.public_url, cam.private_url_1, cam.private_url_2]
      }
    },
    onClickCamTitle() {
      if (this.currentLog) {
        this.onClickSite(this.currentLog.camera_id)
      }
    },
    onClickSite(id) {
      this.$log.debug('onClickSite', id)
      if (id === null) {
        this.initialize()
      } else {
        const cam = this.camList.find((cam) => cam.id === id)
        if (cam) {
          this.onClickCamImage(cam)
        }
      }
    },
    async onClickLog(log) {
      // Defense code to prevent the same log from being updated more than once
      if (!log) this.initVariable()
      else if (log.log_id === this.logInfo?.log_id) return
      else {
        await this.getLog(log.log_id)
        const cam = this.camMap[log.camera_id]
        this.setGotoLink(cam)
        this.currentCam = null
        Util.moveScrollToTop()
        log.callback && log.callback()
      }
    },
    displayLogTime(log) {
      const cam = this.camMap[log.camera_id]
      if (cam) {
        const date = Util.getOffsetDate(log.created_at, log.offset)
        return `[${date}] ${cam.name}`
      } else {
        return log.log
      }
    },
    async onClickLinkShare() {
      try {
        this.clipboardColor = 'secondary'
        this.clipboardMsg = 'Copied to clipboard.'
        let workspace = Constants.CONFIRMED
        if (this.tabIndex == 2) workspace = Constants.SKIPPED
        else if (this.tabIndex == 3) workspace = Constants.SKIPPED_HARD
        await Util.copyClipboard(`${location.origin}/workspace/${workspace}/${this.currentLog.camera_name}/${this.currentLog.log_id}`)
        this.clipboard = true
      } catch (e) {
        this.clipboardColor = 'error'
        this.clipboardMsg = e.toString()
      }
    },
    onClickLinkGoto() {
      this.$log.debug('WorkspaceAlert#onClickLinkGoto')
      this.dialogGoto = true
    },
  },
}
</script>

<style lang="scss" scoped>
.root-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}
.monitoring-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 74px 485px 0px 0px;

  &.worker {
    margin-top: 89px;
  }
}

.default-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 74px 0px 0px 0px;
  height: 0;
  overflow: hidden;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px 0px 0px 30px;
  height: calc(100vh - 89px);
}

.alert-player {
  flex-grow: 1;
}

.live-wrapper {
  height: 100%;

  .live-player {
    border-radius: 8px;
  }
  .live-info-name {
    font-weight: 600;
    font-size: 18px;
    margin: 20px 0 13px 20px;
  }
  .live-info-indicator {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
}

.toolbar {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &.worker {
    margin-top: 3px;
  }

  .label {
    min-width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #ffc32b;

    &.unselected {
      background: none;
      border: 1px solid var(--v-backgroundUnselected);
    }

    &.fire {
      background-color: #ff5b54;
    }

    &.non-fire {
      background-color: #64d280;
    }

    &.unknown {
      background-color: #61a0ff;
    }

    &.planned {
      background-color: #7b61ff;
    }
  }
}

.toolbar-background {
  width: 100%;
  margin-top: 15px;
  margin-left: 30px;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: var(--v-borderToolbarBottom-base);
}

.toolbar-title-container {
  display: flex;
  position: relative;
  margin-left: 50px;
  margin-right: 20px;
  overflow: hidden;

  &.live {
    margin-bottom: 10px;
  }

  &.log {
    margin-bottom: 3px;
  }

  &.empty {
    margin-right: 0px;
  }
}

.toolbar-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--v-textDefault);

  &.cam-name {
    margin-top: 20px;
  }
}

.toolbar-subtitle {
  font-weight: 500;
  font-size: 15px;
  color: var(--v-textDefault);
  user-select: text;

  &.live {
    user-select: none;
    font-weight: 16px;
    color: var(--v-textToolbarLiveview);
  }
}

.toolbar-button {
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 10px;
  cursor: pointer;
  z-index: 1;
  width: 120px;
  height: 34px;
  background-color: #3d51fb;
  border-radius: 30px;
}

.info-hover {
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 15px;
  background-color: #f9f9f9;
  font-size: 12px;
  color: #363636;
}
</style>
