<template>
  <div class="comment-tab">
    <div class="dicussion-list" v-if="discussionList.length">
      <div v-for="discussion in discussionList" :key="discussion.id">
        <DiscussionCommentItem :discussion="discussion" @onClickComment="onClickComment" :selectedId="selectedId" />
      </div>
    </div>
    <div v-else style="flex-grow: 1">
      <NoDataItem />
    </div>
    <CommentTextarea :selectedId="selectedId" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DiscussionCommentItem from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/DiscussionCommentItem'
import CommentTextarea from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/CommentTextarea'
import NoDataItem from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/NoDataItem'
export default {
  components: {
    DiscussionCommentItem,
    CommentTextarea,
    NoDataItem,
  },
  data() {
    return {
      selectedId: -1,
    }
  },
  computed: {
    ...mapState('superadmin', {
      discussionList: (state) => state.discussionList,
    }),
  },
  methods: {
    onClickComment(id) {
      if (this.selectedId === id) this.selectedId = -1
      else this.selectedId = id
    },
  },
}
</script>

<style lang="scss" scoped>
.comment-tab {
  display: flex;
  flex-direction: column;
  height: 506px;
}

.dicussion-list {
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
}
</style>
