<template>
  <div class="camera-list-container">
    <div class="content-container" v-if="!uploadMode">
      <div v-if="navigation === navigationValueCameraList" class="content-title">
        <span>Camera List</span>
        <span v-if="cameraListEditMode === false && !currentTableSelectType" style="text-align: right">
          <Button
            @click="onClickEdit"
            :prependIcon="editIcon"
            :prependIconStyle="iconStyle"
            :prependIconDisabled="editIconDisabled"
            style="margin-left: 10px"
            :disabled="isObserver"
          >
            Edit
          </Button>
        </span>
        <span v-if="cameraListEditMode === true || currentTableSelectType === CAMERA_LIST_TAB_TYPE[3]" style="text-align: right"
          ><SelectAndActionButton @handler="onClickAction" :items="getSelectAndActionButtonItems" menu-class="camera-list-select-action-btn-menu"
        /></span>
      </div>
      <div v-else-if="navigation === navigationValueCameraAllocation" class="allocation-title">
        <div class="content-title clickable" @click="$emit('add')">
          <img src="@/assets/Exit_Icon_Black.svg" height="14px" style="margin-right: 20px" alt="prependIcon" />
          <div style="color: #afb6c3">Camera Allocation</div>
          <div style="color: #afb6c3; margin: 0 10px">></div>
          <div style="color: #8d90a2">{{ organization.name }}</div>
        </div>
        <div style="color: #8d90a2; margin: 0 10px">></div>
        <div>Add Camera</div>
        <div v-if="!!select.length" @click.stop="openAddConfirmDialog = true" class="button-add">
          <img src="@/assets/Check_White_Icon.svg" width="14px" style="margin-right: 10px" alt="icon" />
          <div>Add</div>
        </div>
        <div v-else @click.stop="" class="button-add disabled">
          <img src="@/assets/Check_Gray_Icon.svg" width="14px" style="margin-right: 10px" alt="icon" />
          <div>Add</div>
        </div>
      </div>
      <div class="toolbar-container">
        <InputSearchBar
          :value="searchKeyword"
          @input="onInputSearch"
          placeholder="Search"
          style="width: 300px; background-color: white; margin-right: 16px"
        ></InputSearchBar>
        <AddFilter v-model="selectedFilter" @input="onChangeFilter" :items="filters"></AddFilter>
        <v-spacer></v-spacer>
        <Button
          @click="onClickDownload"
          :disabled="downloadBtnDisabled"
          :prependIconDisabled="downloadDisabledIcon"
          :prependIcon="downloadIcon"
          :prependIconStyle="'width: 16px'"
          :style="
            downloadBtnDisabled
              ? 'background-color: #C9C9C9;color: #959595;width:130px'
              : 'background-color: var(--v-backgroundDownload);color: white;width:130px'
          "
          >Download
        </Button>
        <Button
          v-if="cameraListEditMode === true"
          @click="onClickUpload"
          :style="'background-color: #fff;color: #363636;width:136px;margin-left:16px;'"
          >Upload File
        </Button>
        <SelectAndActionButton
          v-if="cameraListEditMode === true || currentTableSelectType === CAMERA_LIST_TAB_TYPE[3]"
          @handler="onClickEditAction"
          :items="editActionItems"
          nudgeLeftValue="90px"
          menu-class="camera-list-select-action-btn-menu"
          menuStyle="width: 120px;"
          style="margin-left: 16px"
          @updateActionType="tableEditActionButtonType = $event"
          ref="cameraTableEditActionButton"
        />
        <SelectAndActionButton
          v-else-if="!cameraListEditMode && currentTableSelectType === CAMERA_LIST_TAB_TYPE[0] && cameraListTabModeActionAuthority"
          @handler="onClickReviewAction"
          :items="reviewtModeItems"
          itemStyle="height: 31px;"
          menu-class="camera-list-select-action-btn-menu"
          menuStyle="width: 144px;"
          nudgeLeftValue="112px"
          style="margin-left: 16px"
        />
        <!-- <Button
          v-else-if="!cameraListEditMode && currentTableSelectType === CAMERA_LIST_TAB_TYPE[1]"
          @click="onClickRollback"
          :disabled="!isSuperAdmin"
          :prependIcon="rollBackIcon"
          :prependIconDisabled="rollBackIconDisabled"
          :prependIconStyle="'width: 16px'"
          :style="`${isSuperAdmin ? defaultButtonStyle : disabledButtonStyle}`"
          >Rollback
        </Button> -->
      </div>
      <div class="toolbar-subcontainer">
        <div v-for="(item, index) in filters" :key="index">
          <DropdownSelect
            v-if="selectedFilters[item.value] !== undefined"
            menu-class="camera-list-filter-menu"
            :placeholder="item.text"
            :items="filterItems[item.value]"
            @clear="onClear(item.value)"
            @save="onSave(item.value, $event)"
          ></DropdownSelect>
        </div>
        <img
          v-if="Object.keys(selectedFilters).length"
          :src="require('@/assets/Exit_Icon_Black.svg')"
          alt="filter_clear"
          @click="onClickFilterClear"
        />
      </div>
      <div style="flex-grow: 1; overflow: auto; border-radius: 15px; background-color: white; margin-top: 15px">
        <Table
          ref="cameraListTable"
          :headers="headers"
          :items="cameraListTableItems"
          itemKey="id"
          :selectOnlyOneItem="tableEditActionButtonType === EDIT_MODE_ACTION_CONSTANTS.EDIT"
          @change="onChangeSelectItem"
          @click-column="onClickColumn"
          :checkHide="
            navigation !== Constants.NAVI_CAMERA_ALLOCATION && cameraListEditMode !== true && currentTableSelectType !== CAMERA_LIST_TAB_TYPE[3]
          "
          :checkAll="navigation === Constants.NAVI_CAMERA_ALLOCATION || cameraListEditMode === true"
        ></Table>
        <div v-if="!loading && !items.length" class="no-data">No data available.</div>
      </div>
    </div>
    <div class="content-container upload-file-container" v-else>
      <div v-if="navigation === navigationValueCameraList" class="content-title" style="margin-bottom: 20px">
        <span>Camera List</span>
      </div>
      <div class="upload-container">
        <span class="upload-container-title">Upload File</span>
        <div class="upload-container-steps">
          <div v-for="step in uploadSteps" :key="step.text" class="upload-container-step-item">
            <span :class="`step-text ${step.selected ? 'selected' : 'unselected'}`">{{ step.text }}</span>
            <div :class="`step-bottom ${step.selected ? 'selected' : 'unselected'}`" />
          </div>
        </div>
        <div class="upload-container-content">
          <UploadStageContent v-if="uploadSteps[0].selected" :selectedFileProp="selectedFile" @file-selected="selectedFile = $event" />
          <SelectActionStageContent
            v-if="uploadSteps[1].selected"
            :selected-action="selectedFileAction"
            @select-action="selectedFileAction = $event"
          />
          <PreviewStageContent
            v-if="uploadSteps[2].selected"
            ref="CameraListPreviewStageContent"
            :file-name="selectedFile.name"
            :headers="selectedFileHeaderData"
            :items="selectedFileItemData"
            :selected-action="selectedFileAction"
            :table-items="cameraListTableItems"
            @validate="uploadDataExists = $event"
          />
        </div>
        <div class="upload-container-btns">
          <v-btn class="upload-btn cancel" color="#D8D9DA" depressed rounded @click="onClickUploadBtn('cancel')">Cancel</v-btn>
          <div>
            <v-btn
              class="upload-btn previous"
              color="#3D51FB"
              :disabled="uploadBtnPrevDisabled"
              depressed
              rounded
              @click="onClickUploadBtn('previous')"
              >{{ uploadBtnPrevText }}</v-btn
            >
            <v-btn
              class="upload-btn next"
              color="#3D51FB"
              :disabled="uploadBtnNextDisabled"
              depressed
              :loading="uploadBtnNextLoading"
              rounded
              @click="onClickUploadBtn('next')"
              >{{ uploadBtnNextText }}</v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <DialogAddConfirm
      v-if="openAddConfirmDialog"
      v-model="openAddConfirmDialog"
      @ok="onRequestCallback"
      :organization="organization"
      :data="select"
      :all="items.length === select.length"
    ></DialogAddConfirm>
    <DialogCameraDetail
      v-if="openCameraDetailDialog"
      v-model="openCameraDetailDialog"
      @close="
        () => {
          openCameraDetailDialog = false
        }
      "
    ></DialogCameraDetail>
    <DialogAddNewCamera
      v-if="openAddNewCameraDialog"
      v-model="openAddNewCameraDialog"
      :addDialogEditMode="addDialogEditMode"
      :selectedCameraId="currentSelectCameraId"
      :camera="camera"
      @cancel="
        () => {
          openAddNewCameraDialog = false
          addDialogEditMode = false
        }
      "
      @next="
        () => {
          addDialogEditMode = false
          openAddNewCameraDialog = false
        }
      "
    ></DialogAddNewCamera>
    <CameraListTab v-if="!cameraListEditMode && currentTableSelectType !== CAMERA_LIST_TAB_TYPE[3]" />
    <DialogSubmitChanges
      v-if="openSubmitChangesDialog"
      v-model="openSubmitChangesDialog"
      :actionType="selectedAction"
      @resetTableList="resetTableList"
      @next="
        () => {
          openSubmitChangesDialog = false
        }
      "
    ></DialogSubmitChanges>
    <DialogDelete
      v-if="openDeleteDialog"
      v-model="openDeleteDialog"
      :all="allTableItemsSelected"
      :data="select"
      @next="
        ($event) => {
          openDeleteDialog = false
          if ($event) {
            select = []
            $toast('Successfully Deleted the Camera.', {
              position: 'bottom-center',
              toastClassName: 'requestSuccessToastClass',
              timeout: 7000,
            })
          }
        }
      "
    ></DialogDelete>
    <SnackBar :value="snackBarFlag" :color="'secondary'" :message="snackBarMessage"></SnackBar>
  </div>
</template>

<script>
import Constants from '@/constants'
import { mapState, mapActions, mapMutations } from 'vuex'
// input components
import AddFilter from '@desktop/superadminOrWorker/settings/camera-list/AddFilter'
import Button from '@desktop/superadminOrWorker/settings/Button.vue'
import DropdownSelect from '@desktop/superadminOrWorker/settings/DropdownSelect'
import InputSearchBar from '@common/InputSearchBar'
import SelectAndActionButton from '@common/SelectAndActionButton.vue'
// content components
import Table from '@desktop/superadminOrWorker/settings/Table'
import UploadStageContent from '@desktop/superadminOrWorker/settings/upload/UploadStageContent'
import SelectActionStageContent from '@desktop/superadminOrWorker/settings/upload/SelectActionStageContent'
import PreviewStageContent from '@desktop/superadminOrWorker/settings/upload/PreviewStageContent'
// excel file service function(s)
import { readXlsxFile, readCsvFile, writeDataToFile } from '@/cameraUploadExcelService'
// dialog components
import DialogAddConfirm from './DialogAddConfirm'
import DialogCameraDetail from '@desktop/superadminOrWorker/settings/camera-list/DialogCameraDetail.vue'
import DialogAddNewCamera from '@desktop/superadminOrWorker/settings/camera-list/DialogAddNewCamera.vue'
import CameraListTab from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/CameraListTab'
import DialogSubmitChanges from '@desktop/superadminOrWorker/settings/camera-list/DialogSubmitChanges.vue'
import DialogDelete from '@desktop/superadminOrWorker/settings/camera-list/DialogDeleteCameras.vue'
import SnackBar from '@common/SnackBarClipboard'
import { CAMERA_LIST_TAB_TYPE } from '@desktop/superadminOrWorker/settings/camera-list/feature/options'

import { superadmin } from '@/api'

const FILTER_CONSTANTS = {
  COUNTRY: 0,
  REGION: 1,
  COUNTY: 2,
  ORGANIZATION: 3,
  MONITORED: 4,
  CAMERA_GROUP: 5,
  PAN_FIXED: 6,
}

const EDIT_MODE_CONSTANTS = {
  REVIEW: 0,
  MERGE: 1,
  ARCHIVE: 2,
}

const REVIEW_MODE_CONSTANTS = {
  CONFIRM: 0,
  REJECT: 1,
}

const EDIT_MODE_ACTION_CONSTANTS = {
  ADD: 0,
  // DELETE: 1,
  EDIT: 1,
}

export default {
  components: {
    // input components
    AddFilter,
    Button,
    DropdownSelect,
    InputSearchBar,
    SelectAndActionButton,
    // content components
    Table,
    UploadStageContent,
    SelectActionStageContent,
    PreviewStageContent,
    // dialog components
    DialogAddConfirm,
    DialogCameraDetail,
    DialogAddNewCamera,
    CameraListTab,
    DialogSubmitChanges,
    DialogDelete,
    SnackBar,
  },
  props: {
    organization: Object,
    navigation: Number,
  },
  data() {
    return {
      Constants,
      headers: [
        { name: 'Name', key: 'name', clickable: true, valueKey: 'id' },
        { name: 'Region', key: 'region' },
        { name: 'County', key: 'county' },
        { name: 'Organization', key: 'organizations', capitalize: true },
        { name: 'Coordinate', key: 'coordinate' },
        { name: 'Monitored', key: 'monitored' },
        { name: 'Camera Group', key: 'cameraGroup' },
        { name: 'Priority', key: 'priority' },
      ],
      reviewtModeItems: [
        {
          buttonText: 'Confirm',
          text: 'Confirm',
          value: REVIEW_MODE_CONSTANTS.CONFIRM,
        },
        {
          buttonText: 'Reject',
          text: 'Reject',
          value: REVIEW_MODE_CONSTANTS.REJECT,
        },
      ],
      loading: false,
      downloadIcon: require('@/assets/Download_Filled_24px_Icon.svg'),
      downloadDisabledIcon: require('@/assets/Download_Filled_24px_Icon-light.svg'),
      rollBackIcon: require('@/assets/Replay-Icon.svg'),
      rollBackIconDisabled: require('@/assets/Replay-Icon_Disabled.svg'),
      editIcon: require('@/assets/Edit_Icon_Grey.svg'),
      editIconDisabled: require('@/assets/Edit_Icon_Disabled.svg'),
      iconStyle: 'width: 14px;',
      defaultButtonStyle: 'background-color: var(--v-backgroundDownload);color: white;width: 130px;margin-left: 16px;',
      disabledButtonStyle: 'background-color: #C9C9C9;color: #959595;width: 130px;margin-left: 16px;',
      select: [],
      uploadMode: false,
      uploadSteps: [
        {
          text: 'Upload',
          selected: true,
        },
        {
          text: 'Select Action',
          selected: false,
        },
        {
          text: 'Preview',
          selected: false,
        },
      ],
      uploadBtnNextLoading: false,
      selectedFile: null,
      selectedFileHeaderData: [],
      selectedFileItemData: [],
      uploadDataExists: false,
      selectedFileAction: 'add',
      selectedAction: EDIT_MODE_CONSTANTS.MERGE,
      cameraListEditMode: false,
      addDialogEditMode: false,
      selectedFilters: {},
      selectedFilter: null,
      searchKeyword: '',
      searchTimeout: null,
      openAddConfirmDialog: false,
      openCameraDetailDialog: false,
      openAddNewCameraDialog: false,
      openSubmitChangesDialog: false,
      openDeleteDialog: false,
      EDIT_MODE_CONSTANTS,
      EDIT_MODE_ACTION_CONSTANTS,
      FILTER_CONSTANTS,
      CAMERA_LIST_TAB_TYPE,
      REVIEW_MODE_CONSTANTS,
      tableEditActionButtonType: 0,
      filters: [
        {
          text: 'Country',
          value: 'country',
        },
        {
          text: 'Region',
          value: 'region',
        },
        {
          text: 'County',
          value: 'county',
        },
        {
          text: 'Organization',
          value: 'organization',
        },
        {
          text: 'Monitored',
          value: 'monitored',
        },
        {
          text: 'Camera Group',
          value: 'cameraGroup',
        },
        {
          text: 'Pan / Fixed',
          value: 'panFixed',
        },
      ],
      validFileExtensionList: ['xlsx'],
    }
  },
  computed: {
    ...mapState('superadmin', {
      items: (state) => state.camerasTableData,
      allocatedCameras: (state) => state.allocatedCameras,
      organizations: (state) => state.organizations,
      countries: (state) => state.countries,
      regions: (state) => state.regions,
      fullRegions: (state) => state.fullRegions,
      fullCounties: (state) => state.fullCounties,
      counties: (state) => state.counties,
      cameraGroups: (state) => state.cameraGroups,
      cameraProviders: (state) => state.providers,
      panFixed: (state) => state.panFixed,
      monitored: (state) => state.monitored,
      camera: (state) => state.camera,
      addedCameraList: (state) => state.addedCameraList,
      editedCameraList: (state) => state.editedCameraList,
      deletedCameraIdList: (state) => state.deletedCameraIdList,
      cameraListTabModeInfo: (state) => state.cameraListTabModeInfo,
      fullInfoCamerasList: (state) => state.fullInfoCameras,
    }),
    apiKeysList() {
      let result = []
      if (this.cameraProviders.length) {
        for (let i = 0; i < this.cameraProviders.length; i += 1) {
          result = result.concat(this.cameraProviders[i].api_keys)
        }
      }
      return result
    },
    isSuperAdmin() {
      return this.$store.state.user.user_role === Constants.ROLE_SUPER_ADMIN
    },
    isObserver() {
      return this.$store.state.user.user_role === Constants.ROLE_OBSERVER
    },
    isAdmin() {
      return this.$store.state.user.isAdmin
    },
    cameraListChangesExists() {
      return Object.keys(this.addedCameraList).length + Object.keys(this.editedCameraList).length + this.deletedCameraIdList.length
    },
    editModeItems() {
      const disabled = !this.cameraListChangesExists
      return [
        {
          buttonText: 'Review',
          disabled,
          text: 'Submit for review',
          value: EDIT_MODE_CONSTANTS.REVIEW,
        },
        {
          buttonText: 'Merge',
          disabled: disabled || !this.isSuperAdmin,
          text: 'Submit and merge',
          value: EDIT_MODE_CONSTANTS.MERGE,
        },
        {
          buttonText: 'Archive',
          disabled,
          text: 'Archive',
          value: EDIT_MODE_CONSTANTS.ARCHIVE,
        },
      ]
    },
    editActionItems() {
      return [
        {
          buttonText: 'Add',
          disabled: false,
          text: 'Add',
          value: EDIT_MODE_ACTION_CONSTANTS.ADD,
        },
        // {
        //   buttonText: 'Delete',
        //   disabled: !this.select.length,
        //   text: 'Delete',
        //   value: EDIT_MODE_ACTION_CONSTANTS.DELETE,
        // },
        {
          buttonText: 'Edit',
          disabled: this.select.length !== 1,
          text: 'Edit',
          value: EDIT_MODE_ACTION_CONSTANTS.EDIT,
        },
      ]
    },
    getSelectAndActionButtonItems() {
      return this.isAdmin ? this.editModeItems : [this.editModeItems[0], this.editModeItems[2]]
    },
    currentTableSelectType() {
      return this.cameraListTabModeInfo.type
    },
    reviewIdSelected() {
      return this.cameraListTabModeInfo.id
    },
    cameraListTabModeActionAuthority() {
      return this.cameraListTabModeInfo.authority
    },
    cameraListTableItems() {
      let result = []
      const camerasTableData = this.items.slice()

      for (let i = 0; i < camerasTableData.length; i += 1) {
        // grab info from editedCameraList if ID found there
        if (this.editedCameraList[camerasTableData[i].id] && !this.deletedCameraIdList.includes(camerasTableData[i].id)) {
          const editCamInfo = this.editedCameraList[camerasTableData[i].id]
          const cameraGroupId = editCamInfo?.information?.cameraGroupId
          const monitored = editCamInfo?.information?.monitored
          const coordinate = editCamInfo?.information?.coordinate.replace(' ', ', ')
          result.push({
            cameraGroup: monitored == 1 && cameraGroupId ? cameraGroupId : '-',
            coordinate,
            id: camerasTableData[i].id,
            monitored: monitored == 1 ? 'Y' : 'N',
            name: editCamInfo?.information?.cameraName,
            organizations: editCamInfo?.information?.organizations,
            priority: '-',
            county: this.fullCounties.filter((county) => county.id === editCamInfo?.information?.county)[0]?.name,
            region: this.fullRegions.filter((region) => region.id === editCamInfo?.information?.region)[0]?.name,
          })
        } else if (!this.deletedCameraIdList.includes(camerasTableData[i].id)) {
          result.push({
            ...camerasTableData[i],
            monitored: camerasTableData[i].monitored == 1 ? 'Y' : 'N',
            cameraGroup: camerasTableData[i].monitored == 1 && camerasTableData[i].cameraGroup ? camerasTableData[i].cameraGroup : '-',
            coordinate: camerasTableData[i].coordinate.length ? camerasTableData[i].coordinate : '-',
          })
        }
      }
      const addedCameraList = this.addedCameraList
      const addedCameraIdList = Object.keys(addedCameraList)
      for (let j = 0; j < addedCameraIdList.length; j += 1) {
        const camInfo = addedCameraList[addedCameraIdList[j]]
        const monitored = camInfo?.information?.monitored
        const cameraGroupId = camInfo?.information?.cameraGroupId
        const coordinate = camInfo?.information?.coordinate.replace(' ', ', ')
        result.push({
          isAddedCamItem: true,
          id: addedCameraIdList[j],
          coordinate,
          monitored: monitored == 1 ? 'Y' : 'N',
          cameraGroup: monitored == 1 && cameraGroupId ? cameraGroupId : '-',
          priority: '-',
          organizations: [],
          county: this.fullCounties.filter((county) => county.id === camInfo?.information?.county)[0]?.name,
          region: this.fullRegions.filter((region) => region.id === camInfo?.information?.region)[0]?.name,
          name: camInfo?.information?.cameraName,
        })
      }
      if (this.searchKeyword.trim() !== '') {
        result.filter((r) => {
          return (
            r.name.includes(this.searchKeyword) ||
            r.region.includes(this.searchKeyword) ||
            r.county.includes(this.searchKeyword) ||
            r.coordinate.includes(this.searchKeyword) ||
            r.monitored.includes(this.searchKeyword) ||
            r.cameraGroup.toString().includes(this.searchKeyword) ||
            r.priority.toString().includes(this.searchKeyword)
          )
        })
      }
      return result
    },
    downloadBtnDisabled() {
      return !this.cameraListTableItems.length
    },
    uploadBtnPrevDisabled() {
      return this.uploadSteps[0].selected
    },
    maxFileSizeExceeded() {
      return this.selectedFile?.size > 10 * 1024 * 1024
    },
    isValidFileExtension() {
      const fileExtension = this.selectedFile?.name.slice(this.selectedFile?.name.length - 4, this.selectedFile?.name.length)
      return this.validFileExtensionList.includes(fileExtension)
    },
    uploadBtnNextDisabled() {
      return (
        !this.selectedFile ||
        (this.uploadSteps[0].selected && (!this.selectedFile || this.maxFileSizeExceeded || !this.isValidFileExtension)) ||
        (this.uploadSteps[2].selected && (!this.selectedFile || !this.selectedFileItemData.length || !this.uploadDataExists))
      )
    },
    uploadBtnPrevText() {
      return 'Previous'
    },
    uploadBtnNextText() {
      return this.uploadSteps[2].selected ? 'Upload' : 'Next'
    },
    navigationValueCameraList() {
      return Constants.NAVI_CAMERA_LIST
    },
    navigationValueCameraAllocation() {
      return Constants.NAVI_CAMERA_ALLOCATION
    },
    currentSelectCameraId() {
      return this.select[0] ? this.select[0].id : -2
    },
    filterItems() {
      return {
        organization: this.organizations.map((v) => v.name),
        country: this.countries.map((v) => v.name),
        region: this.regions.map((v) => v.name),
        county: this.counties.map((v) => v.name),
        cameraGroup: this.cameraGroups.map((v) => v.id),
        panFixed: this.panFixed.map((v) => v.name),
        monitored: this.monitored.map((v) => v.name),
      }
    },
    allTableItemsSelected() {
      return this.$refs?.cameraListTable?.items.length === this.select.length
    },
  },
  async created() {
    await this.initCameraListTable()
  },
  mounted() {
    this.cameraListEditMode = this.$route.params.logId === 'edit'
    window.onpopstate = () => {
      this.cameraListEditMode = this.$route.params.logId === 'edit'
    }
  },
  beforeDestroy() {
    clearTimeout(this.searchTimeout)
    this.$store.dispatch('superadmin/resetCameraChanges')
    this.setCameras([])
  },
  methods: {
    ...mapMutations('superadmin', ['setCameras', 'duplicateSettingInDefaultMode']),
    ...mapActions('superadmin', [
      'getOrganizations',
      'getCameraSearchOptions',
      'getCameras',
      'getFullInfoCameras',
      'getProviders',
      'getRegions',
      'getCounties',
      'getCamera',
      'rollbackHistoryAction',
      'reviewRequestedReviewAction',
      'getFilterReviewCamListAction',
      'getFilterHistoryCamListAction',
      'getFilterArchiveCamListAction',
    ]),
    resetTableList() {
      this.$router.push('/workspace/settings/cameralist')
      this.cameraListEditMode = false
    },
    async onClickReviewAction(type) {
      if (!this.cameraListTabModeActionAuthority) return
      const actionResult = await this.reviewRequestedReviewAction({
        id: this.cameraListTabModeInfo.id,
        status: type === this.REVIEW_MODE_CONSTANTS.CONFIRM ? 'Confirm' : 'Reject',
      })
      if (actionResult) {
        this.$toast(`Request ${type === this.REVIEW_MODE_CONSTANTS.CONFIRM ? 'Confirmed' : 'Rejected'}.`, {
          position: 'bottom-center',
          toastClassName: 'requestSuccessToastClass',
          timeout: 7000,
        })
      }
    },
    async onClickRollback() {
      const rollbackResult = await this.rollbackHistoryAction()
      if (rollbackResult) {
        this.$toast('Rollback Completed.', {
          position: 'bottom-center',
          toastClassName: 'requestSuccessToastClass',
          timeout: 7000,
        })
      }
    },
    async initCameraListTable() {
      await Promise.all([this.getOrganizations(), this.getCameraSearchOptions(true), this.getProviders(), this.onRequestCallback(true)])
    },
    async onClickFilterClear() {
      this.selectedFilters = {}
      this.initCameraListTable()
    },
    onSnackbar() {
      this.$emit('snackbar', {
        snackbarOpen: true,
        snackbarColor: 'error',
        snackbarMessage: existCameras ? 'Successfully added, excluding the pre-existing cameras.' : 'Successfully Added.',
      })
    },
    onClickEditAction(event) {
      switch (event) {
        case EDIT_MODE_ACTION_CONSTANTS.ADD:
          this.openAddNewCameraDialog = true
          break
        case EDIT_MODE_ACTION_CONSTANTS.EDIT:
          if (this.select.length === 0) return
          else {
            this.openAddNewCameraDialog = true
            this.addDialogEditMode = true
          }
          break
        case EDIT_MODE_ACTION_CONSTANTS.DELETE:
          this.openDeleteDialog = true
          break
      }
    },
    onClickAction(event) {
      this.$log.debug('onClickAction', event)
      this.selectedAction = event
      this.openSubmitChangesDialog = true
    },
    onClickEdit() {
      this.cameraListEditMode = true
      this.duplicateSettingInDefaultMode()
      this.$refs.cameraListTable?.selectClearAll()
      this.$router.push({
        params: {
          logId: 'edit',
          name: 'cameralist',
          tab: 'settings',
        },
        path: '/workspace/settings/cameralist/edit',
      })
    },
    excelFileDownload(data) {
      const blob = new Blob([data], { type: 'application/octet-stream' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('download', 'Camera_List.xlsx')
      link.href = url
      link.click()
    },

    async onClickDownload() {
      const filter = this.createFilterObjectSet()
      filter.q = filter.keyword
      filter.id = this.reviewIdSelected
      let response = null
      if (this.currentTableSelectType === this.CAMERA_LIST_TAB_TYPE[0]) {
        response = await superadmin.getCameraListRequestExcelFile(filter)
      } else if (this.currentTableSelectType === this.CAMERA_LIST_TAB_TYPE[1]) {
        response = await superadmin.getCameraListHistoryExcelFile(filter)
      } else if (this.currentTableSelectType === this.CAMERA_LIST_TAB_TYPE[2]) {
        response = await superadmin.getCameraListArchiveExcelFile(filter)
      } else response = await superadmin.getCameraListExcelFile(filter)
      this.excelFileDownload(response.data)
    },
    async onClickUpload() {
      await this.onInputSearch('')
      await this.onClickFilterClear()
      this.uploadMode = true
    },
    async onClickColumn({ key, value }) {
      this.$log.debug('click column', key, value)
      if (Number.isInteger(value) && !this.editedCameraList[value]) {
        await this.getCamera(value)
        this.openCameraDetailDialog = true
      } else if (Number.isInteger(value) && this.editedCameraList[value]) {
        this.$store.commit('superadmin/setEditedCameraInfo', value)
        this.openCameraDetailDialog = true
      } else {
        this.$store.commit('superadmin/setNewlyAddedCameraInfo', value)
        this.openCameraDetailDialog = true
      }
    },
    createFilterObjectSet() {
      return {
        keyword: this.searchKeyword,
        organizationIds: this.selectedFilters['organization']
          ? this.selectedFilters['organization'].map((v) => this.organizations.find((o) => o.name === v).id)
          : [],
        countryIds: this.selectedFilters['country'] ? this.selectedFilters['country'].map((v) => this.countries.find((o) => o.name === v).id) : [],
        regionIds: this.selectedFilters['region'] ? this.selectedFilters['region'].map((v) => this.regions.find((r) => r.name === v).id) : [],
        countyIds: this.selectedFilters['county'] ? this.selectedFilters['county'].map((v) => this.counties.find((c) => c.name === v).id) : [],
        cameraGroupIds:
          this.selectedFilters['cameraGroup'] && this.selectedFilters['cameraGroup'].length > 0
            ? this.selectedFilters['cameraGroup'].map((v) => this.cameraGroups.find((m) => m.id === v).id)
            : [],
        panFixedIds: this.selectedFilters['panFixed'] ? this.selectedFilters['panFixed'].map((v) => this.panFixed.find((p) => p.name === v).id) : [],
        monitored: this.selectedFilters['monitored'] ? this.selectedFilters['monitored'].map((v) => this.monitored.find((p) => p.name === v).id) : [],
      }
    },
    onInputSearch(text) {
      this.$log.debug('CameraList#onInputSearch', text)
      this.searchKeyword = text
      clearTimeout(this.requestTimeout)
      this.requestTimeout = setTimeout(async () => {
        if (this.currentTableSelectType === this.CAMERA_LIST_TAB_TYPE[0]) {
          const filterSet = this.createFilterObjectSet()
          await this.getFilterReviewCamListAction({ filterSet, id: this.reviewIdSelected })
        } else if (this.currentTableSelectType === this.CAMERA_LIST_TAB_TYPE[1]) {
          const filterSet = this.createFilterObjectSet()
          await this.getFilterHistoryCamListAction({ filterSet, id: this.reviewIdSelected })
        } else if (this.currentTableSelectType === this.CAMERA_LIST_TAB_TYPE[2]) {
          const filterSet = this.createFilterObjectSet()
          await this.getFilterArchiveCamListAction({ filterSet, id: this.reviewIdSelected })
        } else await this.onRequestCallback(true)
      }, 500)
    },
    async onClear(filterName) {
      this.$log.debug('CameraList#onClear' + filterName)
      delete this.selectedFilters[filterName]
      await this.onRequestCallback(true)
    },
    async onSave(filterName, itemIndexes) {
      this.$log.debug('CameraList#onSave' + filterName)
      this.selectedFilters[filterName] = itemIndexes.map((index) => this.filterItems[filterName][index])
      if (filterName === 'country') {
        //하위 필터인 region, county를 초기화
        if (this.selectedFilters.region) {
          if (this.selectedFilters.county) {
            delete this.selectedFilters.region
            await this.onClear('county')
            this.selectedFilters.county = []
          } else {
            await this.onClear('region')
          }
          this.selectedFilters.region = []
        }
        const countryIds = this.selectedFilters.country.map((name) => this.countries.find((country) => country.name === name).id)
        await this.getRegions(countryIds)
      } else if (filterName === 'region') {
        //하위 필터인 county를 초기화
        if (this.selectedFilters.county) {
          await this.onClear('county')
          this.selectedFilters.county = []
        }
        const regionIds = this.selectedFilters.region.map((name) => this.regions.find((region) => region.name === name).id)
        await this.getCounties(regionIds)
      }
      await this.onRequestCallback(true)
    },
    onChangeSelectItem(select) {
      this.select = select
    },
    onChangeFilter(filter) {
      if (this.selectedFilters[filter] === undefined) {
        this.selectedFilters[filter] = []
      }
      this.selectedFilter = null
      this.$log.debug('onChangeFilter', filter, this.select)
    },
    async onClickUploadBtn(buttonType) {
      const selectedUploadStepIndex = this.uploadSteps.map((i) => i.selected).indexOf(true)
      this.$log.debug('selectedUploadStepIndex', selectedUploadStepIndex)
      switch (buttonType) {
        case 'cancel':
          this.editMode = false
          this.uploadMode = false
          this.selectedFile = null
          this.selectedFileHeaderData = []
          this.selectedFileItemData = []
          this.selectedFileAction = 'add'
          if (!this.uploadSteps[0].selected) {
            this.uploadSteps[selectedUploadStepIndex].selected = false
            this.uploadSteps[0].selected = true
          }
          break
        case 'previous':
          if (this.uploadSteps[1].selected) {
            this.selectedFileHeaderData = []
            this.selectedFileItemData = []
          }
          this.uploadSteps[selectedUploadStepIndex].selected = false
          this.uploadSteps[selectedUploadStepIndex - 1].selected = true
          break
        case 'next':
          this.uploadBtnNextLoading = true
          if (this.uploadBtnNextText === 'Next') {
            if (this.uploadSteps[1].selected) {
              this.nextBtnLoading = true
              await new Promise((r) => setTimeout(r, 800))
              try {
                if (this.selectedFile.type === 'text/csv') {
                  const pureFileData = await readCsvFile(this.selectedFile)
                  this.selectedFileHeaderData = pureFileData[1]
                  this.selectedFileItemData = pureFileData.slice(2, pureFileData.length)
                } else {
                  const pureFileData = await readXlsxFile(this.selectedFile)
                  this.selectedFileHeaderData = pureFileData[0][1]
                  this.selectedFileItemData = pureFileData[0].slice(2, pureFileData[0].length)
                }
                this.$log.debug('selectedFileHeaderData', this.selectedFileHeaderData)
                this.$log.debug('selectedFileItemData', this.selectedFileItemData)
              } catch (e) {
                this.$log.debug('CameraList excel/csv file parse error', e)
              }
              this.nextBtnLoading = false
            }
            this.uploadSteps[selectedUploadStepIndex].selected = false
            this.uploadSteps[selectedUploadStepIndex + 1].selected = true
          } else if (this.uploadBtnNextText === 'Upload') {
            const uploadData = this.$refs.CameraListPreviewStageContent.validData
            if (this.selectedFileAction === 'add') {
              this.$store.dispatch('superadmin/addCameraListWithUploadData', uploadData)
            } else if (this.selectedFileAction === 'replace') {
              this.$store.dispatch('superadmin/replaceCameraListWithUploadData', uploadData)
            }
            this.editMode = false
            this.uploadMode = false
            this.selectedFile = null
            this.selectedFileHeaderData = []
            this.selectedFileItemData = []
            this.selectedFileAction = 'add'
            this.uploadSteps[selectedUploadStepIndex].selected = false
            this.uploadSteps[0].selected = true
          }
          this.uploadBtnNextLoading = false
          break
      }
    },
    async onRequestCallback(success) {
      this.$log.debug('CameraList#onRequestCallback', success, this.openAddConfirmDialog)
      if (success) {
        if (this.openAddConfirmDialog) {
          // check existing cameras
          let existCameras = false
          const items = this.allocatedCameras
          const select = this.select
          for (let i = 0; i < items.length; i++) {
            const itemId = items[i].id
            for (let i = 0; i < select.length; i++) {
              const selectId = select[i].id
              if (itemId === selectId) {
                existCameras = true
                break
              }
            }
            if (existCameras) break
          }
          this.$emit('snackbar', {
            snackbarOpen: true,
            snackbarColor: 'secondary',
            snackbarMessage: existCameras ? 'Successfully added, excluding the pre-existing cameras.' : 'Successfully Added.',
          })
          this.$emit('add')
        }
        this.loading = true
        try {
          // this.setCameras([])
          const filterSet = this.createFilterObjectSet()
          const getSelectedXTableActionPayload = {
            id: this.cameraListTabModeInfo.id,
            filterSet,
          }
          switch (this.currentTableSelectType) {
            case this.CAMERA_LIST_TAB_TYPE[0]: // Requested
              this.getFilterReviewCamListAction(getSelectedXTableActionPayload)
              break
            case this.CAMERA_LIST_TAB_TYPE[1]: // History
              this.getFilterHistoryCamListAction(getSelectedXTableActionPayload)
              break
            case this.CAMERA_LIST_TAB_TYPE[2]: // Archived
              this.getFilterArchiveCamListAction(getSelectedXTableActionPayload)
              break
            // case this.CAMERA_LIST_TAB_TYPE[3]: // Edit
            //   await Promise.all([this.setCameras([]), this.getFullInfoCamerasEdit(filterSet)])
            //   break
            default:
              this.setCameras([])
              await Promise.all([this.getCameras(filterSet), this.getFullInfoCameras(filterSet)])
              break
          }
        } catch (e) {
          this.$log.error(e)
          this.$emit('snackbar', {
            snackbarOpen: true,
            snackbarColor: 'error',
            snackbarMessage: 'Server error occurred.',
          })
        }
        this.loading = false
      } else {
        this.$emit('snackbar', {
          snackbarOpen: true,
          snackbarColor: 'error',
          snackbarMessage: 'Server error occurred.',
        })
      }
      this.select = []
    },
  },
}
</script>

<style lang="scss" scoped>
.camera-list-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.contents-row {
  width: 100%;
}

.content-container {
  display: flex;
  flex-direction: column;
  height: 0;
  flex-grow: 1;
  margin: 40px 30px 20px;

  &.upload-file-container {
    margin-bottom: 60px;
  }

  .allocation-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    height: 34px;
    color: #3d51fb;

    .button-add {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 34px;
      background: #3d51fb;
      border-radius: 30px;
      font-weight: 500;
      font-size: 15px;
      color: white;
      margin-left: 20px;
      cursor: pointer;

      &.disabled {
        background: #0000001f;
        color: #00000042;
        cursor: default;
      }
    }
  }

  .content-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    height: 34px;
    color: #3d51fb;

    &.clickable {
      cursor: pointer;
    }

    .button-add {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 34px;
      background: #3d51fb;
      border-radius: 30px;
      font-weight: 500;
      font-size: 15px;
      color: white;
      margin-left: 20px;
      cursor: pointer;

      &.disabled {
        background: #0000001f;
        color: #00000042;
        cursor: default;
      }
    }
  }

  .toolbar-container {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin: 20px 0 0;
  }

  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #8d90a2;
    font-size: 14px;
  }

  .toolbar-subcontainer {
    display: flex;
    align-items: center;
    margin-top: 8px;

    > div:nth-of-type(1) {
      margin-left: 0;
    }

    > div {
      margin-left: 10px;
    }
  }
}

.upload-container {
  display: flex;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px 40px;
  flex-direction: column;
  height: 0;
  flex-grow: 1;
  overflow: auto;

  .upload-container-title {
    color: #3d51fb;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .upload-container-steps {
    display: flex;

    .upload-container-step-item {
      height: 30px;
      width: 130px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-right: 20px;

      .step-text {
        font-weight: 600;
        font-size: 16px;

        &.selected {
          color: #3d51fb;
        }

        &.unselected {
          color: #d9d9d9;
        }
      }

      .step-bottom {
        height: 4px;
        width: 100%;
        border-radius: 4px 4px 0px 0px;
        &.selected {
          background-color: #3d51fb;
        }

        &.unselected {
          background-color: #d9d9d9;
        }
      }
    }
  }

  .upload-container-content {
    display: flex;
    flex-grow: 1;
    padding: 15px 20px 0px 20px;
    overflow: hidden;
  }

  .upload-container-btns {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .upload-btn {
      height: 38px;
      width: 120px;
      font-weight: 500;
      font-size: 15px;
      letter-spacing: 0;

      &.cancel {
        color: #43425d;
      }

      &.previous {
        color: #ffffff;
        margin-right: 15px;
      }

      &.next {
        color: #ffffff;
      }

      &.v-btn--disabled {
        background-color: #c9c9c9 !important;
        color: #959595 !important;
      }
    }
  }
}
</style>
