<template>
  <div :class="{ 'log-asidebar': true, 'log-asidebar--open': value }">
    <div class="log-title">Next</div>
    <div class="log-list--wrapper">
      <div class="log-list">
        <div class="log-item-empty" v-if="!logs.length">No Data</div>
        <div class="log-item" v-for="log in logs" :key="log.id">
          <div class="log-info">{{ getLogText(log) }}</div>
          <div class="timer-wrap">
            <Timer :startTime="getStartTime(log)" :endTime="getEndTime(log)" @expire="$emit('expire', log)"></Timer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/util'
import Timer from './Timer'
import moment from 'moment'

export default {
  components: { Timer },
  props: {
    value: {
      // v-model Boolean
      type: Boolean,
      default: false,
    },
    logs: Array,
    /**
     * emit events
     * v-model Boolean
     * expire
     */
  },
  data() {
    return {
      isOpenRecent: false,
      isOpenSite: false,
      selectSite: -1,
      selectId: -1,
    }
  },
  methods: {
    getStartTime(log) {
      const momentDate = moment(log.created_at).valueOf()
      return momentDate
    },
    getEndTime(log) {
      const momentDate = moment(log.expire_time).valueOf()
      return momentDate
    },
    getLogText(log) {
      return `[${Util.getOffsetDate(log.created_at, log.offset)}] ${log.camera_name}`
    },
  },
}
</script>

<style lang="scss" scoped>
.log-asidebar {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 30px;
  height: calc(100% - 30px);
  background-color: #fff;
}

.log-title {
  display: none;
}

.log-list--wrapper {
  display: none;
}

.log-asidebar--open {
  .log-title {
    display: flex;
    align-items: center;
    height: 50px;
    margin: 0 30px 15px 20px;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    border-radius: 15px;
    background-color: #3d51fb;
    padding-left: 20px;
  }

  .log-list--wrapper {
    display: flex;
    height: 0;
    flex-grow: 1;
  }

  .log-list {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;

    .log-item-empty {
      height: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f9f9f9;
      border-radius: 15px;
      margin: 0px 30px 0 20px;
      font-weight: 500;
      font-size: 16px;
      color: #afb6c3;
    }
  }

  .log-list::-webkit-scrollbar {
    width: 5px;
  }
  .log-list::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .log-list::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #b5b5b5;
  }
  .log-list::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  .log-item {
    height: 90px;
    padding: 15px 20px 10px;
    margin: 0px 30px 10px 20px;
    background-color: #f9f9f9;
    border: 1px solid #d3d5dd;
    border-radius: 15px;
  }

  .log-info {
    position: relative;
    display: block;
    color: #363636;
    font-weight: 500;
    font-size: 16px;
  }

  .timer-wrap {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
