<template>
  <div :class="{ 'status-tab': true, active: tabExpand, 'table-info-open': currentTableInfoActive && !tabExpand }" data-testid="Camera_List_Tab">
    <div class="tab-close" v-if="tabExpand" @click="closeTab">
      <img :src="require('@/assets/Close_Line-16px-light.svg')" alt="close_list_tab" />
    </div>
    <div v-if="!tabExpand" class="status-tab-button">
      <div class="tab" @click="onClickTab(TAB_KEYS[0])">
        Change Request
        <span class="count">{{ requestCount }}</span>
      </div>
      <div class="tab history" @click="onClickTab(TAB_KEYS[1])">History</div>
      <div class="tab" @click="onClickTab(TAB_KEYS[2])">
        Discussion<span class="count">{{ discussionCount }}</span>
      </div>
    </div>
    <ChangeRequestTab v-else-if="tabType === TAB_KEYS[0]" :currentSelectedId="currentSelectedId" />
    <HistoryTab v-else-if="tabType === TAB_KEYS[1]" :selectedId="currentSelectedId"></HistoryTab>
    <DiscussionTab v-else-if="tabType === TAB_KEYS[2]"></DiscussionTab>
    <div v-if="currentTableInfoActive && !tabExpand" class="current-table-info">
      <div class="current-table-title">
        <span class="select-type"> {{ currentTableSelectType }} </span>
        <span class="request-subject">
          {{ currentTableSubject }}
        </span>
      </div>
      <span class="current-table-user"> {{ currentTabActionInfo }} {{ currentTableUserName }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { superadmin } from '@/api'
import ChangeRequestTab from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/ChangeRequestTab'
import HistoryTab from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/HistoryTab'
import DiscussionTab from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/DiscussionTab'
import { CAMERA_LIST_TAB_TYPE } from '@desktop/superadminOrWorker/settings/camera-list/feature/options'

const TAB_TYPE = {
  REQUEST: 'Request',
  HISTORY: 'History',
  DISCUSSION: 'Discussion',
}
const TAB_KEYS = ['REQUEST', 'HISTORY', 'DISCUSSION']

export default {
  components: { ChangeRequestTab, HistoryTab, DiscussionTab },
  data() {
    return {
      TAB_TYPE,
      TAB_KEYS,
      discussionCount: 0,
      requestCount: 0,
      tabExpand: false,
      tabType: '',
    }
  },
  async created() {
    await this.getRequestCounts()
  },
  watch: {
    cameraListTabModeInfo(nextInfo, prevInfo) {
      if (nextInfo !== prevInfo) this.closeTab()
    },
  },
  computed: {
    ...mapState('superadmin', {
      cameraListTabModeInfo: (state) => state.cameraListTabModeInfo,
    }),
    currentTableInfoActive() {
      return this.cameraListTabModeInfo.status
    },
    currentTableSelectType() {
      return this.cameraListTabModeInfo.type
    },
    currentTableSubject() {
      return this.cameraListTabModeInfo.subject
    },
    currentTableUserName() {
      return this.cameraListTabModeInfo.userName
    },
    currentSelectedId() {
      return this.cameraListTabModeInfo.id
    },
    currentTabActionInfo() {
      if (CAMERA_LIST_TAB_TYPE[0] === this.currentTableSelectType) return 'requested By'
      else if (CAMERA_LIST_TAB_TYPE[1] === this.currentTableSelectType) return 'merged By'
      else if (CAMERA_LIST_TAB_TYPE[2] === this.currentTableSelectType) return 'archived By'
    },
  },
  methods: {
    async getRequestCounts() {
      try {
        const { data } = await superadmin.getCameraListTabStatusCount()
        this.requestCount = !!data?.change_requests ? data.change_requests : 0
        this.discussionCount = !!data?.discussions ? data.discussions : 0
      } catch {
        this.requestCount = 0
        this.discussionCount = 0
      }
    },
    onClickTab(keys) {
      this.tabExpand = true
      this.tabType = keys
    },
    async closeTab() {
      this.tabExpand = false
      await this.getRequestCounts()
    },
  },
}
</script>

<style lang="scss" scoped>
.status-tab {
  transition: all 0.3s ease-out;
  position: fixed;
  bottom: 0px;
  right: 0;
  width: 463px;
  height: 56px;
  &.table-info-open {
    bottom: 28px;
  }
  &.active {
    width: 520px;
    height: 645px;
    background-color: #ffffff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 3px;
  }
  .status-tab-button {
    width: 100%;
    padding: 0 24px;
    display: flex;
    align-items: center;
    height: 58px;
    box-shadow: 5px -10px 20px rgba(61, 81, 251, 0.1);
    border-radius: 15px 15px 0px 0px;
    background-color: #fefefe;
    border-bottom: 5px solid #e9ebff;
    .tab {
      font-weight: 500;
      font-size: 15px;
      color: var(--f-text-black);
      cursor: pointer;
      &.history {
        margin: 0 49.5px;
      }
      .count {
        background-color: #e9ebff;
        color: #6d7dff;
        width: 29px;
        height: 24px;
        display: inline-block;
        text-align: center;
        border-radius: 12px;
        margin-left: 4px;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}
.current-table-info {
  height: 28px;
  background-color: #e9ebff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 24px;
  .current-table-title {
    display: flex;
    .select-type {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #3d51fb;
      margin-right: 8px;
    }
    .request-subject {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: var(--f-text-black);
      width: 188px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .current-table-user {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: var(--f-text-black);
    width: 151px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.tab-close {
  position: absolute;
  top: 30px;
  right: 24px;
  cursor: pointer;
}
</style>
