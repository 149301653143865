<template>
  <v-dialog v-model="value" width="520px" persistent>
    <v-card style="background-color: white">
      <v-card-text class="pa-0">
        <div class="user-delete-dialog-title">Remove Camera</div>
        <div class="user-delete-dialog-content">
          <div v-if="all" style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%">
            <img src="@/assets/DeleteAllUserID_Red_Icon.svg" width="60px" style="margin-bottom: 20px" />
            <div style="font-weight: 500; font-size: 15px; color: #363636; text-align: center">
              Do you really want to delete <span style="font-weight: 600; color: #ff3b30">all the data?</span>
            </div>
          </div>
          <div v-else>
            <div style="font-weight: 500; font-size: 15px; color: #363636; margin-top: 50px; margin-bottom: 20px; text-align: center">
              {{ getMainText }}
            </div>
            <div style="height: 120px; overflow: auto">
              <div v-for="(item, index) of sortedData" :key="index" class="item-delete">[{{ item.name }}]</div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-end" style="padding-right: 30px; padding-bottom: 20px">
          <button v-ripple @click="hide(false)" class="user-delete-dialog-button cancel">Cancel</button>
          <button v-ripple @click="ok" class="user-delete-dialog-button ok">Remove</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: Boolean,
    data: Array,
    all: Boolean,
  },
  computed: {
    ...mapState('superadmin', {
      deletedCameraIdList: (state) => state.deletedCameraIdList,
    }),
    getMainText() {
      return `Do you really want to delete ${this.data.length === 1 ? `${this.data.length} camera` : `${this.data.length} cameras`}?`
    },
    sortedData() {
      return this.data.slice().sort((a, b) => a.name.localeCompare(b.name))
    },
  },
  methods: {
    hide(showToast) {
      this.$emit('next', !!showToast)
    },
    ok() {
      this.$store.dispatch(
        'superadmin/addDeleteCameraIdsAction',
        this.data.map((v) => v.id)
      )
      this.hide(true)
    },
  },
}
</script>

<style lang="scss" scoped>
.user-delete-dialog-title {
  padding: 30px 40px 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #ff3b30;
}

.user-delete-dialog-content {
  display: flex;
  justify-content: center;
  height: 249px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;

  .item-delete {
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #363636;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.user-delete-dialog-button {
  font-weight: 500;
  font-size: 15px;
  width: 120px;
  height: 38px;
  border-radius: 30px;

  &.ok {
    background-color: #ff3b30;
    color: #ffffff;
  }

  &.cancel {
    background-color: var(--v-backgroundButtonCancel-base);
    color: var(--v-textButtonCancel);
    margin-right: 15px;
  }
}
</style>
