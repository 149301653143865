<template>
  <v-dialog v-model="value" width="520px" persistent>
    <v-card style="background-color: white">
      <v-card-text class="pa-0">
        <div class="user-add-dialog-title">{{ titleText }}</div>
        <div class="user-add-dialog-content">
          <div class="label-container">
            <span class="label" style="margin-top: 33px">Name</span>
            <span class="label" style="margin-top: 31px">Email</span>
            <span class="label" style="margin-top: 31px">Phone Number</span>
            <span class="label" style="margin-top: 31px">Memo</span>
            <span class="label" style="margin-top: 84px">Receive Alert</span>
          </div>
          <div class="label-container" style="margin-left: 0; width: 300px">
            <Input ref="username" v-model="username" :rules="rules" placeholder="Name" style="margin-top: 30px"></Input>
            <Input
              ref="email"
              v-model="email"
              type="email"
              :rules="emailRules"
              @focus="onFocusEmail"
              placeholder="Email (youremail@email.com)"
              style="margin-top: 9px"
            ></Input>
            <div style="display: flex">
              <Input
                ref="national"
                v-model="national"
                :rules="nationalRules"
                :transform="natinoalTransform"
                @focus="onFocusPhoneNumber"
                placeholder="+"
                width="60px"
                revertErrorMessage
                style="margin-top: 9px; margin-right: 10px; flex-basis: 60px"
              ></Input>
              <Input
                ref="phone"
                type="nubmer"
                v-model="phone"
                :rules="phoneNumberRules"
                :transform="phoneNumberTransform"
                @focus="onFocusPhoneNumber"
                placeholder="Phone Number"
                style="margin-top: 9px; flex-grow: 1"
              ></Input>
            </div>
            <InputBox
              ref="memo"
              v-model="memo"
              placeholder="Type a memo"
              maxlength="70"
              height="54px"
              :rules="memoRules"
              style="margin-top: 9px"
            ></InputBox>
            <div style="display: flex; align-items: center; margin-top: 11px; positino: relative; font-size: 14px; position: relative">
              <div>Email</div>
              <div
                @click="receiveEmail = !receiveEmail"
                style="display: flex; align-items: center; margin-left: 10px; margin-right: 30px; cursor: pointer"
              >
                <img v-if="receiveEmail" src="@/assets/Checkbox_Checked_Icon.svg" width="20px" />
                <img v-else src="@/assets/Checkbox_Unchecked_Grey_Icon.svg" width="20px" />
              </div>
              <div>SMS</div>
              <div @click="receiveSms = !receiveSms" style="display: flex; align-items: center; margin-left: 10px; cursor: pointer">
                <img v-if="receiveSms" src="@/assets/Checkbox_Checked_Icon.svg" width="20px" />
                <img v-else src="@/assets/Checkbox_Unchecked_Grey_Icon.svg" width="20px" />
              </div>
              <div v-if="receiveError" style="position: absolute; top: 24px; font-weight: normal; font-size: 10px; color: #ff3b30">
                Please fill out this field.
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-end" style="padding-right: 30px; padding-bottom: 20px">
          <button v-ripple @click="hide" class="user-add-dialog-button cancel">Cancel</button>
          <button v-ripple @click="ok" class="user-add-dialog-button ok">{{ okText }}</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogAdd from './DialogAdd'
import { superadmin } from '@/api'

export default {
  extends: DialogAdd,
  props: {
    data: {
      type: Object,
      defualt: {},
    },
  },
  data() {
    return {
      titleText: 'Edit Recipient',
      okText: 'Save',
      id: '',
    }
  },
  mounted() {
    this.id = this.data.id
    this.username = this.data.name
    this.email = this.data.email
    this.national = this.data.country_code
    this.phone = this.data.phone_number
    this.memo = this.data.memo
    this.receiveEmail = this.data.allow_email
    this.receiveSms = this.data.allow_sms
  },
  methods: {
    async requestAction() {
      this.$log.debug(
        'DialogEdit#requestAction',
        this.id,
        this.username,
        this.email,
        this.national,
        this.phone,
        this.memo,
        this.receiveEmail,
        this.receiveSms
      )
      await superadmin.updateNotiReceiver(this.id, {
        name: this.username,
        email: this.email,
        country_code: this.national,
        phone_number: this.phone,
        allow_email: this.receiveEmail,
        allow_sms: this.receiveSms,
        memo: this.memo,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-add-dialog-title {
  padding: 30px 40px 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #3d51fb;
}

.user-add-dialog-content {
  display: flex;
  height: 355px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #363636;

  .label-container {
    display: flex;
    flex-direction: column;
    margin-left: 51px;
    width: 118px;
  }
}

.user-add-dialog-button {
  font-weight: 500;
  font-size: 15px;
  width: 120px;
  height: 38px;
  border-radius: 30px;

  &.ok {
    background-color: #3d51fb;
    color: var(--v-textCount);
  }

  &.cancel {
    background-color: var(--v-backgroundButtonCancel-base);
    color: var(--v-textButtonCancel);
    margin-right: 15px;
  }
}
</style>
