<template>
  <v-dialog v-model="value" width="520px" persistent content-class="dialog-HFTD">
    <v-card style="background-color: white; height: 100%; padding: 30px 40px">
      <v-card-title class="modal-title">
        HFTD (Hight Fire Threat District)
        <img :src="require('@/assets/Close_Line-16px-light.svg')" alt="modal_close" @click="$emit('closeHFTDModal')" class="close-btn" />
      </v-card-title>
      <div class="pdf-download">
        <span style="cursor: pointer" @click="onClickPdfLink"> PDF </span>
      </div>
      <img :src="require('@/assets/High_FireThreat_District.jpg')" alt="High_FireThreat_District" width="440" height="579" />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickPdfLink() {
      window.open('https://www.cityofredding.org/home/showpublisheddocument/21488/636984398270830000')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-title {
  font-weight: 700 !important;
  font-size: 20px;
  line-height: 27px;
  color: #3d51fb;
  padding: 0 !important;
  margin-bottom: 20px;
}
.close-btn {
  position: absolute;
  top: 37px;
  right: 25px;
  cursor: pointer;
}

.pdf-download {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #363636;
  text-align: right;
  margin-bottom: 10px;
}
</style>
