<template>
  <div :style="timerTextColor" class="timer-area">
    <span class="txt-time">{{ time }}</span>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    startTime: Number,
    endTime: Number,
    /**
     * emit events
     * expire
     */
  },
  data() {
    return {
      timerTextColor: {},
      now: 0,
      time: '',
      timeout: null,
      minute4: 4 * 60 * 1000,
      minute3: 3 * 60 * 1000,
      minute2: 2 * 60 * 1000,
    }
  },
  created() {
    this.start()
  },
  destroyed() {
    this.stop()
  },
  methods: {
    start() {
      clearTimeout(this.timeout)
      this.now = moment().valueOf()
      if (this.endTime > this.now) {
        let timeout = (this.endTime - this.now) % 1000
        if (!timeout) {
          this.setTime()
          timeout = 1000
        }
        this.timeout = setTimeout(() => {
          this.setTime()
          this.setStyle()
          this.start()
        }, timeout)
      } else {
        this.$log.debug('expire', this.startTime, this.endTime, this.now)
        this.$emit('expire')
      }
    },
    stop() {
      this.$log.debug('stop')
      clearTimeout(this.timeout)
    },
    setTime() {
      this.time = this.toTimeString(this.endTime - this.now)
    },
    setStyle() {
      const time = this.endTime - this.now
      if (time >= this.minute4) {
        this.timerTextColor = { color: '#619CF4' }
      } else if (time >= this.minute2) {
        this.timerTextColor = { color: '#FF9800' }
      } else {
        this.timerTextColor = { color: '#FF5A5A' }
      }
    },
    toTimeString(time) {
      const date = moment(time).utc()
      return date.minute().toString().padStart(2, '0') + ':' + date.second().toString().padStart(2, '0')
    },
  },
}
</script>

<style lang="scss" scoped>
.timer-area {
  display: flex;
  align-items: center;
}

.txt-time {
  font-weight: bold;
  font-size: 26px;
}
</style>
