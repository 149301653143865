export function transFromDataToBox(data, canvasElement, imageObject, doNotCheckMinimum = false) {
  const originWidth = imageObject.width
  const originHeight = imageObject.height
  data = changeCoordinateFromCenterCenterToLeftTop(data)
  checkDrawingBoundary(data, originWidth, originHeight, doNotCheckMinimum)

  const clientWidth = canvasElement.clientWidth
  const clientHeight = canvasElement.clientHeight
  const clientRatio = clientWidth / clientHeight
  const originRatio = originWidth / originHeight
  let startX = 0
  let startY = 0
  let ratio
  if (clientRatio > originRatio) {
    // width longer
    const w = (clientHeight * originWidth) / originHeight
    startX = clientWidth / 2 - w / 2
    ratio = clientHeight / originHeight
  } else {
    const h = (clientWidth * originHeight) / originWidth
    startY = clientHeight / 2 - h / 2
    ratio = clientWidth / originWidth
  }
  const box = {
    id: null,
    x: startX + data.x * ratio,
    y: startY + data.y * ratio,
    width: data.width * ratio,
    height: data.height * ratio,
    originX: 0,
    originY: 0,
    data: null,
    complete: true,
  }
  box.originX = box.x
  box.originY = box.y
  box.logId = data.logId
  return box
}

export function transFromBoxToData(box, canvasElement, imageObject, logId) {
  const originWidth = imageObject.width
  const originHeight = imageObject.height
  const clientWidth = canvasElement.clientWidth
  const clientHeight = canvasElement.clientHeight
  const clientRatio = clientWidth / clientHeight
  const originRatio = originWidth / originHeight
  let startX = 0
  let startY = 0
  let ratio
  if (clientRatio > originRatio) {
    // width longer
    const w = (clientHeight * originWidth) / originHeight
    startX = clientWidth / 2 - w / 2
    ratio = originHeight / clientHeight
  } else {
    const h = (clientWidth * originHeight) / originWidth
    startY = clientHeight / 2 - h / 2
    ratio = originWidth / clientWidth
  }
  box = changeCoordinateFromLeftTopToCenterCenter(box)
  return {
    x: +((box.x - startX) * ratio).toFixed(0),
    y: +((box.y - startY) * ratio).toFixed(0),
    width: +(box.width * ratio).toFixed(0),
    height: +(box.height * ratio).toFixed(0),
    logId,
  }
}

function changeCoordinateFromCenterCenterToLeftTop(box) {
  return Object.assign({}, box, { x: box.x - box.width / 2, y: box.y - box.height / 2 })
}

function changeCoordinateFromLeftTopToCenterCenter(box) {
  return Object.assign({}, box, { x: box.x + box.width / 2, y: box.y + box.height / 2 })
}

function checkDrawingBoundary(box, originWidth, originHeight, doNotCheckMinimum) {
  if (!doNotCheckMinimum) {
    checDrawingMinimumSize(box)
  }
  // if log data is wrong, data is fixing
  if (box.x < 0) {
    box.width = box.width + box.x
    box.x = 0
  }
  if (box.y < 0) {
    box.height = box.height + box.y
    box.y = 0
  }
  // check boundary and modify size
  if (box.x + box.width > originWidth) {
    box.width = originWidth - box.x
  }
  if (box.y + box.height > originHeight) {
    box.height = originHeight - box.y
  }
}

function checDrawingMinimumSize(box) {
  // check box min size and position centerization
  if (box.width < 50) {
    let centerX = box.x + box.width / 2
    box.x = centerX - 25
    box.width = 50
  }
  if (box.height < 50) {
    let centerY = box.y + box.height / 2
    box.y = centerY - 25
    box.height = 50
  }
}
