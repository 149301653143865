<template>
  <div class="preview-content-container">
    <div class="content-top-area">
      <div class="error-text-container">
        <span class="error-text">{{ previewErrorText }}</span>
        <span class="error-download-text" v-if="errorData.length" @click="onClickDownloadErrorData()">Download the error data.</span>
      </div>
      <div class="file-name-text" v-if="errorData">{{ fileName }}</div>
    </div>
    <div style="overflow: auto; border-radius: 15px 15px 0px 0px">
      <PreviewTable
        v-if="dataErrorText === ''"
        :key="tableRenderList"
        :headers="Constants.cameraListUpload.templateFileHeaders"
        :items="tableRenderList"
        :selected-action="selectedAction"
      />
    </div>
    <div class="empty-table-contents" v-if="dataErrorText !== ''">{{ dataErrorText }}</div>
  </div>
</template>

<script>
import Constants from '@/constants'
import Validator from '@desktop/superadminOrWorker/settings/camera-list/feature/validator'
import { mapState } from 'vuex'
import { writeErrorDataToFile } from '@/cameraUploadExcelService'
import PreviewTable from '@desktop/superadminOrWorker/settings/upload/PreviewTable.vue'

export default {
  name: 'PreviewStageContent',
  components: { PreviewTable },
  props: {
    fileName: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    /**
     * headers format
     * [{
     *  name: string,
     *  key: string,
     *  width: string,
     *  align: string,
     * }]
     */
    items: {
      type: Array,
      default: () => [],
    },
    // 'add', 'replace'
    selectedAction: {
      type: String,
      default: 'add',
      required: true,
    },
    tableItems: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      Constants,
      tableRenderList: [],
      validData: [],
      errorData: [],
    }
  },
  mounted() {
    if (this.dataErrorText === '') {
      this.processItems(this.items)
    }
  },
  destroyed() {
    this.$emit('validate', false)
  },
  computed: {
    ...mapState('superadmin', {
      camerasList: (state) => state.camerasTableData,
      countries: (state) => state.countries,
      regions: (state) => state.regions,
      counties: (state) => state.counties,
      cameraProviders: (state) => state.providers,
      cameraGroups: (state) => state.cameraGroups,
      addedCameraMap: (state) => state.addedCameraList,
      editedCameraMap: (state) => state.editedCameraList,
      deletedCameraIdList: (state) => state.deletedCameraIdList,
      fullInfoCamerasList: (state) => state.fullInfoCameras,
    }),
    currentCamerasApiUrlList() {
      let result = []
      for (let i = 0; i < this.tableItems.length; i += 1) {
        if (this.tableItems[i].api_url) {
          result.push(this.tableItems[i].apiUrl)
        } else {
          result.push(this.fullInfoCamerasList.filter((cam) => cam.id === this.tableItems[i].id)[0]?.api_url)
        }
      }
      return result
    },
    currentCamerasIdList() {
      return this.camerasList.map((cam) => cam.id).sort()
    },
    dataErrorText() {
      if (this.selectedAction === 'replace' && (!this.cameraIdCountValid || !this.idColumnValid)) {
        return 'Wrong camera list. The existing camera list is missing.'
      }
      if (!this.isFileTemplateValid) {
        return 'Wrong template. Please use the right template.'
      }
      return ''
    },
    dataExists() {
      if (!this.items.length) return false
      let result = false
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.items[i].join('') !== '') {
          result = true
          break
        }
      }
      return result
    },
    cameraIdCountValid() {
      if (this.selectedAction === 'replace') {
        return this.items.length === this.currentCamerasIdList.length
      }
      return true
    },
    headersValid() {
      return this.headers.join(',') === Constants.cameraListUpload.templateFileHeaders.join(',')
    },
    columnCountValid() {
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.items[i].length > Constants.cameraListUpload.templateFileHeaders.length) {
          return false
        }
      }
      return true
    },
    isFileTemplateValid() {
      this.$log.debug('dataExists', this.dataExists)
      this.$log.debug('headersValid', this.headersValid)
      this.$log.debug('columnCountValid', this.columnCountValid)
      this.$log.debug('idColumnValid', this.idColumnValid)
      return this.dataExists && this.headersValid && this.columnCountValid && this.idColumnValid
    },
    idColumnValid() {
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.selectedAction === 'add' && ![undefined, '-'].includes(this.items[i][0])) {
          return false
        } else if (this.selectedAction === 'replace' && !this.currentCamerasIdList.includes(this.items[i][0])) {
          return false
        }
      }
      return true
    },
    cameraListTableItems() {
      let result = []
      const camerasTableData = this.camerasList.slice()
      for (let i = 0; i < camerasTableData.length; i += 1) {
        if (this.editedCameraMap[camerasTableData[i].id] && !this.deletedCameraIdList.includes(camerasTableData[i].id)) {
          result.push({ ...result[i], ...this.editedCameraMap[result[i].id] })
        } else if (!this.deletedCameraIdList.includes(camerasTableData[i].id)) {
          result.push(camerasTableData[i])
        }
      }
      const addedCameraMap = this.addedCameraMap
      const addedCameraIdList = Object.keys(addedCameraMap)
      for (let j = 0; j < addedCameraIdList.length; j += 1) {
        const camInfo = addedCameraMap[addedCameraIdList[j]]
        result.push({
          cameraGroup: camInfo?.information?.cameraGroupId,
          coordinate: camInfo?.information?.coordinate,
          county: camInfo?.information?.county,
          id: addedCameraIdList[j],
          monitored: camInfo?.information?.monitored.toLowerCase() === 'yes' ? 'Y' : 'N',
          name: camInfo?.information?.cameraName,
          organizations: [],
          priority: '-',
          region: camInfo?.information?.region,
        })
      }
      return result
    },
    errorTexts() {
      return Constants.cameraListUpload.errorTexts
    },
    cameraInformationOptions() {
      return Constants.cameraListUpload.cameraInformationOptions
    },
    previewErrorText() {
      if (this.errorData.length) {
        return `${this.errorData.length} Error${this.errorData.length === 1 ? '' : 's'} Found. Only the data without errors will be uploaded.`
      }
      return ''
    },
  },
  methods: {
    initData() {
      this.tableRenderList = []
      this.validData = []
      this.errorData = []
    },
    processItems() {
      // this.$log.debug('processedItems', this.items)
      this.initData()
      for (let i = 0; i < this.items.length; i += 1) {
        let row = [
          {
            text: this.selectedAction === 'add' ? '-' : this.items[i][0],
            // showErrorIcon: false,
            errorRow: false,
          },
        ]
        for (let j = 1; j < Constants.cameraListUpload.templateFileHeaders.length; j += 1) {
          const unfilteredValue = this.items[i][j]
          const text = !!this.items[i][j] ? this.items[i][j].toString() : ''
          switch (j) {
            case 1:
              // validate Camera Name
              const VALUE_EMPTY_CAMERA_NAME_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_CAMERA_NAME : ''
              const VALUE_EXCEED_MAX_LENGTH_CAMERA_NAME_ERROR_TEXT = text?.length > 40 ? this.errorTexts.EXCEED_MAX_LENGTH_CAMERA_NAME : ''
              const VALUE_FORMAT_INVALID_ERROR_TEXT = !Validator.cameraNameCharacterValidate(text) ? this.errorTexts.INVALID_CAMERA_NAME_FORMAT : ''

              // get camera names from Camera List table (already accounts for add/edit/delete store)
              const tableCameraNames = this.tableItems.length ? this.tableItems.map((cam) => cam.name) : []
              // get camera names from data list
              const currentValidDataCameraNames = this.validData.length ? this.validData.map((v) => v?.information?.name) : []
              // get name of camera using this item's Camera ID ('replace' only)
              const currentItemCameraName =
                this.selectedAction === 'add' ? null : this.tableItems.filter((tItem) => tItem.id === this.items[i][0])[0].name
              // combine table names with data names
              const allCameraNames =
                this.selectedAction === 'add'
                  ? tableCameraNames.concat(currentValidDataCameraNames)
                  : tableCameraNames.concat(currentValidDataCameraNames).filter((name) => name !== currentItemCameraName)

              const VALUE_DUPLICATE_NAME_ERROR_TEXT = allCameraNames.includes(text) ? this.errorTexts.DUPLICATE_CAMERA_NAME : ''

              row.push({
                text,
                errorText: [
                  VALUE_EMPTY_CAMERA_NAME_ERROR_TEXT,
                  VALUE_EXCEED_MAX_LENGTH_CAMERA_NAME_ERROR_TEXT,
                  VALUE_FORMAT_INVALID_ERROR_TEXT,
                  VALUE_DUPLICATE_NAME_ERROR_TEXT,
                ]
                  .filter((v) => v !== '')
                  .join(),
              })
              if (
                [
                  VALUE_EMPTY_CAMERA_NAME_ERROR_TEXT,
                  VALUE_EXCEED_MAX_LENGTH_CAMERA_NAME_ERROR_TEXT,
                  VALUE_FORMAT_INVALID_ERROR_TEXT,
                  VALUE_DUPLICATE_NAME_ERROR_TEXT,
                ].filter((v) => v !== '').length
              ) {
                row[0].errorRow = true
              }
              break
            case 2:
              // validate API URL
              const VALUE_EMPTY_API_URL_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_API_URL : ''
              const VALUE_API_URL_INVALID_ERROR_TEXT = !Validator.urlValidate(text) ? this.errorTexts.INVALID_API_URL_FORMAT : ''

              const tableCameraApiUrls = this.currentCamerasApiUrlList
              // get camera API URLs from data list
              const currentValidDataCameraApiUrls = this.validData.length ? this.validData.map((v) => v?.information?.apiUrl) : []
              // get camera API URL using this item's Camera ID ('replace' only)
              const currentItemCameraApiUrl =
                this.selectedAction === 'add' ? null : this.fullInfoCamerasList.filter((tItem) => tItem.id === this.items[i][0])[0].api_url
              // combine table API URLs with data API URLs
              const allCameraApiUrls =
                this.selectedAction === 'add'
                  ? tableCameraApiUrls.concat(currentValidDataCameraApiUrls)
                  : tableCameraApiUrls.concat(currentValidDataCameraApiUrls).filter((name) => name !== currentItemCameraApiUrl)

              const VALUE_DUPLICATE_API_URL_ERROR_TEXT = allCameraApiUrls.includes(text) ? this.errorTexts.DUPLICATE_API_URL : ''

              row.push({
                text,
                errorText: [VALUE_EMPTY_API_URL_ERROR_TEXT, VALUE_API_URL_INVALID_ERROR_TEXT, VALUE_DUPLICATE_API_URL_ERROR_TEXT]
                  .filter((v) => v !== '')
                  .join(),
              })
              if (
                [VALUE_EMPTY_API_URL_ERROR_TEXT, VALUE_API_URL_INVALID_ERROR_TEXT, VALUE_DUPLICATE_API_URL_ERROR_TEXT].filter((v) => v !== '').length
              ) {
                row[0].errorRow = true
              }
              break
            case 3:
              // validate Public URL
              if (text === '') {
                row.push({
                  text,
                  errorText: '',
                })
                break
              }
              const VALUE_PUBLIC_URL_INVALID_ERROR_TEXT = !Validator.isValidPublicUrl(text) ? this.errorTexts.INVALID_PUBLIC_URL_FORMAT : ''
              row.push({
                text,
                errorText: [VALUE_PUBLIC_URL_INVALID_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_PUBLIC_URL_INVALID_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 4:
              // validate Private URL
              if (text === '') {
                row.push({
                  text,
                  errorText: '',
                })
                break
              }
              const VALUE_PRIVATE_URL_1_INVALID_ERROR_TEXT = !Validator.urlValidate(text) ? this.errorTexts.INVALID_PRIVATE_URL_FORMAT : ''
              row.push({
                text,
                errorText: [VALUE_PRIVATE_URL_1_INVALID_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_PRIVATE_URL_1_INVALID_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 5:
              // validate Private URL 2
              if (text === '') {
                row.push({
                  text,
                  errorText: '',
                })
                break
              }
              const VALUE_PRIVATE_URL_2_INVALID_ERROR_TEXT = !Validator.urlValidate(text) ? this.errorTexts.INVALID_PRIVATE_URL_FORMAT : ''
              row.push({
                text,
                errorText: [VALUE_PRIVATE_URL_2_INVALID_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_PRIVATE_URL_2_INVALID_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 6:
              // validate Guide URL
              if (text === '') {
                row.push({
                  text,
                  errorText: '',
                })
                break
              }
              const VALUE_GUIDE_URL_INVALID_ERROR_TEXT = !Validator.urlValidate(text) ? this.errorTexts.INVALID_GUIDE_URL_FORMAT : ''
              row.push({
                text,
                errorText: [VALUE_GUIDE_URL_INVALID_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_GUIDE_URL_INVALID_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 7:
              // validate Latitude
              const VALUE_EMPTY_LATITUDE_ERROR_TEXT = Validator.isEmpty(unfilteredValue) ? this.errorTexts.EMPTY_LATITUDE : ''
              const VALUE_LATITUDE_INVALID_ERROR_TEXT =
                !Validator.isEmpty(unfilteredValue) && !Validator.isValidLatitude(unfilteredValue) ? this.errorTexts.INVALID_LATITUDE : ''
              row.push({
                text: unfilteredValue,
                errorText: [VALUE_EMPTY_LATITUDE_ERROR_TEXT, VALUE_LATITUDE_INVALID_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_LATITUDE_ERROR_TEXT, VALUE_LATITUDE_INVALID_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 8:
              // validate Longitude
              const VALUE_EMPTY_LONGITUDE_ERROR_TEXT = Validator.isEmpty(unfilteredValue) ? this.errorTexts.EMPTY_LONGITUDE : ''
              const VALUE_LONGITUDE_INVALID_ERROR_TEXT =
                !Validator.isEmpty(unfilteredValue) && !Validator.isValidLongitude(unfilteredValue) ? this.errorTexts.INVALID_LONGITUDE : ''
              row.push({
                text: unfilteredValue,
                errorText: [VALUE_EMPTY_LONGITUDE_ERROR_TEXT, VALUE_LONGITUDE_INVALID_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_LONGITUDE_ERROR_TEXT, VALUE_LONGITUDE_INVALID_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 9:
              // validate Altitude(m)
              const VALUE_EMPTY_ALTITUDE_ERROR_TEXT = Validator.isEmpty(unfilteredValue) ? this.errorTexts.EMPTY_ALTITUDE : ''
              const VALUE_ALTITUDE_INVALID_ERROR_TEXT =
                !Validator.isEmpty(unfilteredValue) && !Validator.isValidAltitude(unfilteredValue) ? this.errorTexts.INVALID_ALTITUDE : ''
              row.push({
                text: unfilteredValue,
                errorText: [VALUE_EMPTY_ALTITUDE_ERROR_TEXT, VALUE_ALTITUDE_INVALID_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_ALTITUDE_ERROR_TEXT, VALUE_ALTITUDE_INVALID_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 10:
              // validate Country
              const VALUE_EMPTY_COUNTRY_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_COUNTRY : ''
              // const VALUE_COUNTRY_INVALID_ERROR_TEXT = !Validator.isValidEnglishString(unfilteredValue) ? this.errorTexts.INVALID_COUNTRY : ''
              // const countryNamesList = this.countries.map((country) => country.name)
              // const VALUE_UNLISTED_COUNTRY_ERROR_TEXT = !countryNamesList.includes(text) ? this.errorTexts.UNLISTED_COUNTRY : ''
              row.push({
                text,
                value: this.countries.filter((country) => country.name === text)[0]?.id,
                errorText: [VALUE_EMPTY_COUNTRY_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_COUNTRY_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 11:
              // validate Region
              const VALUE_EMPTY_REGION_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_REGION : ''
              // const VALUE_REGION_INVALID_ERROR_TEXT = !Validator.isValidEnglishString(unfilteredValue) ? this.errorTexts.INVALID_REGION : ''
              // const regionNamesList = this.regions.map((region) => region.name)
              // const VALUE_UNLISTED_REGION_ERROR_TEXT = !regionNamesList.includes(text) ? this.errorTexts.UNLISTED_REGION : ''
              // const VALUE_REGION_INVALID_ERROR_TEXT = !Validator.isValidRegion(text)
              //   ? this.errorTexts.INVALID_REGION
              //   : ''
              row.push({
                text,
                value: this.regions.filter((region) => region.name === text)[0]?.id,
                errorText: [VALUE_EMPTY_REGION_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_REGION_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 12:
              // validate County
              const VALUE_EMPTY_COUNTY_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_COUNTY : ''
              // const VALUE_COUNTY_INVALID_ERROR_TEXT = !Validator.isValidEnglishString(unfilteredValue) ? this.errorTexts.INVALID_COUNTY : ''
              // const VALUE_UNLISTED_COUNTY_ERROR_TEXT = !countyNamesList.includes(text) ? this.errorTexts.UNLISTED_COUNTY : ''
              // const VALUE_COUNTY_INVALID_ERROR_TEXT = !Validator.isValidCounty(text)
              //   ? this.errorTexts.INVALID_COUNTY
              //   : ''
              row.push({
                text,
                value: this.counties.filter((county) => county.name === text)[0]?.id,
                errorText: [VALUE_EMPTY_COUNTY_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_COUNTY_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 13:
              // validate Camera Provider
              const VALUE_EMPTY_CAMERA_PROVIDER_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_CAMERA_PROVIDER : ''
              // const cameraProviderNamesList = this.cameraProviders.map((provider) => provider.name)
              // const VALUE_UNLISTED_CAMERA_PROVIDER_ERROR_TEXT = !cameraProviderNamesList.includes(text)
              //   ? this.errorTexts.UNLISTED_CAMERA_PROVIDER
              //   : ''
              row.push({
                text,
                value: this.cameraProviders.filter((prov) => prov.name === text)[0]?.id,
                errorText: [VALUE_EMPTY_CAMERA_PROVIDER_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_CAMERA_PROVIDER_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 14:
              // validate API Key
              const VALUE_EMPTY_API_KEY_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_API_KEY : ''

              row.push({
                text,
                value: this.cameraProviders.filter((prov) => prov.name === this.items[i][13])[0]?.api_keys.filter((apiKey) => apiKey.key === text)[0]
                  ?.id,
                errorText: [VALUE_EMPTY_API_KEY_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_API_KEY_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 15:
              // validate Monitored
              const VALUE_EMPTY_MONITORED_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_MONITORED : ''
              row.push({
                text,
                value: text === 'Yes' ? 1 : 2,
                errorText: [VALUE_EMPTY_MONITORED_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_MONITORED_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 16:
              // validate Camera Group
              const VALUE_EMPTY_CAMERA_GROUP_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_CAMERA_GROUP : ''
              const VALUE_CAMERA_GROUP_INVALID_ERROR_TEXT =
                !Validator.isEmpty(text) && !Validator.isValidMonitoredAndCameraGroupId(this.items[i][15], unfilteredValue)
                  ? this.errorTexts.INVALID_CAMERA_GROUP_ID
                  : ''
              row.push({
                text: unfilteredValue !== undefined ? text : '',
                value: unfilteredValue,
                errorText: [VALUE_EMPTY_CAMERA_GROUP_ERROR_TEXT, VALUE_CAMERA_GROUP_INVALID_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_CAMERA_GROUP_ERROR_TEXT, VALUE_CAMERA_GROUP_INVALID_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 17:
              // validate PTZ Value
              const VALUE_EMPTY_PTZ_VALUE_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_PTZ_VALUE : ''
              row.push({
                text,
                errorText: [VALUE_EMPTY_PTZ_VALUE_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_PTZ_VALUE_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 18:
              // validate Image Quality
              const VALUE_EMPTY_IMAGE_QUALITY_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_IMAGE_QUALITY : ''
              row.push({
                text,
                errorText: [VALUE_EMPTY_IMAGE_QUALITY_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_IMAGE_QUALITY_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 19:
              // validate Night Detection
              const VALUE_EMPTY_NIGHT_DETECTION_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_NIGHT_DETECTION : ''
              row.push({
                text,
                errorText: [VALUE_EMPTY_NIGHT_DETECTION_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_NIGHT_DETECTION_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 20:
              // validate IR Filter
              const VALUE_EMPTY_IR_FILTER_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_IR_FILTER : ''
              row.push({
                text,
                errorText: [VALUE_EMPTY_IR_FILTER_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_IR_FILTER_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 21:
              // validate Serviced
              const VALUE_EMPTY_SERVICED_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_SERVICED : ''
              row.push({
                text,
                errorText: [VALUE_EMPTY_SERVICED_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_SERVICED_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 22:
              // validate Evaluated
              const VALUE_EMPTY_EVALUATED_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_EVALUATED : ''
              row.push({
                text,
                errorText: [VALUE_EMPTY_EVALUATED_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_EVALUATED_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 23:
              // validate Internal Usage
              const VALUE_EMPTY_INTERNAL_USAGE_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_INTERNAL_USAGE : ''
              row.push({
                text,
                errorText: [VALUE_EMPTY_INTERNAL_USAGE_ERROR_TEXT].filter((v) => v !== '').join(),
              })
              if ([VALUE_EMPTY_INTERNAL_USAGE_ERROR_TEXT].filter((v) => v !== '').length) {
                row[0].errorRow = true
              }
              break
            case 24:
              // validate Within HFTD Area
              // const VALUE_EMPTY_WITHIN_HFTD_AREA_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_WITHIN_HFTD_AREA : ''
              row.push({
                text,
                errorText: [''].filter((v) => v !== '').join(),
              })
              break
            case 25:
              // validate Pan/Fixed
              // const VALUE_EMPTY_PAN_FIXED_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_PAN_FIXED : ''
              row.push({
                text,
                errorText: [''].filter((v) => v !== '').join(),
              })
              break
            case 26:
              // validate Camera Cleanness
              // const VALUE_EMPTY_CAMERA_CLEANNESS_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_CAMERA_CLEANNESS : ''
              row.push({
                text,
                errorText: [''].filter((v) => v !== '').join(),
              })
              break
            case 27:
              // validate Sky Area
              // const VALUE_EMPTY_SKY_AREA_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_SKY_AREA : ''
              row.push({
                text,
                errorText: [''].filter((v) => v !== '').join(),
              })
              break
            case 28:
              // validate False Positive Area
              // const VALUE_EMPTY_FALSE_POSITIVE_ERROR_TEXT = Validator.isEmpty(text) ? this.errorTexts.EMPTY_FALSE_POSITIVE_AREA : ''
              row.push({
                text,
                errorText: [''].filter((v) => v !== '').join(),
              })
              break
          }
          if (j === Constants.cameraListUpload.templateFileHeaders.length - 1) {
            this.tableRenderList.push(row)
            if (!row[0].errorRow) {
              // handle when row is valid
              this.validData.push({
                id: this.selectedAction === 'add' ? '-' : Number(row[0].text),
                information: {
                  cameraName: row[1].text,
                  country: row[10].value, // Number
                  region: row[11].value, // Number
                  county: row[12].value, // Number
                  coordinate: `${row[7].text} ${row[8].text}`,
                  altitude: row[9].text,
                  cameraProvider: row[13].value, // Number
                  apiKey: row[14].value, // Number
                  apiUrl: row[2].text,
                  publicUrl: row[3].text,
                  privateUrl1: row[4].text,
                  privateUrl2: row[5].text,
                  guide: row[6].text,
                  monitored: row[15].value, // Number
                  cameraGroupId: row[16].value, // Number
                },
                condition: {
                  PTZValue: this.cameraInformationOptions['PTZ_VALUE'][row[17].text],
                  nightDetection: this.cameraInformationOptions['NIGHT_DETECTION'][row[19].text],
                  IRfilter: this.cameraInformationOptions['IR_FILTER'][row[20].text],
                  serviced: this.cameraInformationOptions['SERVICED'][row[21].text],
                  evaluated: this.cameraInformationOptions['EVALUATED'][row[22].text],
                  internalUsage: this.cameraInformationOptions['INTERNAL_USAGE'][row[23].text],
                  panFixed: this.cameraInformationOptions['PAN_OR_FIXED'][row[25].text],
                  falsePositiveAreas: this.cameraInformationOptions['FALSE_POSITIVE_AREAS'][row[28].text],
                  imageQualityValue: this.cameraInformationOptions['IMAGE_QUALITY'][row[18].text],
                  skyAreaValue: this.cameraInformationOptions['SKY_AREA'][row[27].text],
                  cameraClearnessValue: this.cameraInformationOptions['CAMERA_CLEARNESS'][row[26].text],
                  HFTDAreaValue: this.cameraInformationOptions['HFTD_AREA'][row[24].text],
                },
                monitoring: {
                  monitoredValue: row[15].value, // Number
                  monitoringGroupValue: row[16].value, // Number or ""
                },
              })
            } else {
              // handle when row is invalid
              this.errorData.push({
                no: Number(row[0].text) !== NaN ? row[0].text : '-',
                rowIndex: i + 1,
                errorText: row
                  .slice(1, row.length)
                  .map((v) => v.errorText)
                  .join()
                  .replaceAll(',', ' ')
                  .trim(),
              })
            }
          }
        }
      }
      this.$emit('validate', this.validData.length)
    },
    onClickDownloadErrorData() {
      if (this.errorData.length) {
        this.$log.debug('click download error data', this.errorData)
        writeErrorDataToFile(this.fileName.replace('.xlsx', '_Error.xlsx'), this.errorData)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-content-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .content-top-area {
    height: 19px;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 8px;

    .error-text-container {
      display: flex;
      color: #ff3b30;

      .error-text {
        color: #ff3b30;
        margin-right: 8px;
      }

      .error-download-text {
        font-weight: 600;
        font-size: 12px;
        text-decoration-line: underline;
        color: #ff3b30;
        cursor: pointer;
      }
    }

    .file-name-text {
      color: #363636;
    }
  }
}

.empty-table-contents {
  margin-top: 113px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ff3b30;
}
</style>
