<template>
  <table>
    <colgroup>
      <col v-for="(header, index) in headers" :key="index" span="1" :style="header.width ? `width: ${header.width}` : ''" />
    </colgroup>
    <thead>
      <tr>
        <th scope="colgroup" v-for="(header, index) in headers" :key="index" class="text-truncate">
          <div class="header-contents">
            <div :class="header.key === 'organizations' ? '' : 'header-text'" :style="index === 1 ? 'padding-left: 24px' : ''">
              {{ header }}
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, itemIndex) in items" :key="itemIndex">
        <td
          v-for="(header, index) in headers"
          :key="index"
          :align="header.align ? header.align : 'left'"
          :class="item[0].errorRow ? 'text-cell-error' : ''"
        >
          <div class="text-cell-container text-truncate">
            <img v-if="item[0].errorRow && index === 1" :src="require('@/assets/Fail-Circle_Filled_16px_Icon.svg')" style="margin-right: 8px" />
            <div v-else-if="!item[0].errorRow && index === 1" style="width: 24px" />
            {{ item[index].text }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PreviewTable',
  props: {
    /**
     * headers format
     * [{
     *  name: string,
     *  key: string,
     *  width: string,
     *  align: string,
     * }]
     */
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    // 'add', 'replace'
    selectedAction: {
      type: String,
      default: 'add',
      required: true,
    },
  },
  computed: {
    ...mapState('superadmin', {
      organizationsColorMap: (state) => state.organizationsColorMap,
    }),
    headerWidth() {
      return 100 / this.headers.filter((header) => !header.width).length + '%'
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  user-select: text !important;
}

table {
  table-layout: fixed;
  // width: 100%;
  background-color: white;
  border-collapse: collapse;

  thead {
    position: sticky;
    top: 0;
    background-color: #e9ebff;
    color: #6d7dff;
    font-size: 16px;
    line-height: 22px;
    text-align: left;

    tr {
      height: 46px;

      th {
        font-weight: 600;

        &:not(:nth-child(2)) {
          padding: 0 20px;
        }
      }
    }
  }

  tbody {
    font-size: 14px;
    line-height: 19px;
    color: black;

    tr {
      height: 44px;
      border-bottom: 1px solid #f9f9f9;

      td:not(:nth-child(2)) {
        padding: 0 20px;
      }
    }
  }
}

.header-contents {
  display: flex;
  align-items: center;

  .header-text {
    cursor: pointer;
    margin-right: 8px;
  }
}

.text-cell-error {
  background-color: #ff3b301a;
}

.text-cell-container {
  display: flex;
}
</style>
