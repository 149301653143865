<template>
  <div :class="{ 'discussion-comment-continer': true, disable }">
    <div :class="{ 'comment-wrapper': true, select: isSelect }">
      <div class="comment-header">
        <span class="comment-owner name">{{ userName }}</span>
        <span class="time"> {{ commentCreateTime }}</span>
        <img
          class="comment-check icon"
          :src="require('@/assets/Check_24px_Icon-blue.svg')"
          alt="approve"
          style="margin-left: auto"
          v-if="!isResolved && !loading"
          @click="onClickDiscussionResolve"
        />
        <img
          class="comment-replay icon"
          :src="require('@/assets/Replay_Line_24px_Icon-blue.svg')"
          alt="resolve"
          style="margin-left: auto"
          v-else-if="isResolved && !loading"
          @click="onClickDiscussionReplay"
        />
        <v-progress-circular v-else style="margin-left: auto" indeterminate :size="24" :width="3" color="#AFB6C3"></v-progress-circular>
      </div>
      <div class="content">{{ discussionContent }}</div>
      <CountItem :count="replyCount" :select="isSelect" @onClickCount="onClickComment" />
    </div>
    <div v-if="isSelect">
      <CommentReplyItem v-for="(reply, index) in discussionComment" :key="index" :reply="reply" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import CountItem from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/CountItem'
import CommentReplyItem from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/CommentReplyItem'
export default {
  components: { CountItem, CommentReplyItem },
  props: {
    discussion: {
      type: Object,
      default: {},
    },
    isResolved: {
      type: Boolean,
      default: false,
    },
    selectedId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  created() {
    moment.updateLocale('en', {
      relativeTime: {
        future: '%s',
        past: '%s',
        s: '1m',
        ss: '1m',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        w: '1w',
        ww: '%dw',
        M: '1M',
        MM: '%dM',
        y: '1y',
        yy: '%dy',
      },
    })
  },
  computed: {
    userName() {
      return this.discussion.user.username
    },
    discussionComment() {
      return this.discussion.comments === null
        ? []
        : this.discussion.comments.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
    },
    discussionContent() {
      return this.discussion.content
    },
    commentCreateTime() {
      return moment(this.discussion.created_at).fromNow()
    },
    replyCount() {
      return this.discussionComment.length
    },
    isSelect() {
      if (this.selectedId !== -1 && this.selectedId === this.discussion.id) return true
      else return false
    },
    disable() {
      if (this.selectedId !== -1 && this.selectedId !== this.discussion.id) return true
      else return false
    },
  },
  methods: {
    ...mapActions('superadmin', ['updateDiscussionStatusAction']),
    onClickComment() {
      if (this.disable) return
      this.$emit('onClickComment', this.discussion.id)
    },
    async onClickDiscussionReplay() {
      this.loading = true
      await this.updateDiscussionStatusAction({ id: this.discussion.id, isResolve: false })
      this.loading = false
    },
    async onClickDiscussionResolve() {
      this.loading = true
      await this.updateDiscussionStatusAction({ id: this.discussion.id, isResolve: true })
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.discussion-comment-continer {
  &.disable {
    opacity: 0.6;
    cursor: default;
  }
}
.comment-wrapper {
  margin-bottom: 16px;
  width: 472px;
  padding: 16px;
  border-radius: 15px;
  background: rgba(233, 235, 255, 0.24);
  &.select {
    margin-bottom: 8px;
  }
  .count {
    margin-top: 16px;
    cursor: pointer;
  }
}
.comment-header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  .icon {
    cursor: pointer;
  }
}
.time {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.name {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-right: 8px;
}

.content {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}
</style>
