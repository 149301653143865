<template>
  <div class="user-account-container">
    <div class="content-container">
      <div class="content-title">User Account</div>
      <div class="toolbar-container">
        <InputSearchBar
          @input="onInputSearch"
          placeholder="Search"
          style="width: 300px; background-color: white; margin-right: 10px"
        ></InputSearchBar>
        <v-spacer></v-spacer>
        <TimezoneFilter v-model="timezone" @input="onChangeTimezone" :items="timezoneList"></TimezoneFilter>
        <Button @click="openAddDialog = true" :prependIcon="addIcon" :prependIconStyle="iconStyle" style="margin-left: 10px">Add</Button>
        <Button
          @click="onClickEdit"
          :prependIcon="editIcon"
          :prependIconStyle="iconStyle"
          :prependIconDisabled="editIconDisabled"
          :disabled="select.length !== 1"
          style="margin-left: 10px"
        >
          Edit
        </Button>
        <Button
          @click="onClickDelete"
          :prependIcon="deleteIcon"
          :prependIconStyle="iconStyle"
          :prependIconDisabled="deleteIconDisabled"
          :disabled="select.length === 0"
          style="margin-left: 10px"
        >
          Delete
        </Button>
      </div>
      <div style="flex-grow: 1; overflow: auto; border-radius: 15px; background-color: white">
        <Table v-model="select" @change="onChangeSelectItem" :headers="headers" :items="items" itemKey="id" checkAll></Table>
        <div v-if="!loading && !items.length" class="no-data">No data available.</div>
      </div>
    </div>
    <DialogAdd v-if="openAddDialog" v-model="openAddDialog" @ok="onRequestCallback"></DialogAdd>
    <DialogEdit v-if="openEditDialog" v-model="openEditDialog" :data="editData" @ok="onRequestCallback"></DialogEdit>
    <DialogDelete
      v-if="openDeleteDialog"
      v-model="openDeleteDialog"
      :all="items.length === select.length"
      :data="select"
      @ok="onRequestCallback"
    ></DialogDelete>
  </div>
</template>

<script>
import InputSearchBar from '@common/InputSearchBar'
import Table from '@desktop/superadminOrWorker/settings/Table'
import TimezoneFilter from '@desktop/superadminOrWorker/settings/TimezoneFilter'
import Button from '@desktop/superadminOrWorker/settings/Button'
import DialogAdd from './DialogAdd'
import DialogEdit from './DialogEdit'
import DialogDelete from './DialogDelete'
import { mapState, mapMutations, mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: { InputSearchBar, Table, TimezoneFilter, Button, DialogAdd, DialogEdit, DialogDelete },
  data() {
    return {
      openAddDialog: false,
      openEditDialog: false,
      openDeleteDialog: false,
      addIcon: require('@/assets/Add_Icon.svg'),
      editIcon: require('@/assets/Edit_Icon_Grey.svg'),
      editIconDisabled: require('@/assets/Edit_Icon_Disabled.svg'),
      deleteIcon: require('@/assets/Delete_Icon_Red.svg'),
      deleteIconDisabled: require('@/assets/Delete_Icon_Disabled.svg'),
      iconStyle: 'width: 14px;',
      timezone: moment().tz('America/Los_Angeles').format('z'),
      headers: [
        { name: 'Organization', key: 'organization_name' },
        { name: 'ID', key: 'user_id' },
        { name: 'User Role', key: 'user_role' },
        { name: 'Last Login Time', key: 'last_login_time' },
      ],
      select: [],
      loading: false,
      editData: {},
      searchKeyword: '',
      searchTimeout: null,
    }
  },
  computed: {
    ...mapState('superadmin', {
      items: (state) => state.usersTableData,
      timezoneList: (state) => state.timezoneList.map((v) => v.name),
    }),
  },
  created() {
    this.setTimezone(this.timezone)
    this.onRequestCallback(true)
  },
  beforeDestroy() {
    clearTimeout(this.searchTimeout)
  },
  methods: {
    ...mapMutations('superadmin', ['setTimezone', 'setUsers']),
    ...mapActions('superadmin', ['getUsers']),
    onClickEdit() {
      this.$log.debug('UserAccount#onClickEdit', this.editData)
      this.editData = this.select[0]
      this.openEditDialog = true
    },
    onClickDelete() {
      this.$log.debug('UserAccount#onClickDelete', this.editData)
      this.editData = this.select
      this.openDeleteDialog = true
    },
    onInputSearch(text) {
      this.$log.debug('UserAccount#onInputSearch', text)
      this.searchKeyword = text
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.onRequestCallback(true)
      }, 500)
    },
    onChangeTimezone(timezone) {
      this.$log.debug('UserAccount#onChangeTimezone', timezone)
      this.setTimezone(timezone)
      this.setUsers()
    },
    onChangeSelectItem(select) {
      this.select = select
    },
    async onRequestCallback(success) {
      this.$log.debug('UserAccount#onRequestCallback', success, this.openAddDialog, this.openEditDialog, this.openDeleteDialog)
      if (success) {
        if (this.openAddDialog) {
          this.$emit('snackbar', {
            snackbarOpen: true,
            snackbarColor: 'secondary',
            snackbarMessage: 'Successfully Added.',
          })
        } else if (this.openEditDialog) {
          this.$emit('snackbar', {
            snackbarOpen: true,
            snackbarColor: 'secondary',
            snackbarMessage: 'Successfully Edited.',
          })
        } else if (this.openDeleteDialog) {
          this.$emit('snackbar', {
            snackbarOpen: true,
            snackbarColor: 'secondary',
            snackbarMessage: 'Successfully Deleted.',
          })
        }
        this.setUsers([])
        this.loading = true
        try {
          await this.getUsers(this.searchKeyword)
        } catch (e) {
          this.$log.error(e)
          this.$emit('snackbar', {
            snackbarOpen: true,
            snackbarColor: 'error',
            snackbarMessage: 'Server error occurred.',
          })
        }
        this.loading = false
      } else {
        this.$emit('snackbar', {
          snackbarOpen: true,
          snackbarColor: 'error',
          snackbarMessage: 'Server error occurred.',
        })
      }
      this.select = []
    },
  },
}
</script>

<style lang="scss" scoped>
.user-account-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content-container {
  display: flex;
  flex-direction: column;
  height: 0;
  flex-grow: 1;
  margin: 40px 30px 20px;

  .content-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    height: 34px;
    color: #3d51fb;
  }

  .toolbar-container {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin: 25px 0 15px;
  }
  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #8d90a2;
    font-size: 14px;
  }
}
</style>
