<template>
  <div class="organization-container">
    <div class="content-container">
      <div class="content-title">Organization</div>
      <div class="toolbar-container">
        <InputSearchBar
          @input="onInputSearch"
          placeholder="Search"
          style="width: 300px; background-color: white; margin-right: 10px"
        ></InputSearchBar>
        <v-spacer></v-spacer>
        <TimezoneFilter v-model="timezone" @input="onChangeTimezone" :items="timezoneList"></TimezoneFilter>
        <Button @click="openAddDialog = true" :prependIcon="addIcon" :prependIconStyle="iconStyle" style="margin-left: 10px">Add</Button>
        <Button
          @click="onClickEdit"
          :prependIcon="editIcon"
          :prependIconStyle="iconStyle"
          :prependIconDisabled="editIconDisabled"
          :disabled="select.length !== 1"
          style="margin-left: 10px"
          >Edit</Button
        >
      </div>
      <div style="flex-grow: 1; overflow: auto; border-radius: 15px; background-color: white">
        <Table v-model="select" @change="onChangeSelectItem" :headers="headers" :items="items" itemKey="id"></Table>
        <div v-if="!loading && !items.length" class="no-data">No data available.</div>
      </div>
    </div>
    <DialogAdd v-if="openAddDialog" v-model="openAddDialog" @ok="onRequestCallback"></DialogAdd>
    <DialogEdit v-if="openEditDialog" v-model="openEditDialog" :data="editData" @ok="onRequestCallback"></DialogEdit>
  </div>
</template>

<script>
import InputSearchBar from '@common/InputSearchBar'
import Table from '@desktop/superadminOrWorker/settings/Table'
import TimezoneFilter from '@desktop/superadminOrWorker/settings/TimezoneFilter'
import Button from '@desktop/superadminOrWorker/settings/Button'
import DialogAdd from './DialogAdd'
import DialogEdit from './DialogEdit'
import { mapState, mapMutations, mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: { InputSearchBar, Table, TimezoneFilter, Button, DialogAdd, DialogEdit },
  data() {
    return {
      openAddDialog: false,
      openEditDialog: false,
      addIcon: require('@/assets/Add_Icon.svg'),
      editIcon: require('@/assets/Edit_Icon_Grey.svg'),
      editIconDisabled: require('@/assets/Edit_Icon_Disabled.svg'),
      iconStyle: 'width: 14px;',
      timezone: moment().tz('America/Los_Angeles').format('z'),
      headers: [
        { name: 'Organization', key: 'name' },
        { name: 'Workspace', key: 'workspace' },
        { name: 'Contract Type', key: 'contract_type' },
        { name: 'Contract Period', key: 'contract_period' },
      ],
      select: [],
      loading: false,
      editData: {},
      searchKeyword: '',
      searchTimeout: null,
    }
  },
  computed: {
    ...mapState('superadmin', {
      items: (state) => state.organizationTableData,
      timezoneList: (state) => state.timezoneList.map((v) => v.name),
    }),
  },
  async created() {
    this.setTimezone(this.timezone)
    try {
      await this.getContractTypes()
    } catch (e) {
      this.$log.error(e)
      this.$emit('snackbar', {
        snackbarOpen: true,
        snackbarColor: 'error',
        snackbarMessage: 'Server error occurred.',
      })
    }
    await this.onRequestCallback(true)
  },
  beforeDestroy() {
    clearTimeout(this.searchTimeout)
  },
  methods: {
    ...mapMutations('superadmin', ['setTimezone', 'setOrganizations']),
    ...mapActions('superadmin', ['getContractTypes', 'getOrganizations']),
    onClickEdit() {
      this.$log.debug('ContentOrganization#onClickEdit', this.editData)
      const item = this.items.find((v) => v.id === this.select[0].id)
      this.editData = item
      this.openEditDialog = true
    },
    onInputSearch(text) {
      this.$log.debug('ContentOrganization#onInputSearch', text)
      this.searchKeyword = text
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.onRequestCallback(true)
      }, 500)
    },
    onChangeTimezone(timezone) {
      this.$log.debug('ContentOrganization#onChangeTimezone', timezone)
      this.setTimezone(timezone)
      this.setOrganizations()
    },
    onChangeSelectItem(select) {
      this.select = select
    },
    async onRequestCallback(success) {
      this.$log.debug('ContentOrganization#onRequestCallback', success, this.openAddDialog, this.openEditDialog)
      if (success) {
        if (this.openAddDialog) {
          this.$emit('snackbar', {
            snackbarOpen: true,
            snackbarColor: 'secondary',
            snackbarMessage: 'Successfully Added.',
          })
        } else if (this.openEditDialog) {
          this.$emit('snackbar', {
            snackbarOpen: true,
            snackbarColor: 'secondary',
            snackbarMessage: 'Successfully Edited.',
          })
        }
        this.setOrganizations([])
        this.loading = true
        try {
          await this.getOrganizations({ keyword: this.searchKeyword })
        } catch (e) {
          this.$log.error(e)
          this.$emit('snackbar', {
            snackbarOpen: true,
            snackbarColor: 'error',
            snackbarMessage: 'Server error occurred.',
          })
        }
        this.loading = false
      } else {
        this.$emit('snackbar', {
          snackbarOpen: true,
          snackbarColor: 'error',
          snackbarMessage: 'Server error occurred.',
        })
      }
      this.select = []
    },
  },
}
</script>

<style lang="scss" scoped>
.organization-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content-container {
  display: flex;
  flex-direction: column;
  height: 0;
  flex-grow: 1;
  margin: 40px 30px 20px;

  .content-title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    height: 34px;
    color: #3d51fb;
  }

  .toolbar-container {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin: 25px 0 15px;
  }
  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #8d90a2;
    font-size: 14px;
  }
}
</style>
