<template>
  <v-dialog v-model="value" width="520px" persistent>
    <v-card style="background-color: white">
      <v-card-text class="pa-0">
        <div class="org-add-dialog-title">{{ titleText }}</div>
        <div class="org-add-dialog-content">
          <div class="label-container">
            <span class="label" style="margin-top: 33px">Organization</span>
            <span class="label" style="margin-top: 31px">Workspace</span>
            <span class="label" style="margin-top: 31px">Contract Type</span>
            <span class="label" style="margin-top: 31px">Contract Period</span>
          </div>
          <div class="label-container" style="margin-left: 0; width: 300px">
            <Input ref="organization" v-model="organization" :rules="organizationRules" placeholder="Organization" style="margin-top: 30px"></Input>
            <Input
              ref="workspace"
              v-model="workspace"
              :rules="workspaceRules"
              :transform="transformWorkspace"
              placeholder="Workspace"
              style="margin-top: 9px; margin-bottom: 9px"
            ></Input>
            <DropdownNormal
              ref="contract"
              v-model="contractType"
              :items="contractNameList"
              :rules="rules"
              :error="contractError"
              placeholder="Contract Type"
            ></DropdownNormal>
            <div style="display: flex; margin-top: 25px">
              <div style="min-width: 110px">
                <DropdownNormal
                  ref="timezone"
                  v-model="timezone"
                  :items="timezoneNameList"
                  :rules="rules"
                  :error="timezoneError"
                  placeholder="Time Zone"
                ></DropdownNormal>
              </div>
              <div style="margin-left: 10px; width: 100%">
                <ContractPeriod ref="daterange" v-model="dateRange" :rules="rules"></ContractPeriod>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-end" style="padding-right: 30px; padding-bottom: 20px">
          <button v-ripple @click="hide" class="org-add-dialog-button cancel">Cancel</button>
          <button v-ripple @click="ok" class="org-add-dialog-button ok">{{ okText }}</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Input from '@common/signup/Input'
import DropdownNormal from '@desktop/superadminOrWorker/settings/DropdownNormal'
import ContractPeriod from './ContractPeriod'
import { mapState } from 'vuex'
import { superadmin } from '@/api'
import moment from 'moment'
import rules from '@/rules'

export default {
  components: { Input, DropdownNormal, ContractPeriod },
  props: {
    value: Boolean,
    /**
     * emit events
     * ok
     */
  },
  data() {
    const self = this
    return {
      titleText: 'Add Organization',
      okText: 'Add',
      organization: '',
      workspace: '',
      contractType: '',
      contractError: false,
      timezone: '',
      timezoneError: false,
      dateRange: '',
      dateRangeError: false,
      rules: [(v) => !!v || 'Please fill out this field.'],
      organizationRules: [
        (v) => !!v || 'Please fill out this field.',
        (v) => !self.isDuplicatedOrganizationName || 'This organization already exists.',
      ],
      workspaceRules: [(v) => !!v || 'Please fill out this field.', () => !self.isDuplicatedWorkspaceName || 'This workspace already exists.'],
      transformWorkspace: (v) => {
        if (rules.regxSpecial.test(v)) {
          v = v.replaceAll(rules.regxSpecialGlobal, '')
        }
        if (rules.uppercase.test(v)) {
          v = v.replaceAll(rules.uppercaseGlobal, '')
        }
        return v
      },
      organizationTimeout: null,
      isDuplicatedOrganizationName: false,
      workspaceTimeout: null,
      isDuplicatedWorkspaceName: false,
    }
  },
  computed: {
    ...mapState('superadmin', {
      timezoneList: (state) => state.timezoneList,
      contractList: (state) => state.contract_types,
    }),
    timezoneNameList() {
      return this.timezoneList.map((v) => v.name)
    },
    contractNameList() {
      return this.contractList.map((v) => v.name)
    },
  },
  created() {
    this.$log.debug('DialogAdd#created')
    this.organization = ''
    this.workspace = ''
    this.contractType = ''
    this.timezone = ''
    this.dateRange = ''
  },
  watch: {
    organization(val) {
      if (val) {
        this.checkDuplicateOrganization()
      }
    },
    workspace(val) {
      if (val && !this.isEdit) {
        this.checkDuplicateWorkspace()
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.organizationTimeout)
    clearTimeout(this.workspaceTimeout)
  },
  methods: {
    hide() {
      this.$emit('input', false)
    },
    checkDuplicateOrganization() {
      clearTimeout(this.organizationTimeout)
      this.organizationTimeout = setTimeout(async () => {
        const duplicated = await superadmin.checkOrganizationName(this.organization)
        if (this.isEdit) {
          this.isDuplicatedOrganizationName = this.data.name !== this.organization && duplicated
        } else {
          this.isDuplicatedOrganizationName = duplicated
        }
        this.$refs.organization.validate()
      }, 500)
    },
    checkDuplicateWorkspace() {
      clearTimeout(this.workspaceTimeout)
      this.workspaceTimeout = setTimeout(async () => {
        const duplicated = await superadmin.checkWorkspaceName(this.workspace)
        this.isDuplicatedWorkspaceName = duplicated
        this.$refs.workspace.validate()
      }, 500)
    },
    async ok() {
      const organizationValid = this.$refs.organization.validate()
      const workspaceValid = this.$refs.workspace.validate()
      const contractValid = this.$refs.contract.validate()
      const timezoneValid = this.$refs.timezone.validate()
      const daterangeValid = this.$refs.daterange.validate()
      if (organizationValid && workspaceValid && contractValid && timezoneValid && daterangeValid) {
        try {
          await this.requestAction()
          this.$emit('ok', true)
          this.hide()
        } catch (e) {
          this.$log.error(e)
          this.$emit('ok', false)
        }
      }
    },
    async requestAction() {
      this.$log.debug('DialogAdd#requestAction', this.organization, this.workspace, this.contractType, this.timezone, this.dateRange)
      const timezone = this.timezoneList.find((v) => v.name === this.timezone)
      const contract_type = this.contractList.find((v) => v.name === this.contractType)
      const arrDateRange0 = this.dateRange[0].split('-').map((v) => parseInt(v))
      const arrDateRange1 = this.dateRange[1].split('-').map((v) => parseInt(v))
      const contract_start = moment()
        .utcOffset(timezone.offset)
        .set({ year: arrDateRange0[0], month: arrDateRange0[1] - 1, date: arrDateRange0[2] })
        .startOf('day')
        .utc()
        .format()
      const contract_end = moment()
        .utcOffset(timezone.offset)
        .set({ year: arrDateRange1[0], month: arrDateRange1[1] - 1, date: arrDateRange1[2] })
        .startOf('day')
        .utc()
        .format()
      await superadmin.createOrganizations({
        name: this.organization,
        workspace: this.workspace,
        contract_type_id: contract_type.id,
        contract_start,
        contract_end,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.org-add-dialog-title {
  padding: 30px 40px 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #3d51fb;
}

.org-add-dialog-content {
  display: flex;
  height: 249px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #363636;

  .label-container {
    display: flex;
    flex-direction: column;
    margin-left: 51px;
    width: 118px;
  }
}

.org-add-dialog-button {
  font-weight: 500;
  font-size: 15px;
  width: 120px;
  height: 38px;
  border-radius: 30px;

  &.ok {
    background-color: #3d51fb;
    color: var(--v-textCount);
  }

  &.cancel {
    background-color: var(--v-backgroundButtonCancel-base);
    color: var(--v-textButtonCancel);
    margin-right: 15px;
  }
}
</style>
