<template>
  <v-menu v-model="open" offset-y nudge-bottom="5px" :max-width="maxWidth" :disabled="readonly">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" :class="{ dropdown: true, open, select: !!value, 'error-status': errorFlag, readonly }">
        <div class="text-truncate">{{ value ? value : placeholder }}</div>
        <v-spacer></v-spacer>
        <img v-if="readonly" src="@/assets/icon-arrow-down-disabled.svg" width="10px" />
        <img v-else-if="errorFlag" src="@/assets/icon-arrow-down-error.svg" width="10px" />
        <img v-else-if="open" src="@/assets/icon-arrow-up-blue.svg" width="10px" />
        <img v-else src="@/assets/icon-arrow-down-grey.svg" width="10px" />
        <div class="error-status-text" :style="errorMessageStyle">{{ errorMessage }}</div>
      </div>
    </template>
    <div class="dropdown-container">
      <div class="wrapper">
        <div v-for="(item, index) in items" :key="index" @click="onClickItem(item)" class="item">
          <div class="text-truncate">{{ item }}</div>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
    },
  },
  data() {
    return {
      open: false,
      errorMessage: '',
    }
  },
  computed: {
    errorMessageStyle() {
      return this.errorMessage ? 'visibility: visible' : 'visibility: hidden'
    },
  },
  watch: {
    error(val) {
      this.errorFlag = val
    },
  },
  methods: {
    validate(item) {
      if (this.rules.length) {
        for (let i = 0; i < this.rules.length; i++) {
          const error = this.rules[i](item ? item : this.value)
          if (!error || typeof error == 'string') {
            this.errorFlag = true
            this.errorMessage = error
            break
          } else {
            this.errorFlag = false
            this.errorMessage = ''
          }
        }
      }
      return !this.errorFlag
    },
    onClickItem(item) {
      if (!this.readonly) {
        this.open = false
        this.$emit('input', item)
        setTimeout(() => {
          this.validate()
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  color: #00000061;
  font-size: 14px;
  line-height: 19px;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.12);
  padding: 0 10px 7px 8px;

  &.open {
    border-bottom: 1.5px solid #3b77ff;
    font-size: 14px;
    line-height: 19px;
    color: #363636;
  }

  &.select {
    color: #363636;
  }

  &.error-status {
    color: #ff3b30;
    border-bottom: 1px solid #ff3b30;
  }

  &.readonly {
    color: #00000042;
    border-bottom: 1.5px dashed #0000001f;
  }
}

.error-status-text {
  position: absolute;
  top: 31.5px;
  left: 0;
  font-size: 10px;
  font-weight: 400;
  padding-left: 8px;
  height: 11px;
  line-height: 11px;
  color: #ff3b30;
  white-space: nowrap;
}

.dropdown-container {
  width: 100%;
  max-height: 165px;
  padding: 10px 0;
  background: #ffffff;
  box-shadow: 5px 5px 10px rgba(61, 81, 251, 0.1);
  border-radius: 10px;
  overflow: auto;

  .item {
    display: flex;
    align-items: center;
    min-height: 30px;
    padding: 0 20px;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: #363636;
    cursor: pointer;

    &:hover {
      color: #ffffff;
      background: #6d7dff;
    }
  }
}
</style>
