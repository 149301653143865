<template>
  <v-dialog v-model="value" width="562px" persistent content-class="dialog-submit-changes">
    <v-card :style="`${actionType === EDIT_MODE_CONSTANTS.REVIEW ? 'height: 364px' : ''}`">
      <v-card-text class="dialog-card-text">
        <div class="dialog-title">{{ getDialogTitle }}</div>
        <div class="dialog-content">
          <div class="dialog-content-row">
            <div class="dialog-content-row-text">Subject</div>
            <v-text-field
              ref="subjectTextField"
              :class="subjectTextFieldValidated && subjectInputValue.trim() === '' ? 'subject-text-field-error-state' : ''"
              class="subject-text-field"
              dense
              :error="subjectTextFieldValidated && subjectInputValue.trim() === ''"
              :rules="[rules.required]"
              type="text"
              v-model="subjectInputValue"
              placeholder="e.g. ‘PG&E Phase 3 카메라 추가'"
              maxlength="40"
            ></v-text-field>
          </div>
          <div v-if="actionType === EDIT_MODE_CONSTANTS.REVIEW" class="dialog-content-row" style="margin-bottom: 10px; justify-content: flex-start">
            <div class="dialog-content-row-text" style="line-height: 35px">Reviewers</div>
            <span
              :class="{
                error: rrlDropdownErrorState,
                focus: rrlDropdownFocusState,
              }"
              class="multiselect-container"
            >
              <DropdownMultiSelect
                ref="changesDialogDropdownSelect"
                :items="rrlDropdownItems"
                :selected-items="rrlDropdownSelectedItemsList"
                :error="rrlDropdownErrorState"
                placeholder="Select"
                @focus="rrlDropdownFocusState = true"
                @blur="rrlDropdownFocusState = false"
                @click-item="onClickMultiSelectItem($event)"
              />
              <span v-if="rrlDropdownErrorState" class="error-text">Please fill out this field.</span>
            </span>
          </div>
          <div v-if="actionType === EDIT_MODE_CONSTANTS.REVIEW" class="chips-area">
            <div v-for="chip in rrlDropdownSelectedItemsList" :key="chip" class="request-recipient-chip">
              <span style="white-space: nowrap; margin-right: 20px">{{ chip.text }}</span>
              <v-icon small color="#3D51FB" class="chip-clear-icon" @click="onClickClearChip(chip)">clear</v-icon>
            </div>
          </div>
        </div>
        <div class="dialog-btn-container">
          <button @click="onClickCancel" class="btn cancel">Cancel</button>
          <button @click="onClickSubmit" class="btn submit" :loading="loading">{{ getSubmitBtnText }}</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// import Constants from '@/constants'
import DropdownMultiSelect from '@desktop/superadminOrWorker/settings/DropdownMultiSelect.vue'
import { mapState, mapActions } from 'vuex'

const EDIT_MODE_CONSTANTS = {
  REVIEW: 0,
  MERGE: 1,
  ARCHIVE: 2,
}

const USER_ROLE_WHITELIST = ['SUPER ADMIN', 'EDITOR', 'OBSERVER']

export default {
  name: 'DialogSubmitChanges',
  components: { DropdownMultiSelect },
  props: {
    value: Boolean,
    actionType: Number,
  },
  data() {
    return {
      subjectTextFieldValidated: false,
      subjectInputValue: '',
      rrlDropdownSelectedItemsList: [],
      rrlDropdownAtLeastOnceFocused: false,
      loading: false,
      rrlDropdownFocusState: false,
      rules: {
        required: (value) => !!value || 'Please fill out this field.',
      },
      EDIT_MODE_CONSTANTS,
    }
  },
  computed: {
    ...mapState('superadmin', {
      filteredUsers: (state) => state.usersTableData.filter((user) => USER_ROLE_WHITELIST.includes(user.user_role)),
    }),
    userInfo() {
      return this.$store.state.user
    },
    rrlDropdownItems() {
      return this.filteredUsers.filter((user) => user.id !== this.userInfo.id)
    },
    getDialogTitle() {
      return this.getSubmitBtnText + ' Your Changes'
    },
    getSubmitBtnText() {
      switch (this.actionType) {
        case EDIT_MODE_CONSTANTS.REVIEW:
          return 'Submit'
        case EDIT_MODE_CONSTANTS.MERGE:
          return 'Merge'
        case EDIT_MODE_CONSTANTS.ARCHIVE:
          return 'Archive'
      }
    },
    rrlDropdownErrorState() {
      return this.rrlDropdownAtLeastOnceFocused && !this.rrlDropdownSelectedItemsList.length
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.rrlDropdownAtLeastOnceFocused = false
      }
    },
  },
  async created() {
    await this.getUsers()
  },
  methods: {
    // TODO: need to check APIs for submit/merge/archive changes
    ...mapActions('superadmin', ['getUsers', 'submitChanges', 'mergeChanges', 'archiveChanges']),
    async onClickMultiSelectItem(item) {
      if (!this.rrlDropdownAtLeastOnceFocused) {
        this.rrlDropdownAtLeastOnceFocused = true
      }
      if (this.rrlDropdownSelectedItemsList.includes(item)) {
        const index = this.rrlDropdownSelectedItemsList.indexOf(item)
        this.rrlDropdownSelectedItemsList.splice(index, 1)
      } else {
        this.rrlDropdownSelectedItemsList.push(item)
      }
    },
    onClickClearChip(chip) {
      const index = this.rrlDropdownSelectedItemsList.indexOf(chip)
      this.rrlDropdownSelectedItemsList.splice(index, 1)
    },
    async onClickCancel() {
      this.$log.debug('cancel')
      this.clear()
      this.$emit('next')
    },
    async onClickSubmit() {
      this.$log.debug('click submit')
      this.subjectTextFieldValidated = true
      this.rrlDropdownAtLeastOnceFocused = true
      if (this.validateForm()) {
        this.onRequestCallback(true)
        this.$emit('resetTableList')
        this.$emit('next')
      }
    },
    validateForm() {
      const subjectValid = this.subjectInputValue.trim() !== ''
      if (this.actionType === EDIT_MODE_CONSTANTS.REVIEW) {
        return subjectValid && !this.rrlDropdownErrorState
      }
      return subjectValid
    },
    async onRequestCallback(success) {
      if (success) {
        this.loading = true
        try {
          let payload = {
            typeFlag: this.actionType,
            subject: this.subjectInputValue,
          }
          if (this.actionType === EDIT_MODE_CONSTANTS.REVIEW) {
            payload.rrl_ids = this.rrlDropdownSelectedItemsList.map((i) => i.id)
          }
          await this.submitChanges(payload)
          // this.$log.debug(this.actionType, 'payload', payload)
          await this.getUsers()
        } catch (e) {
          this.$log.error(e)
          this.loading = false
        }
        this.loading = false
      } else {
        this.$log.debug('DialogSubmitChanges # onRequestCallback no success')
      }
    },
    clear() {
      this.subjectInputValue = ''
      this.rrlDropdownSelectedItemsList = []
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-submit-changes {
  border-radius: 30px !important;
}

// input {
//   border-bottom: solid 1.5px rgba(0, 0, 0, 0.12);
//   width: 100%;
//   padding-left: 8px;
//   padding-bottom: 7px;
//   outline: none;

//   &::placeholder {
//     color: rgba(0, 0, 0, 0.38);
//   }
// }

.dialog-card-text {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  padding: 30px 40px 20px 40px !important;

  .dialog-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #3d51fb;
    margin-bottom: 30px;
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .dialog-content-row {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      .subject-text-field {
        margin-top: 0px !important;
        width: 100%;
      }

      .dialog-content-row-text {
        font-weight: 500;
        font-size: 15px;
        // line-height: 46px;
        color: #363636;
        white-space: nowrap;
        min-width: 152px;
        margin-right: 20px;
      }

      .multiselect-container {
        width: 100%;
        margin-top: 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        position: relative;

        &.focus {
          border-bottom: 1px solid #3b77ff;
        }
        &.error {
          border-bottom: 1px solid #ff3b30;
        }
        .error-text {
          color: #ff3b30;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          position: absolute;
          top: 40px;
          left: 7px;
        }
      }
    }

    .chips-area {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      // min-height: 114px;
      max-height: 120px;
      margin-bottom: 10px;
      overflow-y: auto;

      .request-recipient-chip {
        height: 34px;
        padding: 7px 16px;
        border: 1px solid #3d51fb;
        color: #3d51fb;
        border-radius: 17px;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        margin-right: 10px;
        margin-bottom: 10px;

        .chip-clear-icon {
          cursor: pointer;
        }
      }
    }
  }

  .dialog-btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn {
      font-weight: 500;
      font-size: 15px;
      width: 120px;
      height: 38px;
      border-radius: 30px;
      &.cancel {
        background-color: var(--v-backgroundButtonCancel-base);
        color: var(--v-textButtonCancel);
        margin-right: 15px;
      }

      &.submit {
        background-color: #3d51fb;
        color: var(--v-textCount);
      }
    }
  }
}
</style>
