<template>
  <span :class="`label ${disable ? 'disable' : ''}`" style="margin-top: 0px; display: flex">
    <div v-for="(info, idx) in items" :key="idx" :class="`radio ${errorClassName}`">
      <input
        type="radio"
        :class="`radio-btn`"
        :name="`${uniqueName}`"
        :id="`${uniqueName + idx}`"
        :checked="selectValue - 1 === idx && !disable"
        @click="onClickButton(idx)"
        :disabled="disable ? true : false"
      />
      <label :for="`${uniqueName + idx}`" :class="`repetitive-text `">{{ info }}</label>
    </div>
  </span>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: [],
    },
    uniqueName: {
      type: String,
      default: 'radio',
    },
    selectValue: {
      type: Number,
      default: -1,
    },
    errorClassName: {
      type: String,
      default: '',
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickButton(idx) {
      if (this.disable) return
      this.$emit('select', idx + 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.radio {
  display: flex;
  align-items: center;
  max-width: 89px;
  flex-basis: 89px;
  flex-grow: 1;
  .radio-btn {
    margin-right: 10px;
  }
}
.label {
  position: relative;
  &.disable {
    color: #959595;
  }

  &:has(.required) {
    &:after {
      content: 'Please fill out this field.';
      position: absolute;
      top: 30px;
      left: 10px;
      color: #ff0000;
      font-size: 10px;
      font-weight: 400;
    }
  }
  &:has(.invalid) {
    &:after {
      content: 'Invalid data.';
      position: absolute;
      top: 30px;
      left: 10px;
      color: #ff0000;
      font-size: 10px;
      font-weight: 400;
    }
  }
  &:has(.duplicate) {
    &:after {
      content: 'The Name aleady exists.';
      position: absolute;
      top: 30px;
      left: 10px;
      color: #ff0000;
      font-size: 10px;
      font-weight: 400;
    }
  }
  > input {
    border-bottom: solid 1.5px rgba(0, 0, 0, 0.12);
    width: 100%;
    padding-left: 8px;
    padding-bottom: 7px;
    outline: none;

    &::placeholder {
      color: rgba(0, 0, 0, 0.38);
    }
    &.input-error {
      color: red;
      border-bottom: solid 1.5px red;
    }
  }
}
</style>
