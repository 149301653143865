<template>
  <div class="player-container">
    <div class="wrapper" @contextmenu="onContextMenu" @mousedown="onMouseDown">
      <canvas ref="fabric"></canvas>
      <div @click="clickDraw" :class="{ 'icon-draw-container': true, 'icon-draw-container--selected': isDraw }">
        <img src="@/assets/Icon feather-map-pin.svg" class="icon-draw" />
      </div>
      <ContextMenu v-click-outside="closeContextMenu" :data="contextmenu" @mousedown="onDownloadFrameImage" :width="120">Save Image</ContextMenu>
    </div>
    <slot
      :currentIndex="currentIndex"
      :playerState="playerState"
      :moveFrame="moveFrame"
      :imageUrlList="imageUrlList"
      :play="play"
      :pause="pause"
    ></slot>
  </div>
</template>

<script>
import FabricCanvas from '@common/newImage/canvas/FabricCanvas'
import Util from '@/util'
import PlayerZoom from '@common/ImagePlayer/PlayerZoom'
import ContextMenu from '@common/ImagePlayer/ContextMenu'
import FileSaver from 'file-saver'
import CONSTANT from '@common/newImage/canvas/constant'

export default {
  components: {
    PlayerZoom,
    ContextMenu,
  },
  props: {
    currentLog: Object,
  },
  data() {
    return {
      CONSTANT,
      playerState: CONSTANT.PAUSE_STATE,
      fabricObject: null,
      imageUrlList: [],
      resizingTimerId: null,
      currentIndex: 0,
      entireImageLength: 0,
      isDraw: false,
      contextmenu: { x: 0, y: 0, display: false },
    }
  },
  watch: {
    currentLog(nextLog, prevLog) {
      if (!prevLog) this.canvasInitialize()
      else if (nextLog && nextLog.id !== prevLog.id) this.canvasUpdate()
      else this.resetCanvas()
    },
  },
  beforeDestroy() {
    if (this.fabricObject) this.fabricObject.playerClear()
  },
  destroyed() {
    window.removeEventListener('resize', this.canvasResizing)
  },
  methods: {
    canvasInitialize() {
      if (!this.currentLog?.before_image_paths) return
      const convertedBeforeImages = this.currentLog.before_image_paths.map((img) => Util.getImageUrl(img))
      const convertedetectImage = Util.getImageUrl(this.currentLog.detected_image_path)
      const frameUpdateCallback = (updatedCurrentIndex) => (this.currentIndex = updatedCurrentIndex)
      const stateUpdateCallback = (state) => (this.playerState = state)

      const detectionObjects = this.currentLog.objects.map((object) => ({
        ...object,
        type: 'Box',
        logId: this.currentLog.log_id,
      }))

      this.fabricObject = new FabricCanvas(
        this.$refs.fabric,
        convertedBeforeImages,
        [],
        convertedetectImage,
        detectionObjects,
        frameUpdateCallback,
        stateUpdateCallback,
        true
      )
      this.fabricObject.boxUpdateHandler(this.boxUpdate.bind(this))
      window.addEventListener('resize', this.canvasResizing)
    },
    resetCanvas() {
      if (this.fabricObject) this.fabricObject.playerClear()
      this.fabricObject = null
      this.imageUrlList = []
      this.resizingTimerId = null
      this.currentIndex = 0
      this.entireImageLength = 0
      this.zoom = 1
      this.isDraw = false
      this.playerState = CONSTANT.PAUSE_STATE
      this.contextmenu = { x: 0, y: 0, display: false }
      window.removeEventListener('resize', this.canvasResizing)
    },
    // ImagePlayer Update
    canvasUpdate() {
      this.resetCanvas()
      this.canvasInitialize()
    },
    canvasResizing() {
      if (this.resizingTimerId) clearTimeout(this.resizingTimerId)
      this.resizingTimerId = setTimeout(() => {
        this.fabricObject.playerPause()
        this.fabricObject.canvasResizing()
      }, 500)
    },
    // ImagePlayer Control
    async play() {
      this.playerState = CONSTANT.DISABLE_STATE
      const loadedImagesInfo = await this.fabricObject.preLoadImageForPlay()
      this.imageUrlList = loadedImagesInfo.precessedImageObjects.map((imageValue) => imageValue.imageUrl)
      this.entireImageLength = loadedImagesInfo.wholeImageLength
      const frameUpdateCallback = (updatedCurrentIndex) => (this.currentIndex = updatedCurrentIndex)
      const stateUpdateCallback = (state) => (this.playerState = state)
      this.$nextTick(() => {
        this.$emit('stopAlertSound')
        this.fabricObject.playPlayer(frameUpdateCallback, stateUpdateCallback)
      })
    },
    pause() {
      this.fabricObject.playerPause()
    },
    moveFrame(frameIndex) {
      if (this.playerState === CONSTANT.STOP_STATE) this.playerState = CONSTANT.PAUSE_STATE
      this.fabricObject.moveFrame(frameIndex)
    },
    // draw box
    clickDraw() {
      this.isDraw = !this.isDraw
      this.fabricObject.setDraw(this.isDraw)
    },
    boxUpdate(boxes) {
      this.$emit('updateBox', boxes)
      this.isDraw = false
      this.fabricObject.setDraw(false)
    },
    // Image Save
    closeContextMenu() {
      this.contextmenu = { x: 0, y: 0, display: false }
    },
    onMouseDown() {
      this.closeContextMenu()
    },
    onContextMenu(event) {
      event.preventDefault()
      const secondaryButton = 2
      if (event.button === secondaryButton && this.imageUrlList.length) {
        this.contextmenu = { x: event.x, y: event.y, display: true }
      } else {
        this.closeContextMenu()
      }
    },
    onDownloadFrameImage(event) {
      const primaryButton = 0
      if (event.button === primaryButton) {
        const src = this.imageUrlList[this.currentIndex] + '?nocache=' + Date.now()
        const srcSplitDot = this.imageUrlList[this.currentIndex].split('.')
        const ext = srcSplitDot[srcSplitDot.length - 1]
        let date = Util.getOffsetDate(this.currentLog.created_at, this.currentLog.offset)
        date = date.replace(/:/g, '-')
        const newFileName = `${date}_${this.currentIndex}.${ext}`
        FileSaver.saveAs(src, newFileName)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.player-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 112px);
}
.wrapper {
  background-color: black;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  flex-grow: 1;
}

.icon-draw-container--selected {
  opacity: 0.5;
}

.icon-draw-container:active {
  opacity: 0.5;
}

.icon-draw {
  width: 22px;
  height: 30px;
}

.icon-draw-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 20px;
  right: 0px;
  width: 60px;
  height: 60px;
  background-color: #ff4e00;
  box-shadow: 0px 4px 10px 0px #0000000f;
  border-radius: 10px 0 0 10px;
  z-index: 1;
  cursor: pointer;
}
</style>
