<template>
  <section class="request-tab">
    <span class="tab-title">Change Requests</span>
    <div class="wrapper">
      <div :class="{ 'pregress-tab': true, select: currentTab === TAB_KEYS[0] }" @click="onClickTab(TAB_KEYS[0])">
        Requested<CountItem :count="requestedCount" :select="currentTab === TAB_KEYS[0]" />
      </div>
      <div :class="{ 'pregress-tab': true, select: currentTab === TAB_KEYS[1] }" @click="onClickTab(TAB_KEYS[1])">
        In Review<CountItem :count="inRreviewCount" :select="currentTab === TAB_KEYS[1]" />
      </div>
      <div :class="{ 'pregress-tab': true, select: currentTab === TAB_KEYS[2] }" @click="onClickTab(TAB_KEYS[2])">
        Reviewed<CountItem :count="reviewdCount" :select="currentTab === TAB_KEYS[2]" />
      </div>
      <div :class="{ 'pregress-tab': true, select: currentTab === TAB_KEYS[3] }" @click="onClickTab(TAB_KEYS[3])">
        Archived<CountItem :count="archiveCount" :select="currentTab === TAB_KEYS[3]" />
      </div>
    </div>
    <div class="review-list">
      <div v-if="loading" class="loading">Loading</div>
      <RequestedListTab v-else-if="!loading && currentTab === TAB_KEYS[0]" :currentSelectedId="cameraListTabModeInfoId" />
      <ArchivedList v-else-if="!loading && currentTab === TAB_KEYS[3]" :currentSelectedId="cameraListTabModeInfoId" />
      <ReviewListTab v-else :isReviewedTab="currentTab === TAB_KEYS[2]" :currentSelectedId="cameraListTabModeInfoId" />
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import RequestedListTab from './RequestedListTab'
import ReviewListTab from './ReviewListTab'
import ArchivedList from './ArchivedList'
import CountItem from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/CountItem'
import CONSTANT from '@/constants'

const TAB_KEYS = ['REQUESTED', 'IN_REVIEW', 'REVIEWED', 'ARCHIVED']

export default {
  components: {
    RequestedListTab,
    ArchivedList,
    ReviewListTab,
    CountItem,
  },
  async created() {
    await this.getReviewListAction()
    if (CONSTANT.ROLE_OBSERVER !== this.userRole) await this.getArchiveListAction()
    else this.setArchiveListMutation({})
    this.loading = false
  },
  data() {
    return {
      TAB_KEYS,
      loading: true,
      currentTab: TAB_KEYS[0],
    }
  },
  computed: {
    ...mapState({
      changeRequestRequestList: (state) => state.superadmin.changeRequestRequestList,
      changeRequestInReviewList: (state) => state.superadmin.changeRequestInReviewList,
      changeRequestReviewedList: (state) => state.superadmin.changeRequestReviewedList,
      archiveList: (state) => state.superadmin.archiveList,
      cameraListTabModeInfo: (state) => state.superadmin.cameraListTabModeInfo,
      userRole: (state) => state.user.user_role,
    }),
    cameraListTabModeInfoId() {
      return this.cameraListTabModeInfo.id
    },
    requestedCount() {
      return this.changeRequestRequestList.length
    },
    inRreviewCount() {
      return this.changeRequestInReviewList.length
    },
    reviewdCount() {
      return this.changeRequestReviewedList.length
    },
    archiveCount() {
      return this.archiveList?.length ? this.archiveList.length : 0
    },
  },
  methods: {
    ...mapMutations('superadmin', ['setArchiveListMutation']),
    ...mapActions('superadmin', ['getReviewListAction', 'getArchiveListAction']),
    onClickTab(key) {
      this.currentTab = key
    },
  },
}
</script>

<style lang="scss" scoped>
.request-tab {
  padding: 24px 0px 0 24px;
  height: 100%;
  .tab-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
  }
  .wrapper {
    margin: 18px 0;
    display: flex;
    width: 100%;
    padding-right: 24px;
    justify-content: space-between;
    .pregress-tab {
      cursor: pointer;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      .count {
        margin-left: 8px;
      }
      &.select {
        font-weight: 600;
        color: #3d51fb;
      }
    }
  }
  .review-list {
    width: 100%;
    height: calc(100% - 108px);
    overflow-y: auto;
    overflow-x: hidden;
    border-top: 2px solid #f9f9f9;
    padding-top: 16px;
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
