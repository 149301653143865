<template>
  <v-dialog v-model="value" width="520px" persistent content-class="dialog-camera-detail">
    <v-card style="background-color: white; height: 100%">
      <v-card-text class="pa-0" style="height: 100%">
        <div class="org-dialog-title">{{ titleText }}</div>
        <div class="org-dialog-content">
          <div class="org-dialog-tab">
            <div class="org-dialog-tab-item information active">
              <div class="tab-wrap">
                <div class="tab-title">{{ tabTitle[activeTab] }}</div>
              </div>
            </div>
          </div>
          <div class="org-dialog-step-section">
            <div :class="'org-dialog-step-1' + (activeTab === 'information' ? ' active' : ' previous')">
              <div class="org-dialog-step-diagram"></div>
              <div class="org-dialog-step-text">Step 1</div>
            </div>
            <div class="org-dialog-step-bar"></div>
            <div :class="'org-dialog-step-2' + (activeTab === 'monitor' ? ' active' : '') + (activeTab === 'conditions' ? ' previous' : '')">
              <div class="org-dialog-step-diagram"></div>
              <div class="org-dialog-step-text">Step 2</div>
            </div>
            <div class="org-dialog-step-bar"></div>
            <div :class="'org-dialog-step-3' + (activeTab === 'conditions' ? ' active' : '')">
              <div class="org-dialog-step-diagram"></div>
              <div class="org-dialog-step-text">Step 3</div>
            </div>
          </div>
          <div class="org-dialog-tab-content">
            <div class="org-dialog-tab-content-wrap" v-if="activeTab === 'information'">
              <div class="org-dialog-section-wrap">
                <div class="org-dialog-section">
                  <div class="label-container">
                    <span class="label" style="margin-top: 3px">Camera Name*</span>
                    <span class="label" style="margin-top: 31px">Country*</span>
                    <span class="label" style="margin-top: 31px">Region*</span>
                    <span class="label" style="margin-top: 31px">County*</span>
                    <span class="label" style="margin-top: 31px">Coordinate*</span>
                    <span class="label" style="margin-top: 31px">Altitude*</span>
                  </div>
                  <div class="label-container" style="margin-left: 0; width: 300px; font-weight: 400">
                    <span class="label" style="margin-top: 0px"
                      ><input
                        @input="onCameraNameInput"
                        :class="`${!validated['cameraName'][0] ? 'input-error' : ''} ${validated['cameraName'][1]}`"
                        type="text"
                        v-model="cameraName"
                        placeholder="Camera Name"
                        maxlength="40"
                    /></span>
                    <span class="label" style="margin-top: 24px"
                      ><DropdownSimple
                        ref="country"
                        v-model="country"
                        :error="!validated['country'][0]"
                        :errorMessage="validated['country'][1]"
                        @resolveError="resolveInformationError('country')"
                        :items="countries.map((item) => ({ text: item.name, value: item.id }))"
                        placeholder="Select"
                        :isCustomUpdate="true"
                        @customUpdate="updateCountry"
                    /></span>
                    <span class="label" style="margin-top: 23px"
                      ><DropdownSingleSelect
                        ref="region"
                        v-model="region"
                        :error="!validated['region'][0]"
                        :errorMessage="validated['region'][1]"
                        @resolveError="resolveInformationError('region')"
                        :items="regions.map((item) => ({ text: item.name, value: item.id }))"
                        placeholder="Select"
                        @save="onSaveRegion"
                        @add="onAddRegion"
                        :readonly="!country"
                        :validateType="[VALIDATE_TYPE.KOREAN]"
                    /></span>
                    <span class="label" style="margin-top: 23px"
                      ><DropdownSingleSelect
                        ref="county"
                        v-model="county"
                        :error="!validated['county'][0]"
                        :errorMessage="validated['county'][1]"
                        @resolveError="resolveInformationError('county')"
                        :items="counties.map((item) => ({ text: item.name, value: item.id }))"
                        placeholder="Select"
                        @save="onSaveCounty"
                        @add="onAddCounty"
                        :readonly="!region"
                        :validateType="[VALIDATE_TYPE.KOREAN]"
                    /></span>
                    <span class="label" style="margin-top: 23px"
                      ><input
                        :class="`${!validated['coordinate'][0] ? 'input-error' : ''} ${validated['coordinate'][1]}`"
                        type="text"
                        v-model="coordinate"
                        placeholder="34.1456, 122.34455"
                        @input="onCoordinateInput"
                        @blur="onCoordinateBlur"
                        maxlength="40"
                    /></span>
                    <span class="label" style="margin-top: 24px"
                      ><input
                        :class="`${!validated['altitude'][0] ? 'input-error' : ''} ${validated['altitude'][1]}`"
                        type="text"
                        v-model="altitude"
                        placeholder="If no data, '0'"
                        @input="onAltitudeInput"
                        maxlength="40"
                    /></span>
                  </div>
                </div>
                <div class="org-dialog-section url-section">
                  <div
                    class="url-button"
                    @click="
                      () => {
                        openUrlContents = !openUrlContents
                      }
                    "
                  >
                    <div class="label-container">URL</div>
                  </div>
                  <div class="url-contents-wrap">
                    <div class="label-container">
                      <span class="label" style="margin-top: 0">Camera Provider*</span>
                      <span class="label" style="margin-top: 31px">API Key*</span>
                      <span class="label" style="margin-top: 31px">API*</span>
                      <span class="label" style="margin-top: 31px">Public</span>
                      <span class="label" style="margin-top: 31px">Private 1</span>
                      <span class="label" style="margin-top: 31px">Private 2</span>
                      <span class="label" style="margin-top: 31px">Guide</span>
                    </div>
                    <div class="label-container" style="margin-left: 24px; width: 250px; font-weight: 400">
                      <span class="label" style="margin-top: 0"
                        ><DropdownSingleSelect
                          ref="cameraProvider"
                          v-model="cameraProvider"
                          :error="!validated['cameraProvider'][0]"
                          :errorMessage="validated['cameraProvider'][1]"
                          @resolveError="resolveInformationError('cameraProvider')"
                          :items="cameraProviders.map((item) => ({ text: item.name, value: item.id }))"
                          placeholder="Select"
                          @save="onSaveProvider"
                          @add="onAddProvider"
                          :validateType="[VALIDATE_TYPE.KOREAN]"
                      /></span>
                      <span class="label" style="margin-top: 23px"
                        ><DropdownSingleSelect
                          ref="apiKeys"
                          v-model="apiKey"
                          :error="!validated['apiKey'][0]"
                          :errorMessage="validated['apiKey'][1]"
                          @resolveError="resolveInformationError('apiKey')"
                          :items="apiKeys.map((item) => ({ text: item.key, value: item.id }))"
                          placeholder="Select"
                          @save="onSaveApiKey"
                          @add="onAddApiKey"
                          :validateType="[VALIDATE_TYPE.KOREAN]"
                      /></span>
                      <span class="label" style="margin-top: 22px"
                        ><input
                          :class="`${!validated['apiUrl'][0] ? 'input-error' : ''} ${validated['apiUrl'][1]} url`"
                          @input="onApiUrlInput"
                          type="text"
                          v-model="apiUrl"
                          placeholder="http(s)://"
                      /></span>
                      <span class="label" style="margin-top: 23px"
                        ><input
                          :class="`${!validated['public'][0] ? 'input-error' : ''} ${validated['public'][1]}`"
                          type="text"
                          v-model="publicUrl"
                          placeholder="Public"
                          @input="onPublicInput"
                          @blur="onPublicBlur"
                      /></span>
                      <span class="label" style="margin-top: 23px"
                        ><input
                          :class="`${!validated['private1'][0] ? 'input-error' : ''} ${validated['private1'][1]}`"
                          type="text"
                          v-model="privateUrl1"
                          placeholder="Private"
                          @input="onPrivate1Input"
                          @blur="onPrivate1Blur"
                      /></span>
                      <span class="label" style="margin-top: 23px"
                        ><input
                          :class="`${!validated['private2'][0] ? 'input-error' : ''} ${validated['private2'][1]}`"
                          type="text"
                          v-model="privateUrl2"
                          placeholder="Private"
                          @input="onPrivate2Input"
                          @blur="onPrivate2Blur"
                      /></span>
                      <span class="label" style="margin-top: 23px"
                        ><input
                          :class="`${!validated['guide'][0] ? 'input-error' : ''} ${validated['guide'][1]}`"
                          type="text"
                          v-model="guide"
                          placeholder="Guide"
                          @input="onGuideInput"
                          @blur="onGuideBlur"
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="org-dialog-tab-content-wrap" v-if="activeTab === 'monitor'">
              <AddCameraMonitoringTab ref="monitorTab" :monitoringValues="monitoringValues" @updateMonitorValues="monitoringValues = $event" />
            </div>
            <div class="org-dialog-tab-content-wrap" v-if="activeTab === 'conditions'">
              <AddCameraConditionTab ref="conditionTab" :conditionValues="conditionValues" @updateConditionValues="updateConditionValues" />
            </div>
          </div>
        </div>
        <div class="d-flex justify-space-between" style="padding-left: 30px; padding-right: 30px; padding-bottom: 20px; margin-top: 20px">
          <div class="justify-start">
            <button v-ripple @click="onClickCancel" class="org-dialog-button cancel">Cancel</button>
          </div>
          <div class="justify-end">
            <button v-ripple @click="onClickPrevious" :disabled="activeTab === 'information'" class="org-dialog-button previous">Previous</button>
            <button v-ripple @click="onClickNext" class="org-dialog-button next" style="margin-left: 15px">{{ okText }}</button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Input from '@common/signup/Input'
import DropdownNormal from '@desktop/superadminOrWorker/settings/DropdownNormal'
import CustomToolTip from '@desktop/superadminOrWorker/settings/camera-list/CustomToolTip'
import { mapActions, mapState } from 'vuex'
import { superadmin } from '@/api'
import moment from 'moment'
import rules from '@/rules'
import DropdownSingleSelect from '@desktop/superadminOrWorker/settings/DropdownSingleSelect'
import DropdownSimple from '@desktop/superadminOrWorker/settings/DropdownSimple'
import Validator from '@desktop/superadminOrWorker/settings/camera-list/feature/validator'
import SelectRadioButton from '@desktop/superadminOrWorker/settings/camera-list/SelectRadioButton'
import AddCameraConditionTab from '@desktop/superadminOrWorker/settings/camera-list/AddCameraConditionTab'
import AddCameraMonitoringTab from '@desktop/superadminOrWorker/settings/camera-list/AddCameraMonitoringTab'
import { VALIDATE_TYPE, ERROR_TYPE } from '@desktop/superadminOrWorker/settings/camera-list/feature/options'

const ADD_SUCCESS_TEXT = 'Camera Successfully Added.'
const EDIT_SUCCESS_TEXT = 'Successfully Edited the Camera Information.'

export default {
  name: 'DialogAddNewCamera',
  components: {
    DropdownSimple,
    DropdownSingleSelect,
    Input,
    DropdownNormal,
    CustomToolTip,
    SelectRadioButton,
    AddCameraConditionTab,
    AddCameraMonitoringTab,
  },
  props: {
    value: Boolean,
    addDialogEditMode: {
      type: Boolean,
      default: false,
    },
    selectedCameraId: {
      type: Number,
      default: -2,
    },
    camera: {
      type: Object,
      default: () => ({
        information: {
          name: '',
        },
        condition: {},
      }),
    },
    /**
     * emit events
     * ok
     */
  },
  data() {
    const self = this
    return {
      ADD_SUCCESS_TEXT,
      EDIT_SUCCESS_TEXT,
      VALIDATE_TYPE,
      conditionValues: {
        PTZValue: 1,
        nightDetection: 1,
        IRfilter: 1,
        serviced: 1,
        evaluated: 2,
        internalUsage: 1,
        panFixed: 1,
        falsePositiveAreas: 2,
        imageQualityValue: 1,
        skyAreaValue: 3,
        cameraClearnessValue: 3,
        HFTDAreaValue: 4,
      },
      monitoringValues: {
        monitoredValue: 1,
        monitoringGroupValue: '',
      },
      validated: {
        cameraName: [true, ''],
        country: [true, ''],
        region: [true, ''],
        county: [true, ''],
        coordinate: [true, ''],
        altitude: [true, ''],
        cameraProvider: [true, ''],
        apiKey: [true, ''],
        apiUrl: [true, ''],
        public: [true, ''],
        private1: [true, ''],
        private2: [true, ''],
        guide: [true, ''],
      },
      ////// Input Debounce TimerId /////
      cameraNameTimeId: null,
      coordinateTimeId: null,
      altitudeTimeId: null,
      apiUrlTimeId: null,
      openUrlContents: false,
      activeTab: 'information',
      tabTitle: {
        information: 'Camera Information',
        monitor: 'Monitoring Information',
        conditions: 'Conditions',
      },
      titleText: 'Add New Camera',
      okText: 'Next',
      cameraName: '',
      organization: '',
      workspace: '',
      contractType: '',
      contractError: false,
      country: '',
      region: '',
      county: '',
      coordinate: '',
      altitude: '',
      cameraProvider: '',
      apiKey: '',
      apiUrl: '',
      publicUrl: '',
      privateUrl1: '',
      privateUrl2: '',
      guide: '',
      timezone: '',
      timezoneError: false,
      dateRange: '',
      dateRangeError: false,
      // for duplicate
      originalCameraName: '',
      originalApiUrl: '',
      rules: [(v) => !!v || 'Please fill out this field.'],
      organizationRules: [
        (v) => !!v || 'Please fill out this field.',
        (v) => !self.isDuplicatedOrganizationName || 'This organization already exists.',
      ],
      workspaceRules: [(v) => !!v || 'Please fill out this field.', () => !self.isDuplicatedWorkspaceName || 'This workspace already exists.'],
      transformWorkspace: (v) => {
        if (rules.regxSpecial.test(v)) {
          v = v.replaceAll(rules.regxSpecialGlobal, '')
        }
        if (rules.uppercase.test(v)) {
          v = v.replaceAll(rules.uppercaseGlobal, '')
        }
        return v
      },
      organizationTimeout: null,
      isDuplicatedOrganizationName: false,
      workspaceTimeout: null,
      isDuplicatedWorkspaceName: false,
    }
  },
  computed: {
    ...mapState('superadmin', {
      timezoneList: (state) => state.timezoneList,
      contractList: (state) => state.contract_types,
      countries: (state) => state.countries,
      regions: (state) => state.regions,
      counties: (state) => state.counties,
      camera: (state) => state.camera,
      cameraProviders: (state) => state.providers,
      apiKeys: (state) => state.apiKeys,
      monitoringGroups: (state) => state.cameraGroups,
      addedCameraList: (state) => state.addedCameraList,
      editedCameraList: (state) => state.editedCameraList,
      cameraNameSetForDuplicate: (state) => state.cameraNameSetForDuplicate,
      apiUrlSetForDuplicate: (state) => state.apiUrlSetForDuplicate,
    }),
    information() {
      return this.camera.information
    },
    condition() {
      return this.camera.condition
    },
    timezoneNameList() {
      return this.timezoneList.map((v) => v.name)
    },
    contractNameList() {
      return this.contractList.map((v) => v.name)
    },
  },
  async created() {
    this.$log.debug('DialogAddNewCamera#created')
    await this.getProviders()
    if (this.addDialogEditMode) await this.setCameraDetailInfo()
    this.organization = ''
    this.workspace = ''
    this.contractType = ''
    this.timezone = ''
    this.dateRange = ''
  },
  watch: {
    organization(val) {
      if (val) {
        this.checkDuplicateOrganization()
      }
    },
    workspace(val) {
      if (val && !this.isEdit) {
        this.checkDuplicateWorkspace()
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.organizationTimeout)
    clearTimeout(this.workspaceTimeout)
  },
  methods: {
    ...mapActions('superadmin', [
      'getProviders',
      'getRegions',
      'cameraValidateAction',
      'cameraAddAction',
      'cameraEditAction',
      'getCounties',
      'getCameraDetailInfoAction',
      'getApiKeys',
      'getFullRegions',
      'getFullCounties',
    ]),
    async setCameraDetailInfo() {
      this.titleText = 'Edit Information'
      const isEditedCamera = !!this.editedCameraList[this.selectedCameraId]
      if (this.selectedCameraId !== -2 && Number.isInteger(this.selectedCameraId) && !isEditedCamera) {
        const { cameraCondition, cameraInformation } = await this.getCameraDetailInfoAction(this.selectedCameraId)
        this.cameraName = cameraInformation.name
        await this.initializeCountry(cameraInformation.country_id, cameraInformation.region_id, cameraInformation.county_id)
        this.coordinate = cameraInformation.coordinate
        this.altitude = cameraInformation.altitude
        await this.initializeProviderAndApiKey(cameraInformation.provider_id, cameraInformation.api_key_id)
        this.apiUrl = cameraInformation.api_url
        this.publicUrl = cameraInformation.public_url
        this.privateUrl1 = cameraInformation.private_url_1
        this.privateUrl2 = cameraInformation.private_url_2
        this.guide = cameraInformation.guide_url
        this.conditionValues = {
          PTZValue: cameraCondition.ptz_value,
          nightDetection: cameraCondition.night_detection,
          IRfilter: cameraCondition.ir_filter,
          serviced: cameraCondition.serviced,
          evaluated: cameraCondition.evaluated,
          internalUsage: cameraCondition.internal_usage,
          panFixed: cameraCondition.pan_or_fixed,
          falsePositiveAreas: cameraCondition.false_positive_areas,
          imageQualityValue: cameraCondition.image_quality,
          skyAreaValue: cameraCondition.sky_area,
          cameraClearnessValue: cameraCondition.camera_cleanness,
          HFTDAreaValue: cameraCondition.within_hftd_area,
        }
        this.monitoringValues = {
          monitoredValue: cameraInformation.monitored === true ? 1 : 2,
          monitoringGroupValue: cameraInformation.monitored === true ? cameraInformation.camera_group_id : '',
        }
        this.originalCameraName = cameraInformation.name
        this.originalApiUrl = cameraInformation.api_url
      } else if (!Number.isInteger(this.selectedCameraId)) {
        const information = this.addedCameraList[this.selectedCameraId].information
        const conditions = this.addedCameraList[this.selectedCameraId].condition
        this.cameraName = information.cameraName
        await this.initializeCountry(information.country, information.region, information.county)
        this.coordinate = information.coordinate
        this.altitude = information.altitude
        await this.initializeProviderAndApiKey(information.cameraProvider, information.apiKey)
        this.apiUrl = information.apiUrl
        this.publicUrl = information.publicUrl
        this.privateUrl1 = information.privateUrl1
        this.privateUrl2 = information.privateUrl2
        this.guide = information.guide
        this.conditionValues = {
          PTZValue: conditions.PTZValue,
          nightDetection: conditions.nightDetection,
          IRfilter: conditions.IRfilter,
          serviced: conditions.serviced,
          evaluated: conditions.evaluated,
          internalUsage: conditions.internalUsage,
          panFixed: conditions.panFixed,
          falsePositiveAreas: conditions.falsePositiveAreas,
          imageQualityValue: conditions.imageQualityValue,
          skyAreaValue: conditions.skyAreaValue,
          cameraClearnessValue: conditions.cameraClearnessValue,
          HFTDAreaValue: conditions.HFTDAreaValue,
        }
        this.monitoringValues = {
          monitoredValue: information.monitored,
          monitoringGroupValue: information.monitored === 1 ? information.cameraGroupId : '',
        }
        this.originalCameraName = information.name
        this.originalApiUrl = information.apiUrl
      } else if (this.selectedCameraId !== -2 && Number.isInteger(this.selectedCameraId) && isEditedCamera) {
        const information = this.editedCameraList[this.selectedCameraId].information
        const conditions = this.editedCameraList[this.selectedCameraId].condition
        this.cameraName = information.cameraName
        await this.initializeCountry(information.country, information.region, information.county)
        this.coordinate = information.coordinate
        this.altitude = information.altitude
        await this.initializeProviderAndApiKey(information.cameraProvider, information.apiKey)
        this.apiUrl = information.apiUrl
        this.publicUrl = information.publicUrl
        this.privateUrl1 = information.privateUrl1
        this.privateUrl2 = information.privateUrl2
        this.guide = information.guide
        this.conditionValues = {
          PTZValue: conditions.PTZValue,
          nightDetection: conditions.nightDetection,
          IRfilter: conditions.IRfilter,
          serviced: conditions.serviced,
          evaluated: conditions.evaluated,
          internalUsage: conditions.internalUsage,
          panFixed: conditions.panFixed,
          falsePositiveAreas: conditions.falsePositiveAreas,
          imageQualityValue: conditions.imageQualityValue,
          skyAreaValue: conditions.skyAreaValue,
          cameraClearnessValue: conditions.cameraClearnessValue,
          HFTDAreaValue: conditions.HFTDAreaValue,
        }
        this.monitoringValues = {
          monitoredValue: information.monitored,
          monitoringGroupValue: information.monitored === 1 ? information.cameraGroupId : '',
        }
        this.originalCameraName = information.name
        this.originalApiUrl = information.apiUrl
      }
    },
    // Information
    updateCountry(id) {
      if (id === this.country) return
      this.country = id
      this.region = ''
      this.county = ''
      this.getRegions(this.country)
    },
    async onSaveRegion(id) {
      if (id === this.region) return
      this.region = id
      this.county = ''
      await this.getCounties(this.region)
    },
    onSaveCounty(id) {
      this.county = id
    },
    async initializeCountry(countryId, regionId, countyId) {
      await this.getRegions(countryId)
      await this.getCounties(regionId)
      this.country = countryId
      this.region = regionId
      this.county = countyId
    },
    async initializeProviderAndApiKey(cameraProviderId, apiKeyId) {
      this.cameraProvider = cameraProviderId
      await this.getApiKeys(cameraProviderId)
      this.apiKey = apiKeyId
    },
    resolveInformationError(key) {
      this.validated[key] = [true, '']
    },
    validateInformationConditions() {
      if (!this.cameraName.length) this.validated.cameraName = [false, ERROR_TYPE.REQUIRE]
      this.validated.country = Validator.validateDropdown(this.country, this.countries)
      this.validated.region = Validator.validateDropdown(this.region, this.regions)
      this.validated.county = Validator.validateDropdown(this.county, this.counties)
      this.validated.cameraProvider = Validator.validateDropdown(this.cameraProvider, this.cameraProviders)
      this.validated.apiKey = Validator.validateDropdown(this.apiKey, this.apiKeys)
      if (!this.apiUrl.length) this.validated.apiUrl = [false, ERROR_TYPE.REQUIRE]
      if (!this.coordinate.length) this.validated.coordinate = [false, ERROR_TYPE.REQUIRE]
      if (!this.altitude && this.altitude !== 0) this.validated.altitude = [false, ERROR_TYPE.REQUIRE]
      if (!this.publicUrl.length) this.validated.public = Validator.validateUrlFormat(this.publicUrl)
      if (!this.privateUrl1.length) this.validated.private1 = Validator.validateUrlFormat(this.privateUrl1)
      if (!this.privateUrl2.length) this.validated.private2 = Validator.validateUrlFormat(this.privateUrl2)
      if (!this.guide.length) this.validated.guide = Validator.validateUrlFormat(this.guide)
      return this.checkInformationError()
    },
    checkInformationError() {
      for (let [_, value] of Object.entries(this.validated)) {
        if (!value[0]) return false
      }
      return true
    },
    async onAddRegion(regionName) {
      if (!this.country) return
      const region = await superadmin.createRegion({ name: regionName, location_id: this.country })
      await this.getRegions(this.country)
      await this.getFullRegions()
      this.region = region.id
      await this.getCounties(this.region)
      this.county = ''
    },
    async onAddCounty(countyName) {
      if (!this.region) return
      const county = await superadmin.createCounty({ name: countyName, region_id: this.region })
      await this.$store.dispatch('superadmin/getCounties', this.region)
      await this.getFullCounties()
      this.county = county.id
    },
    async onAddProvider(providerName) {
      const provider = await superadmin.createProvider({ name: providerName })
      await this.$store.dispatch('superadmin/getProviders')
      this.cameraProvider = provider.id
      await this.$store.dispatch('superadmin/getApiKeys', this.cameraProvider)
      this.apiKey = ''
    },
    async onAddApiKey(key) {
      if (!this.cameraProvider) return
      const apiKey = await superadmin.createApiKey({ key, provider_id: this.cameraProvider })
      await this.$store.dispatch('superadmin/getApiKeys', this.cameraProvider)
      this.apiKey = apiKey.id
    },
    async onSaveProvider(id) {
      if (id === this.cameraProvider) return
      this.cameraProvider = id
      await this.$store.dispatch('superadmin/getApiKeys', this.cameraProvider)
      this.apiKey = ''
    },
    onSaveApiKey(id) {
      this.apiKey = id
    },

    // Information Keyboard Event
    async onCameraNameInput() {
      this.cameraName = Validator.checkCameraNameLettersValidity(this.cameraName)
      if (!this.cameraName.length) this.validated.cameraName = [false, ERROR_TYPE.REQUIRE]
      else if (this.cameraName.length >= 4) {
        clearTimeout(this.cameraNameTimeId)
        this.cameraNameTimeId = setTimeout(async () => await this.validateCameraName(), 500)
      }
    },
    async validateCameraName() {
      if (this.cameraName.length < 4) return
      if (this.cameraName === this.originalCameraName) {
        this.resolveInformationError('cameraName')
        return
      }
      const nameConditionCheck = Validator.cameraNameCharacterValidate(this.cameraName)
      if (!nameConditionCheck) {
        this.validated.cameraName = [false, ERROR_TYPE.INVALID]
        return
      }
      const isDuplicated = await this.cameraValidateAction({ column: 'name', value: this.cameraName })
      const localDataCheck = this.cameraNameSetForDuplicate[this.cameraName]
      if ((!isDuplicated && localDataCheck !== false) || localDataCheck) this.validated.cameraName = [false, ERROR_TYPE.DUPLICATE]
      else this.resolveInformationError('cameraName')
    },
    onCoordinateInput() {
      this.coordinate = Validator.checkCoordinateLettersValidity(this.coordinate)
      if (!this.coordinate.length) this.validated.coordinate = [false, ERROR_TYPE.REQUIRE]
      clearTimeout(this.coordinateTimeId)
      this.coordinateTimeId = setTimeout(async () => {
        if (!this.coordinate.length) this.validated.coordinate = [false, ERROR_TYPE.REQUIRE]
        else {
          const validate = Validator.validateCooldinateCharacter(this.coordinate)
          if (!validate) this.validated.coordinate = [false, ERROR_TYPE.INVALID]
          else this.resolveInformationError('coordinate')
        }
      })
    },
    onCoordinateBlur() {
      if (!this.coordinate.length) return
      const formTestResult = Validator.validateCooldinateForm(this.coordinate)
      if (formTestResult) {
        if (this.coordinate.indexOf(', ') > -1) {
          this.validated.coordinate = [true, '']
        } else if (this.coordinate.indexOf(',') > -1) {
          this.validated.coordinate = [true, '']
          this.coordinate = this.coordinate.replace(',', ', ')
        } else if (this.coordinate.indexOf(' ') > -1) {
          this.validated.coordinate = [true, '']
          this.coordinate = this.coordinate.replace(' ', ', ')
        }
        const [lati, longti] = this.coordinate.split(', ')
        const validRange = Validator.checkCoordinateRange(lati, longti)
        if (!validRange) this.validated.coordinate = [false, ERROR_TYPE.INVALID]
      } else this.validated.coordinate = [false, ERROR_TYPE.INVALID]
    },
    onAltitudeInput() {
      clearTimeout(this.altitudeTimeId)
      this.altitudeTimeId = setTimeout(async () => {
        if (!this.altitude.length) this.validated.altitude = [false, ERROR_TYPE.REQUIRE]
        else {
          const validate = Validator.validateAltitude(this.altitude)
          if (validate) this.validated.altitude = [true, '']
          else this.validated.altitude = [false, ERROR_TYPE.INVALID]
        }
      }, 500)
    },
    onApiUrlInput(event) {
      const text = Validator.preventSpecificCharacterType({ value: event.target.value, types: [VALIDATE_TYPE.KOREAN] })
      if (event.target.value !== text) this.apiUrl = text
      clearTimeout(this.apiUrlTimeId)
      this.apiUrlTimeId = setTimeout(async () => {
        if (!this.apiUrl.length) this.validated.apiUrl = [false, ERROR_TYPE.REQUIRE]
        else {
          this.validated.apiUrl = Validator.validateApiUrl(this.apiUrl)
          if (this.validated.apiUrl[0]) {
            const isDuplicated = await this.cameraValidateAction({ column: 'url', value: this.apiUrl })
            const localDataCheck = this.apiUrlSetForDuplicate[this.apiUrl]
            if ((!isDuplicated && localDataCheck !== false) || localDataCheck) this.validated.apiUrl = [false, ERROR_TYPE.DUPLICATE]
            else this.resolveInformationError('apiUrl')
          }
        }
      }, 500)
    },
    onPublicInput(event) {
      this.publicUrl = Validator.preventSpecificCharacterType({ value: event.target.value, types: [VALIDATE_TYPE.KOREAN] })
    },
    onPrivate1Input(event) {
      this.privateUrl1 = Validator.preventSpecificCharacterType({ value: event.target.value, types: [VALIDATE_TYPE.KOREAN] })
    },
    onPrivate2Input(event) {
      this.privateUrl2 = Validator.preventSpecificCharacterType({ value: event.target.value, types: [VALIDATE_TYPE.KOREAN] })
    },
    onGuideInput(event) {
      this.guide = Validator.preventSpecificCharacterType({ value: event.target.value, types: [VALIDATE_TYPE.KOREAN] })
    },
    onPublicBlur(event) {
      this.validated.public = Validator.validateUrlFormat(event.target.value)
    },
    onPrivate1Blur(event) {
      this.validated.private1 = Validator.validateUrlFormat(event.target.value)
    },
    onPrivate2Blur(event) {
      this.validated.private2 = Validator.validateUrlFormat(event.target.value)
    },
    onGuideBlur(event) {
      this.validated.guide = Validator.validateUrlFormat(event.target.value)
    },
    // Monitor
    validateMonitorConditions() {
      this.$refs.monitorTab.updateData()
      return this.checkMonitorError()
    },
    checkMonitorError() {
      if (this.monitoringValues.monitoredValue === 2 || (this.monitoringValues.monitoredValue === 1 && !!this.monitoringValues.monitoringGroupValue))
        return true
      return false
    },

    // Conditions
    updateConditionValues(updatedConditions) {
      this.conditionValues = updatedConditions
    },

    //etc
    hide() {
      this.$emit('input', false)
    },
    onClickCancel() {
      this.$log.debug('DialogAddNewCamera#onClickCancel')
      this.$emit('cancel')
    },
    onClickPrevious() {
      this.$log.debug('DialogAddNewCamera#onClickPrevious')
      if (this.activeTab === 'conditions') {
        this.$refs.conditionTab.updateData()
        this.activeTab = 'monitor'
        this.okText = 'Next'
      } else if (this.activeTab === 'monitor') {
        this.$refs.monitorTab.updateData()
        this.activeTab = 'information'
        this.okText = 'Next'
      }
    },
    onClickNext() {
      this.$log.debug('DialogAddNewCamera#onClickNext')
      if (this.activeTab === 'information') {
        const informationChecking = this.validateInformationConditions()
        if (informationChecking) this.activeTab = 'monitor'
      } else if (this.activeTab === 'monitor') {
        const monitorChecking = this.validateMonitorConditions()
        if (monitorChecking) {
          this.activeTab = 'conditions'
          if (this.addDialogEditMode) this.okText = 'Edit'
          else this.okText = 'Add'
        }
      } else {
        this.$refs.conditionTab.updateData()
        const cameraInfo = {
          information: {
            cameraName: this.cameraName,
            country: this.country,
            region: this.region,
            county: this.county,
            coordinate: this.coordinate,
            altitude: this.altitude,
            cameraProvider: this.cameraProvider,
            apiKey: this.apiKey,
            apiUrl: this.apiUrl,
            publicUrl: this.publicUrl,
            privateUrl1: this.privateUrl1,
            privateUrl2: this.privateUrl2,
            guide: this.guide,
            monitored: this.monitoringValues.monitoredValue,
            cameraGroupId: this.monitoringValues.monitoredValue === 1 ? this.monitoringValues.monitoringGroupValue : '-',
          },
          condition: { ...this.conditionValues },
          monitoring: { ...this.monitoringValues },
        }
        if (!this.addDialogEditMode) this.cameraAddAction(cameraInfo)
        else
          this.cameraEditAction({
            cameraId: this.selectedCameraId,
            editedCamera: cameraInfo,
            originalApiUrl: this.originalApiUrl,
            originalCameraName: this.originalCameraName,
          })
        this.$toast(`${this.addDialogEditMode ? this.EDIT_SUCCESS_TEXT : this.ADD_SUCCESS_TEXT}`, {
          position: 'bottom-center',
          toastClassName: 'requestSuccessToastClass',
          timeout: 7000,
        })
        this.$emit('next')
      }
    },

    checkDuplicateOrganization() {
      clearTimeout(this.organizationTimeout)
      this.organizationTimeout = setTimeout(async () => {
        const duplicated = await superadmin.checkOrganizationName(this.organization)
        if (this.isEdit) {
          this.isDuplicatedOrganizationName = this.data.name !== this.organization && duplicated
        } else {
          this.isDuplicatedOrganizationName = duplicated
        }
        this.$refs.organization.validate()
      }, 500)
    },
    checkDuplicateWorkspace() {
      clearTimeout(this.workspaceTimeout)
      this.workspaceTimeout = setTimeout(async () => {
        const duplicated = await superadmin.checkWorkspaceName(this.workspace)
        this.isDuplicatedWorkspaceName = duplicated
        this.$refs.workspace.validate()
      }, 500)
    },
    async next() {
      this.$emit('next', this.value)
    },
    async ok() {
      const organizationValid = this.$refs.organization.validate()
      const workspaceValid = this.$refs.workspace.validate()
      const contractValid = this.$refs.contract.validate()
      const timezoneValid = this.$refs.timezone.validate()
      const daterangeValid = this.$refs.daterange.validate()
      if (organizationValid && workspaceValid && contractValid && timezoneValid && daterangeValid) {
        try {
          await this.requestAction()
          this.$emit('ok', true)
          this.hide()
        } catch (e) {
          this.$log.error(e)
          this.$emit('ok', false)
        }
      }
    },
    requiredCheck() {
      if (this.activeTab === 'information') {
        if (!this.cameraName || this.cameraName === '') {
          return false
        }
        if (!this.country || this.country === '') {
          return false
        }
        if (!this.region || this.region === '') {
          return false
        }
        if (!this.county || this.county === '') {
          return false
        }
        if (!this.cameraProvider || this.cameraProvider === '') {
          return false
        }
        if (!this.apiKey || this.apiKey === '') {
          return false
        }
        if (!this.coordinate || this.coordinate === '') {
          return false
        }
        if (!this.altitude || this.altitude === '') {
          return false
        }
        if (!this.apiUrl || this.apiUrl === '') {
          return false
        }
      }
      return true
    },
    async requestAction() {
      this.$log.debug('DialogAdd#requestAction', this.organization, this.workspace, this.contractType, this.timezone, this.dateRange)
      const timezone = this.timezoneList.find((v) => v.name === this.timezone)
      const contract_type = this.contractList.find((v) => v.name === this.contractType)
      const arrDateRange0 = this.dateRange[0].split('-').map((v) => parseInt(v))
      const arrDateRange1 = this.dateRange[1].split('-').map((v) => parseInt(v))
      const contract_start = moment()
        .utcOffset(timezone.offset)
        .set({ year: arrDateRange0[0], month: arrDateRange0[1] - 1, date: arrDateRange0[2] })
        .startOf('day')
        .utc()
        .format()
      const contract_end = moment()
        .utcOffset(timezone.offset)
        .set({ year: arrDateRange1[0], month: arrDateRange1[1] - 1, date: arrDateRange1[2] })
        .startOf('day')
        .utc()
        .format()
      await superadmin.createOrganizations({
        name: this.organization,
        workspace: this.workspace,
        contract_type_id: contract_type.id,
        contract_start,
        contract_end,
      })
    },
    clear() {
      this.cameraName = ''
      this.organization = ''
      this.workspace = ''
      this.cameraProvider = undefined
      this.apiKey = undefined
      this.contractType = ''
      this.contractError = false
      this.coordinate = ''
      this.altitude = ''
      this.country = ''
      this.region = undefined
      this.county = undefined
      this.apiUrl = ''
      this.publicUrl = ''
      this.privateUrl1 = ''
      this.privateUrl2 = ''
      this.guide = ''
      this.monitored = undefined
      this.monitoringGroup = ''
      this.timezone = ''
      this.timezoneError = false
      this.dateRange = ''
      this.dateRangeError = false
    },
  },
}
</script>

<style lang="scss" scoped>
.org-dialog-title {
  padding: 30px 40px 0;
  font-weight: bold;
  font-size: 20px;
  /*line-height: 27px;*/
  color: #3d51fb;
}

.org-dialog-content {
  padding: 30px 40px 0;
  display: flex;
  height: calc(100% - 130px);
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  flex-direction: column;
  color: #363636;

  .org-dialog-tab {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;

    .org-dialog-tab-item {
      margin-left: 20px;

      &:not(.active) {
        cursor: pointer;
      }

      &:nth-of-type(1) {
        margin-left: 0px;
      }

      .tab-wrap {
        .tab-title {
          color: #d9d9d9;
        }

        .tab-title-bottom-border {
          width: 100%;
          height: 4px;
          border-radius: 4px 4px 0px 0px;
          border: 2px solid #d9d9d9;
          background-color: #d9d9d9;
        }
      }

      &.active {
        .tab-title {
          color: #3d51fb;
        }

        .tab-title-bottom-border {
          border-color: #3d51fb;
          background-color: #3d51fb;
        }
      }
    }
  }

  .org-dialog-tab-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    margin-top: 30px;
    .org-dialog-tab-content-wrap {
      display: flex;
      flex-direction: column;

      .org-dialog-section {
        display: flex;
        flex-direction: row;

        &.url-section {
          flex-direction: column;
          margin-top: 20px;
          padding: 20px 0;
          border-top: solid 2px #f9f9f9;
          border-bottom: solid 2px #f9f9f9;

          .url-button {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }

        .label-container {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          width: 150px;
          .radio-btn {
            margin-right: 10px;
          }
          .repetitive-text {
            margin-right: 41px;
          }

          > .label {
            position: relative;
            &:has(.required) {
              &:after {
                content: 'Please fill out this field.';
                position: absolute;
                top: 30px;
                left: 10px;
                color: #ff3830;
                font-size: 10px;
                font-weight: 400;
              }
            }
            &:has(.invalid) {
              &:after {
                content: 'Invalid data.';
                position: absolute;
                top: 30px;
                left: 10px;
                color: #ff3830;
                font-size: 10px;
                font-weight: 400;
              }
            }
            &:has(.duplicate) {
              &:after {
                content: 'The Name aleady exists.';
                position: absolute;
                top: 30px;
                left: 10px;
                color: #ff3830;
                font-size: 10px;
                font-weight: 400;
              }
            }
            &:has(.duplicate.url) {
              &:after {
                content: 'The API aleady exists.';
                position: absolute;
                top: 30px;
                left: 10px;
                color: #ff3830;
                font-size: 10px;
                font-weight: 400;
              }
            }
            > input {
              border-bottom: solid 1.5px rgba(0, 0, 0, 0.12);
              width: 100%;
              padding-left: 8px;
              padding-bottom: 7px;
              outline: none;

              &:focus {
                border-bottom: solid 1.5px #3b77ff;
              }

              &::placeholder {
                color: rgba(0, 0, 0, 0.38);
              }
              &.input-error {
                color: red;
                border-bottom: solid 1.5px red;
              }
            }
          }
        }
      }
    }
  }
}

.value {
  white-space: nowrap;
  color: #363636;

  &.dark {
    color: var(--f-primary-50);
  }

  &.handle-text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    min-width: 125px;
    text-align: end;
  }
}

.org-dialog-button {
  font-weight: 500;
  font-size: 15px;
  width: 120px;
  height: 38px;
  border-radius: 30px;

  &.next {
    background-color: #3d51fb;
    color: var(--v-textCount);
  }

  &.previous {
    background-color: #3d51fb;
    color: var(--v-textCount);
    &:disabled {
      background-color: #c9c9c9;
      color: #959595;
    }
  }

  &.cancel {
    background-color: var(--v-backgroundButtonCancel-base);
    color: var(--v-textButtonCancel);
    margin-right: 15px;
  }
}

.url-contents-wrap {
  display: flex;
  flex-direction: row;
  margin-top: 9px;
  padding: 20px;
  /*background-color: rgba(217, 217, 217, 0.2);*/
  border-radius: 8px;

  > .label-container {
    margin-left: 0 !important;
    margin-top: 0;

    .label {
      color: #363636;
    }

    &:nth-of-type(2) {
      > .label {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.caret-blue-item {
  > img {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  &.active > img {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.org-dialog-step-section {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  .org-dialog-step-text {
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.26);
  }
  .org-dialog-step-diagram {
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border: 3px solid rgba(0, 0, 0, 0.26);
    border-radius: 8px;
    margin-left: 10px;
  }
  .previous {
    > .org-dialog-step-text {
      color: #6d7dff;
    }
    > .org-dialog-step-diagram {
      border-color: #6d7dff;
      background-color: #6d7dff;
    }
    & + .org-dialog-step-bar {
      background-color: #6d7dff;
    }
  }
  .active {
    > .org-dialog-step-text {
      color: #363636;
    }
    > .org-dialog-step-diagram {
      border-color: #3d51fb;
    }
  }
  > .org-dialog-step-1 {
  }
  > .org-dialog-step-2 {
  }
  > .org-dialog-step-bar {
    width: 170px;
    height: 4px;
    background: #d9d9d9;
    border-radius: 4px;
    margin-left: -2px;
    margin-right: -2px;
    margin-top: 5px;
    /*&.active {
      background: #3d51fb;
    }*/
  }
}
</style>

<!-- global style -->
<style lang="scss">
.dialog-camera-detail {
  height: 90%;
}
</style>
