<template>
  <v-dialog v-model="value" width="520px" persistent>
    <v-card style="background-color: white">
      <v-card-text class="pa-0">
        <div class="add-confirm-dialog-title">{{ titleText }}</div>
        <div class="add-confirm-dialog-content">
          <div style="font-weight: 500; font-size: 15px; color: #363636">
            Do you want to add {{ data.length === 1 ? `${data.length} camera` : `${data.length} cameras` }} to {{ organization.name }}?
          </div>
        </div>
        <div class="d-flex justify-end" style="padding-right: 30px; padding-bottom: 20px">
          <button v-ripple @click="hide" class="add-confirm-dialog-button cancel">Cancel</button>
          <button v-ripple @click="ok" class="add-confirm-dialog-button ok">{{ okText }}</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { superadmin } from '@/api'

export default {
  props: {
    value: Boolean,
    organization: Object,
    data: Array,
    all: Boolean,
    /**
     * emit events
     * ok
     */
  },
  data() {
    return {
      titleText: 'Add Camera',
      okText: 'Add',
    }
  },
  computed: {
    sortedData() {
      return this.data.slice().sort((a, b) => a.name.localeCompare(b.name))
    },
  },
  mounted() {},
  methods: {
    hide() {
      this.$emit('input', false)
    },
    async ok() {
      try {
        await this.requestAction()
        this.$emit('ok', true)
        this.hide()
      } catch (error) {
        this.$emit('ok', false)
      }
    },
    async requestAction() {
      this.$log.debug('DialogAddConfirm#requestAction', this.data.length)
      const cameras_ids = this.data.map((v) => v.id)
      await superadmin.createAllocatedCamera(this.organization.id, cameras_ids)
    },
  },
}
</script>

<style lang="scss" scoped>
.add-confirm-dialog-title {
  padding: 30px 40px 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #3d51fb;
}

.add-confirm-dialog-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 190px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

.add-confirm-dialog-button {
  font-weight: 500;
  font-size: 15px;
  width: 120px;
  height: 38px;
  border-radius: 30px;

  &.ok {
    background-color: #3d51fb;
    color: #ffffff;
  }

  &.cancel {
    background-color: var(--v-backgroundButtonCancel-base);
    color: var(--v-textButtonCancel);
    margin-right: 15px;
  }
}
</style>
