<template>
  <div class="review-container">
    <div :class="{ review: true, select, open }">
      <div class="title-wrapper">
        <span
          :class="{ 'review-subject': true, reviewed: isReviewedTab }"
          @click="onClickReview(review.id, review.user.username, review.subject, review.user.userID, review.recipients)"
          >{{ review.subject }}</span
        >
        <span class="reviewed-action-wrapper" v-if="isReviewedTab">
          <button :class="{ 'reviewed-action-button': true, disabled: !showMergeButton }" @click="onClickMerge(review.id)">Merge</button>
          <button
            :class="{ 'reviewed-action-button': true, disabled: !showEditButton }"
            @click="onClickEdit(review.id, review.user.username, review.subject)"
          >
            Edit
          </button>
          <button :class="{ 'reviewed-action-button': true, disabled: !showArchiveButton }" @click="onClickArchive(review.id)">Archive</button>
          <button :class="{ 'reviewed-action-button': true, disabled: !showDeleteButton }" @click="onClickDelete(review.id)">Delete</button>
        </span>
        <img v-if="open" :src="require('@/assets/Chevron-Down_16px_Icon-Blue.svg')" @click="onClickChevron(review.id)" />
        <img v-else :src="require('@/assets/Chevron-Down_16px_Icon-Dark.svg')" @click="onClickChevron(review.id)" />
      </div>
      <div class="info-wrapper">
        <div class="review-request-name">requested by {{ review.user.username }}</div>
        <div class="review-request-time">{{ reviewRequestTime }}</div>
      </div>
    </div>
    <div class="review-situation" v-if="open">
      <div v-for="(recipient, index) in review.recipients" :key="recipient.user.userID">
        <div :class="{ last: review.recipients.length - 1 === index, 'review-situation-info': true }">
          <span style="display: flex; align-items: center">
            <img class="status-icon" v-if="recipient.status === REVIEW_STATUS[0]" :src="require('@/assets/Success-Circle_Filled_16px_Icon.svg')" />
            <img class="status-icon" v-else-if="recipient.status === REVIEW_STATUS[1]" :src="require('@/assets/Input_Search_Bar_Close-red.svg')" />
            <img class="status-icon" v-else-if="recipient.status === REVIEW_STATUS[2]" :src="require('@/assets/Radio-Unselected_16px_Icon.svg')" />
            <span :class="{ uncheck: recipient.status === REVIEW_STATUS[2] }">{{
              getReviewStatusText(recipient.status, recipient.user.username)
            }}</span>
          </span>
          <span class="review-situation-date" v-if="recipient.status !== REVIEW_STATUS[2]">{{ recpientRequestTime(recipient.updated_at) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import CONSTANT from '@/constants'
const REVIEW_STATUS = ['confirmed', 'rejected', 'unchecked']

export default {
  props: {
    review: {
      type: Object,
      default: {},
    },
    select: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    isReviewedTab: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      REVIEW_STATUS,
      CONSTANT,
    }
  },
  computed: {
    ...mapState({
      userRole: (state) => state.user.user_role,
      userId: (state) => state.user.id,
    }),
    requestTime() {
      return moment(this.review.time_point).format('YYYY/MM/DD HH:mm:ss')
    },
    showMergeButton() {
      if (this.userRole === CONSTANT.ROLE_OBSERVER) return false
      for (let i = 0; i < this.review.recipients.length; i++) if (this.review.recipients[i].status !== REVIEW_STATUS[0]) return false
      if (this.userRole === CONSTANT.ROLE_SUPER_ADMIN) return true
      else return false
    },
    showEditButton() {
      if (this.userRole === CONSTANT.ROLE_OBSERVER) return false
      let confirmedCount = 0
      for (let i = 0; i < this.review.recipients.length; i++) if (this.review.recipients[i].status === REVIEW_STATUS[0]) confirmedCount += 1
      if (this.userId == this.review.user.userID && confirmedCount !== this.review.recipients.length) return true
      else return false
    },
    showDeleteButton() {
      return this.userRole !== CONSTANT.ROLE_OBSERVER
    },
    showArchiveButton() {
      if (this.userRole === CONSTANT.ROLE_OBSERVER) return false
      for (let i = 0; i < this.review.recipients.length; i++) if (this.review.recipients[i].status !== REVIEW_STATUS[1]) return false
      if (this.userId == this.review.user.userID) return true
      else return false
    },
  },
  methods: {
    ...mapActions('superadmin', [
      'mergeReviewedRequestAction',
      'editReviewedRequestAction',
      'archiveReviewedRequestAction',
      'deleteReviewedRequestAction',
      'getSelectedRequestTableAction',
      'resetCameraListTableData',
      'getReviewListAction',
    ]),
    async onClickReview(id, userName, subject, owner, recipients) {
      this.$emit('setSelectedReviewId', id)
      const requestedToMe = this.checkRequestRecipent(recipients)
      await this.getSelectedRequestTableAction({ id, userName, subject, authority: owner !== this.userId && requestedToMe })
    },
    checkRequestRecipent(recipients) {
      for (let i = 0; i < recipients.length; i++) if (recipients[i].user.userID === this.userId) return true
      return false
    },
    recpientRequestTime(time) {
      return moment(time).format('YYYY/MM/DD HH:mm:ss')
    },
    getReviewStatusText(status, name) {
      if (status === REVIEW_STATUS[0]) return `confirmed by ${name}`
      else if (status === REVIEW_STATUS[1]) return `rejected by ${name}`
      else return `not reviewed by ${name}`
    },
    async onClickMerge(id) {
      if (!this.showMergeButton) return
      const mergeResult = await this.mergeReviewedRequestAction(id)
      if (mergeResult) {
        this.$toast('Successfully Merged.', {
          position: 'bottom-center',
          toastClassName: 'requestSuccessToastClass',
          timeout: 7000,
        })
      }
    },
    async onClickEdit(id, userName, subject) {
      if (!this.showEditButton) return
      await this.editReviewedRequestAction({ id, userName, subject })
    },
    async onClickDelete(id) {
      if (!this.showDeleteButton) return
      const deleteResult = await this.deleteReviewedRequestAction(id)
      if (deleteResult) {
        this.$toast('Change Request Deleted.', {
          position: 'bottom-center',
          toastClassName: 'requestSuccessToastClass',
          timeout: 7000,
        })
      }
    },
    async onClickArchive(id) {
      if (!this.showArchiveButton) return
      const archiveResult = await this.archiveReviewedRequestAction(id)
      if (archiveResult) {
        this.$toast('Successfully Archived.', {
          position: 'bottom-center',
          toastClassName: 'requestSuccessToastClass',
          timeout: 7000,
        })
      }
    },
    onClickChevron(id) {
      this.$emit('showReviewStatus', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.review {
  background: rgba(233, 235, 255, 0.24);
  width: 472px;
  height: 87px;
  padding: 16px 24px;
  border-radius: 15px;
  margin-bottom: 16px;
  &.open {
    margin-bottom: 8px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), rgba(233, 235, 255, 0.24);
  }
  &:hover {
    background: linear-gradient(0deg, rgba(109, 125, 255, 0.1), rgba(109, 125, 255, 0.1)), rgba(233, 235, 255, 0.24);
  }
  &.select {
    background: linear-gradient(0deg, rgba(109, 125, 255, 0.1), rgba(109, 125, 255, 0.1)),
      linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), rgba(233, 235, 255, 0.24);
  }
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  .review-subject {
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: var(--f-text-black);
    display: inline-block;

    &.reviewed {
      max-width: 220px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .info-wrapper {
    display: flex;
    justify-content: space-between;
    .review-request-name {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }
    .review-request-time {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.reviewed-action-wrapper {
  height: 19px;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .reviewed-action-button {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #6d7dff;
    margin-right: 8px;
    &.disabled {
      color: #8d90a2;
      cursor: default;
    }
  }
}

.review-situation {
  width: 452px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), rgba(233, 235, 255, 0.24);
  margin-left: 20px;
  margin-bottom: 16px;
  padding: 16px 24px;
  border-radius: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  .status-icon {
    margin-right: 8px;
  }

  .uncheck {
    color: rgba(0, 0, 0, 0.26);
  }
  .review-situation-info {
    margin-bottom: 19px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.last {
      margin-bottom: 0px;
    }
    .review-situation-date {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>
