import { VALIDATE_TYPE, EMPTY_ERROR, INVALID_ERROR, ERROR_TYPE } from './options'

function checkValidate({ value, types }) {
  let pattern = ''
  if (types.indexOf(VALIDATE_TYPE.UPPER_CASE) > -1) {
    pattern += 'A-Z'
  }
  if (types.indexOf(VALIDATE_TYPE.LOWER_CASE) > -1) {
    pattern += 'a-z'
  }
  if (types.indexOf(VALIDATE_TYPE.NUMBER) > -1) {
    pattern += '0-9'
  }
  if (types.indexOf(VALIDATE_TYPE.SPECIAL_CHARACTER) > -1) {
    pattern += '!@#$%^&*()_+-=,./<>?;:\'"[]{}|`~'
  }
  if (types.indexOf(VALIDATE_TYPE.KOREAN) > -1) {
    pattern += 'ㄱ-ㅣ가-힣'
  }
  if (types.indexOf(VALIDATE_TYPE.COORDINATE) > -1) {
    pattern += '0-9. ,-'
  }
  const reg = new RegExp(`^[${pattern}]+$`)
  return reg.test(value)
}

function preventSpecificCharacterType({ value, types }) {
  let pattern = ''
  if (types.indexOf(VALIDATE_TYPE.KOREAN) > -1) {
    pattern += 'ㄱ-ㅎㅣ가-힣|ㅏ-ㅣ'
  }
  const reg = new RegExp(`[${pattern}]`, 'g')
  return value.replaceAll(reg, '')
}

function checkCameraNameLettersValidity(cameraName) {
  return cameraName.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣\'\!\@\#\$\%\^\&\*\_\+\=\<\>\?\;\:\\\'\"\[\]\{\}\|\`\~\']/g, '')
}

function checkCoordinateLettersValidity(coordinate) {
  return coordinate.replace(/[(ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z)\(\)\/\'\!\@\#\$\%\^\&\*\_\+\=\<\>\?\;\:\\\'\"\[\]\{\}\|\`\~\']/g, '')
}

function cameraNameCharacterValidate(cameraName) {
  const reg = new RegExp(/^[a-zA-Z0-9](?!.*(?:[-\/()., ]){2})[a-zA-Z0-9\-\/()., ]{1,}/, 'g')
  return reg.test(cameraName)
}

function preventKoreanCharacter(text) {
  return text.replace(/(ㄱ-ㅎ|ㅏ-ㅣ|가-힣)/g, '')
}

function validateMonitored(value) {
  if (value !== 0 && value !== 1) return [false, ERROR_TYPE.REQUIRE]
  else return [true, '']
}

function validateDropdown(value, items) {
  try {
    if (!value) return [false, EMPTY_ERROR]
    const isValidated = items.filter((item) => item.id === value).length
    if (isValidated === 0) return [false, INVALID_ERROR]
    else return [true, '']
  } catch (e) {
    return [false, INVALID_ERROR]
  }
}

function validateCooldinateCharacter(coordinate) {
  const testValue = { value: coordinate, types: [VALIDATE_TYPE.COORDINATE] }
  const testResult = checkValidate(testValue)
  return testResult
}

function validateCooldinateForm(coordinate) {
  const reg = new RegExp(/-?\d+\.?\d*((, )|( ,)|,| ){1}-?\d+\.?\d*/, 'g')
  const testResult = coordinate.replace(reg, '')
  if (testResult.length === 0) return true
  else return false
}

function checkCoordinateRange(lati, lognti) {
  const latiRangeCheck = isValidLatitude(lati)
  const longtiRangeCheck = isValidLongitude(lognti)
  if (latiRangeCheck && longtiRangeCheck) return true
  else return false
}

function validateAltitude(altitude) {
  const testValue = { value: altitude, types: [VALIDATE_TYPE.NUMBER] }
  const testResult = checkValidate(testValue)
  return testResult && +altitude >= 0 && +altitude <= 100000
}

function validateApiUrl(api) {
  if (!api.length) return [false, ERROR_TYPE.REQUIRE]
  const testResult = urlValidate(api)
  if (testResult) return [true, '']
  else return [false, ERROR_TYPE.INVALID]
}

function isEmpty(text) {
  return text === undefined || (typeof text === 'string' && !text.length)
}

function hasSpecialCharacter(text) {
  return /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(text)
}

function urlValidate(value) {
  const pattern = /^https?:\/\/[a-zA-Zㄱ-ㅣ가-힣0-9.\-_'!@#$%^&*()+=,/<>?;:"\[\]{}|`~]+/i
  return pattern.test(value)
}

function isValidLatitude(value) {
  return /^[+-]?(([1-8]?[0-9])(\.[0-9]{1,10})?|90(\.0{1,10})?)$/.test(value)
}

function isValidLongitude(value) {
  return /^[+-]?((([1-9]?[0-9]|1[0-7][0-9])(\.[0-9]{1,10})?)|180(\.0{1,10})?)$/.test(value)
}

function isValidNumber(value) {
  return /^\d+\.\d{0,8}$/.test(value)
}

function isValidAltitude(value) {
  return (typeof value === 'number' || /^\d+\.\d{0,8}$/.test(value)) && Number(value) <= 100000
}

function isValidMonitoredAndCameraGroupId(monitoredVal, cameraGroupIdVal) {
  if (monitoredVal !== undefined && cameraGroupIdVal !== undefined) {
    if ((monitoredVal === 'Yes' && typeof cameraGroupIdVal === 'number') || (monitoredVal === 'No' && cameraGroupIdVal === '-')) {
      return true
    }
  }
  return false
}

function isValidEnglishString(value) {
  return !!value && value !== '' && typeof value === 'string' && /^[A-Za-z]*$/.test(value)
}

function isValidPublicUrl(value) {
  return /^(ftp|http|https):\/\/[^ "]+$/.test(value)
}

function validateUrlFormat(text) {
  if (!text.length) return [true, '']
  const formValidate = /^(http:\/\/|https:\/\/)/.test(text)
  if (formValidate) return [true, '']
  else return [false, ERROR_TYPE.INVALID]
}

export default {
  validateUrlFormat,
  checkCoordinateLettersValidity,
  preventKoreanCharacter,
  checkCameraNameLettersValidity,
  validateCooldinateCharacter,
  validateCooldinateForm,
  validateDropdown,
  validateAltitude,
  checkValidate,
  validateApiUrl,
  validateMonitored,
  preventSpecificCharacterType,
  // returns True/False
  cameraNameCharacterValidate,
  checkCoordinateRange,
  isEmpty,
  hasSpecialCharacter,
  urlValidate,
  isValidLatitude,
  isValidLongitude,
  isValidNumber,
  isValidAltitude,
  isValidMonitoredAndCameraGroupId,
  isValidEnglishString,
  isValidPublicUrl,
}
