import { render, staticRenderFns } from "./DialogSubmitChanges.vue?vue&type=template&id=7a341d32&scoped=true&"
import script from "./DialogSubmitChanges.vue?vue&type=script&lang=js&"
export * from "./DialogSubmitChanges.vue?vue&type=script&lang=js&"
import style0 from "./DialogSubmitChanges.vue?vue&type=style&index=0&id=7a341d32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a341d32",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardText,VDialog,VIcon,VTextField})
