<template>
  <span :class="{ count: true, select }" @click="$emit('onClickCount')">
    {{ count }}
  </span>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0,
    },
    select: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.count {
  border-radius: 12px;
  width: 29px;
  height: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background-color: #e9ebff;
  color: #6d7dff;
  text-align: center;
  display: inline-block;
  &.select {
    background-color: #3d51fb;
    color: #ffffff;
  }
}
</style>
