<template>
  <div :class="{ review: true, select }">
    <div class="review-subject" @click="onClickArchive">{{ review.subject }}</div>
    <div class="wrapper">
      <div class="review-request-name">archived by you</div>
      <div class="review-request-time">{{ requestTime }}</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    review: {
      type: Object,
      default: {},
    },
    select: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    requestTime() {
      if (this.review.time_point) return moment(this.review.time_point).format('YYYY/MM/DD HH:mm:ss')
      else return moment(this.review.created_at).format('YYYY/MM/DD HH:mm:ss')
    },
  },
  methods: {
    onClickArchive() {
      this.$emit('onClickReview', { id: this.review.id, subject: this.review.subject, userName: this.review.user.username })
    },
  },
}
</script>

<style lang="scss" scoped>
.review {
  background: rgba(233, 235, 255, 0.24);
  width: 472px;
  height: 87px;
  padding: 16px 24px;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 16px;
  &:hover {
    background: linear-gradient(0deg, rgba(109, 125, 255, 0.1), rgba(109, 125, 255, 0.1)), rgba(233, 235, 255, 0.24);
  }
  &.select {
    background: linear-gradient(0deg, rgba(109, 125, 255, 0.1), rgba(109, 125, 255, 0.1)),
      linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), rgba(233, 235, 255, 0.24);
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
  }
}
.review-subject {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--f-text-black);
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}
.review-request-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
.review-request-time {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
</style>
