<template>
  <div>
    <div class="org-dialog-section-wrap">
      <div class="select-content radio">
        <div class="select-content-title">PTZ Value*</div>
        <div class="radio-wrapper">
          <SelectRadioButton uniqueName="PTZValue" :items="YES_OR_NO_OPTION" :selectValue="PTZValue" @select="PTZValue = $event" />
        </div>
      </div>
      <div class="select-content">
        <div class="select-content-title">Image Quality*</div>
        <div class="dropdown-wrapper">
          <DropdownSimple :items="IMAGE_QUALITY" v-model="imageQualityValue" />
        </div>
      </div>
      <div class="select-content radio">
        <div class="select-content-title">Night Detection*</div>
        <div class="radio-wrapper">
          <SelectRadioButton uniqueName="nightDetection" :items="YES_OR_NO_OPTION" :selectValue="nightDetection" @select="nightDetection = $event" />
        </div>
      </div>
      <div class="select-content radio">
        <div :class="`select-content-title ${nightDetection === 2 ? 'disable' : ''}`">IR filter*</div>
        <div class="radio-wrapper">
          <SelectRadioButton
            uniqueName="IRFilter"
            :items="YES_OR_NO_OPTION"
            :selectValue="IRfilter"
            @select="IRfilter = $event"
            :disable="nightDetection === 2"
          />
        </div>
      </div>
      <div class="select-content radio">
        <div class="select-content-title">Seviced*</div>
        <div class="radio-wrapper">
          <SelectRadioButton uniqueName="seviced" :items="YES_OR_NO_OPTION" :selectValue="serviced" @select="serviced = $event" />
        </div>
      </div>
      <div class="select-content radio">
        <div class="select-content-title">Evaluated*</div>
        <div class="radio-wrapper">
          <SelectRadioButton uniqueName="evaluated" :items="YES_OR_NO_OPTION" :selectValue="evaluated" @select="evaluated = $event" />
        </div>
      </div>
      <div class="select-content radio">
        <div class="select-content-title">Internal Usage*</div>
        <div class="radio-wrapper">
          <SelectRadioButton uniqueName="internalUsage" :items="YES_OR_NO_OPTION" :selectValue="internalUsage" @select="internalUsage = $event" />
        </div>
      </div>
      <div class="select-content">
        <div class="select-content-title hftd-area">
          Within HFTD Area
          <img
            :src="require('@/assets/Info_Filled_16px_Icon-blue.svg')"
            alt="HFTD_info"
            style="margin-left: 4px; cursor: pointer"
            @click="onClickHFTDInfo"
          />
        </div>
        <div class="dropdown-wrapper">
          <DropdownSimple :items="HFTD_AREA" v-model="HFTDAreaValue" />
        </div>
      </div>
      <div class="select-content radio">
        <div class="select-content-title">Pan / Fixed*</div>
        <div class="radio-wrapper">
          <SelectRadioButton uniqueName="panFixed" :items="PAN_OPTION" :selectValue="panFixed" @select="panFixed = $event" />
        </div>
      </div>
      <div class="select-content">
        <div class="select-content-title">Camera Cleanness*</div>
        <div class="dropdown-wrapper">
          <DropdownSimple :items="CAMERA_CLEARNESS" v-model="cameraClearnessValue" />
        </div>
      </div>
      <div class="select-content">
        <div class="select-content-title">Sky Area*</div>
        <div class="dropdown-wrapper">
          <DropdownSimple :items="SKY_AREA" v-model="skyAreaValue" />
        </div>
      </div>
      <div class="select-content radio">
        <div class="select-content-title">False Positive Areas*</div>
        <div class="radio-wrapper">
          <SelectRadioButton
            uniqueName="falsePositiveAreas"
            :items="YES_OR_NO_OPTION"
            :selectValue="falsePositiveAreas"
            @select="falsePositiveAreas = $event"
          />
        </div>
      </div>
    </div>
    <div class="score-wrapper">
      <span class="score-text">Score</span>
      <div class="score-number">{{ calculatedScore }}</div>
    </div>
    <HFTDAreaInfoModal v-model="openHFDTModal" v-if="openHFDTModal" @closeHFTDModal="closeHFTDModal" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  YES_OR_NO_OPTION,
  IMAGE_QUALITY,
  HFTD_AREA,
  CAMERA_CLEARNESS,
  PAN_OPTION,
  SKY_AREA,
  CONDITION_OPTION,
} from '@desktop/superadminOrWorker/settings/camera-list/feature/options'
import SelectRadioButton from './SelectRadioButton'
import DropdownSimple from '@desktop/superadminOrWorker/settings/DropdownSimple'
import HFTDAreaInfoModal from '@desktop/superadminOrWorker/settings/camera-list/HFTDAreaInfoModal'

export default {
  components: { SelectRadioButton, DropdownSimple, HFTDAreaInfoModal },
  props: {
    conditionValues: {
      type: Object,
    },
  },
  data() {
    return {
      CONDITION_OPTION,
      scoringCriteriaData: null,
      PTZValue: 1,
      imageQualityValue: 1,
      nightDetection: 1,
      IRfilter: 1,
      serviced: 1,
      evaluated: 2,
      internalUsage: 1,
      panFixed: 1,
      falsePositiveAreas: 2,
      skyAreaValue: 3,
      cameraClearnessValue: 3,
      HFTDAreaValue: 4,
      openHFDTModal: false,
      YES_OR_NO_OPTION,
      PAN_OPTION,
      IMAGE_QUALITY,
      HFTD_AREA,
      CAMERA_CLEARNESS,
      SKY_AREA,
    }
  },
  async created() {
    this.scoringCriteriaData = await this.getCameraScoringCriteria()
  },
  mounted() {
    this.PTZValue = this.conditionValues.PTZValue === 0 ? this.PTZValue : this.conditionValues.PTZValue
    this.nightDetection = this.conditionValues.nightDetection === 0 ? this.nightDetection : this.conditionValues.nightDetection
    this.IRfilter = this.conditionValues.IRfilter === 0 ? this.IRfilter : this.conditionValues.IRfilter
    this.serviced = this.conditionValues.serviced === 0 ? this.serviced : this.conditionValues.serviced
    this.evaluated = this.conditionValues.evaluated === 0 ? this.evaluated : this.conditionValues.evaluated
    this.internalUsage = this.conditionValues.internalUsage === 0 ? this.internalUsage : this.conditionValues.internalUsage
    this.panFixed = this.conditionValues.panFixed === 0 ? this.panFixed : this.conditionValues.panFixed
    this.falsePositiveAreas = this.conditionValues.falsePositiveAreas === 0 ? this.falsePositiveAreas : this.conditionValues.falsePositiveAreas
    this.imageQualityValue = this.conditionValues.imageQualityValue === 0 ? this.imageQualityValue : this.conditionValues.imageQualityValue
    this.skyAreaValue = this.conditionValues.skyAreaValue === 0 ? this.skyAreaValue : this.conditionValues.skyAreaValue
    this.cameraClearnessValue =
      this.conditionValues.cameraClearnessValue === 0 ? this.cameraClearnessValue : this.conditionValues.cameraClearnessValue
    this.HFTDAreaValue = this.conditionValues.HFTDAreaValue === 0 ? this.HFTDAreaValue : this.conditionValues.HFTDAreaValue
  },
  computed: {
    PTZValueScore() {
      if (!this.scoringCriteriaData) return 0
      return (
        this.scoringCriteriaData[CONDITION_OPTION.PTZ_VALUE].scores[this.PTZValue] * this.scoringCriteriaData[CONDITION_OPTION.PTZ_VALUE].weighting
      )
    },
    imageQualityScore() {
      if (!this.scoringCriteriaData) return 0
      return (
        this.scoringCriteriaData[CONDITION_OPTION.IMAGE_QUALITY].scores[this.imageQualityValue] *
        this.scoringCriteriaData[CONDITION_OPTION.IMAGE_QUALITY].weighting
      )
    },
    nightDetectionScore() {
      if (!this.scoringCriteriaData) return 0
      return (
        this.scoringCriteriaData[CONDITION_OPTION.NIGHT_DETECTION].scores[this.nightDetection] *
        this.scoringCriteriaData[CONDITION_OPTION.NIGHT_DETECTION].weighting
      )
    },
    IRfilterScore() {
      if (!this.scoringCriteriaData || this.nightDetection !== 1) return 0
      return (
        this.scoringCriteriaData[CONDITION_OPTION.IR_FILTER].scores[this.IRfilter] * this.scoringCriteriaData[CONDITION_OPTION.IR_FILTER].weighting
      )
    },
    servicedScore() {
      if (!this.scoringCriteriaData) return 0
      return this.scoringCriteriaData[CONDITION_OPTION.SERVICED].scores[this.serviced] * this.scoringCriteriaData[CONDITION_OPTION.SERVICED].weighting
    },
    evaluatedScore() {
      if (!this.scoringCriteriaData) return 0
      return (
        this.scoringCriteriaData[CONDITION_OPTION.EVALUATED].scores[this.evaluated] * this.scoringCriteriaData[CONDITION_OPTION.EVALUATED].weighting
      )
    },
    internalUsageScore() {
      if (!this.scoringCriteriaData) return 0
      return (
        this.scoringCriteriaData[CONDITION_OPTION.INTERNAL_USAGE].scores[this.internalUsage] *
        this.scoringCriteriaData[CONDITION_OPTION.INTERNAL_USAGE].weighting
      )
    },
    panFixedScore() {
      if (!this.scoringCriteriaData) return 0
      return (
        this.scoringCriteriaData[CONDITION_OPTION.PAN_FIXED].scores[this.panFixed] * this.scoringCriteriaData[CONDITION_OPTION.PAN_FIXED].weighting
      )
    },
    falsePositiveAreasScore() {
      if (!this.scoringCriteriaData) return 0
      return (
        this.scoringCriteriaData[CONDITION_OPTION.FALSE_POSITIVE_AREAS].scores[this.falsePositiveAreas] *
        this.scoringCriteriaData[CONDITION_OPTION.FALSE_POSITIVE_AREAS].weighting
      )
    },
    HFTDAreaValueScore() {
      if (!this.scoringCriteriaData) return 0
      return (
        this.scoringCriteriaData[CONDITION_OPTION.HFTD_AREA].scores[this.HFTDAreaValue] *
        this.scoringCriteriaData[CONDITION_OPTION.HFTD_AREA].weighting
      )
    },
    calculatedScore() {
      return (
        this.PTZValueScore +
        this.imageQualityScore +
        this.nightDetectionScore +
        this.IRfilterScore +
        this.servicedScore +
        this.evaluatedScore +
        this.internalUsageScore +
        this.panFixedScore +
        this.falsePositiveAreasScore +
        this.skyAreaValue +
        this.cameraClearnessValue +
        this.HFTDAreaValueScore
      )
    },
  },
  methods: {
    ...mapActions('superadmin', ['getCameraScoringCriteria']),
    updateData() {
      const updatedData = {
        PTZValue: this.PTZValue,
        nightDetection: this.nightDetection,
        IRfilter: this.IRfilter,
        serviced: this.serviced,
        evaluated: this.evaluated,
        internalUsage: this.internalUsage,
        panFixed: this.panFixed,
        falsePositiveAreas: this.falsePositiveAreas,
        imageQualityValue: this.imageQualityValue,
        skyAreaValue: this.skyAreaValue,
        cameraClearnessValue: this.cameraClearnessValue,
        HFTDAreaValue: this.HFTDAreaValue,
      }
      this.$emit('updateConditionValues', updatedData)
    },
    onClickHFTDInfo() {
      this.openHFDTModal = true
    },
    closeHFTDModal() {
      this.openHFDTModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.org-dialog-section-wrap {
  padding: 0 10px;
  margin-top: 3px;
  border-bottom: 2px solid #f9f9f9;
}
.select-content {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  &.radio {
    margin-bottom: 25px;
  }
  .select-content-title {
    width: 45.7%;
    font-weight: 500;
    font-size: 15px;
    &.hftd-area {
      display: flex;
    }
    &.disable {
      color: #959595;
    }
  }
  .dropdown-wrapper {
    flex-grow: 1;
  }
}

.radio-wrapper {
  width: 54.3%;
}
.score-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 28px;
  .score-text {
    font-weight: 500;
    font-size: 15px;
    margin-right: 40px;
    color: var(--f-text-black);
  }
  .score-number {
    color: var(--f-text-black);
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.12);
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    min-width: 40px;
    text-align: center;
  }
}
</style>
