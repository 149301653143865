<template>
  <div class="resolved-tab">
    <div v-if="resolvedDiscussionList.length">
      <div v-for="discussion in resolvedDiscussionList" :key="discussion.id">
        <DiscussionCommentItem :discussion="discussion" @onClickComment="onClickComment" :selectedId="selectedId" :isResolved="true" />
      </div>
    </div>
    <div v-else>
      <NoDataItem />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DiscussionCommentItem from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/DiscussionCommentItem'
import NoDataItem from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/NoDataItem'
export default {
  components: { DiscussionCommentItem, NoDataItem },
  data() {
    return {
      selectedId: -1,
    }
  },
  computed: {
    ...mapState('superadmin', {
      resolvedDiscussionList: (state) => state.resolvedDiscussionList,
    }),
  },
  methods: {
    onClickReplay() {},
    onClickComment(id) {
      if (this.selectedId === id) this.selectedId = -1
      else this.selectedId = id
    },
  },
}
</script>

<style lang="scss" scoped>
.resolved-tab {
  height: 506px;
  overflow-y: auto;
}
</style>
