<template>
  <v-dialog v-model="value" width="520px" persistent content-class="dialog-camera-detail">
    <v-card style="background-color: white; height: 100%">
      <v-card-text class="pa-0" style="height: 100%">
        <div class="org-dialog-title">{{ titleText }}</div>
        <div class="org-dialog-content">
          <div class="org-dialog-tab">
            <div class="org-dialog-tab-item" :class="{ active: activeTab === 'information' }" @click="activeTab = 'information'">
              <div class="tab-wrap">
                <div class="tab-title">Information</div>
                <div class="tab-title-bottom-border"></div>
              </div>
            </div>
            <div class="org-dialog-tab-item" :class="{ active: activeTab === 'conditions' }" @click="activeTab = 'conditions'">
              <div class="tab-wrap">
                <div class="tab-title">Conditions</div>
                <div class="tab-title-bottom-border"></div>
              </div>
            </div>
          </div>
          <div class="org-dialog-tab-content">
            <div class="org-dialog-tab-content-wrap" v-if="activeTab === 'information'">
              <div class="org-dialog-section-wrap">
                <div class="org-dialog-section">
                  <div class="label-container">
                    <span class="label" style="margin-top: 33px">Camera Name</span>
                    <span class="label" style="margin-top: 31px">Country</span>
                    <span class="label" style="margin-top: 31px">Region</span>
                    <span class="label" style="margin-top: 31px">County</span>
                    <span class="label" style="margin-top: 31px">Coordinate</span>
                    <span class="label" style="margin-top: 31px">Altitude</span>
                  </div>
                  <div class="label-container" style="margin-left: 0; width: 300px; font-weight: 400">
                    <span class="label" style="margin-top: 33px">{{ information.name }}</span>
                    <span class="label" style="margin-top: 31px">{{ countryText }}</span>
                    <span class="label" style="margin-top: 31px">{{ regionText }}</span>
                    <span class="label" style="margin-top: 31px">{{ countyText }}</span>
                    <span class="label" style="margin-top: 31px">{{ coordinate }}</span>
                    <span class="label" style="margin-top: 31px">{{ information.altitude }}</span>
                  </div>
                </div>
                <div class="org-dialog-section url-section">
                  <div class="url-button" @click="onOpenUrlContent">
                    <div class="label-container">URL</div>
                    <div class="label-container" style="align-items: flex-end">
                      <span :class="'caret-blue-item' + (openUrlContents ? ' active' : '')"><img src="@/assets/Up_Blue_Icon.svg" /></span>
                    </div>
                  </div>
                  <div v-if="openUrlContents" class="url-contents-wrap">
                    <div class="label-container">
                      <span class="label" style="margin-top: 0">Camera Provider</span>
                      <span class="label" style="margin-top: 31px">API Key</span>
                      <span class="label" style="margin-top: 31px">API</span>
                      <span class="label" style="margin-top: 31px">Public</span>
                      <span class="label" style="margin-top: 31px">Private 1</span>
                      <span class="label" style="margin-top: 31px">Private 2</span>
                      <span class="label" style="margin-top: 31px">Guide</span>
                    </div>
                    <div class="label-container" style="margin-left: 24px; width: 250px; font-weight: 400">
                      <span class="label" style="margin-top: 0"><CustomToolTip :value="cameraProviderText" /></span>
                      <span class="label" style="margin-top: 31px"><CustomToolTip :value="apiKeyText" /></span>
                      <span class="label" style="margin-top: 31px"><CustomToolTip :value="information.api_url" /></span>
                      <span class="label link" style="margin-top: 31px"
                        ><CustomToolTip :value="information.public_url" /><img
                          :src="require('@/assets/Goto_Line_16px_Icon-grey.svg')"
                          alt="go_to"
                          class="goto"
                          @click="onClickLink(information.public_url)"
                      /></span>
                      <span class="label" style="margin-top: 31px"><CustomToolTip :value="information.private_url_1" /></span>
                      <span class="label" style="margin-top: 31px"><CustomToolTip :value="information.private_url_2" /></span>
                      <span class="label link" style="margin-top: 31px"
                        ><CustomToolTip :value="information.guide_url" /><img
                          :src="require('@/assets/Goto_Line_16px_Icon-grey.svg')"
                          alt="go_to"
                          class="goto"
                          @click="onClickLink(information.guide_url)"
                      /></span>
                    </div>
                  </div>
                </div>
                <div class="org-dialog-section">
                  <div class="label-container" style="width: 57.6%">
                    <span class="label" style="margin-top: 31px">Monitored</span>
                  </div>
                  <div class="label-container" style="font-weight: 400; margin-left: 0; width: 28.1%">
                    <span class="label" style="margin-top: 31px">{{ information.monitored ? 'Yes' : 'No' }}</span>
                  </div>
                  <div class="label-container" style="width: 45%">
                    <span class="label" style="margin-top: 31px">Camera Group</span>
                  </div>
                  <div class="label-container" style="font-weight: 400">
                    <span class="label" style="margin-top: 31px">{{ cameraGroupId }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="org-dialog-tab-content-wrap" v-if="activeTab === 'conditions'">
              <div class="org-dialog-section-wrap">
                <div class="org-dialog-section">
                  <div class="label-container">
                    <span class="label" style="margin-top: 33px">PTZ Value</span>
                    <span class="label" style="margin-top: 31px">Image Quality</span>
                    <span class="label" style="margin-top: 31px">Night Detection</span>
                    <span class="label" style="margin-top: 31px">IR Filter</span>
                    <span class="label" style="margin-top: 31px">Serviced</span>
                    <span class="label" style="margin-top: 31px">Evaluated</span>
                    <span class="label" style="margin-top: 31px">Internal Usage</span>
                    <span class="label" style="margin-top: 31px">Within HFTD Area</span>
                    <span class="label" style="margin-top: 31px">Pan / Fixed</span>
                    <span class="label" style="margin-top: 31px">Camera Cleanness</span>
                    <span class="label" style="margin-top: 31px">Sky Area</span>
                    <span class="label" style="margin-top: 31px">False Positive Areas</span>
                  </div>
                  <div class="label-container" style="margin-left: 0; width: 300px">
                    <span class="label" style="margin-top: 33px"><CustomToolTip :value="ptzValue" /></span>
                    <span class="label" style="margin-top: 31px"><CustomToolTip :value="imageQuality" /></span>
                    <span class="label" style="margin-top: 31px"><CustomToolTip :value="nightDetection" /></span>
                    <span class="label" style="margin-top: 31px"><CustomToolTip :value="irFilter" /></span>
                    <span class="label" style="margin-top: 31px"><CustomToolTip :value="serviced" /></span>
                    <span class="label" style="margin-top: 31px"><CustomToolTip :value="evaluated" /></span>
                    <span class="label" style="margin-top: 31px"><CustomToolTip :value="internalUsage" /></span>
                    <span class="label" style="margin-top: 31px"><CustomToolTip :value="withinHftdArea" /></span>
                    <span class="label" style="margin-top: 31px"><CustomToolTip :value="panOrFixed" /></span>
                    <span class="label" style="margin-top: 31px"><CustomToolTip :value="cameraCleanness" /></span>
                    <span class="label" style="margin-top: 31px"><CustomToolTip :value="skyArea" /></span>
                    <span class="label" style="margin-top: 31px"><CustomToolTip :value="falsePositiveAreas" /></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-end" style="padding-right: 30px; padding-bottom: 20px; margin-top: 20px">
          <button v-ripple @click="closeModal" class="org-dialog-button ok">{{ okText }}</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Input from '@common/signup/Input'
import DropdownNormal from '@desktop/superadminOrWorker/settings/DropdownNormal'
import CustomToolTip from '@desktop/superadminOrWorker/settings/camera-list/CustomToolTip'
import {
  YES_OR_NO_OPTION,
  CONDITION_OPTION,
  IMAGE_QUALITY,
  HFTD_AREA,
  CAMERA_CLEARNESS,
  PAN_OPTION,
  SKY_AREA,
} from '@desktop/superadminOrWorker/settings/camera-list/feature/options'

export default {
  name: 'DialogCameraDetail',
  components: { Input, DropdownNormal, CustomToolTip },
  props: {
    value: Boolean,
    /*camera: {
      type: Object,
      default: () => ({
        information: {
          name: '',
        },
        condition: {},
      }),
    },*/
    /**
     * emit events
     * ok
     */
  },
  data() {
    return {
      openUrlContents: false,
      activeTab: 'information',
      titleText: 'Detail Information',
      okText: 'Confirm',
      YES_OR_NO_OPTION,
      CONDITION_OPTION,
      IMAGE_QUALITY,
      HFTD_AREA,
      CAMERA_CLEARNESS,
      PAN_OPTION,
      SKY_AREA,
    }
  },
  computed: {
    ...mapState('superadmin', {
      timezoneList: (state) => state.timezoneList,
      contractList: (state) => state.contract_types,
      camera: (state) => state.camera,
      countries: (state) => state.countries,
      fullRegions: (state) => state.fullRegions,
      fullCounties: (state) => state.fullCounties,
      cameraProviders: (state) => state.providers,
      apiKeys: (state) => state.apiKeys,
    }),
    coordinate() {
      const coordinateText = this.information.coordinate
      if (!coordinateText.length) return '-'
      else if (coordinateText.indexOf(', ') > -1) return coordinateText
      else return coordinateText.replace(' ', ', ')
    },
    information() {
      return this.camera.information
    },
    cameraGroupId() {
      return this.camera.information.monitored ? this.camera.information.camera_group_id : '-'
    },
    condition() {
      return this.camera.condition
    },
    countryText() {
      return this.countries.filter((country) => country.id === this.information?.country_id)[0]?.name
    },
    regionText() {
      return this.fullRegions.filter((region) => region.id === this.information?.region_id)[0]?.name
    },
    countyText() {
      return this.fullCounties.filter((county) => county.id === this.information?.county_id)[0]?.name
    },
    apiKeyText() {
      const apiKeyInfo = this.apiKeys.filter((apiKey) => apiKey.id === this.information?.api_key_id)[0]
      return apiKeyInfo ? `${apiKeyInfo.key} ${apiKeyInfo.created_at}` : '-'
    },
    cameraProviderText() {
      return this.cameraProviders.filter((provider) => provider.id === this.information?.provider_id)[0]?.name
    },
    ptzValue() {
      return this.YES_OR_NO_OPTION[this.condition.ptz_value - 1]
    },
    imageQuality() {
      return this.IMAGE_QUALITY.filter((quality) => quality.value === this.condition.image_quality)[0]?.text
    },
    nightDetection() {
      return this.YES_OR_NO_OPTION[this.condition.night_detection - 1]
    },
    irFilter() {
      return this.condition.night_detection === 1 ? this.YES_OR_NO_OPTION[this.condition.ir_filter - 1] : '-'
    },
    serviced() {
      return this.YES_OR_NO_OPTION[this.condition.serviced - 1]
    },
    evaluated() {
      return this.YES_OR_NO_OPTION[this.condition.evaluated - 1]
    },
    internalUsage() {
      return this.YES_OR_NO_OPTION[this.condition.internal_usage - 1]
    },
    withinHftdArea() {
      return this.HFTD_AREA.filter((data) => data.value === this.condition.within_hftd_area)[0]?.text
    },
    panOrFixed() {
      return this.PAN_OPTION[this.condition.pan_or_fixed - 1]
    },
    cameraCleanness() {
      return this.CAMERA_CLEARNESS.filter((clearness) => clearness.value === this.condition.camera_cleanness)[0]?.text
    },
    skyArea() {
      return this.SKY_AREA.filter((skyArea) => skyArea.value === this.condition.sky_area)[0]?.text
    },
    falsePositiveAreas() {
      return this.YES_OR_NO_OPTION[this.condition.false_positive_areas - 1]
    },
  },
  async created() {
    this.$log.debug('DialogDetail#created')
    const providerId = this.information?.provider_id
    if (providerId) await this.getApiKeys(providerId)
  },
  methods: {
    ...mapActions('superadmin', ['getApiKeys']),
    async closeModal() {
      this.$emit('close')
    },
    onClickLink(link) {
      if (link) window.open(link)
    },
    onOpenUrlContent() {
      this.openUrlContents = !this.openUrlContents
    },
  },
}
</script>

<style lang="scss" scoped>
.org-dialog-title {
  padding: 30px 40px 0;
  font-weight: bold;
  font-size: 20px;
  /*line-height: 27px;*/
  color: #3d51fb;
}

.org-dialog-content {
  padding: 30px 40px 0;
  display: flex;
  height: calc(100% - 130px);
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  flex-direction: column;
  color: #363636;

  .org-dialog-tab {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;

    .org-dialog-tab-item {
      margin-left: 20px;

      &:not(.active) {
        cursor: pointer;
      }

      &:nth-of-type(1) {
        margin-left: 0;
      }

      .tab-wrap {
        .tab-title {
          padding: 4px 5px;
          color: #d9d9d9;
        }

        .tab-title-bottom-border {
          width: 100%;
          height: 4px;
          border-radius: 4px 4px 0px 0px;
          border: 2px solid #d9d9d9;
          background-color: #d9d9d9;
        }
      }

      &.active {
        .tab-title {
          color: #3d51fb;
        }

        .tab-title-bottom-border {
          border-color: #3d51fb;
          background-color: #3d51fb;
        }
      }
    }
  }

  .org-dialog-tab-content {
    overflow-x: hidden;
    overflow-y: auto;

    .org-dialog-tab-content-wrap {
      display: flex;
      flex-direction: column;

      .org-dialog-section {
        display: flex;
        flex-direction: row;

        &.url-section {
          flex-direction: column;
          margin-top: 20px;
          padding: 20px 0;
          border-top: solid 2px #f9f9f9;
          border-bottom: solid 2px #f9f9f9;

          .url-button {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }

        .label-container {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          width: 150px;
        }
      }
    }
  }
}

.value {
  white-space: nowrap;
  color: #363636;

  &.dark {
    color: var(--f-primary-50);
  }

  &.handle-text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    min-width: 125px;
    text-align: end;
  }
}

.org-dialog-button {
  font-weight: 500;
  font-size: 15px;
  width: 120px;
  height: 38px;
  border-radius: 30px;

  &.ok {
    background-color: #3d51fb;
    color: var(--v-textCount);
  }

  &.cancel {
    background-color: var(--v-backgroundButtonCancel-base);
    color: var(--v-textButtonCancel);
    margin-right: 15px;
  }
}

.url-contents-wrap {
  display: flex;
  flex-direction: row;
  margin-top: 9px;
  padding: 20px;
  background-color: rgba(217, 217, 217, 0.2);
  border-radius: 8px;

  > .label-container {
    margin-left: 0 !important;
    margin-top: 0;

    .label {
      color: #363636;
      &.link {
        display: flex;
      }
      .goto {
        cursor: pointer;
      }
    }

    &:nth-of-type(2) {
      > .label {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.caret-blue-item {
  > img {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  &.active > img {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
</style>

<!-- global style -->
<style lang="scss">
.dialog-camera-detail {
  height: 90%;
}
</style>
