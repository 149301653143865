import FileSaver from 'file-saver'
import Excel from 'exceljs'
import Stream from 'stream'
import Encoding from 'encoding-japanese'
import Vue from 'vue'

let workbook
let sheet

function createWorkBook() {
  workbook = new Excel.Workbook()
  workbook.creator = 'Alchera'
  workbook.lasModifiedBy = 'FireScout'
  workbook.created = new Date()
  workbook.modified = workbook.created
}

function createSheet(fileName) {
  let options = {
    properties: {
      defaultColWidth: 14,
    },
  }
  sheet = workbook.addWorksheet(fileName, options)
}

function createRowCell(rowData) {
  sheet.addRow([
    'Camera Information',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Camera Conditions',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ])
  sheet.addRow([
    'ID',
    'Camera Name',
    'API URL',
    'Public URL',
    'Private URL',
    'Private URL 2',
    'Guide URL',
    'Latitude',
    'Longitude',
    'Altitude(m)',
    'Country',
    'Region',
    'County',
    'Camera Provider',
    'API Key',
    'Monitored',
    'Camera Group',
    'PTZ Value',
    'Image Quality',
    'Night Detection',
    'IR Filter',
    'Serviced',
    'Evaluated',
    'Internal Usage',
    'Within HFTD Area',
    'Pan/Fixed',
    'Camera Cleanness',
    'Sky Area',
    'False Positive Area',
  ])
  sheet.mergeCells('A1:Q1')
  sheet.mergeCells('R1:AC1')

  rowData.forEach((row, rIndex) => {
    // Vue.$log.debug('download data', rIndex, row)
    sheet.addRow([
      // No.
      row.CAMERA_ID || '-',
      // Camera Name
      row.CAMERA_NAME || '',
      // API URL
      row.API_URL || '',
      // Public URL
      row.PUBLIC_URL || '',
      // Private URL
      row.PRIVATE_URL_1 || '',
      // Private URL 2
      row.PRIVATE_URL_2 || '',
      // Guide URL
      row.GUIDE_URL || '',
      // Latitude
      row.LATITUDE || '',
      // Longitude
      row.LONGITUDE || '',
      // Altitude (m)
      row.ALTITUDE || '',
      // Country
      row.COUNTRY || '',
      // Region
      row.REGION || '',
      // County
      row.COUNTY || '',
      // Camera Provider
      row.CAMERA_PROVIDER || '',
      // API Key
      row.API_KEY || '',
      // Monitored
      row.MONITORED || '',
      // Camera Group
      row.CAMERA_GROUP || '',
      // PTZ Value
      row.PTZ_VALUE || '',
      // Image Quality
      row.IMAGE_QUALITY || '',
      // Night Detection
      row.NIGHT_DETECTION || '',
      // IR Filter
      row.IR_FILTER || '',
      // Serviced
      row.SERVICED || '',
      // Evaluated
      row.EVALUATED || '',
      // Internal Usage
      row.INTERNAL_USAGE || '',
      // Within HFTD Area
      row.WITHIN_HFTD_AREA || '',
      // Pan/Fixed
      row.PAN_FIXED || '',
      // Camera Cleanness
      row.CAMERA_CLEANNESS || '',
      // Sky Area
      row.SKY_AREA || '',
      // False Positive Area
      row.FALSE_POSITIVE_AREA || '',
    ])
  })
}

function setStyles(sheetType) {
  // switch (sheetType) {
  //   case 'Camera List':
  //     break
  //   case 'Dropdown':
  //     break
  //   default:
  //     break
  // }
  sheet.getColumn(1).alignment = {
    horizontal: 'left',
  }
}

function downloadFile(fileName) {
  workbook.xlsx.writeBuffer().then((buffer) => {
    FileSaver.saveAs(new Blob([buffer]), fileName)
  })
}

function writeErrorDataToFile(fileName, rowData) {
  createWorkBook()
  createSheet('Camera List')
  createErrorDataRowCell(rowData)
  setErrorDataStyles('Camera List')
  downloadFile(fileName)
}

function createErrorDataRowCell(rowData) {
  sheet.addRow(['Camera ID', 'No.', 'Error Message'])
  rowData.forEach((row, rIndex) => {
    sheet.addRow([row.no, row.rowIndex, row.errorText])
  })
}

function setErrorDataStyles() {
  sheet.getColumn(3).width = 30
  sheet.getColumn(1).alignment = {
    horizontal: 'left',
  }
}

function writeDataToFile(fileName, rowData) {
  createWorkBook()
  createSheet('Camera List')
  createRowCell(rowData)
  setStyles('Camera List')
  downloadFile(fileName)
}

function writeSheetData(sheetName, rowData) {
  createSheet(sheetName)
  createRowCell(rowData)
  setStyles(sheetName)
}

function makeData(sheet) {
  let data = []
  let rowNo = 0
  sheet.eachRow((row) => {
    let rowData = []
    let colNo = 0
    row.eachCell({ includeEmpty: true }, (cell) => {
      if (typeof cell.model.value === 'object') {
        if (cell.value instanceof Date) {
          rowData.push(cell.value)
        } else {
          let result = ''
          if (cell.model.value.richText) {
            cell.model.value.richText.map((n) => {
              result += n.text
            })
          }
          rowData.push(result)
        }
      } else {
        if (cell.model.value === 0 || cell.model?.value) {
          rowData.push(cell.model.value)
        } else {
          rowData.push(cell.model.text)
        }
      }
      colNo += 1
    })
    if (rowData.join('') !== '') {
      data.push(rowData)
    }
    rowNo += 1
  })
  return data
}

function readXlsxFile(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onloadend = (e) => {
      Promise.resolve(e.target.result)
        .then((buffer) => {
          workbook = new Excel.Workbook()
          workbook.xlsx
            .load(buffer)
            .then(function () {
              let worksheets = []
              workbook.eachSheet((sheet) => {
                worksheets.push(makeData(sheet))
              })
              resolve(worksheets)
            })
            .catch((err) => {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    }
    reader.readAsArrayBuffer(file)
  })
}
function readCsvFile(file) {
  return new Promise((resolve, reject) => {
    workbook = new Excel.Workbook()
    const reader = new FileReader()
    reader.onload = () => {
      const sjisArray = new Uint8Array(reader.result)
      var unicodeArray = Encoding.convert(sjisArray, {
        to: 'UNICODE',
        from: Encoding.detect(sjisArray),
      })
      var unicodeString = Encoding.codeToString(unicodeArray)
      let stream = new Stream.Readable()
      stream.push(unicodeString)
      stream.push(null)
      workbook.csv
        .read(stream)
        .then((worksheet) => {
          resolve(makeData(worksheet))
        })
        .catch((err) => {
          reject(err)
        })
    }
    reader.readAsArrayBuffer(file)
  })
}

export { createWorkBook, writeErrorDataToFile, writeDataToFile, writeSheetData, downloadFile, readXlsxFile, readCsvFile }
