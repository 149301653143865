<template>
  <v-tooltip :disabled="isOverflow" :content-class="`station-tooltip`" left nudge-top="30" nudge-right="300">
    <template v-slot:activator="{ on, attrs }">
      <div ref="station" :class="`value handle-text-overflow`" v-bind="attrs" v-on="on" @mouseover.stop.prevent="overflowCheck">
        {{ nullOrBlankToDash(value) }}
      </div>
    </template>
    <div>{{ nullOrBlankToDash(value) }}</div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '-',
    },
  },
  data() {
    return {
      isOverflow: true,
    }
  },
  methods: {
    overflowCheck() {
      const offsetWidth = this.$refs.station.offsetWidth
      const scrollWidth = this.$refs.station.scrollWidth
      if (offsetWidth !== scrollWidth) this.isOverflow = false
      else this.isOverflow = true
    },
    nullOrBlankToDash(value) {
      return !value || value.trim() === '' ? '-' : value
    },
  },
}
</script>

<style scoped>
.handle-text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  min-width: 125px;
  white-space: nowrap;
  color: #363636;
  font-weight: 400;
}
</style>
