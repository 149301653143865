<template>
  <button v-ripple @click="$emit('click')" :class="{ disabled }" :disabled="disabled" :style="style">
    <img v-if="prependIcon && disabled" :src="prependIconDisabled" :style="prependIconStyle" alt="prependIcon" width="16" height="16" />
    <img v-else-if="prependIcon" :src="prependIcon" :style="prependIconStyle" alt="prependIcon" width="16" height="16" />
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    prependIcon: String,
    prependIconStyle: String,
    prependIconDisabled: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    style: String,
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 34px;
  background-color: white;
  border-radius: 30px;
  font-weight: 500;
  font-size: 15px;
  color: #363636;

  &.disabled {
    color: #00000042;
    background-color: #0000001f;
  }

  img {
    margin-right: 10px;
  }
}
</style>
