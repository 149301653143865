<template>
  <div>
    <div v-if="requestedList.length">
      <div
        v-for="(review, index) in requestedList"
        :key="index"
        :class="{ review: true, select: selectReviewId === review.id }"
        @click="onClickReview(review.id, review.user.username, review.subject, review.user.userID, review.recipients)"
      >
        <div class="review-subject">{{ review.subject }}</div>
        <div class="wrapper">
          <div class="review-request-name">requested by {{ review.user.username }}</div>
          <div class="review-request-time">{{ requestTime(review.time_point) }}</div>
        </div>
      </div>
    </div>
    <div v-else style="flex-grow: 1">
      <NoDataItem />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import NoDataItem from '@desktop/superadminOrWorker/settings/camera-list/cameraListTab/NoDataItem'
export default {
  components: {
    NoDataItem,
  },
  props: {
    currentSelectedId: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      selectReviewId: -1,
    }
  },
  created() {
    this.selectReviewId = this.currentSelectedId
  },
  computed: {
    ...mapState({
      changeRequestRequestList: (state) => state.superadmin.changeRequestRequestList,
      userId: (state) => state.user.id,
    }),
    requestedList() {
      return this.changeRequestRequestList
    },
  },
  methods: {
    ...mapActions('superadmin', ['getSelectedRequestTableAction']),
    async onClickReview(id, userName, subject, ownerId, recipients) {
      this.selectReviewId = id
      const requestedToMe = this.checkRequestRecipent(recipients)
      await this.getSelectedRequestTableAction({ id, userName, subject, authority: ownerId !== this.userId && requestedToMe })
    },
    checkRequestRecipent(recipients) {
      for (let i = 0; i < recipients.length; i++) if (recipients[i].user.userID === this.userId) return true
      return false
    },
    requestTime(time) {
      return moment(time).format('YYYY/MM/DD HH:mm:ss')
    },
  },
}
</script>

<style lang="scss" scoped>
.review {
  background: rgba(233, 235, 255, 0.24);
  width: 472px;
  height: 87px;
  padding: 16px 24px;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 16px;
  &:hover {
    background: linear-gradient(0deg, rgba(109, 125, 255, 0.1), rgba(109, 125, 255, 0.1)), rgba(233, 235, 255, 0.24);
  }
  &.select {
    background: linear-gradient(0deg, rgba(109, 125, 255, 0.1), rgba(109, 125, 255, 0.1)),
      linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), rgba(233, 235, 255, 0.24);
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
  }
}
.review-subject {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--f-text-black);
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}
.review-request-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
.review-request-time {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
</style>
