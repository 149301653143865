import { render, staticRenderFns } from "./DialogError.vue?vue&type=template&id=eb2ee89e&scoped=true&"
import script from "./DialogError.vue?vue&type=script&lang=js&"
export * from "./DialogError.vue?vue&type=script&lang=js&"
import style0 from "./DialogError.vue?vue&type=style&index=0&id=eb2ee89e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb2ee89e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VCardText,VDialog})
