<template>
  <v-menu v-model="open" offset-y nudge-bottom="5px" :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" :class="{ 'filter-container': true, disabled }">
        <span class="text-truncate" style="margin-left: 20px">{{ value }}</span>
        <v-spacer></v-spacer>
        <img v-if="disabled" src="@/assets/icon-arrow-down-disabled.svg" width="10px" />
        <img v-else-if="open" src="@/assets/icon-arrow-up-black.svg" width="10px" />
        <img v-else src="@/assets/icon-arrow-down-black.svg" width="10px" />
      </div>
    </template>
    <div class="item-container">
      <div class="wrapper">
        <div v-for="(item, index) in items" :key="index" @click="onClickItem(item)" class="item">
          {{ item }}
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: String,
    items: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {},
  created() {},
  methods: {
    onClickItem(item) {
      this.$emit('input', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-container {
  display: flex;
  align-items: center;
  width: 100px;
  height: 34px;
  background-color: white;
  border-radius: 30px;
  font-weight: 500;
  font-size: 15px;
  color: #363636;

  &.disabled {
    color: #00000042;
    background-color: #0000001f;
  }

  img {
    margin-right: 15px;
  }
}

.item-container {
  max-height: 136px;
  border-radius: 10px;
  background-color: white;
  padding: 10px 0;
  overflow: hidden;

  .wrapper {
    display: flex;
    flex-direction: column;
    max-height: calc(136px - 20px);
    overflow: auto;
  }

  .item {
    display: flex;
    align-items: center;
    height: 29px;
    font-size: 14px;
    padding: 0 20px;
    color: #363636;
    cursor: pointer;

    &:hover {
      color: white;
      background: #6d7dff;
    }
  }
}
</style>
