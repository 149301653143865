<template>
  <v-dialog v-model="value" width="520px" persistent>
    <v-card style="background-color: white">
      <v-card-text class="pa-0">
        <div class="user-add-dialog-title">{{ titleText }}</div>
        <div class="user-add-dialog-content">
          <div class="label-container">
            <span class="label" style="margin-top: 33px">Organization</span>
            <span class="label" style="margin-top: 31px">User Role</span>
            <span class="label" style="margin-top: 31px">ID</span>
            <span class="label" style="margin-top: 31px">Password</span>
          </div>
          <div class="label-container" style="margin-left: 0; width: 300px">
            <div style="margin-top: 30px">
              <DropdownNormal
                ref="organization"
                v-model="organization"
                :items="organizationList"
                :rules="rules"
                :error="organizationError"
                maxWidth="300px"
                placeholder="Organization"
              ></DropdownNormal>
            </div>
            <div style="margin-top: 25px">
              <DropdownNormal ref="role" v-model="role" :items="roleList" :rules="rules" :error="roleError" placeholder="User Role"></DropdownNormal>
            </div>
            <Input
              ref="username"
              v-model="username"
              :transform="transformUsername"
              :rules="usernameRules"
              placeholder="ID"
              style="margin-top: 25px"
            ></Input>
            <InputPassword ref="password" v-model="password" :infoMessage="infoMessage" :icon="true" style="margin-top: 9px"></InputPassword>
          </div>
        </div>
        <div class="d-flex justify-end" style="padding-right: 30px; padding-bottom: 20px">
          <button v-ripple @click="hide" class="user-add-dialog-button cancel">Cancel</button>
          <button v-ripple @click="ok" class="user-add-dialog-button ok">{{ okText }}</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Constants from '@/constants'
import Input from '@common/signup/Input'
import InputPassword from '@common/signup/InputPassword'
import DropdownNormal from '@desktop/superadminOrWorker/settings/DropdownNormal'
import { mapState, mapActions } from 'vuex'
import { superadmin } from '@/api'
import rules from '@/rules'

export default {
  components: { Input, DropdownNormal, InputPassword },
  props: {
    value: Boolean,
    /**
     * emit events
     * ok
     */
  },
  data() {
    const self = this
    return {
      titleText: 'Add User ID',
      okText: 'Add',
      organization: '',
      organizationError: false,
      role: '',
      roleList: [Constants.ROLE_ADMIN, Constants.ROLE_VIEWER],
      roleMap: {},
      roleError: false,
      username: '',
      password: '',
      rules: [(v) => !!v || 'Please fill out this field.'],
      nameTimeout: null,
      isDuplicatedName: false,
      isDeletedName: false,
      usernameRules: [
        (v) => !!v || 'Please fill out this field.',
        () => !self.isDuplicatedName || 'This ID already exists.',
        () => !self.isDeletedName || 'This ID is no longer available',
      ],
      transformUsername: (v) => {
        if (rules.regxSpecial.test(v)) {
          v = v.replaceAll(rules.regxSpecialGlobal, '')
        }
        if (rules.uppercase.test(v)) {
          v = v.replaceAll(rules.uppercaseGlobal, '')
        }
        return v
      },
      infoMessage: 'Use at least 8 characters. Include both an uppercase letter, lowercase letter, number and a symbol.',
    }
  },
  computed: {
    ...mapState('superadmin', {
      organizations: (state) => state.organizations,
    }),
    organizationList() {
      return this.organizations.map((v) => v.name)
    },
  },
  async created() {
    this.organization = ''
    this.role = ''
    this.username = ''
    this.password = ''
    await this.getOrganizations()
    const list = await this.getUserRoleListAction()
    list.forEach((role) => {
      this.roleMap[role.name] = role.id
    })
    this.roleList = list.map((role) => role.name)
  },
  watch: {
    username(val) {
      if (val) {
        this.checkDuplicateName()
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.nameTimeout)
  },
  methods: {
    ...mapActions('superadmin', ['getOrganizations', 'getUserRoleListAction']),
    hide() {
      this.$emit('input', false)
    },
    checkDuplicateName() {
      clearTimeout(this.nameTimeout)
      this.nameTimeout = setTimeout(async () => {
        const { duplicated, deleted } = await superadmin.checkUserId(this.username)
        if (this.isEdit) {
          this.isDuplicatedName = this.data.user_id !== this.username && duplicated
        } else {
          this.isDuplicatedName = duplicated
        }
        this.isDeletedName = deleted
        this.$refs.username.validate()
      }, 500)
    },
    async ok() {
      const organizationValid = this.$refs.organization.validate()
      const roleValid = this.$refs.role.validate()
      const usernameValid = this.$refs.username.validate()
      const passwordValid = this.$refs.password.validate()
      if (organizationValid && roleValid && usernameValid && passwordValid) {
        try {
          await this.requestAction()
          this.$emit('ok', true)
          this.hide()
        } catch (error) {
          this.$log.error(error)
          this.$emit('ok', false)
        }
      }
    },
    async requestAction() {
      this.$log.debug('DialogAdd#requestAction', this.organization, this.role, this.username, this.password)
      const organization = this.organizations.find((v) => v.name === this.organization)
      await superadmin.createUser({
        organization_id: organization.id,
        user_role_id: this.roleMap[this.role],
        user_id: this.username,
        password: this.password,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user-add-dialog-title {
  padding: 30px 40px 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #3d51fb;
}

.user-add-dialog-content {
  display: flex;
  height: 282px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #363636;

  .label-container {
    display: flex;
    flex-direction: column;
    margin-left: 51px;
    width: 118px;
  }
}

.user-add-dialog-button {
  font-weight: 500;
  font-size: 15px;
  width: 120px;
  height: 38px;
  border-radius: 30px;

  &.ok {
    background-color: #3d51fb;
    color: var(--v-textCount);
  }

  &.cancel {
    background-color: var(--v-backgroundButtonCancel-base);
    color: var(--v-textButtonCancel);
    margin-right: 15px;
  }
}
</style>
