<template>
  <v-dialog v-model="value" width="500px" @keydown.esc="onClickClose" scrollable persistent>
    <v-card>
      <v-card-text class="d-flex pa-0 ma-0">
        <div class="container">
          <div class="text-title">{{ title }}</div>
          <div class="text-info" v-html="info"></div>
          <div class="control-container">
            <button @click="onClickOk" class="ok">확인</button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: Boolean,
    /**
     * emit events
     * result Boolean
     */
  },
  computed: {
    title() {
      return '작업 시간 확인'
    },
    info() {
      return `작업 시간이 아닙니다.<br />작업 시간 확인 후 재접속해주세요.`
    },
  },
  methods: {
    onClickClose() {
      this.$emit('input', false)
    },
    async onClickOk() {
      this.onClickClose()
      this.$emit('result', true)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.text-title {
  font-weight: bold;
  font-size: 20px;
  color: #3d51fb;
  margin-top: 30px;
  margin-left: 40px;
  height: 27px;
}

.text-button {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 150px;
  height: 40px;
  background-color: #3d51fb;
  border-radius: 30px;
  font-size: 15px;
  color: white;
  margin-top: 33px;
}

.text-info {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #363636;
  flex-grow: 1;
}

.control-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  margin-bottom: 20px;
}

button {
  width: 120px;
  height: 38px;
  border-radius: 30px;
  font-size: 15px;

  &.cancel {
    background-color: #d8d9da;
    color: #484e60;
    margin-right: 15px;
  }

  &.ok {
    background-color: #3d51fb;
    color: white;
  }
}
</style>
